/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHatChef} from '@fortawesome/pro-duotone-svg-icons';

const MealIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHatChef} {...props} />
);

export default MealIcon;
