/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {useField} from 'formik';
import FieldWrapperInline from './FieldWrapperInline';
import {Form} from 'react-bootstrap';

const ToggleField = ({type, label, ...props}, ref) => {
  const [field, meta] = useField({...props});
  return (
    <FieldWrapperInline label={label} meta={meta} {...props}>
      <Form.Switch
        label={' '}
        checked={field.value}
        {...field}
        {...props}
        id={`custom-switch ${props.id}`} // necessary
      />
    </FieldWrapperInline>
  );
};
ToggleField.displayName = 'ToggleField';

export default forwardRef(ToggleField);
