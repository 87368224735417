/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import TimesheetsIcon from 'icons/TimesheetsIcon';
import BillingPeriodsItem from './timesheets/BillingPeriodsItem';
import ProgramsItem from './timesheets/ProgramsItem';
import ReviewTimesheetsItem from './timesheets/ReviewTimesheetsItem';
import SummaryItem from './timesheets/SummaryItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.timesheets'},
  sectionTitle: {id: 'navigation.settings.timesheets-settings'},
});

const TimesheetsMenu = () => (
  <NavigationMenu
    iconBefore={TimesheetsIcon}
    route="midas/settings/timesheets"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ReviewTimesheetsItem />
    <SummaryItem />
    <BillingPeriodsItem />
    <ProgramsItem />
  </NavigationMenu>
);

export default TimesheetsMenu;
