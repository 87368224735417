/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsersClass} from '@fortawesome/pro-duotone-svg-icons';

const StudentsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faUsersClass} {...props} />
);

export default StudentsIcon;
