/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';
import {wrapNonLocalizedMessageUnlessEmpty} from 'app/intl';
import {useUserContext} from 'app/UserContext';
import {useMidasJsonApi} from 'app/hooks';
import NavigationMenu from './NavigationMenu';
import AutomatedSchedulerItem from './event/AutomatedSchedulerItem';
import EducationLeadersItem from './event/EducationLeadersItem';
import EventDetailsItem from './event/EventDetailsItem';
import IndustryLeadersItem from './event/IndustryLeadersItem';
import MeetingGridItem from './event/MeetingGridItem';
import MeetingListItem from './event/MeetingListItem';
import MeetingsItem from './event/MeetingsItem';
import MyMeetingsItem from './event/MyMeetingsItem';
import RequestsItem from './event/RequestsItem';
import StatisticsItem from './event/StatisticsItem';

const useEventName = (eventID) => {
  const [response, , , request] = useMidasJsonApi({
    url: `/midas/rest/event/${eventID}?fields=name`,
    expectedStatus: [200, 403, 404],
    immediate: false,
  });

  useEffect(() => {
    if (eventID) {
      request();
    }
  }, [eventID, request]);

  return eventID && response.status === 200
    ? response.json.event.name
    : `Event ${eventID}`;
};

const EventMenu = () => {
  const [{currentEventID}] = useUserContext();
  const title = useEventName(currentEventID);

  return (
    <NavigationMenu
      route="midas/event"
      title={wrapNonLocalizedMessageUnlessEmpty(title)}
      detached
    >
      <EventDetailsItem />
      <EducationLeadersItem />
      <IndustryLeadersItem />
      <MeetingsItem />
      <MyMeetingsItem />
      <RequestsItem />
      <MeetingListItem />
      <MeetingGridItem />
      <AutomatedSchedulerItem />
      <StatisticsItem />
    </NavigationMenu>
  );
};

export default EventMenu;
