/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.curricula'},
});

const CurriculaItem = () => (
  <RouteLinkItem
    route="midas/microcredentialing/curricula-and-pathways/curricula"
    title={messages.title}
  />
);

export default CurriculaItem;
