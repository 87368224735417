/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {lazy} from 'react';
import {Route, Switch} from 'react-router-dom';
import {pathFromRoute} from './routing';
import MidasLayout from 'app/MidasLayout';
import MidasServerSideRequest from 'components/MidasServerSideRequest';
import Login from 'views/Login';
import NotFound from 'views/NotFound';
import SelfRegister from 'views/self-registration/SelfRegister';
import {PublicCatalogContextProvider} from 'app/PublicCatalogContext';
import Curriculum from 'views/Curriculum';
import Pathway from 'views/Pathway';

const Microcredentials = lazy(() => import('../views/Microcredentials'));
const MicrocredentialDetail = lazy(() => import('../views/Microcredential'));
const ProfessionalDevelopment = lazy(() =>
  import('../views/ProfessionalDevelopment')
);
const Rims = lazy(() => import('../views/Rims'));

const AppRoutes = () => (
  <Switch>
    <Route path={pathFromRoute('midas')}>
      <MidasLayout />
    </Route>
    <Route path={pathFromRoute('curricula/detail')} exact strict>
      <PublicCatalogContextProvider>
        <Curriculum />
      </PublicCatalogContextProvider>
    </Route>
    <Route path={pathFromRoute('pathways/detail')} exact strict>
      <PublicCatalogContextProvider>
        <Pathway />
      </PublicCatalogContextProvider>
    </Route>
    <Route path={pathFromRoute('microcredentials')} exact strict>
      <PublicCatalogContextProvider>
        <Microcredentials />
      </PublicCatalogContextProvider>
    </Route>
    <Route path={pathFromRoute('microcredentials/detail')} exact strict>
      <MicrocredentialDetail />
    </Route>
    <Route path={pathFromRoute('professional-development')}>
      <ProfessionalDevelopment />
    </Route>
    <Route path={pathFromRoute('rims')}>
      <Rims />
    </Route>
    <Route path={pathFromRoute('login')}>
      <Login />
    </Route>
    <Route path={pathFromRoute('self-register')}>
      <SelfRegister />
    </Route>
    <Route path="/(\d+__school_.+|.+\.php)">
      <MidasServerSideRequest />
    </Route>
    <NotFound />
  </Switch>
);

export default AppRoutes;
