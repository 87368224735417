/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import AtlaskitIcon from '@atlaskit/icon';

const style = {
  fontSize: '20px',
};

const IconBefore = ({Icon, label}) => {
  const Glyph = () => <Icon style={style} fixedWidth />;
  return <AtlaskitIcon glyph={Glyph} label={label} />;
};

export default IconBefore;
