/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const lastFirstAscSort = (a, b) => {
  const cmp = a.lastName.localeCompare(b.lastName);
  if (cmp !== 0) {
    return cmp;
  }
  return a.firstName.localeCompare(b.firstName);
};

export default lastFirstAscSort;
