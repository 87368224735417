/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.state-reporting.sims'},
});

const SimsItem = () => (
  <StudentRouteLinkItem
    route="midas/student/state-reporting/sims"
    title={messages.title}
  />
);

export default SimsItem;
