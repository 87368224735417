/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const health = {
  '': '/midas/students/health',
  allergens: {
    '': '/midas/students/health/allergens',
    'add-allergen': '/midas/students/health/allergens/add-allergen',
    'add-student': '/midas/students/health/allergens/add-student/:allergenID',
    'edit-student-allergy':
      '/midas/students/health/allergens/edit-student-allergy/:studentID',
    'edit-allergen':
      '/midas/students/health/allergens/edit-allergen/:allergenID',
  },
  'health-visit-log': {
    '': '/midas/students/health/health-visit-log',
    'add-visit': '/midas/students/health/health-visit-log/add-visit',
    'edit-visit': '/midas/students/health/health-visit-log/edit-visit/:visitID',
    'view-visit-details':
      '/midas/students/health/health-visit-log/view-visit-details/:visitID',
  },
  guidance: {
    '': '/midas/students/health/guidance',
    'add-guidance': '/midas/students/health/guidance/add-guidance',
    'edit-guidance':
      '/midas/students/health/guidance/edit-guidance/:guidanceID',
  },
  'medication-log': {
    '': '/midas/students/health/medication-log',
    'add-scheduled-medication':
      '/midas/students/health/medication-log/add-scheduled-medication',
    'edit-scheduled-medication':
      '/midas/students/health/medication-log/edit-scheduled-medication/:medicationID/:instanceID',
    'view-scheduled-medication-details':
      '/midas/students/health/medication-log/view-scheduled-medication-details/:medicationID/:instanceID',
    'edit-record':
      '/midas/students/health/medication-log/edit-record/:scheduleID',
  },
  'health-screenings': {
    '': '/midas/students/health/health-screenings',
    'add-screening': '/midas/students/health/health-screenings/add-screening',
    'edit-screening':
      '/midas/students/health/health-screenings/edit-screening/:screeningID',
    'view-screening-details':
      '/midas/students/health/health-screenings/view-screening-details/:screeningID',
  },
  immunizations: {
    '': '/midas/students/health/immunizations',
    'edit-student-record':
      '/midas/students/health/immunizations/edit-student-record/:immunizationID',
    'add-dose': '/midas/students/health/immunizations/add-dose/:complianceID',
    'edit-dose': '/midas/students/health/immunizations/edit-dose/:doseID',
    'bulk-add-dose': '/midas/students/health/immunizations/bulk-add-dose',
  },
};

export default health;
