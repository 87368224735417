/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {HeadingItem} from '@atlaskit/side-navigation';
import {useUserContext} from 'app/UserContext';
import {useStudentName} from 'app/hooks';
import NavigationMenu from '../NavigationMenu';
import HealthIcon from 'icons/HealthIcon';
import AllergiesItem from './health/AllergiesItem';
import ConditionsItem from './health/ConditionsItem';
import HealthVisitsItem from './health/HealthVisitsItem';
import ImmunizationsItem from './health/ImmunizationsItem';
import MedicationsItem from './health/MedicationsItem';
import ProvidersItem from './health/ProvidersItem';
import ScreeningsItem from './health/ScreeningsItem';

const messages = defineMessages({
  title: {id: 'navigation.health'},
});

const HealthMenu = () => {
  const [{currentStudentID}] = useUserContext();
  const name = useStudentName(currentStudentID);

  return (
    <NavigationMenu
      iconBefore={HealthIcon}
      route="midas/student/health"
      title={messages.title}
    >
      <HeadingItem>{name}</HeadingItem>
      <HealthVisitsItem />
      <AllergiesItem />
      <MedicationsItem />
      <ImmunizationsItem />
      <ConditionsItem />
      <ProvidersItem />
      <ScreeningsItem />
    </NavigationMenu>
  );
};

export default HealthMenu;
