/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {RouteLink} from 'app/routing';
import BigNewIcon from 'icons/BigNewIcon';
import './BigNewLink.css';

const BigNewLink = ({text, route, params = {}}) => {
  return (
    <RouteLink
      route={route}
      params={params}
      className="BigNewLink font-weight-light"
    >
      <BigNewIcon />
      <FormattedMessage {...text} />
    </RouteLink>
  );
};

export default BigNewLink;
