/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import WrappedFormControl from './WrappedFormControl';

const NumberField = ({as, type, ...props}, ref) => {
  return <WrappedFormControl ref={ref} type="number" {...props} />;
};
NumberField.displayName = 'NumberField';

export default forwardRef(NumberField);
