/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PortfolioIcon from 'icons/PortfolioIcon';

const messages = defineMessages({
  title: {id: 'navigation.portfolio'},
});

const PortfolioItem = () => (
  <RouteLinkItem
    iconBefore={PortfolioIcon}
    route="midas/my/portfolio"
    title={messages.title}
  />
);

export default PortfolioItem;
