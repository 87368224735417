/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {RouteLink} from 'app/routing';
import {Button} from 'react-bootstrap';

const RouteButtonLink = (
  {text, route, params = {}, icon: Icon, ...props},
  ref
) => {
  return (
    <Button ref={ref} as={RouteLink} route={route} params={params} {...props}>
      {Icon && <Icon className="mr-2" />}
      <FormattedMessage {...text} />
    </Button>
  );
};
RouteButtonLink.displayName = 'RouteButtonLink';

export default forwardRef(RouteButtonLink);
