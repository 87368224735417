/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import SchedulerGameBoardIcon from 'icons/SchedulerGameBoardIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.meeting-grid'},
});

const MeetingGridItem = () => (
  <EventRouteLinkItem
    iconBefore={SchedulerGameBoardIcon}
    route="midas/event/meeting-grid"
    title={messages.title}
  />
);

export default MeetingGridItem;
