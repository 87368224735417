/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const calendar = {
  '': '/midas/settings/calendar',
  'auto-setup': {
    '': '/midas/settings/calendar/auto-setup',
  },
  'calendar-year': {
    '': '/midas/settings/calendar/calendar-year',
  },
  terms: {
    '': '/midas/settings/calendar/terms',
    'add-term': '/midas/settings/calendar/terms/add-term',
    'edit-term': '/midas/settings/calendar/terms/edit-term/:termID',
    'select-term': '/midas/settings/calendar/terms/select-term',
  },
  days: {
    '': '/midas/settings/calendar/days',
    'add-day': '/midas/settings/calendar/days/add-day',
    'edit-day': '/midas/settings/calendar/days/edit-day/:dayID',
  },
  periods: {
    '': '/midas/settings/calendar/periods',
    'add-period': '/midas/settings/calendar/periods/add-period',
    'edit-period': '/midas/settings/calendar/periods/edit-period/:periodID',
  },
  'bell-schedules': {
    '': '/midas/settings/calendar/bell-schedules',
    'add-bell-schedule':
      '/midas/settings/calendar/bell-schedules/add-bell-schedule',
    'edit-bell-schedule':
      '/midas/settings/calendar/bell-schedules/edit-bell-schedule/:bellScheduleID',
    'select-period':
      '/midas/settings/calendar/bell-schedules/select-period/:bellScheduleID',
    'edit-period':
      '/midas/settings/calendar/bell-schedules/edit-period/:periodBellID',
  },
  'manual-setup': {
    '': '/midas/settings/calendar/manual-setup',
  },
  'not-in-session': {
    '': '/midas/settings/calendar/not-in-session',
    'add-new-designation':
      '/midas/settings/calendar/not-in-session/add-new-designation',
    'edit-not-in-session':
      '/midas/settings/calendar/not-in-session/edit-not-in-session/:sessionID',
  },
};

export default calendar;
