/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import CourseRequestsIcon from 'icons/CourseRequestsIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.requests'},
});

const RequestsItem = () => (
  <EventRouteLinkItem
    iconBefore={CourseRequestsIcon}
    route="midas/event/requests"
    title={messages.title}
  />
);

export default RequestsItem;
