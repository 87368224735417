/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import ClassroomItem from './menu/ClassroomItem';
import DataExplorerItem from './menu/DataExplorerItem';
import DataIntegrationsMenu from './menu/DataIntegrationsMenu';
import DataToolsMenu from './menu/DataToolsMenu';
import EventsMenu from './menu/EventsMenu';
import FinanceMenu from './menu/FinanceMenu';
import GrantsMenu from './menu/GrantsMenu';
import GuardiansMenu from './menu/GuardiansMenu';
import InstructionalMaterialsMenu from './menu/InstructionalMaterialsMenu';
import MicrocredentialingMenu from './menu/MicrocredentialingMenu';
import PdCatalogItem from './menu/PdCatalogItem';
import ReportsMenu from './menu/ReportsMenu';
import SettingsMenu from './menu/SettingsMenu';
import StaffMenu from './menu/StaffMenu';
import StudentsMenu from './menu/StudentsMenu';
import EducatorMeritPayMenu from './menu/EducatorMeritPayMenu';
import DataForgeMenu from 'components/navigation/side-navigation/menu/DataForgeMenu';
import SelfRegistration from './menu/SelfRegistrationMenu';

const RootMenus = () => (
  <>
    <StudentsMenu />
    <StaffMenu />
    <GuardiansMenu />
    <ClassroomItem />
    <PdCatalogItem />
    <DataExplorerItem />
    <DataIntegrationsMenu />
    <DataForgeMenu />
    <EventsMenu />
    <FinanceMenu />
    <DataToolsMenu />
    <EducatorMeritPayMenu />
    <GrantsMenu />
    <InstructionalMaterialsMenu />
    <MicrocredentialingMenu />
    <ReportsMenu />
    <SelfRegistration />
    <SettingsMenu />
  </>
);

export default RootMenus;
