/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import IconActionButton from './IconActionButton';
import RemoveIcon from 'icons/RemoveIcon';

const messages = defineMessages({
  remove: {id: 'button.remove'},
});

const RemoveActionButton = ({message = messages.remove, ...props}, ref) => (
  <IconActionButton
    ref={ref}
    icon={RemoveIcon}
    message={message}
    variant="outline-danger"
    {...props}
  />
);
RemoveActionButton.displayName = 'RemoveActionButton';

export default forwardRef(RemoveActionButton);
