/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import EventsIcon from 'icons/EventsIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.event-details'},
});

const EventDetailsItem = () => (
  <EventRouteLinkItem
    iconBefore={EventsIcon}
    route="midas/event/view-event"
    title={messages.title}
  />
);

export default EventDetailsItem;
