/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Link} from 'react-router-dom';
import {useUserContext} from 'app/UserContext';
import {useNavigationContext} from 'app/NavigationContext';
import {urlFromRoute} from 'app/routing';
import {useMidasJsonApi} from 'app/hooks';
import {CustomItem} from '@atlaskit/side-navigation';
import IconBefore from 'components/navigation/side-navigation/icon/IconBefore';
import {
  NestingItemIcon,
  nestingItemStyle,
} from 'components/navigation/side-navigation/icon/NestingItemIcon';
import ClassroomIcon from 'icons/ClassroomIcon';

const ClassroomItem = ({targetUserID}) => {
  const [{userID, roles}] = useUserContext();
  const {dismissMobileSidebar} = useNavigationContext();

  const [showItem, setShowItem] = useState(false);

  const [rolesResponse, , , rolesRequest] = useMidasJsonApi({
    url: `/midas/rest/user-roles/${targetUserID}`,
    expectedStatus: [200, 403],
    immediate: false,
  });

  useEffect(() => {
    setShowItem(
      rolesResponse.status === 200 &&
        rolesResponse.json.roles.teacher.length > 0
    );
  }, [rolesResponse]);

  const staffID = targetUserID || userID;

  useEffect(() => {
    if (staffID === userID) {
      setShowItem(roles.teacher.length > 0);
    } else {
      rolesRequest();
    }
  }, [staffID, userID, roles, rolesRequest]);

  if (!showItem) {
    return null;
  }
  return (
    <CustomItem
      component={Link}
      to={urlFromRoute('midas/classroom', {staffID})}
      iconBefore={<IconBefore Icon={ClassroomIcon} />}
      iconAfter={<NestingItemIcon />}
      cssFn={nestingItemStyle}
      onClick={dismissMobileSidebar}
    >
      <FormattedMessage id="navigation.classroom" />
    </CustomItem>
  );
};

export default ClassroomItem;
