/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import EducatorGrowthIcon from 'icons/EducatorGrowthIcon';
import AdministratorsItem from './educator-growth/AdministratorsItem';
import AnalyticsItem from './educator-growth/AnalyticsItem';
import CompetenciesItem from './educator-growth/CompetenciesItem';
import DatesItem from './educator-growth/DatesItem';
import MentorsAndEvaluatorsItem from './educator-growth/MentorsAndEvaluatorsItem';
import PlansItem from './educator-growth/PlansItem';
import ProfessionalLearningCommunitiesItem from './educator-growth/ProfessionalLearningCommunitiesItem';
import ResourcesItem from './educator-growth/ResourcesItem';
import TemplatesItem from './educator-growth/TemplatesItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.educator-growth'},
  sectionTitle: {id: 'navigation.settings.educator-growth-settings'},
});

const EducatorGrowthMenu = () => (
  <NavigationMenu
    iconBefore={EducatorGrowthIcon}
    route="midas/settings/educator-growth"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <PlansItem />
    <CompetenciesItem />
    <MentorsAndEvaluatorsItem />
    <ProfessionalLearningCommunitiesItem />
    <TemplatesItem />
    <AnalyticsItem />
    <DatesItem />
    <ResourcesItem />
    <AdministratorsItem />
  </NavigationMenu>
);

export default EducatorGrowthMenu;
