/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import MasterCatalogIcon from 'icons/MasterCatalogIcon';

const messages = defineMessages({
  title: {id: 'navigation.master-catalog'},
});

const MasterCatalogItem = () => (
  <RouteLinkItem
    iconBefore={MasterCatalogIcon}
    route="midas/students/master-catalog"
    title={messages.title}
  />
);

export default MasterCatalogItem;
