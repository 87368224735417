const LocalizeOptions = (formatMessage, options) => {
  return options.map((option) => {
    const Icon = option.icon;
    const newOption = {
      ...option,
      label: Icon ? (
        <>
          <Icon />
          <span className="ml-2">
            {formatMessage(option.label, option.label.values)}
          </span>
        </>
      ) : (
        formatMessage(option.label, option.label.values)
      ),
    };
    if (newOption.description) {
      newOption.description = formatMessage(
        newOption.description,
        newOption.description.values
      );
    }
    if (newOption.options) {
      newOption.options = LocalizeOptions(formatMessage, newOption.options);
    }
    return newOption;
  });
};

export default LocalizeOptions;
