/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const contextRouteParams = {
  staffID: 'currentStaffID',
};

const StaffMemberRouteLinkItem = (props) => (
  <RouteLinkItem contextRouteParams={contextRouteParams} {...props} />
);

export default StaffMemberRouteLinkItem;
