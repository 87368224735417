/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const masterCatalog = {
  '': '/midas/students/master-catalog',
  'add-courses': '/midas/students/master-catalog/add-courses',
  'edit-course': '/midas/students/master-catalog/edit-course/:courseID',
};

export default masterCatalog;
