import {defineMessages, useIntl} from 'react-intl';
import {Markdown} from 'components/layout';
import {Form} from 'components/form';
import ConfirmConfirmationDialogModal from 'components/form/ConfirmConfirmationDialogModal';

const messages = defineMessages({
  title: {
    id: 'midas.self-registration.register.success',
    defaultMessage: 'Account Registration Request Sent',
  },
  message: {
    id: 'midas.self-registration.register.success-confirmation-message',
  },
});

const SelfRegisterSuccessConfirmationModal = ({show, onCancel, ...props}) => {
  const intl = useIntl();
  return (
    <Form initialValues={{}} {...props}>
      <ConfirmConfirmationDialogModal
        show={show}
        title={messages.title}
        onCancel={onCancel}
      >
        <Markdown>{intl.formatMessage(messages.message)}</Markdown>
      </ConfirmConfirmationDialogModal>
    </Form>
  );
};

export default SelfRegisterSuccessConfirmationModal;
