/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSlidersV} from '@fortawesome/pro-duotone-svg-icons';

const ResponseToInterventionIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faSlidersV} {...props} />
);

export default ResponseToInterventionIcon;
