/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import MicrocredentialIcon from 'icons/MicrocredentialIcon';
import ApprovalsItem from './microcredentialing/ApprovalsItem';
import MicrocredentialsItem from './microcredentialing/MicrocredentialsItem';
import PrepaymentsItem from './microcredentialing/PrepaymentsItem';
import ReflectionTemplatesItem from './microcredentialing/ReflectionTemplatesItem';
import ReviewerQueueItem from './microcredentialing/ReviewerQueueItem';
import StacksItem from './microcredentialing/StacksItem';
import SubmissionsItem from './microcredentialing/SubmissionsItem';
import CoachingSubjectsItems from 'components/navigation/side-navigation/menu/microcredentialing/CoachingSubjectsItems';
import CurriculaAndPathwaysMenu from 'components/navigation/side-navigation/menu/microcredentialing/CurriculaAndPathwaysMenu';
import MyStudentsItem from 'components/navigation/side-navigation/menu/microcredentialing/MyStudentsItem';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing'},
});

const MicrocredentialingMenu = () => (
  <NavigationMenu
    iconBefore={MicrocredentialIcon}
    route="midas/microcredentialing"
    title={messages.title}
  >
    <MicrocredentialsItem />
    <CurriculaAndPathwaysMenu />
    <ApprovalsItem />
    <CoachingSubjectsItems />
    <StacksItem />
    <ReflectionTemplatesItem />
    <SubmissionsItem />
    <PrepaymentsItem />
    <ReviewerQueueItem />
    <MyStudentsItem />
  </NavigationMenu>
);

export default MicrocredentialingMenu;
