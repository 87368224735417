/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PublishersIcon from 'icons/PublishersIcon';

const messages = defineMessages({
  title: {id: 'navigation.instructional-materials.publishers'},
});

const PublishersItem = () => (
  <RouteLinkItem
    iconBefore={PublishersIcon}
    route="midas/instructional-materials/publishers"
    title={messages.title}
  />
);

export default PublishersItem;
