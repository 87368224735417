/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useFormatMessage} from 'app/hooks';
import {Alert as BootstrapAlert} from 'react-bootstrap';
import {Markdown} from 'components/layout';

const Alert = ({text, variant = 'primary', ...props}) => {
  const source = useFormatMessage(text, text.values);
  return (
    <BootstrapAlert variant={variant} {...props}>
      <Markdown>{source}</Markdown>
    </BootstrapAlert>
  );
};

export default Alert;
