/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef} from 'react';
import {useIntl} from 'react-intl';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';
import FieldWrapper from './FieldWrapper';

const WrappedFormControl = (
  {
    as = 'input',
    type,
    id,
    label,
    placeholder,
    help,
    required = false,
    disabled = false,
    ...props
  },
  ref
) => {
  const intl = useIntl();

  const [{name, value, onBlur, onChange}, meta] = useField({type, ...props});
  const {isSubmitting} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const isInvalid = meta.touched && meta.error && true;

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <Form.Control
        ref={ref}
        as={as}
        type={type}
        id={id}
        name={name}
        placeholder={
          placeholder && intl.formatMessage(placeholder, placeholder.values)
        }
        value={value ?? ''}
        onBlur={onBlur}
        onChange={onChange}
        aria-required={required}
        disabled={isSubmitting || disabled}
        isInvalid={isInvalid}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? `${id}-error` : undefined}
        aria-labelledby={label && `${id}-label`}
        aria-describedby={help && `${id}-help`}
        {...props}
      />
    </FieldWrapper>
  );
};
WrappedFormControl.displayName = 'WrappedFormControl';

export default forwardRef(WrappedFormControl);
