/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import ScheduleIcon from 'icons/ScheduleIcon';

const messages = defineMessages({
  title: {id: 'navigation.schedule'},
});

const ScheduleItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={ScheduleIcon}
    route="midas/staff-member/schedule"
    title={messages.title}
  />
);

export default ScheduleItem;
