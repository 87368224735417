/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import AlertsIcon from 'icons/AlertsIcon';

const messages = defineMessages({
  title: {id: 'navigation.alerts'},
});

const AlertsItem = () => (
  <StudentRouteLinkItem
    iconBefore={AlertsIcon}
    route="midas/student/alerts"
    title={messages.title}
  />
);

export default AlertsItem;
