/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import IndustryLeaderIcon from 'icons/IndustryLeaderIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.industry-leaders'},
});

const IndustryLeadersItem = () => (
  <EventRouteLinkItem
    iconBefore={IndustryLeaderIcon}
    route="midas/event/industry-leaders"
    title={messages.title}
  />
);

export default IndustryLeadersItem;
