/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.students.scheduling.enroll-section-to-section'},
});

const EnrollSectionToSectionItem = () => (
  <RouteLinkItem
    route="midas/students/scheduling/enroll-section-to-section"
    title={messages.title}
  />
);

export default EnrollSectionToSectionItem;
