/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {wrapNonLocalizedMessage} from 'app/intl';
import {useStaffName} from 'app/hooks';
import {useUserContext} from 'app/UserContext';
import NavigationMenu from './NavigationMenu';
import AttendanceItem from './staff-member/AttendanceItem';
import BiographyItem from './staff-member/BiographyItem';
import CalendarItem from './staff-member/CalendarItem';
import ClassroomItem from './staff-member/ClassroomItem';
import ConferencesItem from './staff-member/ConferencesItem';
import ContactInfoItem from './staff-member/ContactInfoItem';
import DemographicsItem from './staff-member/DemographicsItem';
import EmploymentItem from './staff-member/EmploymentItem';
import GrowthPlanItem from './staff-member/GrowthPlanItem';
import GrowthResourcesItem from './staff-member/GrowthResourcesItem';
import InstituteFormsItem from './staff-member/InstituteFormsItem';
import InstituteSelectPanelistsItem from './staff-member/InstituteSelectPanelistsItem';
import InstituteSelectPanelsItem from './staff-member/InstituteSelectPanelsItem';
import IntentToBidItem from './staff-member/IntentToBidItem';
import LanguageCitizenshipItem from './staff-member/LanguageCitizenshipItem';
import LeaveRequestsItem from './staff-member/LeaveRequestsItem';
import LicensesItem from './staff-member/LicensesItem';
import MenteesItem from './staff-member/MenteesItem';
import MicrocredentialsItem from './staff-member/MicrocredentialsItem';
import ObservationsItem from './staff-member/ObservationsItem';
import OtherContactsItem from './staff-member/OtherContactsItem';
import PdTranscriptItem from './staff-member/PdTranscriptItem';
import PdPlaylistsItem from './staff-member/PdPlaylistsItem';
import PhotoItem from './staff-member/PhotoItem';
import ProfessionalLearningItem from './staff-member/ProfessionalLearningItem';
import PublishersItem from './staff-member/PublishersItem';
import PublisherMaterialsItem from './staff-member/PublisherMaterialsItem';
import ReviewerMaterialsItem from './staff-member/ReviewerMaterialsItem';
import ScheduleItem from './staff-member/ScheduleItem';
import StateReportingItem from './staff-member/StateReportingItem';
import TimesheetsItem from './staff-member/TimesheetsItem';
import VideoMentoringItem from './staff-member/VideoMentoringItem';

const StaffMemberMenu = () => {
  const [{currentStaffID}] = useUserContext();
  const name = useStaffName(currentStaffID);

  return (
    <NavigationMenu
      route="midas/staff-member"
      title={wrapNonLocalizedMessage(name)}
      detached
    >
      <DemographicsItem />
      <PhotoItem />
      <ContactInfoItem />
      <BiographyItem />
      <ScheduleItem />
      <ClassroomItem />
      <MicrocredentialsItem />
      <CalendarItem />
      <AttendanceItem />
      <ProfessionalLearningItem />
      <PdPlaylistsItem />
      <PdTranscriptItem />
      <IntentToBidItem />
      <PublishersItem />
      <PublisherMaterialsItem />
      <ReviewerMaterialsItem />
      <InstituteFormsItem />
      <InstituteSelectPanelsItem />
      <InstituteSelectPanelistsItem />
      <EmploymentItem />
      <TimesheetsItem />
      <LeaveRequestsItem />
      <StateReportingItem />
      <GrowthPlanItem />
      <ObservationsItem />
      <ConferencesItem />
      <VideoMentoringItem />
      <GrowthResourcesItem />
      <MenteesItem />
      <LicensesItem />
      <LanguageCitizenshipItem />
      <OtherContactsItem />
    </NavigationMenu>
  );
};

export default StaffMemberMenu;
