/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import TransactionTypeIcon from 'icons/ReportsIcon';

const messages = defineMessages({
  title: {id: 'navigation.finance.year-end-report'},
});

const YearEndReportItem = () => (
  <RouteLinkItem
    iconBefore={TransactionTypeIcon}
    route="midas/finance/year-end-report"
    title={messages.title}
  />
);

export default YearEndReportItem;
