/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const foodServices = {
  '': '/midas/settings/food-services',
  'free-and-reduced-lunch': {
    '': '/midas/settings/food-services/free-and-reduced-lunch',
    'update-student-eligibility':
      '/midas/settings/food-services/free-and-reduced-lunch/update-student-eligibility',
  },
  'menu-items': {
    '': '/midas/settings/food-services/menu-items',
    'add-menu-item': '/midas/settings/food-services/menu-items/add-menu-item',
    'edit-menu-item':
      '/midas/settings/food-services/menu-items/edit-menu-item/:itemID',
  },
  'lunch-menu': {
    '': '/midas/settings/food-services/lunch-menu',
    'add-menu':
      '/midas/settings/food-services/lunch-menu/add-menu/:startDate/:endDate',
    'view-menu-details':
      '/midas/settings/food-services/lunch-menu/view-menu-details/:menuID',
  },
  'meal-accounts': '/midas/settings/food-services/meal-accounts',
};

export default foodServices;
