/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from '@fortawesome/pro-solid-svg-icons';

const SearchIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faSearch} {...props} />
);

export default SearchIcon;
