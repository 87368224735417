/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const activities = {
  '': '/midas/settings/activities',
  activities: {
    '': '/midas/settings/activities/activities',
    'add-activity': '/midas/settings/activities/activities/add-activity',
    'edit-activity':
      '/midas/settings/activities/activities/edit-activity/:activityID',
    'add-members':
      '/midas/settings/activities/activities/add-members/:activityID',
    'edit-member':
      '/midas/settings/activities/activities/edit-member/:activityID',
  },
  'activities-requirements': {
    '': '/midas/settings/activities/activities-requirements',
    'add-requirement':
      '/midas/settings/activities/activities-requirements/add-requirement',
    'edit-requirement':
      '/midas/settings/activities/activities-requirements/edit-requirement/:requirementID',
  },
};

export default activities;
