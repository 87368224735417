/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {wrapNonLocalizedMessage} from 'app/intl';
import {useGuardianName} from 'app/hooks';
import {useUserContext} from 'app/UserContext';
import NavigationMenu from './NavigationMenu';
import CalendarItem from './guardian/CalendarItem';
import ChildrenItem from './guardian/ChildrenItem';
import ContactInfoItem from './guardian/ContactInfoItem';
import DemographicsItem from './guardian/DemographicsItem';
import LanguageCitizenshipItem from './guardian/LanguageCitizenshipItem';
import PhotoItem from './guardian/PhotoItem';
import SchoolMembershipItem from './guardian/SchoolMembershipItem';

const GuardianMenu = () => {
  const [{currentGuardianID}] = useUserContext();
  const name = useGuardianName(currentGuardianID);

  return (
    <NavigationMenu
      route="midas/guardian"
      title={wrapNonLocalizedMessage(name)}
      detached
    >
      <DemographicsItem />
      <LanguageCitizenshipItem />
      <PhotoItem />
      <ContactInfoItem />
      <ChildrenItem />
      <CalendarItem />
      <SchoolMembershipItem />
    </NavigationMenu>
  );
};

export default GuardianMenu;
