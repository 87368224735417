/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {urlFromRoute} from 'app/routing';
import NavigationMenu from './NavigationMenu';
import ReportsIcon from 'icons/ReportsIcon';
import LettersItem from './reports/LettersItem';
import ReportsIndexItem from './reports/ReportsIndexItem';
import SavedParametersItem from './reports/SavedParametersItem';
import ScheduledReportsItem from './reports/ScheduledReportsItem';
import TimeAndEffortItem from './reports/TimeAndEffortItem';

const messages = defineMessages({
  title: {id: 'navigation.reports'},
});

const ReportsMenu = () => {
  // TODO: This click handler is temporary, and is only here to maintain the
  //  behavior of the former implementation, which performed a route transition
  //  to the reports index page, not just a nav transition. Eventually, the
  //  reports index page should be removed in favor of simply listing the user's
  //  available reports directly within this menu.
  const history = useHistory();
  const handleClick = () => {
    history.push(urlFromRoute('midas/reports/reports-index'));
  };

  return (
    <NavigationMenu
      iconBefore={ReportsIcon}
      route="midas/reports"
      title={messages.title}
      onClick={handleClick}
    >
      <ReportsIndexItem />
      <TimeAndEffortItem />
      <LettersItem />
      <SavedParametersItem />
      <ScheduledReportsItem />
    </NavigationMenu>
  );
};

export default ReportsMenu;
