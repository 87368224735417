/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext, useLayoutEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Redirect, Route, Switch, useLocation} from 'react-router-dom';
import {pathFromRoute, RouteRedirect} from 'app/routing';
import {useMidasJsonApi} from 'app/hooks';
import {UserContext} from 'app/UserContext';
import MidasLogo from 'components/MidasLogo';
import Login from './login/Login';
import ResetPassword from './login/ResetPassword';
import ForgotUsername from './login/ForgotUsername';
import './Login.css';

const LoginContent = () => {
  const [response] = useMidasJsonApi({
    url: '/midas/rest/login-page-detail',
  });

  if (response.status !== 200) {
    return <div className="loadingPlaceholder" />;
  }

  const {logoURL, message, usernameMode} = response.json.detail;

  return (
    <div className="LoginContent">
      {logoURL ? (
        <img src={logoURL} alt="Logo" className="logo" />
      ) : (
        <MidasLogo width="84px" height="84px" className="logo" />
      )}
      <Switch>
        <Route path={pathFromRoute('login')} exact strict>
          <Login message={message} usernameMode={usernameMode} />
        </Route>
        <Route path={pathFromRoute('login/reset-password')} exact strict>
          <ResetPassword usernameMode={usernameMode} />
        </Route>
        <Route path={pathFromRoute('login/forgot-username')} exact strict>
          <ForgotUsername usernameMode={usernameMode} />
        </Route>
        <RouteRedirect route="login" />
      </Switch>
    </div>
  );
};

const LoginView = () => {
  const [context] = useContext(UserContext);
  const [redirectUrl, setRedirectUrl] = useState(null);
  const params = {studentID: context.userID, staffID: context.userID};

  const location = useLocation();

  useLayoutEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('return_url')) {
      setRedirectUrl(searchParams.get('return_url'));
    }
    // capture return_url in the request only on initial render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (context.userID !== 0) {
    if (redirectUrl) {
      return <Redirect to={redirectUrl} />;
    }
    return <RouteRedirect route={context.loginRoute} params={params} />;
  }

  return (
    <div className="LoginView">
      <div className="LoginContainer">
        <LoginContent />
      </div>

      <footer>
        <p className="logo">
          <MidasLogo width="32px" height="32px" stroke />
        </p>
        <p className="poweredBy">
          <FormattedMessage
            id="text.powered-by-midas"
            description="Footer marketing powered by message"
            defaultMessage="Powered by MIDAS Education"
          />
        </p>
        <p className="link">
          <a href="https://www.midaseducation.com/">www.midaseducation.com</a>
        </p>
      </footer>
    </div>
  );
};

export default LoginView;
