/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import PortfolioIcon from 'icons/PortfolioIcon';

const messages = defineMessages({
  title: {id: 'navigation.portfolio'},
});

const PortfolioItem = () => (
  <StudentRouteLinkItem
    iconBefore={PortfolioIcon}
    route="midas/student/portfolio"
    title={messages.title}
  />
);

export default PortfolioItem;
