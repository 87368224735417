/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faMagic} from '@fortawesome/pro-duotone-svg-icons';

const MagicIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faMagic} {...props} />
);

export default MagicIcon;
