/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages, FormattedMessage} from 'react-intl';
import {HeadingItem} from '@atlaskit/side-navigation';
import NavigationMenu from '../NavigationMenu';
import CalendarIcon from 'icons/CalendarIcon';
import AutoSetupItem from './calendar/AutoSetupItem';
import BellSchedulesItem from './calendar/BellSchedulesItem';
import DaysItem from './calendar/DaysItem';
import ManualSetupItem from './calendar/ManualSetupItem';
import NotInSessionItem from './calendar/NotInSessionItem';
import PeriodsItem from './calendar/PeriodsItem';
import TermsItem from './calendar/TermsItem';
import YearsItem from './calendar/YearsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.calendar'},
  sectionTitle: {id: 'navigation.settings.calendar-settings'},
});

const CalendarMenu = () => (
  <NavigationMenu
    iconBefore={CalendarIcon}
    route="midas/settings/calendar"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <YearsItem />
    <TermsItem />
    <DaysItem />
    <PeriodsItem />
    <BellSchedulesItem />

    <HeadingItem>
      <FormattedMessage id="navigation.actions" />
    </HeadingItem>
    <AutoSetupItem />
    <ManualSetupItem />
    <NotInSessionItem />
  </NavigationMenu>
);

export default CalendarMenu;
