/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import DataIntegrationsIcon from 'icons/DataIntegrationsIcon';
import AdtDashboardItem from './data-integrations/AdtDashboardItem';
import BulkOperationsItem from './data-integrations/BulkOperationsItem';
import CustomerImportsItem from './data-integrations/CustomerImportsItem';

const messages = defineMessages({
  title: {id: 'navigation.data-integrations'},
});

const DataIntegrationsMenu = () => (
  <NavigationMenu
    iconBefore={DataIntegrationsIcon}
    route="midas/data-integrations"
    title={messages.title}
  >
    <AdtDashboardItem />
    <BulkOperationsItem />
    <CustomerImportsItem />
  </NavigationMenu>
);

export default DataIntegrationsMenu;
