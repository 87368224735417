/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import CurriculaItem from 'components/navigation/side-navigation/menu/microcredentialing/curricula-and-pathways/CurriculaItem';
import PathwaysItem from 'components/navigation/side-navigation/menu/microcredentialing/curricula-and-pathways/PathwaysItem';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.curricula-and-pathways'},
});

const CurriculaAndPathwaysMenu = () => (
  <NavigationMenu
    route="midas/microcredentialing/curricula-and-pathways"
    title={messages.title}
    sectionTitle={messages.title}
  >
    <CurriculaItem />
    <PathwaysItem />
  </NavigationMenu>
);

export default CurriculaAndPathwaysMenu;
