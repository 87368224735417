/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const setFieldErrors = (errors, formikProps) => {
  if (!errors) {
    return;
  }
  for (let [fieldName, error] of Object.entries(errors)) {
    formikProps.setFieldTouched(fieldName, true, false);
    formikProps.setFieldError(fieldName, error);
  }
};

const setFormError = (error, formikProps) => {
  formikProps.setStatus({
    ...formikProps.status,
    error,
  });
};

const setApiResponseFormErrors = (json, formikProps) => {
  if (json.errors) {
    setFieldErrors(json.errors, formikProps);
  } else {
    setFormError(
      {
        id: 'server-message',
        values: {message: json.detail},
      },
      formikProps
    );
  }
};

export {setApiResponseFormErrors, setFieldErrors, setFormError};
