/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserClock} from '@fortawesome/pro-duotone-svg-icons';

const EnrollmentIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faUserClock} {...props} />
);

export default EnrollmentIcon;
