/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import approvalAreas from './professional-development/approval-areas';
import forms from './professional-development/forms';

const professionalDevelopment = {
  '': '/midas/settings/professional-development',
  'approval-areas': approvalAreas,
  'credit-types': {
    '': '/midas/settings/professional-development/credit-types',
    'add-credit-type':
      '/midas/settings/professional-development/credit-types/add-credit-type',
    'edit-credit-type':
      '/midas/settings/professional-development/credit-types/edit-credit-type/:creditTypeID',
    'add-approval-area':
      '/midas/settings/professional-development/credit-types/add-approval-area/:creditTypeID',
    'edit-approval-area':
      '/midas/settings/professional-development/credit-types/edit-approval-area/:approvalAreaID',
  },
  forms,
  lcap: {
    '': '/midas/settings/professional-development/lcap',
    'add-goal': '/midas/settings/professional-development/lcap/add-goal',
    'edit-goal':
      '/midas/settings/professional-development/lcap/edit-goal/:goalID',
    'add-action':
      '/midas/settings/professional-development/lcap/:goalID/add-action',
    'edit-action':
      '/midas/settings/professional-development/lcap/edit-action/:actionID',
    services:
      '/midas/settings/professional-development/lcap/:actionID/services',
    'add-service':
      '/midas/settings/professional-development/lcap/:actionID/add-service',
    'edit-service':
      '/midas/settings/professional-development/lcap/edit-service/:serviceID',
  },
  'pd-tags': {
    '': '/midas/settings/professional-development/pd-tags',
  },
};

export default professionalDevelopment;
