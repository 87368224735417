/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import StandardsIcon from 'icons/StandardsIcon';
import CategoriesItem from './standards/CategoriesItem';
import CollectionItem from './standards/CollectionItem';
import CoursesItem from './standards/CoursesItem';
import CourseCodesItem from './standards/CourseCodesItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.standards'},
  sectionTitle: {id: 'navigation.settings.standards-settings'},
});

const StandardsMenu = () => (
  <NavigationMenu
    iconBefore={StandardsIcon}
    route="midas/settings/standards"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <CategoriesItem />
    <CollectionItem />
    <CoursesItem />
    <CourseCodesItem />
  </NavigationMenu>
);

export default StandardsMenu;
