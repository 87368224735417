/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleLeft} from '@fortawesome/pro-solid-svg-icons';

const style = {
  fontSize: '1.8em',
  verticalAlign: '-20%',
};

const PreviousIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faAngleLeft} style={style} {...props} />
);

export default PreviousIcon;
