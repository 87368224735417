import PathwayInfo from 'components/microcredentials/public/PathwayInfo';
import PathwaySteps from 'components/microcredentials/public/PathwaySteps';

const PathwayDetailContent = ({pathway}) => {
  return (
    <>
      <PathwayInfo pathway={pathway} />
      <PathwaySteps pathwayID={pathway.id} />
    </>
  );
};

export default PathwayDetailContent;
