/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import GuardianRouteLinkItem from './GuardianRouteLinkItem';
import ContactInformationIcon from 'icons/ContactInformationIcon';

const messages = defineMessages({
  title: {id: 'navigation.contact-info'},
});

const ContactInfoItem = () => (
  <GuardianRouteLinkItem
    iconBefore={ContactInformationIcon}
    route="midas/guardian/contact-information"
    title={messages.title}
  />
);

export default ContactInfoItem;
