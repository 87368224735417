/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import {Button, ButtonGroup} from 'react-bootstrap';
import './SegmentedControlField.css';

const SegmentedControlField = (
  {
    id,
    name,
    label,
    options,
    help,
    required = false,
    disabled = false,
    multiple = false,
    ...props
  },
  ref
) => {
  const intl = useIntl();

  let [{value}, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  value = value ?? (multiple ? [] : '');

  const isInvalid = meta.touched && meta.error && true;

  // TODO: update callbacks on ref to intelligently handle focus and blur events

  /* TODO: track focus and call Formik's onBlur once focus leaves any of our buttons
   */
  // const handleOnBlur = (event) => {
  //   onBlur(name)(event);
  // };

  const onClick = (event) => {
    const optionValue = event.target.dataset.value;
    if (multiple) {
      const index = value.indexOf(optionValue);
      if (index < 0) {
        value.push(optionValue);
      } else {
        value.splice(index, 1);
      }
      setFieldValue(name, value);
    } else if (required) {
      setFieldValue(name, optionValue);
    } else {
      setFieldValue(name, optionValue === value ? '' : optionValue);
    }
  };

  const isSelected = (optionValue) => {
    if (multiple) {
      return value.includes(optionValue);
    }
    return value === optionValue;
  };

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <div>
        <ButtonGroup
          ref={ref}
          className={`SegmentedControlField ${isInvalid ? 'is-invalid' : ''}`}
          aria-required={required}
          aria-invalid={isInvalid}
          aria-errormessage={isInvalid ? `${id}-error` : undefined}
          aria-labelledby={label && `${id}-label`}
          aria-describedby={help && `${id}-help`}
        >
          {options.map((option) => (
            <Button
              key={option.value}
              data-value={option.value}
              variant={
                isSelected(option.value)
                  ? isInvalid
                    ? 'danger'
                    : 'primary'
                  : 'light'
              }
              title={
                option.title &&
                intl.formatMessage(option.title, option.title.values)
              }
              disabled={isSubmitting || disabled || option.disabled || false}
              onClick={onClick}
            >
              <FormattedMessage {...option.label} />
            </Button>
          ))}
        </ButtonGroup>
      </div>
    </FieldWrapper>
  );
};
SegmentedControlField.displayName = 'SegmentedControlField';

export default forwardRef(SegmentedControlField);
