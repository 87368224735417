/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandshake} from '@fortawesome/pro-duotone-svg-icons';

const MenteesIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHandshake} {...props} />
);

export default MenteesIcon;
