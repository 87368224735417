/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.data-suites'},
});

const DataSuitesItem = () => (
  <RouteLinkItem route="midas/data-forge/data-suites" title={messages.title} />
);

export default DataSuitesItem;
