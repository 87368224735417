/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import ClassroomRouteLinkItem from './ClassroomRouteLinkItem';
import SeatingChartIcon from 'icons/SeatingChartIcon';

const messages = defineMessages({
  title: {id: 'navigation.seating-chart'},
});

const SeatingChartItem = () => (
  <ClassroomRouteLinkItem
    iconBefore={SeatingChartIcon}
    route="midas/classroom/seating-chart"
    title={messages.title}
  />
);

export default SeatingChartItem;
