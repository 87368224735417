/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const humanResources = {
  '': '/midas/settings/human-resources',
  'hr-administrators': {
    '': '/midas/settings/human-resources/hr-administrators',
    'add-administrator':
      '/midas/settings/human-resources/hr-administrators/add-administrator',
  },
  positions: {
    '': '/midas/settings/human-resources/positions',
    'add-position': '/midas/settings/human-resources/positions/add-position',
    'select-position':
      '/midas/settings/human-resources/positions/select-position',
    'edit-position':
      '/midas/settings/human-resources/positions/edit-position/:positionID',
  },
  supervisors: {
    '': '/midas/settings/human-resources/supervisors',
    'add-supervisor':
      '/midas/settings/human-resources/supervisors/add-supervisor/:staffMemberID',
    'edit-supervisor-privileges':
      '/midas/settings/human-resources/supervisors/edit-supervisor-privileges/:supervisorID',
  },
};

export default humanResources;
