/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import SubmitButton from './SubmitButton';

const messages = defineMessages({
  delete: {id: 'button.delete'},
  deleting: {id: 'button.deleting'},
});

const DeleteButton = (
  {
    submitMessage = messages.delete,
    submittingMessage = messages.deleting,
    variant = 'danger',
    type = 'button',
    ...props
  },
  ref
) => {
  return (
    <SubmitButton
      ref={ref}
      submitMessage={submitMessage}
      submittingMessage={submittingMessage}
      variant={variant}
      type={type}
      {...props}
    />
  );
};
DeleteButton.displayName = 'DeleteButton';

export default forwardRef(DeleteButton);
