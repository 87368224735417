/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import BulkOperationsIcon from 'icons/BulkOperationsIcon';

const messages = defineMessages({
  title: {id: 'navigation.data-integrations.bulk-operations'},
});

const BulkOperationsItem = () => (
  <RouteLinkItem
    iconBefore={BulkOperationsIcon}
    route="midas/data-integrations/bulk-operations"
    title={messages.title}
  />
);

export default BulkOperationsItem;
