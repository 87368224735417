/**
 * Monitors the status of the user's server-side login session, presenting a
 * warning modal dialog if their session is about to expire, and immediately
 * redirecting to the login route if their session has expired.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState, useEffect} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {callMidasJsonApi} from 'app/api';
import {sendToLogin, logout} from 'app/userSession';
import {useFormatMessage} from 'app/hooks';
import {Modal, Button} from 'react-bootstrap';
import FullPageLoading from 'components/FullPageLoading';
import {Markdown} from 'components/layout';

const messages = defineMessages({
  message: {id: 'session.expiring-message'},
});

const pollIntervalMs = 1000 * 60;

const sessionStates = {
  unknown: 0,
  active: 1,
  expiring: 2,
  expired: 3,
};

const handleHide = () => {};

const SessionExpiring = ({onExtend, onLogout}) => {
  const message = useFormatMessage(messages.message);
  return (
    <Modal show={true} onHide={handleHide}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="session.expiring-title" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Markdown>{message}</Markdown>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onExtend}>
          <FormattedMessage id="session.extend-session" />
        </Button>
        <Button variant="primary" onClick={onLogout}>
          <FormattedMessage id="session.logout" />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const SessionMonitor = ({children}) => {
  const [sessionState, setSessionState] = useState(sessionStates.unknown);
  const [error, setError] = useState(null);

  useEffect(() => {
    let timer;
    const checkTimeout = () => {
      callMidasJsonApi('/midas/rest/user-session', 'GET')
        .then(({json, status}) => {
          if (status === 200) {
            const newState =
              json.status === 'active'
                ? sessionStates.active
                : sessionStates.expiring;
            if (newState !== sessionState) {
              setSessionState(newState);
            }
          } else if (status >= 400 && status < 500) {
            setSessionState(sessionStates.expired);
          } else {
            setError(
              new Error(
                `Received error ${status} while attempting to check session status`
              )
            );
          }
        })
        .catch((error) => {
          setError(error);
        });
      timer = setTimeout(checkTimeout, pollIntervalMs);
    };
    checkTimeout();
    return () => clearTimeout(timer);
  }, [sessionState]);

  if (error) {
    throw error;
  }

  if (sessionState === sessionStates.unknown) {
    return <FullPageLoading delayMs={0} />;
  }

  if (sessionState === sessionStates.expired) {
    sendToLogin(true);
    return null;
  }

  const extendClicked = () => {
    callMidasJsonApi('/midas/rest/user-context', 'GET')
      .then(({status}) => {
        if (status === 200) {
          setSessionState(sessionStates.active);
        } else {
          setSessionState(sessionStates.expired);
        }
      })
      .catch(() => {
        // not really anything that can be done with an error here... just ignore
        // it and treat the session as expired.
        setSessionState(sessionStates.expired);
      });
  };

  const logoutClicked = () => {
    logout();
  };

  return (
    <>
      {children}
      {sessionState === sessionStates.expiring && (
        <SessionExpiring onExtend={extendClicked} onLogout={logoutClicked} />
      )}
    </>
  );
};

export default SessionMonitor;
