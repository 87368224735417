/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import InstructionalMaterialsIcon from 'icons/InstructionalMaterialsIcon';
import {useMidasJsonApi} from 'app/hooks';
import {useUserContext} from 'app/UserContext';

const messages = defineMessages({
  title: {id: 'navigation.reviewer-materials'},
});

const ReviewerMaterialsItem = () => {
  const [{userID}] = useUserContext();

  const [response] = useMidasJsonApi({
    url: `/midas/rest/instructional-material-bid-cycle-reviewer?userID=${userID}`,
    expectedStatus: [200],
  });

  // If a BidCycleReviewer entity does not exist, do not show the Reviewer link (this item)
  if (response.status === 200 && response.json.reviewers.length < 1) {
    return null;
  }

  return (
    <RouteLinkItem
      iconBefore={InstructionalMaterialsIcon}
      route="midas/my/reviewer-materials"
      title={messages.title}
    />
  );
};

export default ReviewerMaterialsItem;
