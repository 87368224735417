/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const institutes = {
  '': '/midas/settings/institutes',
  forms: {
    '': '/midas/settings/institutes/:instituteID/forms',
    'add-form': '/midas/settings/institutes/:instituteID/forms/add-form',
    'add-section':
      '/midas/settings/institutes/:instituteID/forms/add-section/:formType/:formID',
    'preview-questions':
      '/midas/settings/institutes/:instituteID/forms/preview-questions/:formType/:formID',
    'edit-section':
      '/midas/settings/institutes/:instituteID/forms/edit-section/:id/:class',
    'edit-section-inner':
      '/midas/settings/institutes/:instituteID/forms/edit-section-inner/:id/:class',
    'edit-form':
      '/midas/settings/institutes/:instituteID/forms/edit-form/:formType/:formID',
    'add-question':
      '/midas/settings/institutes/:instituteID/forms/add-question/:formType/:formID',
  },
  'form-submissions': {
    '': '/midas/settings/institutes/:instituteID/form-submissions',
    'select-forms':
      '/midas/settings/institutes/:instituteID/form-submissions/select-forms',
    'view-submission':
      '/midas/settings/institutes/:instituteID/form-submissions/view-submission/:submissionID',
  },
  reports: {
    '': '/midas/settings/institutes/:instituteID/reports',
  },
  administrators: {
    '': '/midas/settings/institutes/:instituteID/administrators',
    'add-administrator':
      '/midas/settings/institutes/:instituteID/administrators/add-administrator',
  },
};

export default institutes;
