/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import TimesheetsIcon from 'icons/TimesheetsIcon';

const messages = defineMessages({
  title: {id: 'navigation.timesheets'},
});

const TimesheetsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={TimesheetsIcon}
    route="midas/staff-member/timesheets"
    title={messages.title}
  />
);

export default TimesheetsItem;
