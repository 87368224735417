/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import TransactionTypeIcon from 'icons/TransactionTypeIcon';

const messages = defineMessages({
  title: {id: 'navigation.finance.transaction-types'},
});

const TransactionTypesItem = () => (
  <RouteLinkItem
    iconBefore={TransactionTypeIcon}
    route="midas/finance/transaction-types"
    title={messages.title}
  />
);

export default TransactionTypesItem;
