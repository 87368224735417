/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const dataForge = {
  '': '/midas/data-forge',
  'data-suites': {
    '': '/midas/data-forge/data-suites',
    'data-suite': '/midas/data-forge/data-suites/data-suite/:dataSuiteID',
  },
  dashboards: {
    '': '/midas/data-forge/dashboards',
    dashboard: '/midas/data-forge/dashboards/dashboard/:dashboardID',
  },
};

export default dataForge;
