import SelfRegistrationForm from 'components/self-registration/SelfRegistrationForm';
import './SelfRegister.css';
import MidasLogo from 'components/MidasLogo';
import {FormattedMessage} from 'react-intl';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {Base64} from 'js-base64';
import {toNumber} from 'lodash';
import {useMidasJsonApi} from 'app/hooks';
import SelfRegisterSuccessConfirmationModal from 'components/self-registration/SelfRegisterSuccessConfirmationModal';
import {urlFromRoute} from 'app/routing';
import {Alert} from 'react-bootstrap';
import {ApiResponseWrapper} from 'components/layout';

const SelfRegister = () => {
  const location = useLocation();
  const [inviteSchoolID, setInviteSchoolID] = useState('');
  const [inviteEmail, setInviteEmail] = useState('');
  const formikPropsRef = useRef();
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const history = useHistory();

  // api call to get list of schools with self registrations
  const [response] = useMidasJsonApi({
    url: `/api/self-registration`,
    expectedStatus: [200],
  });

  // set urlParams so they can be used in and out of other hooks
  const urlParams = useMemo(() => {
    return new URLSearchParams(location.search);
  }, [location]);

  // determine if any school has registration enabled and walk ups turned on
  const allowWalkUps = useMemo(() => {
    let walkUp = false;
    if (response.status === 200) {
      response.json.schools.forEach((school) => {
        if (school.allowWalkUp) {
          walkUp = true;
        }
      });
    }
    return walkUp;
  }, [response]);

  // api call used to submit new self registrations
  const [postResponse, , , postRequest] = useMidasJsonApi({
    method: 'POST',
    url: `/api/self-registration`,
    expectedStatus: [201, 403, 422],
    immediate: false,
  });

  // if the self-registration url param exists, decode it and set the schoolID and email
  useEffect(() => {
    if (urlParams.has('self-registration')) {
      const inviteKey = Base64.decode(urlParams.get('self-registration'));
      const inviteKeyArray = inviteKey.split('::');
      setInviteSchoolID(toNumber(inviteKeyArray[0]));
      setInviteEmail(inviteKeyArray[1]);
    }
  }, [urlParams, location]);

  // method that processes the post
  const handleSubmit = (values, formikProps) => {
    formikPropsRef.current = formikProps;
    postRequest({
      body: {
        email: values.email,
        password: values.password,
        schoolID: values.schoolID,
        roleID: values.roleID,
        firstName: values.firstName,
        lastName: values.lastName,
        gender: values.gender,
        dob: values.dob,
        phoneNumber: values.phoneNumber,
        source: inviteSchoolID ? 'invt' : 'walk',
      },
    });
  };

  useEffect(() => {
    switch (postResponse.status) {
      case 201:
        setConfirmationModalOpen(true);
        break;
      case 403:
      case 422:
        alert(postResponse.json.detail);
        const formikProps = formikPropsRef.current;
        formikProps.setSubmitting(false);
        break;
      default:
      // waiting for submit
    }
  }, [postResponse]);

  // set initial values based on the url query param if it exists
  const initialValues = useMemo(() => {
    let values = {};
    values.email = inviteEmail;
    values.schoolID = inviteSchoolID;
    return values;
  }, [inviteSchoolID, inviteEmail]);

  // close confirmation modal
  const handleCancelModal = () => {
    setConfirmationModalOpen(false);
  };

  // on confirmation redirect to login
  const handleConfirmModalSuccess = () => {
    history.push(urlFromRoute('login'));
  };

  // on cancel registration redirect to login
  const onCancel = () => {
    history.push(urlFromRoute('login'));
  };

  // If it's not an invite & walk ups aren't allowed display a message. Closing message redirects to login screen
  if (!allowWalkUps && !urlParams.has('self-registration')) {
    return (
      <ApiResponseWrapper>
        {response.status === 200 && (
          <div className="SelfRegisterView">
            <div className="RegisterFormContainer">
              <div className="FormContent">
                <Alert
                  className="mt-2"
                  variant={'warning'}
                  onClose={() => {
                    history.push(urlFromRoute('login'));
                  }}
                  dismissible
                >
                  Self Registration is not currently available.
                </Alert>
              </div>
            </div>
          </div>
        )}
      </ApiResponseWrapper>
    );
  }

  return (
    <div className="SelfRegisterView">
      <div className="RegisterFormContainer">
        <div className="FormContent">
          <h2>Register for an account</h2>
          <hr />
          <SelfRegistrationForm
            onCancel={onCancel}
            onSubmit={handleSubmit}
            initialValues={initialValues}
          />
        </div>
      </div>
      <SelfRegisterSuccessConfirmationModal
        onCancel={handleCancelModal}
        onSubmit={handleConfirmModalSuccess}
        show={confirmationModalOpen}
      />
      <footer>
        <p className="logo">
          <MidasLogo width="32px" height="32px" stroke />
        </p>
        <p className="poweredBy">
          <FormattedMessage
            id="text.powered-by-midas"
            description="Footer marketing powered by message"
            defaultMessage="Powered by MIDAS Education"
          />
        </p>
        <p className="link">
          <a href="https://www.midaseducation.com/">www.midaseducation.com</a>
        </p>
      </footer>
    </div>
  );
};

export default SelfRegister;
