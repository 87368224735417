/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const event = {
  '': '/midas/event/:eventID',
  'view-event': '/midas/event/:eventID/view-event',
  'edit-event': '/midas/event/:eventID/edit-event',
  /*attendees: {
    '': '/midas/event/:eventID/attendees',
    'add-attendee': '/midas/event/:eventID/attendees/add-attendee',
  },*/
  'education-leaders': {
    '': '/midas/event/:eventID/education-leaders',
    'add-education-leader':
      '/midas/event/:eventID/education-leaders/add-education-leader',
    'set-education-leader-availability':
      '/midas/event/:eventID/education-leaders/set-education-leader-availability',
  },
  'industry-leaders': {
    '': '/midas/event/:eventID/industry-leaders',
    'add-industry-leader':
      '/midas/event/:eventID/industry-leaders/add-industry-leader',
    'edit-industry-leader':
      '/midas/event/:eventID/industry-leaders/edit-industry-leader/:partnerID',
  },
  'my-meetings': '/midas/event/:eventID/my-meetings',
  meetings: {
    '': '/midas/event/:eventID/meetings',
    'add-meeting': '/midas/event/:eventID/meetings/add-meeting',
    'edit-meeting': '/midas/event/:eventID/meetings/edit-meeting/:meetingID',
  },
  requests: {
    '': '/midas/event/:eventID/requests',
  },
  'meeting-list': {
    '': '/midas/event/:eventID/meeting-list',
    'add-panelist':
      '/midas/event/:eventID/meeting-list/add-panelist/:parentID/:innerID',
    'edit-panel-instances':
      '/midas/event/:eventID/meeting-list/edit-panel-instances/:id/:class/:innerID',
    'edit-panel-instances-inner':
      '/midas/event/:eventID/meeting-list/edit-panel-instances-inner/:id/:class',
    'print-all-schedules':
      '/midas/event/:eventID/meeting-list/print-all-schedules',
    'print-panel-schedule':
      '/midas/event/:eventID/meeting-list/print-panel-schedule/:panelInstanceID',
  },
  'meeting-grid': {
    '': '/midas/event/:eventID/meeting-grid',
    'add-meeting-instance':
      '/midas/event/:eventID/meeting-grid/add-meeting-instance',
  },
  'automated-meeting-scheduler': {
    '': '/midas/event/:eventID/automated-meeting-scheduler',
  },
  statistics: {
    '': '/midas/event/:eventID/statistics',
  },
};

export default event;
