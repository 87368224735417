/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import FoodServicesIcon from 'icons/FoodServicesIcon';
import FreeAndReducedLunchItem from './food-services/FreeAndReducedLunchItem';
import LunchMenuItem from './food-services/LunchMenuItem';
import MealAccountsItem from './food-services/MealAccountsItem';
import MenuItemsItem from './food-services/MenuItemsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.food-services'},
  sectionTitle: {id: 'navigation.settings.food-services-settings'},
});

const FoodServicesMenu = () => (
  <NavigationMenu
    iconBefore={FoodServicesIcon}
    route="midas/settings/food-services"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <FreeAndReducedLunchItem />
    <MenuItemsItem />
    <LunchMenuItem />
    <MealAccountsItem />
  </NavigationMenu>
);

export default FoodServicesMenu;
