/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {
    id: 'navigation.self-registration.manage-invitations',
    defaultMessage: 'Manage Invitations',
  },
});

const ManageInvitationsItem = () => (
  <RouteLinkItem
    route="midas/self-registration/manage-invitations"
    title={messages.title}
  />
);

export default ManageInvitationsItem;
