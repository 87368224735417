/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import GrantsIcon from 'icons/GrantsIcon';

const messages = defineMessages({
  title: {id: 'navigation.finance.ledger'},
});

const LedgerItem = () => (
  <RouteLinkItem
    iconBefore={GrantsIcon}
    route="midas/finance/ledger"
    title={messages.title}
  />
);

export default LedgerItem;
