/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {Link} from 'react-router-dom';
import {urlFromRoute} from 'app/routing';

const RouteLink = ({route, params = {}, to, state, search, ...props}, ref) => {
  if (to !== undefined) {
    throw new Error(`RouteLink cannot have a “to” prop`);
  }
  return (
    <Link
      ref={ref}
      to={{pathname: urlFromRoute(route, params), state, search}}
      {...props}
    />
  );
};
RouteLink.displayName = 'RouteLink';

export default forwardRef(RouteLink);
