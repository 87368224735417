/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import CurrentUserItem from 'components/navigation/side-navigation/item/CurrentUserItem';

const CurrentStaffMemberItem = () => (
  <CurrentUserItem contextParam="currentStaffID" menuID="midas/staff-member" />
);

export default CurrentStaffMemberItem;
