/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGavel} from '@fortawesome/pro-duotone-svg-icons';

const AppealsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faGavel} {...props} />
);

export default AppealsIcon;
