/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import SchedulerAutomatedIcon from 'icons/SchedulerAutomatedIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.automated-scheduler'},
});

const AutomatedSchedulerItem = () => (
  <EventRouteLinkItem
    iconBefore={SchedulerAutomatedIcon}
    route="midas/event/automated-meeting-scheduler"
    title={messages.title}
  />
);

export default AutomatedSchedulerItem;
