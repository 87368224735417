/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const plugins = [gfm];

const Markdown = ({source, ...props}) => {
  if (source !== undefined) {
    throw new Error('Markdown source prop is obsolete, use children instead');
  }

  return <ReactMarkdown plugins={plugins} linkTarget="_blank" {...props} />;
};

export default Markdown;
