/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {ErrorDetails} from 'components/error';
import ContentLoading from './ContentLoading';

const ApiResponseWrapper = ({isLoading, error, children}) => {
  if (error) {
    if (error.name === 'AbortError') {
      return (
        <p className="text-warning">
          <FormattedMessage
            id="error.api-request-cancelled"
            defaultMessage="The request was cancelled."
          />
        </p>
      );
    }
    return <ErrorDetails error={error} />;
  }

  if (isLoading) {
    return <ContentLoading />;
  }

  return children || null;
};

export default ApiResponseWrapper;
