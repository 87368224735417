/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import ObservationsIcon from 'icons/ObservationsIcon';

const messages = defineMessages({
  title: {id: 'navigation.observations'},
});

const ObservationsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={ObservationsIcon}
    route="midas/staff-member/observations"
    title={messages.title}
  />
);

export default ObservationsItem;
