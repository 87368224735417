/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {ButtonGroup} from 'react-bootstrap';
import MoveUpIconButton from './MoveUpIconButton';
import MoveDownIconButton from './MoveDownIconButton';

const MoveUpDownButtonGroup = (
  {onUp, upDisabled = false, onDown, downDisabled = false},
  ref
) => (
  <ButtonGroup ref={ref} size="sm">
    <MoveUpIconButton disabled={upDisabled} onClick={onUp} />
    <MoveDownIconButton disabled={downDisabled} onClick={onDown} />
  </ButtonGroup>
);
MoveUpDownButtonGroup.displayName = 'MoveUpDownButtonGroup';

export default forwardRef(MoveUpDownButtonGroup);
