/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import GuardianRouteLinkItem from './GuardianRouteLinkItem';
import CalendarIcon from 'icons/CalendarIcon';

const messages = defineMessages({
  title: {id: 'navigation.calendar'},
});

const CalendarItem = () => (
  <GuardianRouteLinkItem
    iconBefore={CalendarIcon}
    route="midas/guardian/calendar"
    title={messages.title}
  />
);

export default CalendarItem;
