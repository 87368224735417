/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import {Link} from 'react-router-dom';
import {Button} from 'react-bootstrap';
import {useFormatMessage} from 'app/hooks';
import PreviousIcon from 'icons/PreviousIcon';

const messages = defineMessages({
  title: {
    id: 'label.button.previous',
    description: 'Previous button label',
    defaultMessage: 'Previous',
  },
});

const PrevIconButton = (
  {variant = 'light', title = messages.title, href, ...props},
  ref
) => {
  const titleText = useFormatMessage(title, title.values);
  if (href) {
    return (
      <Button
        ref={ref}
        as={Link}
        to={href}
        variant={variant}
        title={titleText}
        {...props}
      >
        <PreviousIcon />
      </Button>
    );
  }
  return (
    <Button ref={ref} variant={variant} title={titleText} {...props}>
      <PreviousIcon />
    </Button>
  );
};
PrevIconButton.displayName = 'PrevIconButton';

export default forwardRef(PrevIconButton);
