/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import SecurityIcon from 'icons/SecurityIcon';
import DistrictUsersItem from './security/DistrictUsersItem';
import EducatorGrowthAdministratorsItem from './security/EducatorGrowthAdministratorsItem';
import GrantAdministratorsItem from './security/GrantAdministratorsItem';
import GrantAwardAdministratorsItem from './security/GrantAwardAdministratorsItem';
import PdAdministratorsItem from './security/PdAdministratorsItem';
import PermissionsItem from './security/PermissionsItem';
import ReportsPermissionsItem from './security/ReportsPermissionsItem';
import RtiAdministratorsItem from './security/RtiAdministratorsItem';
import SchedulingAdministratorsItem from './security/SchedulingAdministratorsItem';
import SitePageEditorsItem from './security/SitePageEditorsItem';
import UserRelationshipPermissionsItem from './security/UserRelationshipPermissionsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.security'},
  sectionTitle: {id: 'navigation.settings.security-settings'},
});

const SecurityMenu = () => (
  <NavigationMenu
    iconBefore={SecurityIcon}
    route="midas/settings/security"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <PermissionsItem />
    <DistrictUsersItem />
    <UserRelationshipPermissionsItem />
    <SitePageEditorsItem />
    <EducatorGrowthAdministratorsItem />
    <GrantAdministratorsItem />
    <GrantAwardAdministratorsItem />
    <PdAdministratorsItem />
    <ReportsPermissionsItem />
    <RtiAdministratorsItem />
    <SchedulingAdministratorsItem />
  </NavigationMenu>
);

export default SecurityMenu;
