/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import TransportationIcon from 'icons/TransportationIcon';

const messages = defineMessages({
  title: {id: 'navigation.transportation'},
});

const TransportationItem = () => (
  <StudentRouteLinkItem
    iconBefore={TransportationIcon}
    route="midas/student/transportation"
    title={messages.title}
  />
);

export default TransportationItem;
