/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import BehaviorIcon from 'icons/BehaviorIcon';

const messages = defineMessages({
  title: {id: 'navigation.behavior-concerns'},
});

const BehaviorConcernsItem = () => (
  <StudentRouteLinkItem
    iconBefore={BehaviorIcon}
    route="midas/student/behavior-concerns"
    title={messages.title}
  />
);

export default BehaviorConcernsItem;
