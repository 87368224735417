/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.reflection-templates'},
});

const ReflectionTemplatesItem = () => (
  <RouteLinkItem
    route="midas/microcredentialing/reflection-templates"
    title={messages.title}
  />
);

export default ReflectionTemplatesItem;
