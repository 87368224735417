/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import AtlaskitIcon from '@atlaskit/icon';
import NavSelectIndicatorIcon from 'icons/NavSelectIndicatorIcon';

const style = {
  fontSize: '1.3em',
};

const SelectIcon = ({label}) => {
  const Glyph = () => <NavSelectIndicatorIcon style={style} fixedWidth />;
  return <AtlaskitIcon glyph={Glyph} label={label} />;
};

export default SelectIcon;
