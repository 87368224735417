import {useMidasJsonApi} from 'app/hooks';
import {ApiResponseWrapper} from 'components/layout';
import PathwayStep from 'components/microcredentials/public/PathwayStep';

const PathwaySteps = ({pathwayID}) => {
  const [response, loading, error] = useMidasJsonApi({
    url: `/api/microcredentials/badge-pathway-steps?pathwayID=${pathwayID}`,
    expectedStatus: [200, 404],
  });

  return (
    <>
      <ApiResponseWrapper error={error} isLoading={loading}>
        {response.status === 200 &&
          response.json.badgePathwaySteps.map((step) => {
            return (
              <div className="pl-4">
                {response.json.badgePathwaySteps.length > 1 ? (
                  <h6>Steps</h6>
                ) : (
                  <h6>Microcredentials</h6>
                )}
                <PathwayStep
                  step={step}
                  stepCount={response.json.badgePathwaySteps.length}
                />
              </div>
            );
          })}
      </ApiResponseWrapper>
    </>
  );
};

export default PathwaySteps;
