/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import LanguageCitizenshipIcon from 'icons/LanguageCitizenshipIcon';

const messages = defineMessages({
  title: {id: 'navigation.language-citizenship'},
});

const LanguageCitizenshipItem = () => (
  <StudentRouteLinkItem
    iconBefore={LanguageCitizenshipIcon}
    route="midas/student/language-citizenship"
    title={messages.title}
  />
);

export default LanguageCitizenshipItem;
