/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useUserContext} from 'app/UserContext';
import {PUSH_MENU, useNavigationContext} from 'app/NavigationContext';
import {ButtonItem} from '@atlaskit/side-navigation';
import {
  NestingItemIcon,
  nestingItemStyle,
} from 'components/navigation/side-navigation/icon/NestingItemIcon';
import UserName from 'components/user/UserName';
import UserAvatar from 'components/UserAvatar';

const CurrentUserItem = ({contextParam, menuID}) => {
  const [context] = useUserContext();
  const {dispatch} = useNavigationContext();

  const userID = context[contextParam];
  if (!userID) {
    return null;
  }

  const AvatarIcon = () => (
    <UserAvatar userID={userID} appearance="square" size="small" />
  );

  const handleClick = () => {
    dispatch({
      type: PUSH_MENU,
      payload: menuID,
    });
  };

  return (
    <ButtonItem
      iconBefore={<AvatarIcon />}
      iconAfter={<NestingItemIcon />}
      cssFn={nestingItemStyle}
      onClick={handleClick}
    >
      <UserName userID={userID} />
    </ButtonItem>
  );
};

export default CurrentUserItem;
