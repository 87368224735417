/**
 * Hook that sets a new document title by concatenating all arguments using ' - '.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';

const useDocumentTitle = (title) => {
  useEffect(() => {
    document.title = `${title} - MIDAS`;
  }, [title]);
  return null;
};

export default useDocumentTitle;
