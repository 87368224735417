import {ApiResponseWrapper} from 'components/layout';
import {Col, Row} from 'react-bootstrap';
import {useMidasJsonApi} from 'app/hooks';
import PathwayStepItem from 'components/microcredentials/public/PathwayStepItem';

const PathwayStep = ({step, stepCount}) => {
  const [response, isLoading, error] = useMidasJsonApi({
    url: `/api/microcredentials/badge-pathway-step-badge?stepID=${step.id}`,
    expectedStatus: [200, 404],
  });

  return (
    <ApiResponseWrapper isLoading={isLoading} error={error}>
      {stepCount > 1 && (
        <Col id="step-pill" sm={12} md={6} lg={4} xl={3} className="mb-5">
          <Row>
            <div
              className="text-center bg-warning rounded-circle"
              style={{width: '42px', height: '42px'}}
            >
              <span
                style={{fontSize: '26px'}}
                className="font-weight-bold text-center text-white"
              >
                {step.order}
              </span>
            </div>
            <Col className="p-2">
              <div className="d-flex">
                {step.completeAll === 1 ? (
                  <div>Complete All</div>
                ) : (
                  <div>Complete {step.numberRequired}</div>
                )}
              </div>
            </Col>
          </Row>
        </Col>
      )}
      <Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 row-cols-xl-4">
        {response.status === 200 &&
          response.json.badgePathwayStepBadges.map((item, index) => (
            <Col
              id={item.badgeID}
              className="p-3 ml-5 mb-4"
              style={{minHeight: '92px'}}
            >
              <PathwayStepItem
                key={item.badgeID}
                badgeID={item.badgeID}
                id={item.badgeID}
                required={item.required}
              />
            </Col>
          ))}
      </Row>
    </ApiResponseWrapper>
  );
};

export default PathwayStep;
