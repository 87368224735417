/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import health from './staff-member/health';

const staffMember = {
  '': '/midas/staff-member/:staffID',
  attendance: {
    '': '/midas/staff-member/:staffID/attendance',
    'attendance-calendar':
      '/midas/staff-member/:staffID/attendance/attendance-calendar',
    'arrival-dismissal-times':
      '/midas/staff-member/:staffID/attendance/arrival-dismissal-times/:date',
  },
  'auto-call': {
    '': '/midas/staff-member/:staffID/auto-call',
    'add-number': '/midas/staff-member/:staffID/auto-call/add-number',
    'select-number': '/midas/staff-member/:staffID/auto-call/select-number',
  },
  badges: {
    '': '/midas/staff-member/:staffID/badges',
    'add-evidence':
      '/midas/staff-member/:staffID/badges/add-evidence/:badgeID/:templateID/:isUsersOwnPage',
    'add-reflection':
      '/midas/staff-member/:staffID/badges/add-reflection/:badgeID/:templateID/:isUsersOwnPage/:questionID',
    'edit-reflection':
      '/midas/staff-member/:staffID/badges/edit-reflection/:badgeID/:templateID/:isUsersOwnPage/:questionID/:ID',
    'edit-evidence':
      '/midas/staff-member/:staffID/badges/edit-evidence/:badgeID/:isUsersOwnPage/:ID',
    'view-evidence':
      '/midas/staff-member/:staffID/badges/view-evidence/:badgeID/:evidenceID',
  },
  calendar: '/midas/staff-member/:staffID/calendar',
  children: '/midas/staff-member/:staffID/children',
  conferences: {
    '': '/midas/staff-member/:staffID/conferences',
    'ad-hoc-conference':
      '/midas/staff-member/:staffID/conferences/ad-hoc-conference',
    'edit-conference':
      '/midas/staff-member/:staffID/conferences/edit-conference/:conferenceID',
    'view-conference-details':
      '/midas/staff-member/:staffID/conferences/view-conference-details/:conferenceID',
  },
  'contact-information': {
    '': '/midas/staff-member/:staffID/contact-information',
    'add-contact-information':
      '/midas/staff-member/:staffID/contact-information/add-contact-information',
    'edit-contact':
      '/midas/staff-member/:staffID/contact-information/edit-contact/:contactID',
    'view-shared-contact':
      '/midas/staff-member/:staffID/contact-information/view-shared-contact/:contactID/:contactType/:sharedUsers',
  },
  demographics: {
    '': '/midas/staff-member/:staffID/demographics',
    'edit-demographics':
      '/midas/staff-member/:staffID/demographics/edit-demographics',
  },
  employment: {
    '': '/midas/staff-member/:staffID/employment',
    'add-employment': '/midas/staff-member/:staffID/employment/add-employment',
    'edit-employment':
      '/midas/staff-member/:staffID/employment/edit-employment/:employmentID',
    'add-compliance-check':
      '/midas/staff-member/:staffID/employment/add-compliance-check',
    'edit-compliance-check':
      '/midas/staff-member/:staffID/employment/edit-compliance-check/:complianceID',
  },
  goals: '/midas/staff-member/:staffID/goals',
  'assign-new-growth-plan':
    '/midas/staff-member/:staffID/growth-plan/assign-new-plan',
  'growth-plan': {
    '': '/midas/staff-member/:staffID/growth-plan/:userPlanID?',
    'edit-plan-settings':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-plan-settings',
    'assign-tag':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/assign-tag',
    'add-summative-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/add-summative-comment',
    'edit-summative-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-summative-comment/:summativeCommentID',
    'add-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/add-comment/:planID/:stepID',
    'edit-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-comment/:commentID',
    'sign-step':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/sign-step/:planID/:stepID',
    'view-resources':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/view-resources/:planID',
    'add-supporting-documentation':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/add-supporting-documentation',
    'view-supporting-documentation':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/view-supporting-documentation/:planID',
    'select-growth-area':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/select-growth-area/:planID/:stepID',
    'add-evidence':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/add-evidence/:planID/:stepID',
    'edit-evidence':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-evidence/:evidenceID',
    'view-evidence':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/view-evidence/:evidenceID',
    'add-evidence-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/add-evidence-comment/:evidenceID',
    'edit-evidence-comment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-evidence-comment',
    'edit-observation':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-observation/:observationID',
    'view-observation':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/view-observation/:observationID',
    'edit-conference':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/edit-conference/:conferenceID',
    'mark-step-complete':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/mark-step-complete/:stepID/:planID',
    'mark-step-incomplete':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/mark-step-incomplete/:stepID/:planID',
    'growth-assessment':
      '/midas/staff-member/:staffID/growth-plan/:userPlanID/growth-assessment/:stepID/:planID',
  },
  'growth-resources': '/midas/staff-member/:staffID/growth-resources',
  health,
  'institute-forms': {
    '': '/midas/staff-member/:staffID/institute-forms',
  },
  'institute-panel-preferences': {
    '':
      '/midas/staff-member/:staffID/institute-panel-preferences/:instituteID?',
    'select-panels':
      '/midas/staff-member/:staffID/institute-panel-preferences/:instituteID/select-panels/:panelIDs',
    'edit-panel-rankings':
      '/midas/staff-member/:staffID/institute-panel-preferences/:instituteID/edit-panel-rankings/:panelID',
  },
  'institute-panelist-preferences': {
    '':
      '/midas/staff-member/:staffID/institute-panelist-preferences/:instituteID?',
    'select-panelists':
      '/midas/staff-member/:staffID/institute-panelist-preferences/:instituteID/select-panelists/:panelistIDs',
    'edit-panelist-rankings':
      '/midas/staff-member/:staffID/institute-panelist-preferences/:instituteID/edit-panelist-rankings/:panelistID',
  },
  'instructional-materials': {
    'intent-to-bid': {
      '': '/midas/staff-member/:staffID/instructional-materials/intent-to-bid',
      'add-intent':
        '/midas/staff-member/:staffID/instructional-materials/intent-to-bid/:publisherID/add-intent',
      'edit-subject-area':
        '/midas/staff-member/:staffID/instructional-materials/intent-to-bid/:intentID/edit-subject-area',
    },
    publishers: {
      '': '/midas/staff-member/:staffID/instructional-materials/publishers',
      'edit-publisher':
        '/midas/staff-member/:staffID/instructional-materials/publishers/edit-publisher/:publisherID',
      'add-representative':
        '/midas/staff-member/:staffID/instructional-materials/publishers/:publisherID/add-representative',
      'edit-representative':
        '/midas/staff-member/:staffID/instructional-materials/publishers/edit-representative/:publisherUserID',
    },
    'publisher-materials': {
      '':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials',
      detail:
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/detail/:materialID',
      'download-csv':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/download-csv',
      'add-instructional-material':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/add-instructional-material',
      'add-review-collection':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/add-review-collection',
      'edit-review-collection':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/edit-review-collection/:collectionID',
      'view-review-collection':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/view-review-collection/:collectionID',
      'view-materials':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/view-materials',
      'manage-review-collection-materials':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/manage-review-collection-materials/:collectionID',
      'add-pricing-model':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:materialID/add-pricing-model',
      'related-materials':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:materialID/related-materials',
      'add-appeal':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:collectionID/add-appeal',
      'edit-appeal':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:collectionID/edit-appeal/:appealID',
      'add-substitution':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:materialID/add-substitution',
      'edit-substitution':
        '/midas/staff-member/:staffID/instructional-materials/publisher-materials/:materialID/edit-substitution/:substitutionID',
    },
    'reviewer-materials': {
      '':
        '/midas/staff-member/:staffID/instructional-materials/reviewer-materials',
      detail:
        '/midas/staff-member/:staffID/instructional-materials/reviewer-materials/detail/:materialID',
      'view-review-collection':
        '/midas/staff-member/:staffID/instructional-materials/reviewer-materials/view-review-collection/:collectionID',
      'evaluate-review-collection':
        '/midas/staff-member/:staffID/instructional-materials/reviewer-materials/evaluate-review-collection/:collectionID',
    },
  },
  'language-citizenship': {
    '': '/midas/staff-member/:staffID/language-citizenship',
    'edit-language-citizenship':
      '/midas/staff-member/:staffID/language-citizenship/edit-language-citizenship',
  },
  'leave-requests': {
    '': '/midas/staff-member/:staffID/leave-requests',
    'add-leave-request':
      '/midas/staff-member/:staffID/leave-requests/add-leave-request',
    'edit-leave-request':
      '/midas/staff-member/:staffID/leave-requests/edit-leave-request/:leaveRequestID',
  },
  licenses: {
    '': '/midas/staff-member/:staffID/licenses',
    'add-license': '/midas/staff-member/:staffID/licenses/add-license',
    'edit-license':
      '/midas/staff-member/:staffID/licenses/edit-license/:licenseID',
    'add-endorsement': '/midas/staff-member/:staffID/licenses/add-endorsement',
    'edit-endorsement':
      '/midas/staff-member/:staffID/licenses/edit-endorsement/:endorsementID',
  },
  mentees: '/midas/staff-member/:staffID/mentees',
  observations: {
    '': '/midas/staff-member/:staffID/observations',
    'edit-observation':
      '/midas/staff-member/:staffID/observations/edit-observation/:observationID',
    'view-observation-details':
      '/midas/staff-member/:staffID/observations/view-observation-details/:observationID',
  },
  'other-contacts': {
    '': '/midas/staff-member/:staffID/other-contacts',
    'add-contact': '/midas/staff-member/:staffID/other-contacts/add-contact',
    'edit-contact':
      '/midas/staff-member/:staffID/other-contacts/edit-contact/:contactID',
  },
  'pd-approval': '/midas/staff-member/:staffID/pd-approval',
  'pd-playlists': {
    '': '/midas/staff-member/:staffID/pd-playlists',
    detail: '/midas/staff-member/:staffID/pd-playlists/:playlistID',
  },
  'pd-transcript': {
    '': '/midas/staff-member/:staffID/pd-transcript',
    'print-transcript':
      '/midas/staff-member/:staffID/pd-transcript/print-transcript',
  },
  photo: {
    '': '/midas/staff-member/:staffID/photo',
    'edit-photo': '/midas/staff-member/:staffID/photo/edit-photo/:yearID',
  },
  'professional-learning': {
    '': '/midas/staff-member/:staffID/professional-learning',
    'class-page':
      '/midas/staff-member/:staffID/professional-learning/class-page/:sectionID/:date?',
  },
  schedule: {
    '': '/midas/staff-member/:staffID/schedule',
    'add-drop-log':
      '/midas/staff-member/:staffID/schedule/add-drop-log/:yearID',
    'edit-enrollment':
      '/midas/staff-member/:staffID/schedule/edit-enrollment/:scheduleID',
  },
  'state-reporting': {
    '': '/midas/staff-member/:staffID/state-reporting',
    'edit-mepid-assignment':
      '/midas/staff-member/:staffID/state-reporting/edit-mepid-assignment',
    'edit-staff-roster':
      '/midas/staff-member/:staffID/state-reporting/edit-staff-roster/:yearID',
    'add-work-assignment':
      '/midas/staff-member/:staffID/state-reporting/add-work-assignment/:yearID',
    'edit-work-assignment':
      '/midas/staff-member/:staffID/state-reporting/edit-work-assignment/:workAssignmentID',
  },
  biography: {
    '': '/midas/staff-member/:staffID/biography',
    'edit-bio': '/midas/staff-member/:staffID/biography/edit-bio',
  },
  timesheets: {
    '': '/midas/staff-member/:staffID/timesheets',
    'add-time-sheet': '/midas/staff-member/:staffID/timesheets/add-time-sheet',
    'edit-time-sheet':
      '/midas/staff-member/:staffID/timesheets/edit-time-sheet/:timeSheetID',
    'edit-time-sheet-default':
      '/midas/staff-member/:staffID/timesheets/edit-time-sheet-default/:dayID',
  },
  'video-evaluation': {
    '': '/midas/staff-member/:staffID/video-evaluation',
    'add-video': '/midas/staff-member/:staffID/video-evaluation/add-video',
    'edit-video':
      '/midas/staff-member/:staffID/video-evaluation/edit-video/:videoID',
    'manage-video-sharing':
      '/midas/staff-member/:staffID/video-evaluation/manage-video-sharing/:videoID',
  },
};

export default staffMember;
