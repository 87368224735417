/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ScheduleIcon from 'icons/ScheduleIcon';

const messages = defineMessages({
  title: {id: 'navigation.ad-hoc-schedule'},
});

const AdHocScheduleItem = () => (
  <RouteLinkItem
    iconBefore={ScheduleIcon}
    route="midas/students/ad-hoc-schedule"
    title={messages.title}
  />
);

export default AdHocScheduleItem;
