/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useMemo} from 'react';
import {wrapNonLocalizedMessageUnlessEmpty} from 'app/intl';

/**
 * Wraps and returns a memoized version the non-localizable message, which is
 * usually generated server-side, in a FormatJS message identifier object so
 * it can be used with components and APIs that expect localizable strings.
 * Returns null if there is no message.
 *
 * @param {string|null|undefined} message
 * @returns {{id: string, values: {message: *}}|null}
 */
const useNonLocalizedMessage = (message) => {
  return useMemo(() => {
    return wrapNonLocalizedMessageUnlessEmpty(message);
  }, [message]);
};

export default useNonLocalizedMessage;
