import {defineMessages, useIntl} from 'react-intl';
import {Form} from 'components/form';
import {Markdown} from 'components/layout';
import CheckoutModal from 'components/form/CheckoutModal';

const messages = defineMessages({
  title: {id: 'public.cart.modal.title.remember-to-checkout'},
  message: {id: 'public.cart.modal.message.remember-to-checkout'},
});

const RememberToCheckoutModal = ({show, goToCart, onCancel, ...props}) => {
  const intl = useIntl();
  return (
    <Form initialValues={{}} {...props}>
      <CheckoutModal
        show={show}
        title={messages.title}
        onCancel={onCancel}
        goToCart={goToCart}
      >
        <Markdown>{intl.formatMessage(messages.message)}</Markdown>
      </CheckoutModal>
    </Form>
  );
};

export default RememberToCheckoutModal;
