/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {isEmpty} from 'lodash';
import {FormattedMessage} from 'react-intl';
import {Form} from 'react-bootstrap';
import FieldLabel from './FieldLabel';

const FieldWrapper = ({id, label, help, required, meta, children}) => {
  if (meta.error && !isEmpty(meta.error) && !meta.error.id) {
    let errorMessage =
      'Form validation errors must be react-intl compatible message descriptor objects containing at minimum an `id` key.\n' +
      `Received ${typeof meta.error} instead: ${JSON.stringify(meta.error)}`;
    throw new Error(errorMessage);
  }

  return (
    <>
      <FieldLabel id={id} label={label} required={required} />
      {help && (
        <Form.Text id={`${id}-help`}>
          <FormattedMessage {...help} />
        </Form.Text>
      )}
      {children}
      {meta.error && !isEmpty(meta.error) && meta.touched && (
        <Form.Control.Feedback
          id={`${id}-error`}
          type="invalid"
          aria-live="polite"
          style={{
            /* Override Bootstrap CSS rules since we control visibility here; fixes problem with complex field types like date picker */
            display: 'block',
          }}
        >
          <FormattedMessage {...meta.error} />
        </Form.Control.Feedback>
      )}
    </>
  );
};

export default FieldWrapper;
