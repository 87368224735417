/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {
    id: 'navigation.settings.educator-growth.professional-learning-communities',
  },
});

const ProfessionalLearningCommunitiesItem = () => (
  <RouteLinkItem
    route="midas/settings/educator-growth/professional-learning-communities"
    title={messages.title}
  />
);

export default ProfessionalLearningCommunitiesItem;
