/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import AddStaffIcon from 'icons/AddStaffIcon';

const messages = defineMessages({
  title: {id: 'navigation.add'},
});

const AddItem = () => (
  <RouteLinkItem
    iconBefore={AddStaffIcon}
    route="midas/staff/add"
    title={messages.title}
  />
);

export default AddItem;
