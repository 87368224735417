/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import ActivitiesIcon from 'icons/ActivitiesIcon';
import ActivitiesItem from './activities/ActivitiesItem';
import ParticipationRequirementsItem from './activities/ParticipationRequirementsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.activities'},
  sectionTitle: {id: 'navigation.settings.activities-settings'},
});

const ActivitiesMenu = () => (
  <NavigationMenu
    iconBefore={ActivitiesIcon}
    route="midas/settings/activities"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ActivitiesItem />
    <ParticipationRequirementsItem />
  </NavigationMenu>
);

export default ActivitiesMenu;
