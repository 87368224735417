/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useFormikContext} from 'formik';
import {Button} from 'react-bootstrap';

const messages = defineMessages({
  dismiss: {id: 'button.dismiss'},
});

const DismissButton = (
  {message = messages.dismiss, variant = 'danger', disabled = false, ...props},
  ref
) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button
      ref={ref}
      variant={variant}
      disabled={isSubmitting || disabled}
      {...props}
    >
      <FormattedMessage {...message} />
    </Button>
  );
};
DismissButton.displayName = 'DismissButton';

export default forwardRef(DismissButton);
