/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import IconActionButton from './IconActionButton';
import EditIcon from 'icons/EditIcon';

const messages = defineMessages({
  edit: {id: 'button.edit'},
});

const EditActionButton = ({message = messages.edit, ...props}, ref) => (
  <IconActionButton ref={ref} icon={EditIcon} message={message} {...props} />
);
EditActionButton.displayName = 'EditActionButton';

export default forwardRef(EditActionButton);
