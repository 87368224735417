/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PdTranscriptIcon from 'icons/PdTranscriptIcon';

const messages = defineMessages({
  title: {id: 'navigation.pd-transcript'},
});

const PdTranscriptItem = () => (
  <RouteLinkItem
    iconBefore={PdTranscriptIcon}
    route="midas/my/pd-transcript"
    title={messages.title}
  />
);

export default PdTranscriptItem;
