/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useFeature} from 'app/hooks';
import {HIDE_GUARDIAN_NAVIGATION} from 'app/features';
import NavigationMenu from './NavigationMenu';
import GuardiansIcon from 'icons/GuardiansIcon';
import AddItem from './guardians/AddItem';
import CurrentGuardianItem from './guardians/CurrentGuardianItem';
import SearchItem from './guardians/SearchItem';

const messages = defineMessages({
  title: {id: 'navigation.guardians'},
});

const GuardiansMenu = () => {
  const hideNavigation = useFeature(HIDE_GUARDIAN_NAVIGATION);
  if (hideNavigation) {
    return null;
  }

  return (
    <NavigationMenu
      iconBefore={GuardiansIcon}
      route="midas/guardians"
      title={messages.title}
    >
      <CurrentGuardianItem />
      <SearchItem />
      <AddItem />
    </NavigationMenu>
  );
};

export default GuardiansMenu;
