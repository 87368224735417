/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import SettingsIcon from 'icons/SettingsIcon';
import AcademicsMenu from './settings/AcademicsMenu';
import ActivitiesMenu from './settings/ActivitiesMenu';
import AttendanceMenu from './settings/AttendanceMenu';
import BehaviorMenu from './settings/BehaviorMenu';
import BillingMenu from './settings/BillingMenu';
import CalendarMenu from './settings/CalendarMenu';
import EducatorGrowthMenu from './settings/EducatorGrowthMenu';
import EndOfYearMenu from './settings/EndOfYearMenu';
import FacilitiesMenu from './settings/FacilitiesMenu';
import FinancialMenu from './settings/FinancialMenu';
import FoodServicesMenu from './settings/FoodServicesMenu';
import HealthMenu from './settings/HealthMenu';
import HumanResourcesMenu from './settings/HumanResourcesMenu';
import InstitutesMenu from './settings/InstitutesMenu';
import InterventionMenu from './settings/InterventionMenu';
import LeaveRequestsMenu from './settings/LeaveRequestsMenu';
import ProfessionalDevelopmentMenu from './settings/ProfessionalDevelopmentMenu';
import RegistrationFormsMenu from './settings/RegistrationFormsMenu';
import SchoolsMenu from './settings/SchoolsMenu';
import SecurityMenu from './settings/SecurityMenu';
import SelfRegistrationConfigurationItem from './settings/self-registration/SelfRegistrationConfigurationItem';
import SitePagesItem from './settings/SitePagesItem';
import StandardsMenu from './settings/StandardsMenu';
import TimesheetsMenu from './settings/TimesheetsMenu';
import TransportationMenu from './settings/TransportationMenu';

const messages = defineMessages({
  title: {id: 'navigation.settings'},
});

const SettingsMenu = () => (
  <NavigationMenu
    iconBefore={SettingsIcon}
    route="midas/settings"
    title={messages.title}
  >
    <AcademicsMenu />
    <ActivitiesMenu />
    <AttendanceMenu />
    <BehaviorMenu />
    <BillingMenu />
    <CalendarMenu />
    <EducatorGrowthMenu />
    <EndOfYearMenu />
    <FacilitiesMenu />
    <FinancialMenu />
    <FoodServicesMenu />
    <HealthMenu />
    <HumanResourcesMenu />
    <InstitutesMenu />
    <InterventionMenu />
    <LeaveRequestsMenu />
    <ProfessionalDevelopmentMenu />
    <RegistrationFormsMenu />
    <SchoolsMenu />
    <SelfRegistrationConfigurationItem />
    <SitePagesItem />
    <StandardsMenu />
    <TimesheetsMenu />
    <TransportationMenu />
    <SecurityMenu />
  </NavigationMenu>
);

export default SettingsMenu;
