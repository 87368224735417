/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import catalog from './professional-development/catalog';
import courses from './professional-development/courses';

const professionalDevelopment = {
  '': '/professional-development',
  catalog,
  courses,
};

export default professionalDevelopment;
