import {Button, Col, Row} from 'react-bootstrap';
import {RouteLink, urlFromRoute} from 'app/routing';
import {Markdown} from 'components/layout';
import {useContext, useEffect, useMemo, useState} from 'react';
import {UserContext} from 'app/UserContext';
import {useMidasJsonApi} from 'app/hooks';
import {FormattedMessage} from 'react-intl';
import {useHistory} from 'react-router-dom';

const imageSize = {
  maxWidth: '175px',
  maxHeight: '175px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
};

const PathwayInfo = ({pathway}) => {
  const history = useHistory();
  const [context] = useContext(UserContext);
  const [inProgress, setInProgress] = useState(false);

  const [pathwayUserResponse] = useMidasJsonApi({
    url: `/api/microcredentials/badge-pathway-user?pathwayID=${pathway.id}&userID=${context.userID}`,
    expectedStatus: [200, 422],
  });

  const [, , , requestPatch] = useMidasJsonApi({
    method: 'PATCH',
    url: `/midas/rest/microcredentials/${pathway.id}`,
    expectedStatus: [200, 422],
    immediate: false,
  });

  const isLoggedIn = useMemo(() => {
    return context.userID !== 0;
  }, [context]);

  useEffect(() => {
    if (pathwayUserResponse.status === 200) {
      if (pathwayUserResponse.json.badgePathwayUsers.length > 0) {
        setInProgress(true);
      }
    }
  }, [pathwayUserResponse]);

  const pathwayFileURL = pathway.fileUrl
    ? pathway.fileUrl
    : '/images/missing-micro.png';

  const button = useMemo(() => {
    if (inProgress) {
      return (
        <Button
          className="mt-2 mb-2"
          variant="primary"
          onClick={() => {
            history.push(
              urlFromRoute('midas/my/microcredentials/pathway', {
                pathwayID: pathway.id,
              })
            );
          }}
        >
          <FormattedMessage id="microcredentials.public.catalog.start-working" />
        </Button>
      );
    } else {
      return (
        <Button
          className="mt-2 mb-2"
          variant="success"
          onClick={() => {
            setInProgress(true);
            requestPatch({
              body: {
                entity: 'badgePathwayUser',
              },
            });
          }}
        >
          <FormattedMessage id="microcredentials.public.catalog.earn-this-pathway" />
        </Button>
      );
    }
  }, [inProgress, requestPatch, history, pathway]);

  return (
    <Row>
      <Col lg={2}>
        <img
          style={imageSize}
          src={pathwayFileURL}
          alt="Curriculum Icon"
          className="mb-4"
        />
      </Col>
      <Col>
        <div className="d-flex">
          <h6>
            <RouteLink route="pathways/detail" params={{pathwayID: pathway.id}}>
              {pathway.name}
            </RouteLink>
          </h6>
        </div>
        {isLoggedIn && <>{button}</>}
        <p>
          <Markdown>{pathway.description}</Markdown>
        </p>
      </Col>
    </Row>
  );
};

export default PathwayInfo;
