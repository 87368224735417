/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {HeadingItem} from '@atlaskit/side-navigation';
import {useUserContext} from 'app/UserContext';
import {useStudentName} from 'app/hooks';
import NavigationMenu from '../NavigationMenu';
import StateReportingIcon from 'icons/StateReportingIcon';
import PimsItem from './state-reporting/PimsItem';
import SimsItem from './state-reporting/SimsItem';

const messages = defineMessages({
  title: {id: 'navigation.state-reporting'},
});

const StateReportingMenu = () => {
  const [{currentStudentID}] = useUserContext();
  const name = useStudentName(currentStudentID);

  return (
    <NavigationMenu
      iconBefore={StateReportingIcon}
      route="midas/student/state-reporting"
      title={messages.title}
    >
      <HeadingItem>{name}</HeadingItem>
      <PimsItem />
      <SimsItem />
    </NavigationMenu>
  );
};

export default StateReportingMenu;
