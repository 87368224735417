/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import GrantsIcon from 'icons/GrantsIcon';

const messages = defineMessages({
  title: {id: 'navigation.grants.grant-awards'},
});

const GrantAwardsItem = () => (
  <RouteLinkItem
    iconBefore={GrantsIcon}
    route="midas/grants/awards"
    title={messages.title}
  />
);

export default GrantAwardsItem;
