/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import MeetingIcon from 'icons/MeetingIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.meetings'},
});

const MeetingsItem = () => (
  <EventRouteLinkItem
    iconBefore={MeetingIcon}
    route="midas/event/meetings"
    title={messages.title}
  />
);

export default MeetingsItem;
