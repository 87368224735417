import {
  DateField,
  Form,
  FormGroup,
  FormRow,
  PasswordField,
  PhoneNumberField,
  SelectField,
  SubmitCancelButtonToolbar,
  TextField,
} from 'components/form';
import {Col} from 'react-bootstrap';
import {defineMessages} from 'react-intl';
import {useMidasJsonApi} from 'app/hooks';
import {useMemo} from 'react';
import {ApiResponseWrapper} from 'components/layout';
import {isEmpty, toNumber} from 'lodash';
import {wrapNonLocalizedMessage} from 'app/intl';
import * as yup from 'app/yup';

const messages = defineMessages({
  emailLabel: {
    id: 'midas.self-registration.public.form.field.email.label',
    defaultMessage: 'Email',
  },
  emailPlaceHolder: {
    id: 'midas.self-registration.public.form.field.email.placeholder',
    defaultMessage: 'Enter a valid email address',
  },
  passwordLabel: {
    id: 'midas.self-registration.public.form.field.password.label',
    defaultMessage: 'Password',
  },
  passwordPlaceHolder: {
    id: 'midas.self-registration.public.form.field.password.placeholder',
    defaultMessage: 'Enter your password (8 characters minimum)',
  },
  schoolNameLabel: {
    id: 'midas.self-registration.public.form.field.school.label',
    defaultMessage: 'School',
  },
  firstNameLabel: {
    id: 'midas.self-registration.public.form.field.first-name.label',
    defaultMessage: 'First Name',
  },
  firstNamePlaceHolder: {
    id: 'midas.self-registration.public.form.field.first-name.placeholder',
    defaultMessage: 'Enter your first name',
  },
  lastNameLabel: {
    id: 'midas.self-registration.public.form.field.last-name.label',
    defaultMessage: 'Last Name',
  },
  lastNamePlaceHolder: {
    id: 'midas.self-registration.public.form.field.last-name.placeholder',
    defaultMessage: 'Enter your last name',
  },
  dobLabel: {
    id: 'midas.self-registration.public.form.field.dob.label',
    defaultMessage: 'Date of Birth',
  },
  phoneNumberLabel: {
    id: 'midas.self-registration.public.form.field.phone-number.label',
    defaultMessage: 'Phone Number',
  },
  genderLabel: {
    id: 'midas.self-registration.public.form.field.gender.label',
    defaultMessage: 'Gender',
  },
  female: {
    id: 'midas.self-registration.public.form.field.gender.female',
    defaultMessage: 'Female',
  },
  male: {
    id: 'midas.self-registration.public.form.field.gender.male',
    defaultMessage: 'Male',
  },
  nonBinary: {
    id: 'midas.self-registration.public.form.field.gender.non-binary',
    defaultMessage: 'Non Binary',
  },
  unknown: {
    id: 'midas.self-registration.public.form.field.gender.unknown',
    defaultMessage: 'Prefer not to specify',
  },
  roleLabel: {
    id: 'midas.self-registration.public.form.field.role.label',
    defaultMessage: 'Role',
  },
  student: {
    id: 'midas.self-registration.public.form.field.role.student',
    defaultMessage: 'Student',
  },
  staff: {
    id: 'midas.self-registration.public.form.field.role.staff',
    defaultMessage: 'Staff',
  },
  parent: {
    id: 'midas.self-registration.public.form.field.role.parent',
    defaultMessage: 'Parent',
  },
});

// options for the gender selectField
const genderOptions = [
  {value: 1, label: messages.female},
  {value: 2, label: messages.male},
  {value: 3, label: messages.nonBinary},
  {value: 4, label: messages.unknown},
];

const roleOptions = [
  {value: 1, label: messages.student},
  {value: 2, label: messages.staff},
  {value: 3, label: messages.parent},
];

const validationSchema = yup.object().shape({
  email: yup.string().required(),
  password: yup.string().min(8).required(),
  schoolID: yup.number().required(),
  roleID: yup.number().required(),
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  gender: yup.number().required(),
  dob: yup.string().required(),
  phoneNumber: yup.string().required(),
});

const SelfRegistrationForm = ({initialValues, onCancel, ...props}) => {
  const [response] = useMidasJsonApi({
    url: `/api/self-registration`,
    expectedStatus: [200],
  });

  const schoolDbOptions = useMemo(() => {
    const schoolValues = [];

    if (response.status === 200) {
      for (let school of response.json.schools) {
        // if it's not invite only show schools that allow walk ups otherwise populate them all
        // because the field will be disabled with the specific school aready selected
        if (school.allowWalkUp && initialValues.schoolID === '') {
          schoolValues.push({
            item: {value: toNumber(school.schoolID), text: school.schoolName},
          });
        } else if (initialValues.schoolID !== '') {
          schoolValues.push({
            item: {value: toNumber(school.schoolID), text: school.schoolName},
          });
        }
      }

      return schoolValues.map((schoolValue) => schoolValue.item);
    }
  }, [response, initialValues]);

  const schoolOptions = useMemo(() => {
    if (isEmpty(schoolDbOptions)) {
      return [];
    }
    return schoolDbOptions.map(({value, text}) => {
      return {value, label: wrapNonLocalizedMessage(text)};
    });
  }, [schoolDbOptions]);

  return (
    <Form
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      {...props}
    >
      <FormRow>
        <Col>
          <FormGroup>
            <TextField
              label={messages.emailLabel}
              name="email"
              placeholder={messages.emailPlaceHolder}
              disabled={initialValues.email}
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <PasswordField
              label={messages.passwordLabel}
              name="password"
              required
            />
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormGroup>
            <ApiResponseWrapper>
              {response.status === 200 && (
                <SelectField
                  label={messages.schoolNameLabel}
                  name="schoolID"
                  options={schoolOptions}
                  disabled={initialValues.schoolID}
                  required
                />
              )}
            </ApiResponseWrapper>
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormGroup>
            <TextField
              label={messages.firstNameLabel}
              name="firstName"
              placeholder={messages.firstNamePlaceHolder}
              required
            />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <TextField
              label={messages.lastNameLabel}
              name="lastName"
              placeholder={messages.lastNamePlaceHolder}
              required
            />
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormGroup>
            <SelectField
              label={messages.genderLabel}
              name="gender"
              options={genderOptions}
              required
            />
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormGroup>
            <DateField name="dob" label={messages.dobLabel} required />
          </FormGroup>
        </Col>
        <Col>
          <FormGroup>
            <PhoneNumberField
              name="phoneNumber"
              label={messages.phoneNumberLabel}
              required
            />
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col>
          <FormGroup>
            <SelectField
              label={messages.roleLabel}
              name="roleID"
              options={roleOptions}
              required
            />
          </FormGroup>
        </Col>
      </FormRow>
      <FormRow>
        <Col className="mb-4">
          <SubmitCancelButtonToolbar onCancel={onCancel} className="mt-4" />
        </Col>
      </FormRow>
    </Form>
  );
};

export default SelfRegistrationForm;
