/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const catalog = {
  '': '/professional-development/catalog',
  cart: '/professional-development/catalog/cart',
  help: '/professional-development/catalog/help',
  introduction: '/professional-development/catalog/introduction',
  detail: '/professional-development/catalog/detail',
  section: {
    enroll: '/professional-development/catalog/section/enroll',
    unenroll: '/professional-development/catalog/section/unenroll',
  },
};

export default catalog;
