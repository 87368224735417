/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChartPie} from '@fortawesome/pro-duotone-svg-icons';

const DataExplorerIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faChartPie} {...props} />
);

export default DataExplorerIcon;
