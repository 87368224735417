/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages, FormattedMessage} from 'react-intl';
import {RouteLink} from 'app/routing';
import {useFormatMessage} from 'app/hooks';
import {Markdown} from 'components/layout';

const messages = defineMessages({
  message: {
    id: 'text.password-reset-sent-message',
    description: 'Message block for the password reset confirmation page',
    defaultMessage:
      'We have sent an email to the address linked to your account containing instructions for resetting your password.\n\n' +
      'It can take a few minutes for the message to arrive. If you don’t see it in your inbox, check your spam folders.\n\n' +
      'If you don’t receive the message, or if you are unable to successfully reset your password, contact your site administrator for assistance.',
  },
});

const PasswordResetSent = () => {
  const message = useFormatMessage(messages.message);

  return (
    <>
      <Markdown>{message}</Markdown>

      <p className="mb-0 text-center small">
        <RouteLink route="login">
          <FormattedMessage
            id="link.back-to-login"
            description="Link text back to the login page"
            defaultMessage="← Back to login"
          />
        </RouteLink>
      </p>
    </>
  );
};

export default PasswordResetSent;
