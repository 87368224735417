/**
 * Returns a new string concatenating the staff member name and localized title using ' - '.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {toNumber} from 'lodash';
import useStaffName from './useStaffName';

const useStaffPageTitle = (descriptor, values) => {
  const match = useRouteMatch();

  const staffName = useStaffName(toNumber(match.params.staffID ?? 0));

  const intl = useIntl();
  const title = intl.formatMessage(descriptor, values);

  return `${staffName} - ${title}`;
};

export default useStaffPageTitle;
