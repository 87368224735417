/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import DataForgeIcon from 'icons/DataForgeIcon';
import DataSuitesItem from 'components/navigation/side-navigation/menu/data-suites/DataSuitesItem';
import DashboardsItem from 'components/navigation/side-navigation/menu/data-suites/DashboardsItem';

const messages = defineMessages({
  title: {id: 'navigation.data-forge'},
});

const DataForgeMenu = () => (
  <NavigationMenu
    iconBefore={DataForgeIcon}
    route="midas/data-forge"
    title={messages.title}
  >
    <DataSuitesItem />
    <DashboardsItem />
  </NavigationMenu>
);

export default DataForgeMenu;
