/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {SET_STACK, useNavigationContext} from 'app/NavigationContext';
import {
  SideNavigation as AtlaskitSideNavigation,
  NestableNavigationContent,
  NavigationHeader,
} from '@atlaskit/side-navigation';
import SideNavigationLogo from './SideNavigationLogo';
import ChangeSchoolItem from './item/ChangeSchoolItem';
import RootMenus from './RootMenus';
import DetachedMenus from './DetachedMenus';

const SideNavigation = () => {
  const {stack, dispatch} = useNavigationContext();

  const handleChange = (newStack) => {
    dispatch({
      type: SET_STACK,
      payload: newStack,
    });
  };

  return (
    <AtlaskitSideNavigation>
      <NavigationHeader>
        <SideNavigationLogo />
        <ChangeSchoolItem />
      </NavigationHeader>
      <NestableNavigationContent stack={stack} onChange={handleChange}>
        <RootMenus />
        <DetachedMenus />
      </NestableNavigationContent>
    </AtlaskitSideNavigation>
  );
};

export default SideNavigation;
