/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import StateReportingIcon from 'icons/StateReportingIcon';

const messages = defineMessages({
  title: {id: 'navigation.state-reporting.iowa'},
});

const IowaStateReportingItem = () => (
  <StudentRouteLinkItem
    iconBefore={StateReportingIcon}
    route="midas/student/ia-state-reporting"
    title={messages.title}
  />
);

export default IowaStateReportingItem;
