import PublicContainer from 'components/layout/PublicContainer';
import PublicHeader from 'components/layout/PublicHeader';
import PublicNav from 'components/layout/PublicNav';
import PublicFooter from 'components/layout/PublicFooter';
import {ApiResponseWrapper} from 'components/layout';
import PathwayDetailContent from 'components/microcredentials/public/PathwayDetailContent';
import {useMidasJsonApi} from 'app/hooks';
import {useRouteMatch} from 'react-router-dom';
import {BackToRouteLink} from 'components/link';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  backToPathways: {
    id: 'navigation.back-to-pathways',
  },
});
const Pathway = () => {
  const match = useRouteMatch();

  const [response, loading, error] = useMidasJsonApi({
    url: `/api/curricula/pathways/${match.params.pathwayID}`,
    expectedStatus: [200, 404],
  });

  return (
    <PublicContainer>
      <PublicHeader />
      <PublicNav />
      <p className="small font-weight-light">
        <BackToRouteLink
          text={messages.backToPathways}
          route="microcredentials"
        />
      </p>
      <ApiResponseWrapper isLoading={loading} error={error}>
        {response.status === 200 && (
          <PathwayDetailContent pathway={response.json.badgePathway} />
        )}
      </ApiResponseWrapper>
      <PublicFooter />
    </PublicContainer>
  );
};

export default Pathway;
