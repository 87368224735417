/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const guardians = {
  '': '/midas/guardians',
  add: '/midas/guardians/add',
  edit: '/midas/guardians/edit/:guardianID',
  search: '/midas/guardians/search',
  'link-child': '/midas/guardians/link-child/:guardianID',
  'edit-guardian-permissions':
    '/midas/guardians/edit-guardian-permissions/:guardianID/:studentID',
};

export default guardians;
