/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const health = {
  '': '/midas/staff-member/:staffID/health',
  allergies: '/midas/staff-member/:staffID/health/allergies',
  conditions: '/midas/staff-member/:staffID/health/conditions',
  immunizations: '/midas/staff-member/:staffID/health/immunizations',
  medications: '/midas/staff-member/:staffID/health/medications',
  providers: '/midas/staff-member/:staffID/health/providers',
  screenings: '/midas/staff-member/:staffID/health/screenings',
  visits: '/midas/staff-member/:staffID/health/visits',
};

export default health;
