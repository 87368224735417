/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import Alert from './Alert';

const ErrorAlert = (props) => {
  return <Alert variant="danger" {...props} />;
};

export default ErrorAlert;
