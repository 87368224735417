/**
 * Hook that sets a new document title by concatenating all arguments using ' - '.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useIntl} from 'react-intl';

const useFormatMessage = (descriptor, values) => {
  const intl = useIntl();
  return intl.formatMessage(descriptor, values || descriptor.values);
};

export default useFormatMessage;
