/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const pathways = {
  '': '/pathways',
  detail: '/pathways/detail/:pathwayID',
};

export default pathways;
