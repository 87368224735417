/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {Link, Redirect, generatePath, matchPath} from 'react-router-dom';
import {get} from 'lodash';
import routes from './routes';

export const pathFromRoute = (route) => {
  let path = get(routes, route.split('/'));
  if (typeof path === 'string') {
    return path;
  } else if (typeof path === 'object' && path.hasOwnProperty('')) {
    return path[''];
  }
  throw new Error(`Route named “${route}” does not exist`);
};

export const urlFromRoute = (route, params = {}) => {
  return generatePath(pathFromRoute(route), params);
};

/**
 * Returns the first route that matches the given path, or null if no
 * route was found.
 */
export const pathToRoute = (path) => {
  const components = evaluateRoute([], path, routes);
  if (components.length < 1) {
    return null;
  }
  if (components[components.length] === '') {
    components.pop();
  }
  return components.join('/');
};
const evaluateRoute = (components, path, routes) => {
  for (let [key, value] of Object.entries(routes)) {
    if (path === value) {
      components.push(key);
      return components;
    }
    if (typeof value === 'object') {
      const length = components.push(key);
      components = evaluateRoute(components, path, value);
      if (components.length > length) {
        return components;
      }
      components.pop();
    }
  }
  return components;
};

/**
 * Returns the first route that matches the given url path, or null if no
 * route was found.
 */
export const urlPathToRoute = (urlPath) => {
  const components = evaluateUrlPath([], urlPath, routes);
  if (components.length < 1) {
    return null;
  }
  if (components[components.length - 1] === '') {
    components.pop();
  }
  return components.join('/');
};
const evaluateUrlPath = (components, urlPath, routes) => {
  for (let [key, value] of Object.entries(routes)) {
    const routeMatch = matchPath(urlPath, {
      path: value,
      strict: true,
      exact: true,
    });
    if (routeMatch?.isExact) {
      components.push(key);
      return components;
    }
    if (typeof value === 'object') {
      const length = components.push(key);
      components = evaluateUrlPath(components, urlPath, value);
      if (components.length > length) {
        return components;
      }
      components.pop();
    }
  }
  return components;
};

// TODO: Update all usages with src/components/routing/RouteLink instead
export const RouteLink = ({
  route,
  params = {},
  to,
  state,
  search,
  ...props
}) => {
  if (to !== undefined) {
    throw new Error(`RouteLink cannot have a “to” prop`);
  }
  return (
    <Link
      to={{pathname: urlFromRoute(route, params), state, search}}
      {...props}
    />
  );
};

export const RouteRedirect = ({route, params = {}, to, state, ...props}) => {
  if (to !== undefined) {
    throw new Error(`RouteLink cannot have a “to” prop`);
  }
  return (
    <Redirect to={{pathname: urlFromRoute(route, params), state}} {...props} />
  );
};
