/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const billing = {
  '': '/midas/settings/billing/',
  invoices: {
    '': '/midas/settings/billing/invoices',
    'add-invoice': '/midas/settings/billing/invoices/add-invoice',
    'edit-invoice': '/midas/settings/billing/invoices/edit-invoice/:invoiceID',
    'add-item': '/midas/settings/billing/invoices/add-item/:invoiceID',
    'edit-item': '/midas/settings/billing/invoices/edit-item/:itemID',
    'print-invoice':
      '/midas/settings/billing/invoices/print-invoice/:invoiceID',
  },
  contracts: {
    '': '/midas/settings/billing/contracts',
    'add-contract': '/midas/settings/billing/contracts/add-contract',
    'edit-contract':
      '/midas/settings/billing/contracts/edit-contract/:contractID',
    'sign-contract':
      '/midas/settings/billing/contracts/sign-contract/:contractID',
    'view-contract':
      '/midas/settings/billing/contracts/view-contract/:contractID',
  },
};

export default billing;
