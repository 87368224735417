/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const leaveRequests = {
  '': '/midas/settings/leave-requests',
  'leave-request-managers': {
    '': '/midas/settings/leave-requests/leave-request-managers',
    'add-manager':
      '/midas/settings/leave-requests/leave-request-managers/add-manager',
    'edit-leave-request':
      '/midas/settings/leave-requests/leave-request-managers/edit-leave-request/:leaveRequestID',
  },
  'leave-requests-allowed': {
    '': '/midas/settings/leave-requests/leave-requests-allowed',
    'bulk-assign-leave':
      '/midas/settings/leave-requests/leave-requests-allowed/bulk-assign-leave/:yearID',
    'roll-over-leave':
      '/midas/settings/leave-requests/leave-requests-allowed/roll-over-leave/:yearID',
    'bulk-edit-allowances':
      '/midas/settings/leave-requests/leave-requests-allowed/bulk-edit-allowances/:userID',
    'edit-leave-type-allowance':
      '/midas/settings/leave-requests/leave-requests-allowed/edit-leave-type-allowance/:userID',
  },
  'leave-request-reasons': {
    '': '/midas/settings/leave-requests/leave-request-reasons',
    'add-leave-reason':
      '/midas/settings/leave-requests/leave-request-reasons/add-leave-reason',
    'edit-leave-reason':
      '/midas/settings/leave-requests/leave-request-reasons/edit-leave-reason/:reasonID',
  },
};

export default leaveRequests;
