/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import ServiceIcon from 'icons/ServiceIcon';

const messages = defineMessages({
  title: {id: 'navigation.service-hours'},
});

const ServiceHoursItem = () => (
  <StudentRouteLinkItem
    iconBefore={ServiceIcon}
    route="midas/student/service-hours"
    title={messages.title}
  />
);

export default ServiceHoursItem;
