/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages, useIntl, FormattedMessage} from 'react-intl';
import {ButtonToolbar, Modal} from 'react-bootstrap';
import ConfirmButton from 'components/form/button/ConfirmButton';

const messages = defineMessages({
  confirm: {id: 'button.confirm'},
});

const ErrorConfirmationModal = ({show, title, onConfirm, children}) => {
  const intl = useIntl();

  return (
    <Modal show={show} backdrop="static" keyboard={false} onHide={onConfirm}>
      <Modal.Header
        closeButton={false}
        closeLabel={intl.formatMessage(messages.confirm)}
      >
        <Modal.Title>
          <FormattedMessage {...title} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <ButtonToolbar>
          <ConfirmButton onClick={onConfirm} />
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};

export default ErrorConfirmationModal;
