/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import ResponseToInterventionIcon from 'icons/ResponseToInterventionIcon';
import AdministrationItem from './intervention/AdministrationItem';
import ProgressMonitorsItem from './intervention/ProgressMonitorsItem';
import RtiFormsItem from './intervention/RtiFormsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.intervention'},
  sectionTitle: {id: 'navigation.settings.intervention-settings'},
});

const InterventionMenu = () => (
  <NavigationMenu
    iconBefore={ResponseToInterventionIcon}
    route="midas/settings/intervention"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <AdministrationItem />
    <ProgressMonitorsItem />
    <RtiFormsItem />
  </NavigationMenu>
);

export default InterventionMenu;
