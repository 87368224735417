/* this hook checks for any clicks
 * made outside of the ref'd component,
 * and if so, triggers the funtion passed in
 * */
import {useEffect} from 'react';

const useOutsideAlerter = (ref, functionToCall) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        functionToCall();
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, functionToCall]);
};

export default useOutsideAlerter;
