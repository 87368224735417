/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import InstitutePanelsIcon from 'icons/InstitutePanelsIcon';

const messages = defineMessages({
  title: {id: 'navigation.institute-select-panels'},
});

const InstituteSelectPanelsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={InstitutePanelsIcon}
    route="midas/staff-member/institute-panel-preferences"
    title={messages.title}
  />
);

export default InstituteSelectPanelsItem;
