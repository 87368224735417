/**
 * Internationalization (i18n) and Localization (l10n) provider. Responsible
 * for adding necessary polyfills to support older browsers and setting up
 * the Intl context based on the language specified in the user context.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext, useState, useEffect} from 'react';
import {set, split} from 'lodash';
import {Settings} from 'luxon';
import 'intl-pluralrules';
import 'date-time-format-timezone';
import '@formatjs/intl-getcanonicallocales/polyfill';
import '@formatjs/intl-locale/polyfill';
import '@formatjs/intl-relativetimeformat/polyfill';
import '@formatjs/intl-relativetimeformat/locale-data/en';
import '@formatjs/intl-relativetimeformat/locale-data/es';
import {IntlProvider} from 'react-intl';
import uploadcare from 'uploadcare-widget';
import {UserContext} from './UserContext';
import en from '../messages/en.json';

/* The published API for localizing the react-uploadcare component doesn't
 * actually work, so must call into the the underlying library via a plugin.
 */
const setUploadcareOverrides = (locale, messages) => {
  const localeTranslations = {};
  for (let [key, value] of Object.entries(messages)) {
    if (key.indexOf('uploadcare.') === 0) {
      set(localeTranslations, key.substr(11), value);
    }
  }
  uploadcare.plugin((uc) => {
    uc.locale.rebuild({locale, localeTranslations});
  });
};

const AppIntlProvider = ({children}) => {
  const [context] = useContext(UserContext);
  const [locale] = split(context.language, '-', 1); // en-US >>> en

  const [messages, setMessages] = useState(en);
  useEffect(() => {
    Settings.defaultLocale = locale;
    if (locale === 'en') {
      setMessages(en);
      setUploadcareOverrides(locale, en);
    } else {
      import(`../messages/${locale}.json`).then((module) => {
        setMessages(module.default);
        setUploadcareOverrides(locale, module.default);
      });
    }
  }, [locale]);

  return (
    <IntlProvider locale={locale} messages={messages}>
      {children}
    </IntlProvider>
  );
};

export default AppIntlProvider;
