/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import React from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileCsv} from '@fortawesome/pro-duotone-svg-icons';

const CustomerImportsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faFileCsv} {...props} />
);

export default CustomerImportsIcon;
