/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import FinanceIcon from 'icons/FinanceIcon';
import AuthorizeNetCredentialsItem from './financial/AuthorizeNetCredentialsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.financial'},
  sectionTitle: {id: 'navigation.settings.financial-settings'},
});

const FinancialMenu = () => (
  <NavigationMenu
    iconBefore={FinanceIcon}
    route="midas/settings/financial"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <AuthorizeNetCredentialsItem />
  </NavigationMenu>
);

export default FinancialMenu;
