import {ApiResponseWrapper} from 'components/layout';
import {Col, Row} from 'react-bootstrap';
import {RouteLink} from 'app/routing';
import {truncate} from 'lodash';
import {useMidasJsonApi} from 'app/hooks';
import {useMemo} from 'react';

const imageSize = {
  maxWidth: '60px',
  maxHeight: '60px',
  display: 'block',
};

const PathwayStepItem = ({badgeID, required}) => {
  const [response, isLoading, error] = useMidasJsonApi({
    url: `/api/microcredentials/badge/${badgeID}`,
    expectedStatus: [200, 404],
  });

  const imageUrl = useMemo(() => {
    if (response.status === 200 && response.json[0].file_url) {
      return response.json[0].file_url;
    }
    return '/images/missing-micro.png';
  }, [response]);

  return (
    <ApiResponseWrapper error={error} isLoading={isLoading}>
      {response.status === 200 && (
        <Row className="ml-0 mr-0">
          <div className="p-0 mr-4" style={{maxHeight: '60px'}}>
            <img style={imageSize} src={imageUrl} alt="Badge Icon" />
          </div>
          <div>
            <Row className="ml-0">
              <Col className="p-0">
                <RouteLink
                  style={{fontSize: '.875rem'}}
                  route="microcredentials/detail"
                  params={{badgeID: response.json[0].id}}
                  target="_blank"
                >
                  {truncate(response.json[0].name, {
                    length: 26,
                    omission: '…',
                    separator: /\W/,
                  })}
                </RouteLink>
                <p className="small text-muted">
                  {truncate(response.json[0].description, {
                    length: 120,
                    omission: '…',
                    separator: /\W/,
                  })}
                </p>
              </Col>
            </Row>
            <Row className="ml-0">
              <div className="d-flex">
                {required === 1 && (
                  <div className="p-0 mr-2">
                    <span className="text-muted small">Required</span>
                  </div>
                )}
              </div>
            </Row>
          </div>
        </Row>
      )}
    </ApiResponseWrapper>
  );
};

export default PathwayStepItem;
