/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import classnames from 'classnames';
import './MessageItem.css';

const MessageItem = ({className, ...props}) => (
  <p
    className={classnames('SideNavigationMessageItem', className)}
    {...props}
  />
);

export default MessageItem;
