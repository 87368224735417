/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import ContactInformationIcon from 'icons/ContactInformationIcon';

const messages = defineMessages({
  title: {id: 'navigation.contact-info'},
});

const ContactInfoItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={ContactInformationIcon}
    route="midas/staff-member/contact-information"
    title={messages.title}
  />
);

export default ContactInfoItem;
