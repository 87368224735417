/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useRef} from 'react';
import {uniqueId} from 'lodash';
import {useFormatMessage} from 'app/hooks';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import './ProductNavigationButton.css';

const ProductNavigationButton = ({onClick, title, Icon}) => {
  const formattedTitle = useFormatMessage(title);
  const {current: id} = useRef(uniqueId('navigation-item-'));

  return (
    <OverlayTrigger
      overlay={<Tooltip id={id}>{formattedTitle}</Tooltip>}
      placement="right"
    >
      <button
        onClick={onClick}
        title={formattedTitle}
        className="ProductNavigationButton"
      >
        <Icon />
      </button>
    </OverlayTrigger>
  );
};

export default ProductNavigationButton;
