/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useReducer, useEffect} from 'react';
import useMidasJsonApi from './useMidasJsonApi';
import {buildValidationSchema} from 'app/customerForm';

const initialState = {
  status: 0,
  form: undefined,
  sections: undefined,
  questions: undefined,
  initialValues: undefined,
  validationSchema: undefined,
  error: null,
};

const PROCESS_FORM = 'PROCESS_FORM';
const PROCESS_SECTIONS = 'PROCESS_SECTIONS';
const PROCESS_QUESTIONS = 'PROCESS_QUESTIONS';
const API_ERROR = 'API_ERROR';

const reducer = (state, action) => {
  switch (action.type) {
    case PROCESS_FORM:
      if (action.payload.status === 0) {
        return initialState;
      } else if (action.payload.status !== 200) {
        return {
          ...state,
          status: action.payload.status,
        };
      } else {
        return {
          ...state,
          form: action.payload.json.form,
          status: 200,
        };
      }

    case PROCESS_SECTIONS:
      if (action.payload.status !== 200) {
        return state; // 403, 404 handled in PROCESS_FORM
      }
      return {
        ...state,
        sections: action.payload.json.sections,
      };

    case PROCESS_QUESTIONS:
      if (action.payload.status !== 200) {
        return state; // 403, 404 handled in PROCESS_FORM
      }
      const questions = action.payload.json.questions;
      const validationSchema = buildValidationSchema(questions);
      return {
        ...state,
        questions,
        initialValues: validationSchema.default(),
        validationSchema,
      };

    case API_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

const expectedStatus = [200, 403, 404];

const useCustomerForm = (formApi, formID) => {
  const [
    {status, form, sections, questions, initialValues, validationSchema, error},
    dispatch,
  ] = useReducer(reducer, initialState);

  const [formResponse, , formError] = useMidasJsonApi({
    url: `/midas/rest/${formApi}/form/${formID}`,
    expectedStatus,
  });
  const [sectionsResponse, , sectionsError] = useMidasJsonApi({
    url: `/midas/rest/${formApi}/section/${formID}`,
    expectedStatus,
  });
  const [questionsResponse, , questionsError] = useMidasJsonApi({
    url: `/midas/rest/${formApi}/question/${formID}`,
    expectedStatus,
  });

  useEffect(() => {
    dispatch({type: PROCESS_FORM, payload: formResponse});
  }, [formResponse]);

  useEffect(() => {
    dispatch({type: PROCESS_SECTIONS, payload: sectionsResponse});
  }, [sectionsResponse]);

  useEffect(() => {
    dispatch({type: PROCESS_QUESTIONS, payload: questionsResponse});
  }, [questionsResponse]);

  useEffect(() => {
    if (formError) {
      dispatch({type: API_ERROR, payload: {error: formError}});
    }
    if (sectionsError) {
      dispatch({type: API_ERROR, payload: {error: sectionsError}});
    }
    if (questionsError) {
      dispatch({type: API_ERROR, payload: {error: questionsError}});
    }
  }, [formError, sectionsError, questionsError]);

  return [
    status,
    form,
    sections,
    questions,
    initialValues,
    validationSchema,
    error,
  ];
};

export default useCustomerForm;
