/**
 * MIDAS web application main entry point. Sets up the various top-level providers
 * which are common to every page type, as well as the router and root routes.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import './yup';
import {UserContextProvider} from './UserContext';
import {UserCacheContextProvider} from './UserCacheContext';
import {NavigationContextProvider} from './NavigationContext';
import IntlProvider from './IntlProvider';
import ErrorBoundary from './ErrorBoundary';
import AppRoutes from './AppRoutes';
import FullPageLoading from 'components/FullPageLoading';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const App = () => (
  <UserContextProvider>
    <IntlProvider>
      <BrowserRouter>
        <ErrorBoundary>
          <UserCacheContextProvider>
            <NavigationContextProvider>
              <Suspense fallback={<FullPageLoading delayMs={0} />}>
                <AppRoutes />
              </Suspense>
            </NavigationContextProvider>
          </UserCacheContextProvider>
        </ErrorBoundary>
      </BrowserRouter>
    </IntlProvider>
  </UserContextProvider>
);

export default App;
