/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const financial = {
  '': '/midas/settings/financial',
  'authorize-net-credentials': {
    '': '/midas/settings/financial/authorize-net-credentials',
    'add-authorization-net-info':
      '/midas/settings/financial/authorize-net-credentials/add-authorization-net-info',
    'edit-authorization-net-info':
      '/midas/settings/financial/authorize-net-credentials/edit-authorization-net-info/:authorizationID',
  },
};

export default financial;
