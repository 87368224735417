/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Alert} from 'react-bootstrap';
import {RouteLink} from 'app/routing';
import {useDocumentTitle, useFormatMessage} from 'app/hooks';
import {Markdown} from 'components/layout';
import ResetPasswordForm from 'components/authentication/ResetPasswordForm';
import SetNewPassword from 'components/authentication/SetNewPassword';
import PasswordResetSent from 'components/authentication/PasswordResetSent';

const messages = defineMessages({
  title: {
    id: 'title.reset-password',
    description: 'Reset password page title',
    defaultMessage: 'Reset Password',
  },
  instructionsEmail: {
    id: 'text.password-reset-instructions-email',
    description:
      'Instructions block for the user reset password form on the login page when configured to login with an email address',
    defaultMessage:
      'If you don’t know your password, we can help you reset it.\n\nEnter your email address below and we will send you a one-time link that you can use to choose a new password.',
  },
  instructionsUsername: {
    id: 'text.password-reset-instructions-username',
    description:
      'Instructions block for the user reset password form on the login page when configured to login with a username',
    defaultMessage:
      'If you don’t know your password, we can help you reset it.\n\nEnter your username below and we will send you an email containing a one-time link that you can use to choose a new password.',
  },
});

const ResetPassword = ({usernameMode}) => {
  useDocumentTitle(useFormatMessage(messages.title));

  const [tokenValidationError, setTokenValidationError] = useState(null);
  const [resetSent, setResetSent] = useState(false);

  const match = useRouteMatch();

  const instructions = useFormatMessage(
    usernameMode === 'email'
      ? messages.instructionsEmail
      : messages.instructionsUsername
  );

  if (resetSent) {
    return <PasswordResetSent />;
  }

  if (match.params.token && !tokenValidationError) {
    return (
      <SetNewPassword
        token={match.params.token}
        setTokenValidationError={setTokenValidationError}
      />
    );
  }

  return (
    <>
      {tokenValidationError && (
        <Alert variant="warning">
          <Markdown>{tokenValidationError}</Markdown>
        </Alert>
      )}

      <Markdown>{instructions}</Markdown>

      <ResetPasswordForm
        usernameMode={usernameMode}
        onResetSent={() => {
          setResetSent(true);
        }}
      />

      {usernameMode === 'username' && (
        <p className="text-center">
          <RouteLink route="login/forgot-username">
            <FormattedMessage
              id="link.dont-know-username"
              description="Link text to the recover username page"
              defaultMessage="Don’t know your username?"
            />
          </RouteLink>
        </p>
      )}

      <p className="mb-0 text-center small">
        <RouteLink route="login">
          <FormattedMessage
            id="link.back-to-login"
            description="Link text back to the login page"
            defaultMessage="← Back to login"
          />
        </RouteLink>
      </p>
    </>
  );
};

export default ResetPassword;
