/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import Form from './Form';
import FormError from './FormError';
import FormValuesChangeHandler from './FormValuesChangeHandler';
import {FormGroup, Form as BootstrapForm} from 'react-bootstrap';

import AddActionButton from './button/AddActionButton';
import BigNewActionButton from './button/BigNewActionButton';
import CancelButton from './button/CancelButton';
import ContinueButton from './button/ContinueButton';
import DeleteButton from './button/DeleteButton';
import EditActionButton from './button/EditActionButton';
import EditButton from './button/EditButton';
import EditRemoveButtonToolbar from './button/EditRemoveButtonToolbar';
import IconActionButton from './button/IconActionButton';
import InlineActionButton from './button/InlineActionButton';
import MoveDownIconButton from './button/MoveDownIconButton';
import MoveUpDownButtonGroup from './button/MoveUpDownButtonGroup';
import MoveUpIconButton from './button/MoveUpIconButton';
import NewButton from './button/NewButton';
import NextIconButton from './button/NextIconButton';
import PrevIconButton from './button/PrevIconButton';
import RemoveActionButton from './button/RemoveActionButton';
import RemoveButton from './button/RemoveButton';
import RevertButton from './button/RevertButton';
import SubmitButton from './button/SubmitButton';
import SubmitCancelButtonToolbar from './button/SubmitCancelButtonToolbar';

import CheckboxField from './field/CheckboxField';
import ColorPickerField from './field/ColorPickerField';
import DateField from './field/DateField';
import EmailField from './field/EmailField';
import FileField from './field/FileField';
import NumberField from './field/NumberField';
import OptionsField from './field/OptionsField';
import PasswordField from './field/PasswordField';
import PhoneNumberField from './field/PhoneNumberField';
import RatingButtonsField from './field/RatingButtonsField';
import SegmentedControlField from './field/SegmentedControlField';
import SelectField from './field/SelectField';
import TextField from './field/TextField';
import ToggleField from './field/ToggleField';
/*
const TYPE_PERSON_NAME = 'name';
const TYPE_ADDRESS = 'addr';
*/

import RemoveConfirmationModal from './RemoveConfirmationModal';
import RevertConfirmationModal from './RevertConfirmationModal';
import ErrorConfirmationModal from './ErrorConfirmationModal';

const FormRow = BootstrapForm.Row;

export {
  AddActionButton,
  BigNewActionButton,
  CancelButton,
  CheckboxField,
  ColorPickerField,
  ContinueButton,
  DateField,
  DeleteButton,
  EditActionButton,
  EditButton,
  EditRemoveButtonToolbar,
  EmailField,
  ErrorConfirmationModal,
  FileField,
  Form,
  FormError,
  FormGroup,
  FormRow,
  FormValuesChangeHandler,
  IconActionButton,
  InlineActionButton,
  MoveDownIconButton,
  MoveUpDownButtonGroup,
  MoveUpIconButton,
  NewButton,
  NextIconButton,
  NumberField,
  OptionsField,
  PasswordField,
  PhoneNumberField,
  PrevIconButton,
  RatingButtonsField,
  RemoveActionButton,
  RemoveButton,
  RemoveConfirmationModal,
  RevertButton,
  RevertConfirmationModal,
  SegmentedControlField,
  SelectField,
  SubmitButton,
  SubmitCancelButtonToolbar,
  TextField,
  ToggleField,
};

export * from './form-helpers';
