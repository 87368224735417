/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const dataIntegrations = {
  '': '/midas/data-integrations',
  'adt-dashboard': {
    '': '/midas/data-integrations/adt-dashboard',
    logdetail: '/midas/data-integrations/adt-dashboard/logdetail/:id',
    processing: '/midas/data-integrations/adt-dashboard/processing',
  },
  'bulk-operations': {
    '': '/midas/data-integrations/bulk-operations',
    'update-enrollment-status':
      '/midas/data-integrations/bulk-operations/update-enrollment-status',
    'recover-lost-user':
      '/midas/data-integrations/bulk-operations/recover-lost-user',
    'update-school-enrollment-status':
      '/midas/data-integrations/bulk-operations/update-school-enrollment-status',
    'assign-grading-period-to-sections':
      '/midas/data-integrations/bulk-operations/assign-grading-period-to-sections',
    'upload-user-photos':
      '/midas/data-integrations/bulk-operations/upload-user-photos',
    'copy-contact-information':
      '/midas/data-integrations/bulk-operations/copy-contact-information',
    'find-grade-level-mismatches':
      '/midas/data-integrations/bulk-operations/find-grade-level-mismatches',
    'generate-blackboard-connect-csv':
      '/midas/data-integrations/bulk-operations/generate-blackboard-connect-csv',
    'generate-onecall-now-csv':
      '/midas/data-integrations/bulk-operations/generate-onecall-now-csv',
  },
  'customer-imports': {
    '': '/midas/data-integrations/customer-imports',
    'new-import': '/midas/data-integrations/customer-imports/new-import',
    import: {
      '': '/midas/data-integrations/customer-imports/import/:importID',
      'log-entry':
        '/midas/data-integrations/customer-imports/import/:importID/log-entry/:logID',
      'edit-column-mapping':
        '/midas/data-integrations/customer-imports/import/:importID/edit-column-mapping',
    },
  },
};

export default dataIntegrations;
