import CurriculaInfo from 'components/microcredentials/public/CurriculaInfo';
import CurriculaPathways from 'components/microcredentials/public/CurriculaPathways';

const CurriculaDetailContent = ({curriculum}) => {
  return (
    <>
      <CurriculaInfo curriculum={curriculum} />
      <div className="pl-4">
        <CurriculaPathways curriculumID={curriculum.id} />
      </div>
    </>
  );
};

export default CurriculaDetailContent;
