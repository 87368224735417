/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState} from 'react';
import {defineMessages} from 'react-intl';
import {Alert} from 'react-bootstrap';
import {
  EmailField,
  Form,
  FormGroup,
  PasswordField,
  SubmitButton,
  TextField,
} from 'components/form';
import {callMidasJsonApi} from 'app/api';

const messages = defineMessages({
  emailLabel: {id: 'form.label.email'},
  usernameLabel: {id: 'form.label.username'},
  usernamePlaceholder: {id: 'form.placeholder.username'},
  passwordLabel: {id: 'form.label.password'},
  login: {id: 'form.button.login'},
  loggingIn: {id: 'form.button.logging-in'},
});

const LoginForm = ({usernameMode, onLoginSuccess}) => {
  const [loginFailed, setLoginFailed] = useState(false);

  const logIn = (values, {setSubmitting}) => {
    setLoginFailed(false);
    callMidasJsonApi('/midas/rest/user-session', 'POST', null, values).then(
      ({json, status}) => {
        if (status === 204) {
          onLoginSuccess();
        } else if (status === 422) {
          setLoginFailed(true); // always show a generic failure message
          setSubmitting(false);
        } else {
          throw new Error(
            `Received ${status} response while attempting to log in`
          );
        }
      }
    );
  };

  return (
    <Form initialValues={{username: '', password: ''}} onSubmit={logIn}>
      {loginFailed && (
        <Alert variant="danger">The username or password was incorrect.</Alert>
      )}
      <FormGroup>
        {usernameMode === 'email' ? (
          <EmailField label={messages.emailLabel} name="username" autoFocus />
        ) : (
          <TextField
            label={messages.usernameLabel}
            name="username"
            placeholder={messages.usernamePlaceholder}
            autoFocus
          />
        )}
      </FormGroup>
      <FormGroup>
        <PasswordField label={messages.passwordLabel} name="password" />
      </FormGroup>
      <SubmitButton
        submitMessage={messages.login}
        submittingMessage={messages.loggingIn}
        block
        className="my-4"
      />
    </Form>
  );
};

export default LoginForm;
