/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBringFront} from '@fortawesome/pro-duotone-svg-icons';

const AreasIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faBringFront} {...props} />
);

export default AreasIcon;
