/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import SavedParametersIcon from 'icons/SavedParametersIcon';

const messages = defineMessages({
  title: {id: 'navigation.reports.saved-parameters'},
});

const SavedParametersItem = () => (
  <RouteLinkItem
    iconBefore={SavedParametersIcon}
    route="midas/reports/saved-parameters"
    title={messages.title}
  />
);

export default SavedParametersItem;
