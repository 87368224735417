/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const behavior = {
  '': '/midas/settings/behavior',
  concerns: {
    '': '/midas/settings/behavior/concerns',
    'add-concern': '/midas/settings/behavior/concerns/add-concern',
    'edit-concern': '/midas/settings/behavior/concerns/edit-concern/:concernID',
    'add-administrator': '/midas/settings/behavior/concerns/add-administrator',
  },
  consequences: {
    '': '/midas/settings/behavior/consequences',
    'add-consequence': '/midas/settings/behavior/consequences/add-consequence',
    'edit-consequence':
      '/midas/settings/behavior/consequences/edit-consequence/:consequenceID',
    'add-incident-guideline':
      '/midas/settings/behavior/consequences/add-incident-guideline/:incidentID',
    'edit-incident-guideline':
      '/midas/settings/behavior/consequences/edit-incident-guideline/:incidentID',
    'add-points-guideline':
      '/midas/settings/behavior/consequences/add-points-guideline/:incidentID',
  },
  incidents: {
    '': '/midas/settings/behavior/incidents',
    'add-incident': '/midas/settings/behavior/incidents/add-incident',
    'edit-incident':
      '/midas/settings/behavior/incidents/edit-incident/:incidentID',
    'set-all-students-points':
      '/midas/settings/behavior/incidents/set-all-students-points',
  },
};

export default behavior;
