/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import AttendanceIcon from 'icons/AttendanceIcon';

const messages = defineMessages({
  title: {id: 'navigation.attendance'},
});

const AttendanceItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={AttendanceIcon}
    route="midas/staff-member/attendance"
    title={messages.title}
  />
);

export default AttendanceItem;
