/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const my = {
  '': '/midas/my',
  goals: {
    '': '/midas/my/goals',
    'edit-goal': '/midas/my/goals/edit-goal/:schoolID/:goalNumber',
    goal: '/midas/my/goals/goal/:schoolID/:goalNumber',
    'new-goal': '/midas/my/goals/new-goal',
  },
  'merit-pay-report-card': {
    '': '/midas/my/merit-pay-report-card',
  },
  microcredentials: {
    '': '/midas/my/microcredentials',
    microcredential: {
      '': '/midas/my/microcredentials/microcredential/:badgeUserID',
      'evidence-reflections':
        '/midas/my/microcredentials/microcredential/:badgeUserID/evidence-reflections',
      evidence:
        '/midas/my/microcredentials/microcredential/:badgeUserID/evidence/:evidenceID?',
      reflection:
        '/midas/my/microcredentials/microcredential/:badgeUserID/reflection/:reflectionID?',
      summary:
        '/midas/my/microcredentials/microcredential/:badgeUserID/summary',
    },
    curriculum: '/midas/my/microcredentials/curriculum/:curriculumID',
    pathway: '/midas/my/microcredentials/pathway/:pathwayID',
  },
  'pd-approvals': {
    '': '/midas/my/pd-approvals',
  },
  'pd-course-surveys': {
    '': '/midas/my/pd-course-surveys',
  },
  'pd-transcript': {
    '': '/midas/my/pd-transcript',
    'print-transcript': '/midas/my/pd-transcript/print-transcript',
  },
  portfolio: {
    '': '/midas/my/portfolio',
    'add-evidence': '/midas/my/portfolio/add-evidence',
    evidence: '/midas/my/portfolio/evidence/:contentUUID',
  },
  'professional-learning': {
    '': '/midas/my/professional-learning',
    'class-page':
      '/midas/my/professional-learning/class-page/:sectionID/:date?',
  },
  'reviewer-materials': {
    '': '/midas/my/reviewer-materials',
    detail: '/midas/my/reviewer-materials/detail/:materialID',
    'view-review-collection':
      '/midas/my/reviewer-materials/view-review-collection/:collectionID',
    'evaluate-review-collection':
      '/midas/my/reviewer-materials/evaluate-review-collection/:collectionID',
  },
};

export default my;
