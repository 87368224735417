/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const facilities = {
  '': '/midas/settings/facilities',
  buildings: {
    '': '/midas/settings/facilities/buildings',
    'add-building': '/midas/settings/facilities/buildings/add-building',
    'add-floor': '/midas/settings/facilities/buildings/add-floor/:buildingID',
    'edit-building':
      '/midas/settings/facilities/buildings/edit-building/:buildingID',
    'select-building': '/midas/settings/facilities/buildings/select-building',
  },
  equipment: {
    '': '/midas/settings/facilities/equipment',
    'add-equipment-type':
      '/midas/settings/facilities/equipment/add-equipment-type',
    'edit-equipment-type':
      '/midas/settings/facilities/equipment/edit-equipment-type/:equipmentTypeID',
    'add-equipment-piece':
      '/midas/settings/facilities/equipment/add-equipment-piece/:equipmentTypeID',
    'edit-equipment-piece':
      '/midas/settings/facilities/equipment/edit-equipment-piece/:equipmentPieceID',
  },
  'equipment-checkout': {
    '': '/midas/settings/facilities/equipment-checkout',
    'add-equipment-piece':
      '/midas/settings/facilities/equipment-checkout/add-equipment-piece/:equipmentTypeID',
    'edit-equipment-piece':
      '/midas/settings/facilities/equipment-checkout/edit-equipment-piece/:equipmentPieceID',
    'check-piece-in-or-out':
      '/midas/settings/facilities/equipment-checkout/check-piece-in-or-out/:equipmentPieceID/:assignmentID?',
  },
  lockers: {
    '': '/midas/settings/facilities/lockers',
    'add-locker': '/midas/settings/facilities/lockers/add-locker',
    'edit-locker': '/midas/settings/facilities/lockers/edit-locker/:lockerID',
    'add-locker-type': '/midas/settings/facilities/lockers/add-locker-type',
    'edit-locker-type':
      '/midas/settings/facilities/lockers/edit-locker-type/:lockerTypeID',
    'add-user': '/midas/settings/facilities/lockers/add-user',
    'edit-user': '/midas/settings/facilities/lockers/edit-user/:userID',
  },
  rooms: {
    '': '/midas/settings/facilities/rooms',
    'add-room': '/midas/settings/facilities/rooms/add-room',
    'edit-room': '/midas/settings/facilities/rooms/edit-room/:roomID',
  },
  'ticket-types': {
    '': '/midas/settings/facilities/ticket-types',
    'add-type': '/midas/settings/facilities/ticket-types/add-type',
    'edit-type': '/midas/settings/facilities/ticket-types/edit-type/:typeID',
    'add-group': '/midas/settings/facilities/ticket-types/:typeID/add-group',
  },
  'ticket-groups': {
    '': '/midas/settings/facilities/ticket-groups',
    'add-group': '/midas/settings/facilities/ticket-groups/add-group',
    'edit-group':
      '/midas/settings/facilities/ticket-groups/edit-group/:groupID',
    'add-member':
      '/midas/settings/facilities/ticket-groups/:groupID/add-member',
  },
};

export default facilities;
