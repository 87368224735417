/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {ButtonToolbar} from 'react-bootstrap';
import EditActionButton from './EditActionButton';
import RemoveActionButton from 'components/form/button/RemoveActionButton';

const EditRemoveButtonToolbar = ({
  onEdit,
  onRemove,
  canEdit,
  canDelete,
  compact = true,
  ...props
}) => {
  return (
    <ButtonToolbar className="my-2" {...props}>
      {canEdit && (
        <EditActionButton compact={compact} onClick={onEdit} className="mr-2" />
      )}
      {canDelete && <RemoveActionButton compact={compact} onClick={onRemove} />}
    </ButtonToolbar>
  );
};

export default EditRemoveButtonToolbar;
