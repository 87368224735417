/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import MenteesIcon from 'icons/MenteesIcon';

const messages = defineMessages({
  title: {id: 'navigation.mentees'},
});

const MenteesItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={MenteesIcon}
    route="midas/staff-member/mentees"
    title={messages.title}
  />
);

export default MenteesItem;
