/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {Container} from 'react-bootstrap';

const PageContainer = (props) => {
  return <Container className="p-3" {...props} />;
};

export default PageContainer;
