/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const courses = {
  '': '/midas/students/courses',
  'add-courses': '/midas/students/courses/add-courses',
  'edit-catalog': '/midas/students/courses/edit-catalog/:catalogID',
  'copy-course-catalog': '/midas/students/courses/copy-course-catalog/:yearID',
  'set-possible-time-slots':
    '/midas/students/courses/set-possible-time-slots/:yearID',
  'change-teacher-notification-settings':
    '/midas/students/courses/change-teacher-notification-settings',
};

export default courses;
