/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import Alert from './Alert';

const SuccessAlert = (props) => {
  return <Alert variant="success" {...props} />;
};

export default SuccessAlert;
