/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlusCircle} from '@fortawesome/pro-light-svg-icons';

const BigNewIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faPlusCircle} {...props} />
);

export default BigNewIcon;
