/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const scheduling = {
  '': '/midas/students/scheduling',
  'enroll-section-to-section': {
    '': '/midas/students/scheduling/enroll-section-to-section',
  },
  'course-request-set-up': {
    '': '/midas/students/scheduling/course-request-set-up',
    'copy-course-request-setup':
      '/midas/students/scheduling/course-request-set-up/copy-course-request-setup/:yearID',
    'set-credit-limit':
      '/midas/students/scheduling/course-request-set-up/set-credit-limit/:gradeLevel/:yearID',
    'edit-request-option':
      '/midas/students/scheduling/course-request-set-up/edit-request-option/:requestID',
    'add-new-request':
      '/midas/students/scheduling/course-request-set-up/add-new-request/:gradeLevel/:yearID',
    'set-dates':
      '/midas/students/scheduling/course-request-set-up/set-dates/:yearID',
  },
  'teacher-fte': {
    '': '/midas/students/scheduling/teacher-fte',
    'set-values-for-all-teachers':
      '/midas/students/scheduling/teacher-fte/set-values-for-all-teachers',
    'add-period-by-term':
      '/midas/students/scheduling/teacher-fte/add-period-by-term/:termID',
    'edit-teacher-time-slot':
      '/midas/students/scheduling/teacher-fte/edit-teacher-time-slot/:teacherID',
  },
  'teacher-rooms': {
    '': '/midas/students/scheduling/teacher-rooms',
    'add-room': '/midas/students/scheduling/teacher-rooms/add-room/:teacherID',
  },
  'course-recommendations': {
    '': '/midas/students/scheduling/course-recommendations',
    'edit-house':
      '/midas/students/scheduling/course-recommendations/edit-house/:schedulingYearID',
    'add-recommendation':
      '/midas/students/scheduling/course-recommendations/add-recommendation/:schedulingYearID',
    'convert-recommendations':
      '/midas/students/scheduling/course-recommendations/convert-recommendations/:schedulingYearID/:destSchoolID',
  },
  'teacher-availability': {
    '': '/midas/students/scheduling/teacher-availability',
    'set-default-time-slots':
      '/midas/students/scheduling/teacher-availability/set-default-time-slots/:schedulingYearID',
    'edit-teacher-time-slots':
      '/midas/students/scheduling/teacher-availability/edit-teacher-time-slots/:teacherID/:schedulingYearID',
  },
};

export default scheduling;
