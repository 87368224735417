/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import classnames from 'classnames';
import {Button} from 'react-bootstrap';

const IconActionButton = (
  {
    icon: Icon,
    message,
    compact = false,
    variant = 'outline-primary',
    type = 'button',
    size = 'sm',
    className = '',
    ...props
  },
  ref
) => {
  const buttonClassNames = classnames('d-flex align-items-center', className, {
    'flex-column': !compact,
  });
  const iconClassNames = classnames({
    'mr-2': compact,
    'my-2': !compact,
  });
  return (
    <Button
      ref={ref}
      variant={variant}
      type={type}
      size={size}
      className={buttonClassNames}
      {...props}
    >
      <Icon
        className={message !== undefined ? iconClassNames : ''}
        size={compact ? undefined : '2x'}
      />
      {message === undefined ? null : <FormattedMessage {...message} />}
    </Button>
  );
};
IconActionButton.displayName = 'IconActionButton';

export default forwardRef(IconActionButton);
