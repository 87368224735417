/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext} from 'react';
import {UserContext} from 'app/UserContext';
import './SideNavigationLogo.css';

const SideNavigationLogo = () => {
  const [{schoolID, navLogoURL, allowedSchools}] = useContext(UserContext);

  let currentSchool;
  for (let school of allowedSchools) {
    if (school.schoolID === schoolID) {
      currentSchool = school;
      break;
    }
  }
  if (!currentSchool) {
    return null;
  }

  return (
    <div className="SideNavigationLogo">
      <img src={navLogoURL} alt={`${currentSchool.districtName} logo`} />
    </div>
  );
};

export default SideNavigationLogo;
