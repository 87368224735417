/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const health = {
  '': '/midas/settings/health',
  actions: {
    '': '/midas/settings/health/actions',
    'add-action': '/midas/settings/health/actions/add-action',
    'edit-action': '/midas/settings/health/actions/edit-action/:actionID',
  },
  assessments: {
    '': '/midas/settings/health/assessments',
    'add-assessment': '/midas/settings/health/assessments/add-assessment',
  },
  concerns: {
    '': '/midas/settings/health/concerns',
    'add-concern': '/midas/settings/health/concerns/add-concern',
    'edit-concern': '/midas/settings/health/concerns/edit-concern/:concernID',
  },
  conditions: {
    '': '/midas/settings/health/conditions',
    'add-condition': '/midas/settings/health/conditions/add-condition',
    'edit-condition':
      '/midas/settings/health/conditions/edit-condition/:conditionID',
    'add-question':
      '/midas/settings/health/conditions/add-question/:conditionID',
    'edit-question':
      '/midas/settings/health/conditions/edit-question/:questionID',
  },
  logs: {
    '': '/midas/settings/health/logs',
    'add-log': '/midas/settings/health/logs/add-log',
    'edit-log': '/midas/settings/health/logs/edit-log/:logID',
    'add-question': '/midas/settings/health/logs/add-question/:logID',
    'edit-question': '/midas/settings/health/logs/edit-question/:questionID',
    'add-heading': '/midas/settings/health/logs/add-heading',
    'edit-heading': '/midas/settings/health/logs/edit-heading/:headingID',
  },
  medications: {
    '': '/midas/settings/health/medications',
    'add-medication': '/midas/settings/health/medications/add-medication',
    'edit-medication':
      '/midas/settings/health/medications/edit-medication/:medicationID',
  },
  outcomes: {
    '': '/midas/settings/health/outcomes',
    'add-outcome': '/midas/settings/health/outcomes/add-outcome',
    'edit-outcome': '/midas/settings/health/outcomes/edit-outcome/:outcomeID',
  },
  providers: {
    '': '/midas/settings/health/providers',
    'add-provider': '/midas/settings/health/providers/add-provider',
  },
  symptoms: {
    '': '/midas/settings/health/symptoms',
    'add-symptom': '/midas/settings/health/symptoms/add-symptom',
    'edit-symptom': '/midas/settings/health/symptoms/edit-symptom/:symptomID',
  },
  templates: {
    '': '/midas/settings/health/templates',
    'add-template': '/midas/settings/health/templates/add-template',
    'edit-template':
      '/midas/settings/health/templates/edit-template/:templateID',
    'add-item': '/midas/settings/health/templates/add-item/:templateID',
  },
};

export default health;
