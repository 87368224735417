/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {wrapNonLocalizedMessage} from 'app/intl';
import {useStudentName} from 'app/hooks';
import {useUserContext} from 'app/UserContext';
import NavigationMenu from './NavigationMenu';
import AlertsItem from './student/AlertsItem';
import AssignedToItem from './student/AssignedToItem';
import AssignmentsItem from './student/AssignmentsItem';
import AttendanceItem from './student/AttendanceItem';
import AutoCallItem from './student/AutoCallItem';
import BehaviorConcernsItem from './student/BehaviorConcernsItem';
import BehaviorItem from './student/BehaviorItem';
import CalendarItem from './student/CalendarItem';
import ContactInfoItem from './student/ContactInfoItem';
import CourseRequestsItem from './student/CourseRequestsItem';
import DemographicsItem from './student/DemographicsItem';
import EnrollmentItem from './student/EnrollmentItem';
import FamilyItem from './student/FamilyItem';
import GoalsItem from './student/GoalsItem';
import GradesItem from './student/GradesItem';
import GraduationRequirementsItem from './student/GraduationRequirementsItem';
import HealthMenu from './student/HealthMenu';
import IepItem from './student/IepItem';
import InterventionsItem from './student/InterventionsItem';
import IowaStateReporting2Item from './student/IowaStateReporting2Item';
import IowaStateReportingItem from './student/IowaStateReportingItem';
import LanguageCitizenshipItem from './student/LanguageCitizenshipItem';
import MicrocredentialsItem from './student/MicrocredentialsItem';
import OtherContactsItem from './student/OtherContactsItem';
import PhotoItem from './student/PhotoItem';
import PortfolioItem from './student/PortfolioItem';
import RegistrationItem from './student/RegistrationItem';
import ScheduleItem from './student/ScheduleItem';
import ScheduleTimelineItem from './student/ScheduleTimelineItem';
import ServiceHoursItem from './student/ServiceHoursItem';
import StandardsGradesItem from './student/StandardsGradesItem';
import StateReportingMenu from './student/StateReportingMenu';
import TestScoresItem from './student/TestScoresItem';
import TransportationItem from './student/TransportationItem';

const StudentMenu = () => {
  const [{currentStudentID}] = useUserContext();
  const name = useStudentName(currentStudentID);

  return (
    <NavigationMenu
      route="midas/student"
      title={wrapNonLocalizedMessage(name)}
      detached
    >
      <AlertsItem />
      <DemographicsItem />
      <PhotoItem />
      <ContactInfoItem />
      <AutoCallItem />
      <FamilyItem />
      <OtherContactsItem />
      <EnrollmentItem />
      <MicrocredentialsItem />
      <LanguageCitizenshipItem />
      <AttendanceItem />
      <GradesItem />
      <StandardsGradesItem />
      <AssignedToItem />
      <CalendarItem />
      <ScheduleItem />
      <ScheduleTimelineItem />
      <CourseRequestsItem />
      <AssignmentsItem />
      <GraduationRequirementsItem />
      <ServiceHoursItem />
      <BehaviorItem />
      <BehaviorConcernsItem />
      <IepItem />
      <InterventionsItem />
      <StateReportingMenu />
      <IowaStateReportingItem />
      <IowaStateReporting2Item />
      <TransportationItem />
      <HealthMenu />
      <GoalsItem />
      <PortfolioItem />
      <TestScoresItem />
      <RegistrationItem />
    </NavigationMenu>
  );
};

export default StudentMenu;
