/**
 * Returns a new string concatenating the guardian name and localized title using ' - '.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {toNumber} from 'lodash';
import useGuardianName from './useGuardianName';

const useGuardianPageTitle = (descriptor, values) => {
  const match = useRouteMatch();

  const guardianName = useGuardianName(toNumber(match.params.guardianID ?? 0));

  const intl = useIntl();
  const title = intl.formatMessage(descriptor, values);

  return `${guardianName} - ${title}`;
};

export default useGuardianPageTitle;
