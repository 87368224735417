/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const selfRegistration = {
  '': '/midas/self-registration',
  'manage-invitations': '/midas/self-registration/manage-invitations',
  registrations: {
    '': '/midas/self-registration/registrations',
    registration:
      '/midas/self-registration/registrations/registration/:userID/:registeredDateTime',
  },
};

export default selfRegistration;
