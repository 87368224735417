/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const events = {
  '': '/midas/events',
  events: {
    '': '/midas/events/events',
  },
  'add-event': '/midas/events/add-event',
};

export default events;
