/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import GrantsIcon from 'icons/GrantsIcon';
import AwardWorkflowsItem from './grants/AwardWorkflowsItem';
import CategoriesItem from './grants/CategoriesItem';
import EvidenceItem from './grants/EvidenceItem';
import GrantAwardsItem from './grants/GrantAwardsItem';
import GrantsItem from './grants/GrantsItem';
import ProgramsItem from './grants/ProgramsItem';

const messages = defineMessages({
  title: {id: 'navigation.grants'},
});

const GrantsMenu = () => (
  <NavigationMenu
    iconBefore={GrantsIcon}
    route="midas/grants"
    title={messages.title}
  >
    <GrantsItem />
    <GrantAwardsItem />
    <EvidenceItem />
    <ProgramsItem />
    <CategoriesItem />
    <AwardWorkflowsItem />
  </NavigationMenu>
);

export default GrantsMenu;
