/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';
import {faTicketAlt} from '@fortawesome/pro-duotone-svg-icons';

const TicketQueueIcon = ({icon, ...props}) => (
  <span className="fa-layers fa-fw">
    <FontAwesomeIcon icon={faTicketAlt} {...props} />
    <FontAwesomeIcon
      icon={faPlus}
      style={{top: '-3px'}}
      transform="shrink-9"
      {...props}
    />
  </span>
);

export default TicketQueueIcon;
