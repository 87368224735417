/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {urlFromRoute} from './routing';
import {callMidasJsonApi} from './api';

/**
 * Immediately changes the browser's location to the login route, optionally
 * preserving the current location in the return_url query parameter.
 */
export const sendToLogin = (includeReturnURL = false) => {
  let loginURL = urlFromRoute('login');
  if (includeReturnURL) {
    loginURL +=
      '?return_url=' +
      encodeURIComponent(
        window.location.pathname + window.location.search + window.location.hash
      );
  }
  // window.location is set instead of pushing a new entry to the history
  // object to force a full application reload, clearing all cached state.
  window.location = loginURL;
};

/**
 * Immediately ends the user's current login session and sends them to the
 * login route, optionally preserving the current location in the return_url
 * query parameter.
 */
export const logout = (includeReturnURL = false) => {
  callMidasJsonApi('/midas/rest/user-session', 'DELETE')
    .then(() => {
      sendToLogin(includeReturnURL);
    })
    .catch(() => {
      // not really anything that can be done with an error here... just ignore
      // it and go to the login page anyway.
      sendToLogin(includeReturnURL);
    });
};
