/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const AGGREGATE_FG_USE_ACTUAL_PERCENTAGE = 'agg-fg-use-actual-percentage';
const FILTER_PD_ON_SCHOOL = 'filter-on-school';
const MDDE_PRINT_DISABLED = 'mdde-print-disabled';
const PD_ALLOW_INSTANT_ENROLL = 'pd-allow-instant-enroll';
const PD_CUSTOMER = 'pd-customer';
const PD_SECTION_APPROVAL_REQUIRED = 'pd-section-approval';
const PD_SHOW_STIPEND_OPTIONS = 'pd-show-stipend-options';
const REPORTS_COLLAB_ADD_FIELDS = 'reports-collab-add-fields';
const STUDENT_GOALS = 'student-goals';
const STUDENT_PORTFOLIO = 'student-portfolio';
const USER_CHART_OPTIONS = 'user-chart-options';
const USES_AD_HOC_CALENDAR = 'uses-ad-hoc-calendar';
const DISPLAY_HELP_DOCS = 'display-help-documents';
const IS_BADGE = 'is_badge';
const HIDE_STUDENT_NAVIGATION = 'hide-student-navigation';
const HIDE_STAFF_NAVIGATION = 'hide-staff-navigation';
const HIDE_GUARDIAN_NAVIGATION = 'hide-guardian-navigation';

export {
  AGGREGATE_FG_USE_ACTUAL_PERCENTAGE,
  FILTER_PD_ON_SCHOOL,
  MDDE_PRINT_DISABLED,
  PD_ALLOW_INSTANT_ENROLL,
  PD_CUSTOMER,
  PD_SECTION_APPROVAL_REQUIRED,
  PD_SHOW_STIPEND_OPTIONS,
  REPORTS_COLLAB_ADD_FIELDS,
  STUDENT_GOALS,
  STUDENT_PORTFOLIO,
  USER_CHART_OPTIONS,
  USES_AD_HOC_CALENDAR,
  DISPLAY_HELP_DOCS,
  IS_BADGE,
  HIDE_STUDENT_NAVIGATION,
  HIDE_STAFF_NAVIGATION,
  HIDE_GUARDIAN_NAVIGATION,
};
