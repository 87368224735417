/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import FinanceIcon from 'icons/FinanceIcon';
import NavigationMenu from 'components/navigation/side-navigation/menu/NavigationMenu';
import SpotObservationsItem from './educator-merit-pay/SpotObservationsItem';
import EducatorCategoriesItem from 'components/navigation/side-navigation/menu/educator-merit-pay/EducatorCategoriesItem';
import EffectivenessAreasItem from 'components/navigation/side-navigation/menu/educator-merit-pay/EffectivenessAreasItem';
import AnnualEvaluationSettingsItem from 'components/navigation/side-navigation/menu/educator-merit-pay/AnnualEvaluationSettingsItem';

const messages = defineMessages({
  title: {id: 'navigation.educator-merit-pay'},
});

const EducatorMeritPayMenu = () => (
  <NavigationMenu
    iconBefore={FinanceIcon}
    route="midas/educator-merit-pay"
    title={messages.title}
  >
    <EducatorCategoriesItem />
    <EffectivenessAreasItem />
    <AnnualEvaluationSettingsItem />
    <SpotObservationsItem />
  </NavigationMenu>
);

export default EducatorMeritPayMenu;
