/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import PdPlaylistIcon from 'icons/PdPlaylistIcon';

const messages = defineMessages({
  title: {id: 'navigation.pd-playlists'},
});

const PdPlaylistsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={PdPlaylistIcon}
    route="midas/staff-member/pd-playlists"
    title={messages.title}
  />
);

export default PdPlaylistsItem;
