/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChair} from '@fortawesome/pro-solid-svg-icons';

const SeatingChartIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faChair} {...props} />
);

export default SeatingChartIcon;
