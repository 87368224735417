/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import SubmitButton from './SubmitButton';

const messages = defineMessages({
  continue: {id: 'button.continue'},
});

const ContinueButton = ({submitMessage = messages.continue, ...props}, ref) => {
  return <SubmitButton ref={ref} submitMessage={submitMessage} {...props} />;
};
ContinueButton.displayName = 'ContinueButton';

export default forwardRef(ContinueButton);
