/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const transportation = {
  '': '/midas/settings/transportation',
  'bus-drivers': {
    '': '/midas/settings/transportation/bus-drivers',
    'add-driver': '/midas/settings/transportation/bus-drivers/add-driver',
    'edit-driver':
      '/midas/settings/transportation/bus-drivers/edit-driver/:driverID',
    'select-driver': '/midas/settings/transportation/bus-drivers/select-driver',
  },
  'bus-providers': {
    '': '/midas/settings/transportation/bus-providers',
    'add-provider': '/midas/settings/transportation/bus-providers/add-provider',
    'edit-provider':
      '/midas/settings/transportation/bus-providers/edit-provider/:providerID',
  },
  'bus-routes': {
    '': '/midas/settings/transportation/bus-routes',
    'add-route': '/midas/settings/transportation/bus-routes/add-route',
    'edit-route':
      '/midas/settings/transportation/bus-routes/edit-route/:routeID',
    'select-routes': '/midas/settings/transportation/bus-routes/select-routes',
    'clear-all-riders':
      '/midas/settings/transportation/bus-routes/clear-all-riders',
    'select-riders':
      '/midas/settings/transportation/bus-routes/select-riders/:stopID',
    'select-stop':
      '/midas/settings/transportation/bus-routes/select-stop/:routeID',
    'edit-bus-stops':
      '/midas/settings/transportation/bus-routes/edit-bus-stops/:stopID',
  },
  'bus-stops': {
    '': '/midas/settings/transportation/bus-stops',
    'add-stop': '/midas/settings/transportation/bus-stops/add-stop',
    'edit-stop': '/midas/settings/transportation/bus-stops/edit-stop/:stopID',
  },
};

export default transportation;
