/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useMemo} from 'react';
import {useIntl} from 'react-intl';
import SelectField from './SelectField';
import SegmentedControlField from './SegmentedControlField';

const OptionsField = ({options, ...props}, ref) => {
  const intl = useIntl();

  const totalLength = useMemo(() => {
    return options.reduce((length, option) => {
      return (
        length + intl.formatMessage(option.label, option.label.values).length
      );
    }, 0);
  }, [intl, options]);

  if (totalLength < 50) {
    return <SegmentedControlField ref={ref} options={options} {...props} />;
  }
  return <SelectField ref={ref} options={options} {...props} />;
};
OptionsField.displayName = 'OptionsField';

export default forwardRef(OptionsField);
