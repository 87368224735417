/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext, useMemo} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {Alert} from 'react-bootstrap';
import {RouteLink} from 'app/routing';
import {useDocumentTitle, useFormatMessage, useMidasJsonApi} from 'app/hooks';
import {UserContext, LOGIN_SUCCESS} from 'app/UserContext';
import {Markdown} from 'components/layout';
import LoginForm from 'components/authentication/LoginForm';

const messages = defineMessages({
  title: {id: 'authentication.login.title'},
});

const Login = ({message, usernameMode}) => {
  useDocumentTitle(useFormatMessage(messages.title));

  const [, dispatch] = useContext(UserContext);

  const [response] = useMidasJsonApi({
    url: `/api/self-registration`,
    expectedStatus: [200],
  });

  // determine if any school has registration enabled and walk ups turned on
  const allowWalkUps = useMemo(() => {
    let walkUp = false;
    if (response.status === 200) {
      response.json.schools.forEach((school) => {
        if (school.allowWalkUp) {
          walkUp = true;
        }
      });
    }
    return walkUp;
  }, [response]);

  const onLoginSuccess = () => {
    dispatch({type: LOGIN_SUCCESS});
  };

  return (
    <>
      {message && (
        <Alert variant="info">
          <Markdown skipHtml>{message}</Markdown>
        </Alert>
      )}

      <LoginForm usernameMode={usernameMode} onLoginSuccess={onLoginSuccess} />

      <p className="mb-0 text-center">
        <RouteLink route="login/reset-password">
          <FormattedMessage
            id="link.forgot-login"
            description="Link text to the forgot password/first-time user page"
            defaultMessage="Forgot your login or first-time user?"
          />
        </RouteLink>
      </p>
      {allowWalkUps && (
        <>
          <hr />
          <p className="mb-0 text-center">
            <RouteLink route="self-register">
              <FormattedMessage
                id="link.register-for-account"
                description="Link text to the self registration form"
                defaultMessage="Register for an Account"
              />
            </RouteLink>
          </p>
        </>
      )}
    </>
  );
};

export default Login;
