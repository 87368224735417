/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages, useIntl} from 'react-intl';
import {useNavigationContext} from 'app/NavigationContext';
import MidasLogo from 'components/MidasLogo';
import NavigationExpandIcon from 'icons/NavigationExpandIcon';
import './MobileNavigation.css';

const messages = defineMessages({
  showNavigation: {id: 'navigation.show-navigation'},
});

const MobileNavigation = ({onToggleClick}) => {
  const intl = useIntl();
  const {nonProduction} = useNavigationContext();

  return (
    <div className={`MobileNavigation ${nonProduction ? 'NonProduction' : ''}`}>
      <span role="presentation" className="nav-shadow" />
      <span className="balance" />
      <span role="presentation" className="logo">
        <MidasLogo width={35} height={35} stroke />
      </span>
      <span className="toggle">
        <button
          onClick={onToggleClick}
          title={intl.formatMessage(messages.showNavigation)}
        >
          <NavigationExpandIcon />
        </button>
      </span>
    </div>
  );
};

export default MobileNavigation;
