/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {Card} from 'react-bootstrap';
import './CardHeading.css';

const CardHeading = ({text, icon: Icon}) => {
  return (
    <Card.Header as="h5" className="CardHeading">
      {Icon && <Icon />}
      <FormattedMessage {...text} />
    </Card.Header>
  );
};

export default CardHeading;
