/**
 * Hook that returns an object with name components for the requested MIDAS school ID.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext} from 'react';
import {UserContext} from 'app/UserContext';

const useSchoolName = (schoolID) => {
  const [context] = useContext(UserContext);

  for (let school of context.allowedSchools) {
    if (school.schoolID === schoolID) {
      return school.schoolName;
    }
  }

  return schoolID;
};

export default useSchoolName;
