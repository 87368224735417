/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import useMatchMedia from './useMatchMedia';

const useMaxGridBreakpoint = (breakpoint) => {
  const width = getComputedStyle(document.documentElement).getPropertyValue(
    `--breakpoint-${breakpoint}`
  );
  if (width.length < 1) {
    throw new Error(`Invalid CSS grid breakpoint: ${breakpoint}`);
  }
  return useMatchMedia(`(max-width: ${width})`);
};

export default useMaxGridBreakpoint;
