/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import useUser from 'app/hooks/useUser';

const UserName = ({userID}) => {
  const user = useUser(userID);
  if (!user) {
    return null;
  }

  // TODO: If the authenticated user is a "student", format staff names using formal titles

  return <>{`${user.firstName} ${user.lastName}`}</>;
};

export default UserName;
