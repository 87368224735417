/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faFileInvoiceDollar} from '@fortawesome/pro-duotone-svg-icons';

const BillingIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faFileInvoiceDollar} {...props} />
);

export default BillingIcon;
