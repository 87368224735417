/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const security = {
  '': '/midas/settings/security',
  'widget-permissions': {
    '': '/midas/settings/security/widget-permissions',
  },
  'district-users': {
    '': '/midas/settings/security/district-users',
    'add-user': '/midas/settings/security/district-users/add-user',
    'edit-user': '/midas/settings/security/district-users/edit-user/:userID',
  },
  'user-relationship-permissions': {
    '': '/midas/settings/security/user-relationship-permissions',
  },
  'site-page-editors': {
    '': '/midas/settings/security/site-page-editors',
    'add-page': '/midas/settings/security/site-page-editors/add-page/:editorID',
    'add-editor': '/midas/settings/security/site-page-editors/add-editor',
  },
  'educator-growth-admin': {
    '': '/midas/settings/security/educator-growth-admin',
    'add-administrator':
      '/midas/settings/security/educator-growth-admin/add-administrator',
  },
  'grant-administrators': {
    '': '/midas/settings/security/grant-administrators',
    'add-administrator':
      '/midas/settings/security/grant-administrators/add-administrator',
  },
  'grant-award-administrators': {
    '': '/midas/settings/security/grant-award-administrators',
    'add-administrator':
      '/midas/settings/security/grant-award-administrators/add-administrator',
  },
  'pd-administrators': {
    '': '/midas/settings/security/pd-administrators',
    'add-administrator':
      '/midas/settings/security/pd-administrators/add-administrator',
  },
  'reports-permissions': {
    '': '/midas/settings/security/reports-permissions',
    'add-user':
      '/midas/settings/security/reports-permissions/add-user/:reportID',
  },
  'rti-administrators': {
    '': '/midas/settings/security/rti-administrators',
    'add-administrator':
      '/midas/settings/security/rti-administrators/add-administrator',
  },
  'scheduling-administrators': {
    '': '/midas/settings/security/scheduling-administrators',
    'add-administrator':
      '/midas/settings/security/scheduling-administrators/add-administrator',
  },
};

export default security;
