/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import academics from './settings/academics';
import activities from './settings/activities';
import attendance from './settings/attendance';
import behavior from './settings/behavior';
import billing from './settings/billing';
import calendar from './settings/calendar';
import educatorGrowth from './settings/educator-growth';
import endOfYear from './settings/end-of-year';
import financial from './settings/financial';
import facilities from './settings/facilities';
import foodServices from './settings/food-services';
import health from './settings/health';
import humanResources from './settings/human-resources';
import intervention from './settings/intervention';
import leaveRequests from './settings/leave-requests';
import microCredentialingResources from './microcredentialing';
import professionalDevelopment from './settings/professional-development';
import schools from './settings/schools';
import timesheets from './settings/timesheets';
import transportation from './settings/transportation';
import standards from './settings/standards';
import security from './settings/security';
import institutes from './settings/institutes';
import registrationForms from './settings/registration-forms';
import selfRegistration from './settings/self-registration';

const settings = {
  '': '/midas/settings',
  academics,
  activities,
  attendance,
  behavior,
  billing,
  calendar,
  'educator-growth': educatorGrowth,
  'end-of-year': endOfYear,
  financial,
  facilities,
  'food-services': foodServices,
  'human-resources': humanResources,
  health,
  intervention,
  'leave-requests': leaveRequests,
  microcredentialing: microCredentialingResources,
  'professional-development': professionalDevelopment,
  'registration-forms': registrationForms,
  'self-registration': selfRegistration,
  schools,
  timesheets,
  transportation,
  standards,
  security,
  institutes,
};

export default settings;
