/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {DateTime} from 'luxon';
import {mixed} from 'yup';
import {datetime as locale} from './locale';

const invalidDateTime = DateTime.invalid(
  'Datetime value must be a JS Date or ISO-formatted string'
);

class DateTimeSchema extends mixed {
  constructor() {
    super({type: 'datetime'});

    this.withMutation(() => {
      this.transform(function (value) {
        if (this.isType(value)) {
          return value;
        }
        let dt;
        if (Object.prototype.toString.call(value) === '[object Date]') {
          dt = DateTime.fromJSDate(value);
          console.log(value, dt);
        } else {
          dt = DateTime.fromISO(value);
        }
        return dt.isValid ? dt : invalidDateTime;
      });
    });
  }

  _typeCheck(value) {
    return DateTime.isDateTime(value);
  }

  _prepDateParam(param, label) {
    if (!this._typeCheck(param)) {
      param = this.cast(param);
      if (!this._typeCheck(param)) {
        throw new TypeError(
          `\`${label}\` must be a Luxon DateTime, JS Date, or ISO-formatted string`
        );
      }
    }
    if (!param.isValid) {
      throw new TypeError(`\`${label}\` is not a valid DateTime`);
    }
    return param;
  }

  after(after, message = locale.after) {
    after = this._prepDateParam(after, 'after');
    return this.test({
      message,
      name: 'after',
      exclusive: true,
      params: {after: after.toLocaleString()},
      test: (value) => {
        return value == null || value > after;
      },
    });
  }

  onOrAfter(after, message = locale.onOrAfter) {
    after = this._prepDateParam(after, 'after');
    return this.test({
      message,
      name: 'onOrAfter',
      exclusive: true,
      params: {after: after.toLocaleString()},
      test: (value) => {
        return value == null || value >= after;
      },
    });
  }

  before(before, message = locale.before) {
    before = this._prepDateParam(before, 'before');
    return this.test({
      message,
      name: 'before',
      exclusive: true,
      params: {before: before.toLocaleString()},
      test: (value) => {
        return value == null || value < before;
      },
    });
  }

  onOrBefore(before, message = locale.onOrBefore) {
    before = this._prepDateParam(before, 'before');
    return this.test({
      message,
      name: 'onOrBefore',
      exclusive: true,
      params: {before: before.toLocaleString()},
      test: (value) => {
        return value == null || value <= before;
      },
    });
  }

  between(after, before, message = locale.between) {
    after = this._prepDateParam(after, 'after');
    before = this._prepDateParam(before, 'before');
    return this.test({
      message,
      name: 'between',
      exclusive: true,
      params: {
        after: after.toLocaleString(),
        before: before.toLocaleString(),
      },
      test: (value) => {
        return value == null || (value > after && value < before);
      },
    });
  }

  betweenInclusive(after, before, message = locale.betweenInclusive) {
    after = this._prepDateParam(after, 'after');
    before = this._prepDateParam(before, 'before');
    return this.test({
      message,
      name: 'betweenInclusive',
      exclusive: true,
      params: {
        after: after.toLocaleString(),
        before: before.toLocaleString(),
      },
      test: (value) => {
        return value == null || (value >= after && value <= before);
      },
    });
  }
}

const datetime = () => new DateTimeSchema();
export default datetime;
