/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const login = {
  '': '/login',
  'forgot-username': '/login/forgot-username',
  'reset-password': '/login/reset-password/:token?',
};

export default login;
