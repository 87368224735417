/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {ButtonItem} from '@atlaskit/side-navigation';
import {
  UPDATE_CURRENT_STUDENT,
  UPDATE_CURRENT_STAFF_MEMBER,
  UPDATE_CURRENT_GUARDIAN,
  useUserContext,
} from 'app/UserContext';
import {PUSH_MENU, useNavigationContext} from 'app/NavigationContext';
import DemographicsIcon from 'icons/DemographicsIcon';
import IconBefore from 'components/navigation/side-navigation/icon/IconBefore';
import {
  NestingItemIcon,
  nestingItemStyle,
} from 'components/navigation/side-navigation/icon/NestingItemIcon';

const MyProfileItem = () => {
  const [{userID, roles}, userContextDispatch] = useUserContext();
  const {dispatch} = useNavigationContext();

  const handleClick = () => {
    let action = UPDATE_CURRENT_GUARDIAN;
    let menuID = 'midas/guardian';
    if (roles.student.length > 0) {
      action = UPDATE_CURRENT_STUDENT;
      menuID = 'midas/student';
    } else if (roles.staff.length > 0) {
      action = UPDATE_CURRENT_STAFF_MEMBER;
      menuID = 'midas/staff-member';
    }
    userContextDispatch({
      type: action,
      payload: userID,
    });
    dispatch({
      type: PUSH_MENU,
      payload: menuID,
    });
  };

  return (
    <ButtonItem
      iconBefore={<IconBefore Icon={DemographicsIcon} />}
      iconAfter={<NestingItemIcon />}
      cssFn={nestingItemStyle}
      onClick={handleClick}
    >
      <FormattedMessage id="navigation.my-profile" />
    </ButtonItem>
  );
};

export default MyProfileItem;
