/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import React from 'react';
import {defineMessages} from 'react-intl';
import {useHistory, useLocation} from 'react-router-dom';
import {useFormatMessage} from 'app/hooks';
import {Markdown, PageContainer} from 'components/layout';

const messages = defineMessages({
  message: {id: 'error.page-not-found'},
});

const NotFound = () => {
  const message = useFormatMessage(messages.message);

  const location = useLocation();
  const history = useHistory();

  // "redirect" URLs that end with a trailing slash
  const match = location.pathname.match('^(/.+)/$');
  if (match) {
    history.replace(`${match[1]}${location.search}${location.hash}`);
    return null;
  }

  let stack = null;
  if (process.env.NODE_ENV === 'development') {
    stack = React[
      '__SECRET_INTERNALS_DO_NOT_USE_OR_YOU_WILL_BE_FIRED'
    ]?.ReactDebugCurrentFrame?.getStackAddendum();
  }

  return (
    <PageContainer>
      <img
        src="https://s3.amazonaws.com/midas-shared-static/assets/images/snapped-pencil.png"
        alt="Snapped Pencil"
        className="d-block mx-auto"
      />
      <div className="text-center">
        <Markdown>{message}</Markdown>
      </div>
      {stack && (
        <div
          className="small font-weight-light text-muted"
          style={{whiteSpace: 'pre-wrap'}}
        >
          {stack}
        </div>
      )}
    </PageContainer>
  );
};

export default NotFound;
