/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import * as yup from './yup';

export const questionTypes = {
  TEXT: 'text',
  MULTILINE_TEXT: 'line',
  // PERSON_NAME: 'name',
  // ADDRESS: 'addr',
  EMAIL_ADDRESS: 'eml',
  PHONE_NUMBER: 'phon',
  NUMBER: 'num',
  DATE_TIME: 'date',
  OPTIONS: 'opts',
  CHECKBOX: 'chek',
  TOGGLE: 'togl',
  FILE: 'file',
};

export const buildQuestionFieldName = (question) => {
  return `${question.formID}-${question.questionNumber}`;
};

const shapeForQuestionType = (question) => {
  switch (question.questionType) {
    case questionTypes.TEXT:
      return yup.string().ensure();

    case questionTypes.MULTILINE_TEXT:
      return yup.string().ensure();

    case questionTypes.EMAIL_ADDRESS:
      return yup.string().ensure().email();

    case questionTypes.PHONE_NUMBER:
      return yup.string().ensure().phoneNumber();

    case questionTypes.NUMBER:
      return yup.number();

    case questionTypes.DATE_TIME:
      return yup.datetime();

    case questionTypes.OPTIONS:
      if (question.metadata?.multi) {
        return yup.array().ensure().of(yup.string());
      }
      return yup.string().ensure();

    case questionTypes.CHECKBOX:
      return yup.array().ensure().of(yup.string());

    case questionTypes.TOGGLE:
      return yup.array().ensure().of(yup.string());

    case questionTypes.FILE:
      return yup.object().nullable();

    default:
      throw new Error(`Unknown question type: ${question.questionType}`);
  }
};

export const buildValidationSchema = (questions) => {
  const shape = {};
  for (let question of questions) {
    let fieldShape = shapeForQuestionType(question);
    if (question.metadata?.required) {
      fieldShape = fieldShape.required();
    }
    shape[buildQuestionFieldName(question)] = fieldShape;
  }
  return yup.object().shape(shape);
};
