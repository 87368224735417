/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useFormatMessage, useRouteIsAuthorized} from 'app/hooks';
import {NestingItem, Section} from '@atlaskit/side-navigation';
import IconBefore from 'components/navigation/side-navigation/icon/IconBefore';
import {useDetachedMenuShouldRender} from 'app/NavigationContext';

const NavigationMenu = ({
  iconBefore,
  route,
  title,
  sectionTitle,
  onClick,
  detached = false,
  children,
}) => {
  const formattedTitle = useFormatMessage(title);
  const formattedSectionTitle = useFormatMessage(sectionTitle || title);

  const routeIsAuthorized = useRouteIsAuthorized();
  const shouldRender = useDetachedMenuShouldRender(route);

  if (!routeIsAuthorized(route) || (detached && !shouldRender)) {
    return null;
  }

  return (
    <NestingItem
      id={route}
      iconBefore={iconBefore && <IconBefore Icon={iconBefore} />}
      title={formattedTitle}
      onClick={onClick}
    >
      <Section title={formattedSectionTitle}>{children}</Section>
    </NestingItem>
  );
};

export default NavigationMenu;
