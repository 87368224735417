/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {CustomItem} from '@atlaskit/side-navigation';
import {logout} from 'app/userSession';
import RouteLink from 'components/routing/RouteLink';
import IconBefore from 'components/navigation/side-navigation/icon/IconBefore';
import LogOutIcon from 'icons/LogOutIcon';

const doLogout = (event) => {
  event.preventDefault();
  logout();
};

const LogOutItem = () => (
  <CustomItem
    component={RouteLink}
    route="logout"
    iconBefore={<IconBefore Icon={LogOutIcon} />}
    onClick={doLogout}
  >
    <FormattedMessage id="navigation.log-out" />
  </CustomItem>
);

export default LogOutItem;
