/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useLocation} from 'react-router-dom';

const MidasServerSideRequest = () => {
  const location = useLocation();

  window.location.assign(
    `https://${window.location.hostname}${location.pathname}${location.search}${location.hash}`
  );

  return null;
};

export default MidasServerSideRequest;
