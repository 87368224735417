/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState, useEffect} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useLocation} from 'react-router-dom';
import {urlPathToRoute} from 'app/routing';
import useMidasJsonApi from 'app/hooks/useMidasJsonApi';
import {Modal} from 'react-bootstrap';
import {ApiResponseWrapper, ContentLoading} from 'components/layout';
import {ErrorAlert} from 'components/alert';
import './HelpDocs.css';

const messages = defineMessages({
  noContent: {id: 'midas.help.error.no-content-for-document'},
});

// This function is hyper specific to how the confluence API returns html.
// It does a simple string replace on some specific css classes and subs them with bootstrap classes.
// If styling breaks this is a good place to check.
const updateCSS = (html) => {
  html = html.replace('confluenceTable', 'table table-bordered table-striped');
  html = html.replace(
    'confluence-embedded-image image-center',
    'img-thumbnail img-fluid'
  );
  html = html.replace(
    'confluence-information-macro-information',
    'alert alert-primary'
  );
  html = html.replace(
    'confluence-information-macro-tip',
    'alert alert-success'
  );
  html = html.replace(
    'confluence-information-macro-note',
    'alert alert-warning'
  );
  html = html.replace(
    'confluence-information-macro-warning',
    'alert alert-danger'
  );
  html = html.replace('border-color: #998DD9', '');
  return html;
};

const HelpContent = ({title, children}) => (
  <>
    <Modal.Header closeButton>
      <Modal.Title>{title}</Modal.Title>
    </Modal.Header>
    <Modal.Body className="HelpContent">{children}</Modal.Body>
  </>
);

const HelpLoading = () => (
  <HelpContent title={<FormattedMessage id="midas.help" />}>
    <ContentLoading delayMs={0} />
  </HelpContent>
);

const HelpDocument = ({documentID, setDocumentID, routeAnchor}) => {
  const [response, isLoading, error] = useMidasJsonApi({
    url: `/midas/rest/confluence/${documentID}`,
    method: 'GET',
    expectedStatus: [200, 404],
  });

  useEffect(() => {
    if (routeAnchor && response.status === 200) {
      document.getElementById(routeAnchor).scrollIntoView(true);
    }
  });

  return (
    <ApiResponseWrapper error={error}>
      {isLoading && <HelpLoading />}
      {response.status === 200 && (
        <HelpContent title={response.json.document.title}>
          <div
            dangerouslySetInnerHTML={{
              __html: updateCSS(response.json.document.html),
            }}
          />
        </HelpContent>
      )}
      {response.status === 404 && (
        <HelpContent title={<FormattedMessage id="midas.help" />}>
          <ErrorAlert text={{...messages.noContent, values: {documentID}}} />
        </HelpContent>
      )}
    </ApiResponseWrapper>
  );
};

const HelpDocumentSelection = ({route, documents, routeAnchor}) => {
  const [documentID, setDocumentID] = useState(0);

  useEffect(() => {
    let candidateRoute = route;
    while (candidateRoute !== '') {
      if (documents[candidateRoute]?.length > 0) {
        const document = documents[candidateRoute][0];
        setDocumentID(document.id);
        break;
      } else {
        // no match for this route, pop the last component and keep looking
        candidateRoute = candidateRoute.substring(
          0,
          candidateRoute.lastIndexOf('/')
        );
      }
    }
  }, [route, documents]);

  if (!documentID) {
    return <HelpLoading />;
  }
  return (
    <HelpDocument
      documentID={documentID}
      setDocumentID={setDocumentID}
      routeAnchor={routeAnchor}
    />
  );
};

const HelpDocsModal = ({onHide, routeAnchor = null}) => {
  const location = useLocation();
  const route = urlPathToRoute(location.pathname);

  const [response, isLoading, error] = useMidasJsonApi({
    url: `/midas/rest/confluence?label=${route}`,
    method: 'GET',
    expectedStatus: [200],
  });

  return (
    <Modal show={true} size={'lg'} onHide={onHide}>
      <ApiResponseWrapper error={error}>
        {isLoading && <HelpLoading />}
        {response.status === 200 && (
          <HelpDocumentSelection
            route={route}
            documents={response.json.documents}
            routeAnchor={routeAnchor}
          />
        )}
      </ApiResponseWrapper>
    </Modal>
  );
};

export default HelpDocsModal;
