/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {createContext, useContext, useMemo, useReducer} from 'react';

// because every naming convention has exceptions
const productionHostExceptions = [
  'cheyenne.ccsd.midaseducation.com',
  'elizondo.ccsd.midaseducation.com',
];

const nonProduction = !!(
  window?.location &&
  !window.location.hostname.match(/^[^.]+\.midaseducation.com$/) &&
  productionHostExceptions.indexOf(window.location.hostname) === -1 &&
  window.location.search.indexOf(
    '__DANGEROUSLY_DISABLE_NON_PRODUCTION_INDICATOR__' // escape hatch for creating documentation screenshots
  ) === -1
);

const initialState = {
  stack: [],
  mobileSidebarNavVisible: false,
  helpPanelVisible: false,
  helpAnchor: null,
  courseSurveyNotificationCount: null,
};

export const SET_STACK = 'SET_STACK';
export const PUSH_MENU = 'PUSH_MENU';
export const SHOW_MOBILE_SIDEBAR_NAV = 'SHOW_MOBILE_SIDEBAR_NAV';
export const HIDE_MOBILE_SIDEBAR_NAV = 'HIDE_MOBILE_SIDEBAR_NAV';
export const SHOW_HELP_PANEL = 'SHOW_HELP_PANEL';
export const HIDE_HELP_PANEL = 'HIDE_HELP_PANEL';
export const COURSE_SURVEY_NOTIFICATION_COUNT =
  'COURSE_SURVEY_NOTIFICATION_COUNT';

const pushMenu = (state, menuID) => {
  // Special case: go all the way back to the root nav
  if (menuID === 'midas') {
    return {...state, stack: []};
  }

  // If the stack is empty -- i.e., fresh page load -- check for nested
  // and/or detached menu IDs and build intermediate layers as necessary
  if (state.stack.length === 0) {
    const stack = [];

    let layer = '';
    for (let part of menuID.split('/')) {
      layer += (layer.length > 0 ? '/' : '') + part;
      switch (layer) {
        case 'midas':
          break; // skip root menu

        case 'midas/student':
          stack.push('midas/students');
          stack.push(layer);
          break;

        case 'midas/staff-member':
          stack.push('midas/staff');
          stack.push(layer);
          break;

        case 'midas/guardian':
          stack.push('midas/guardians');
          stack.push(layer);
          break;

        case 'midas/event':
          stack.push('midas/events');
          stack.push(layer);
          break;

        default:
          stack.push(layer);
      }
    }

    return {...state, stack};
  }

  // If the new menu is already in the stack, unwind to that point
  const index = state.stack.indexOf(menuID);
  if (index === state.stack.length - 1) {
    return state; // already at top of stack; nothing to do
  } else if (index !== -1) {
    return {...state, stack: state.stack.slice(0, index + 1)};
  }

  return {...state, stack: [...state.stack, menuID]};
};

const reducer = (state, action) => {
  switch (action.type) {
    case SET_STACK:
      return {...state, stack: action.payload};

    case PUSH_MENU:
      return pushMenu(state, action.payload);

    case SHOW_MOBILE_SIDEBAR_NAV:
      return {...state, mobileSidebarNavVisible: true};

    case HIDE_MOBILE_SIDEBAR_NAV:
      return {...state, mobileSidebarNavVisible: false};

    case SHOW_HELP_PANEL:
      return {
        ...state,
        helpPanelVisible: true,
        helpAnchor: action.payload || null,
      };

    case HIDE_HELP_PANEL:
      return {...state, helpPanelVisible: false, helpAnchor: null};

    case COURSE_SURVEY_NOTIFICATION_COUNT:
      return {...state, courseSurveyNotificationCount: action.payload};

    default:
      return state;
  }
};

const NavigationContext = createContext(undefined);

export const NavigationContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => {
    const dismissMobileSidebar = () => {
      if (state.mobileSidebarNavVisible) {
        dispatch({type: HIDE_MOBILE_SIDEBAR_NAV});
      }
    };

    return {
      stack: state.stack,
      mobileSidebarNavVisible: state.mobileSidebarNavVisible,
      dismissMobileSidebar,
      helpPanelVisible: state.helpPanelVisible,
      helpAnchor: state.helpAnchor,
      courseSurveyNotificationCount: state.courseSurveyNotificationCount,
      dispatch,
      nonProduction,
    };
  }, [state, dispatch]);

  // TODO: Add monitor component to track stack as route data for back button behavior

  return (
    <NavigationContext.Provider value={value}>
      {children}
    </NavigationContext.Provider>
  );
};

export const useNavigationContext = () => {
  const context = useContext(NavigationContext);
  if (context === undefined) {
    throw new Error(
      'useNavigationContext must be used within a NavigationContextProvider'
    );
  }
  return context;
};

export const useDetachedMenuShouldRender = (menuID) => {
  const {stack} = useNavigationContext();
  return stack.length > 0 && stack[stack.length - 1].startsWith(menuID);
};
