/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const strokeStyle = {
  fill: '#fff',
  stroke: 'rgba(255,255,255,0.8)',
  strokeMiterlimit: 10,
  strokeWidth: '20px',
};

const blueStyle = {
  fill: '#2360ac',
};

const greenStyle = {
  fill: '#6aa442',
};

const MidasLogo = ({
  width = '40px',
  height = '40px',
  stroke = false,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 276.56 275.08"
    style={{width, height}}
    className={className}
  >
    {stroke && (
      <path
        d="M403,523.88l30-30.28v0C408.2,470.29,392.5,435.66,392.5,397c0-38.9,15.9-73.72,40.95-97l-30.3-30L306,366.93,208.85,270l-30.3,30c25,23.3,40.95,58.12,40.95,97,0,38.66-15.7,73.29-40.48,96.58v0l30,30.28,97-96.73Z"
        transform="translate(-167.72 -259.43)"
        style={strokeStyle}
      />
    )}
    <path
      d="M209,523.88l93-92.73-30-30.3-12.72,12.68h0C255.5,457,237,495.79,209.21,523.67"
      transform="translate(-167.72 -259.43)"
      style={blueStyle}
    />
    <path
      d="M403.11,524C371.71,492.61,352,447.34,352,397q0-8.33.71-16.44h0L339.24,394l-30.31-30,94.22-94,30.32,30,0,0c-25.05,23.3-40.95,58.12-40.95,97,0,38.66,15.7,73.29,40.48,96.58v0l-30,30.28"
      transform="translate(-167.72 -259.43)"
      style={blueStyle}
    />
    <path
      d="M178.53,300l30.32-30L352.72,413.53h0c3.8,43.6,22.42,82.44,50.27,110.34h0L259.29,380.56c.47,5.41.71,10.9.71,16.44,0,50.27-19.66,95.49-51,126.87h0L179,493.6v0C203.8,470.29,219.5,435.66,219.5,397c0-38.9-15.9-73.72-40.95-97l30.32-30"
      transform="translate(-167.72 -259.43)"
      style={greenStyle}
    />
  </svg>
);

export default MidasLogo;
