/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import WrappedFormControl from './WrappedFormControl';

const messages = defineMessages({
  placeholder: {id: 'form.placeholder.password'},
});

const PasswordField = (
  {as, type, placeholder = messages.placeholder, ...props},
  ref
) => {
  return (
    <WrappedFormControl
      ref={ref}
      type="password"
      placeholder={placeholder}
      {...props}
    />
  );
};
PasswordField.displayName = 'PasswordField';

export default forwardRef(PasswordField);
