/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faBars} from '@fortawesome/free-solid-svg-icons';

const NavigationExpandIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faBars} {...props} />
);

export default NavigationExpandIcon;
