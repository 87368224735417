/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.intervention.progress-monitors'},
});

const ProgressMonitorsItem = () => (
  <RouteLinkItem
    route="midas/settings/intervention/progress-monitors"
    title={messages.title}
  />
);

export default ProgressMonitorsItem;
