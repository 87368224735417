/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import FinanceIcon from 'icons/FinanceIcon';
import CategoriesItem from './finance/CategoriesItem';
import LedgerItem from './finance/LedgerItem';
import ReportsItem from './finance/ReportsItem';
import RulesItem from './finance/RulesItem';
import TransactionTypesItem from './finance/TransactionTypesItem';
import YearEndReportItem from './finance/YearEndReportItem';

const messages = defineMessages({
  title: {id: 'navigation.finance'},
});

const FinanceMenu = () => (
  <NavigationMenu
    iconBefore={FinanceIcon}
    route="midas/finance"
    title={messages.title}
  >
    <LedgerItem />
    <TransactionTypesItem />
    <CategoriesItem />
    <RulesItem />
    <ReportsItem />
    <YearEndReportItem />
  </NavigationMenu>
);

export default FinanceMenu;
