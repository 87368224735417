/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import MicrocredentialIcon from 'icons/MicrocredentialIcon';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.microcredentials'},
});

const MicrocredentialsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={MicrocredentialIcon}
    route="midas/staff-member/badges"
    title={messages.title}
  />
);

export default MicrocredentialsItem;
