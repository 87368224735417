/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useFormikContext} from 'formik';
import {Button} from 'react-bootstrap';

const messages = defineMessages({
  goToCart: {id: 'button.go-to-cart'},
});

const GoToCartButton = (
  {
    message = messages.goToCart,
    variant = 'primary',
    disabled = false,
    ...props
  },
  ref
) => {
  const {isSubmitting} = useFormikContext();

  return (
    <Button
      ref={ref}
      variant={variant}
      disabled={isSubmitting || disabled}
      {...props}
    >
      <FormattedMessage {...message} />
    </Button>
  );
};
GoToCartButton.displayName = 'GoToCartButton';

export default forwardRef(GoToCartButton);
