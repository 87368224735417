/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useCallback} from 'react';
import {includes} from 'lodash';
import {useUserContext} from 'app/UserContext';

const useRouteIsAuthorized = () => {
  const [{allowedRoutes}] = useUserContext();
  return useCallback(
    (route) => {
      return includes(allowedRoutes, route);
    },
    [allowedRoutes]
  );
};

export default useRouteIsAuthorized;
