/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useUserImage, useUser} from 'app/hooks';
import Avatar from '@atlaskit/avatar';

const UserAvatar = ({userID, ...props}) => {
  const avatarSrc = useUserImage(userID, 'thm');
  const user = useUser(userID);

  const avatarName = user ? `${user.firstName} ${user.lastName}` : undefined;

  return <Avatar src={avatarSrc} name={avatarName} {...props} />;
};

export default UserAvatar;
