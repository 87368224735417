/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEye} from '@fortawesome/pro-duotone-svg-icons';

const SpotObservationsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faEye} {...props} />
);

export default SpotObservationsIcon;
