/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {toNumber} from 'lodash';
import {STAFF_ID_MATCHES_USER} from './routing';

/**
 * Returns true if the user has the named permission.
 */
export const userHasPermission = (
  userContext,
  permission,
  routeParams = {}
) => {
  // Evaluate routing permissions directly
  switch (permission) {
    case STAFF_ID_MATCHES_USER:
      return (
        routeParams.staffID &&
        toNumber(routeParams.staffID) === userContext.userID
      );

    default:
      return userContext.permissions.indexOf(permission) !== -1;
  }
};

/**
 * Returns true if the user has all of the named permissions in the specified
 * array. For example:
 *
 *   const permissions = [NAME_OF_PERMISSION, ADDITIONAL_PERMISSION];
 *
 * If the user must only have one of a set of possible permissions, the set
 * can be defined as an array:
 *
 *   const permissions = [
 *       NAME_OF_PERMISSION,
 *       [ADDITIONAL_PERMISSION, OR_THIS_PERMISSION, OR_MAYBE_PERMISSION],
 *       THIS_ONE_STILL_REQUIRED,
 *   ];
 *
 * If no permissions are defined, returns true.
 */
export const userHasAllPermissions = (
  userContext,
  permissions,
  routeParams = {}
) => {
  if (!permissions || permissions.length < 1) {
    return true;
  }
  for (let permission of permissions) {
    if (typeof permission === 'string') {
      if (!userHasPermission(userContext, permission, routeParams)) {
        return false;
      }
    } else {
      let hasOnePermission = false;
      for (let subPermission of permission) {
        if (userHasPermission(userContext, subPermission, routeParams)) {
          hasOnePermission = true;
          break;
        }
      }
      if (!hasOnePermission) {
        return false;
      }
    }
  }
  return true;
};
