/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import BehaviorIcon from 'icons/BehaviorIcon';
import SubmittedConcernsItem from './behavior/SubmittedConcernsItem';
import SubmitConcernItem from './behavior/SubmitConcernItem';
import IncidentManagementItem from './behavior/IncidentManagementItem';
import IncidentManagementBicoItem from './behavior/IncidentManagementBicoItem';

const messages = defineMessages({
  title: {id: 'navigation.behavior'},
});

const BehaviorMenu = () => (
  <NavigationMenu
    iconBefore={BehaviorIcon}
    route="midas/students/behavior"
    title={messages.title}
  >
    <SubmittedConcernsItem />
    <SubmitConcernItem />
    <IncidentManagementItem />
    <IncidentManagementBicoItem />
  </NavigationMenu>
);

export default BehaviorMenu;
