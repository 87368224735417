/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef} from 'react';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import {Form} from 'react-bootstrap';
import FieldWrapper from './FieldWrapper';
import FieldLabel from 'components/form/field/FieldLabel';

const CheckboxField = (
  {
    id,
    label,
    help,
    value = '1',
    radio = false,
    required = false,
    disabled = false,
    ...props
  },
  ref
) => {
  const [{name, checked, onBlur, onChange}, meta] = useField({
    type: radio ? 'radio' : 'checkbox',
    value,
    ...props,
  });
  const {isSubmitting} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const isInvalid = meta.touched && meta.error && true;

  return (
    <FieldWrapper id={id} help={help} required={required} meta={meta}>
      <Form.Control
        ref={ref}
        as="input"
        type={radio ? 'radio' : 'checkbox'}
        id={id}
        name={name}
        value={value}
        checked={checked}
        onBlur={onBlur}
        onChange={onChange}
        aria-required={required}
        disabled={isSubmitting || disabled}
        bsPrefix="form-check-input"
        isInvalid={isInvalid}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? `${id}-error` : undefined}
        aria-labelledby={label && `${id}-label`}
        aria-describedby={help && `${id}-help`}
        {...props}
      />
      <FieldLabel
        id={id}
        label={label}
        required={required}
        className="form-check-label"
      />
    </FieldWrapper>
  );
};
CheckboxField.displayName = 'CheckboxField';

export default forwardRef(CheckboxField);
