/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const registrationForms = {
  '': '/midas/settings/registration-forms',
  forms: {
    '': '/midas/settings/registration-forms/forms',
    'add-form': '/midas/settings/registration-forms/forms/add-form',
    'edit-form': '/midas/settings/registration-forms/forms/edit-form/:formID',
    'add-section-heading':
      '/midas/settings/registration-forms/forms/add-section-heading/:formID',
    'edit-section-heading':
      '/midas/settings/registration-forms/forms/edit-section-heading/:formID/:sectionID',
    'preview-questions':
      '/midas/settings/registration-forms/forms/preview-questions/:formID',
    'add-question':
      '/midas/settings/registration-forms/forms/add-question/:sectionID',
    'edit-question':
      '/midas/settings/registration-forms/forms/edit-question/:sectionID/:questionID',
  },
  submissions: {
    '': '/midas/settings/registration-forms/submissions',
    'add-submission':
      '/midas/settings/registration-forms/submissions/add-submission/:userID',
    'edit-submission':
      '/midas/settings/registration-forms/submissions/edit-submission/:submissionID',
    'view-submission':
      '/midas/settings/registration-forms/submissions/view-submission/:submissionID',
  },
  categories: {
    '': '/midas/settings/registration-forms/categories',
    'add-category':
      '/midas/settings/registration-forms/categories/add-category',
    'edit-category':
      '/midas/settings/registration-forms/categories/edit-category/:categoryID',
  },
};

export default registrationForms;
