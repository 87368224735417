/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState, useEffect} from 'react';
import {isEqual} from 'lodash';

const useDebouncedValue = (value, delay, deepEqual = false) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (deepEqual) {
        setDebouncedValue((oldValue) =>
          isEqual(oldValue, value) ? oldValue : value
        );
      } else {
        setDebouncedValue(value);
      }
    }, delay);

    return () => {
      clearTimeout(timeout);
    };
  }, [value, delay, deepEqual]);

  return debouncedValue;
};

export default useDebouncedValue;
