/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import AutosizeTextarea from 'react-textarea-autosize';
import WrappedFormControl from './WrappedFormControl';

const TextField = (
  {as, type, multiline = false, minRows = 2, maxRows = 12, ...props},
  ref
) => {
  if (multiline) {
    return (
      <WrappedFormControl
        ref={ref}
        as={AutosizeTextarea}
        minRows={minRows}
        maxRows={maxRows}
        {...props}
      />
    );
  }
  return <WrappedFormControl ref={ref} type="text" {...props} />;
};
TextField.displayName = 'TextField';

export default forwardRef(TextField);
