/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import MagicIcon from 'icons/MagicIcon';

const messages = defineMessages({
  title: {id: 'navigation.finance.rules'},
});

const RulesItem = () => (
  <RouteLinkItem
    iconBefore={MagicIcon}
    route="midas/finance/transaction-rules"
    title={messages.title}
  />
);

export default RulesItem;
