/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const finance = {
  '': '/midas/finance',
  ledger: {
    '': '/midas/finance/ledger',
    'add-transaction': '/midas/finance/ledger/add-transaction',
    'edit-transaction': '/midas/finance/ledger/edit-transaction/:transactionID',
  },
  'transaction-types': {
    '': '/midas/finance/transaction-types',
    'add-type': '/midas/finance/transaction-types/add-type',
    'edit-type':
      '/midas/finance/transaction-types/edit-type/:transactionTypeID',
  },
  'transaction-categories': {
    '': '/midas/finance/transaction-categories',
    'add-category': '/midas/finance/transaction-categories/add-category',
    'edit-category':
      '/midas/finance/transaction-categories/edit-category/:transactionCategoryID',
    'add-code':
      '/midas/finance/transaction-categories/add-code/:transactionCategoryID',
    'edit-code':
      '/midas/finance/transaction-categories/edit-code/:transactionCodeID',
  },
  'transaction-rules': {
    '': '/midas/finance/transaction-rules',
    'add-rule': '/midas/finance/transaction-rules/add-rule',
    'edit-rule':
      '/midas/finance/transaction-rules/edit-rule/:transactionruleID',
  },
  reports: {
    '': '/midas/finance/reports',
  },
  'year-end-report': {
    '': '/midas/finance/year-end-report',
  },
};

export default finance;
