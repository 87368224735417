/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import SpotObservationsIcon from 'icons/SpotObservationsIcon';

const messages = defineMessages({
  title: {id: 'navigation.educator-merit-pay.spot-observations'},
});

const SpotObservationsItem = () => (
  <RouteLinkItem
    iconBefore={SpotObservationsIcon}
    route="midas/educator-merit-pay/spot-observations"
    title={messages.title}
  />
);

export default SpotObservationsItem;
