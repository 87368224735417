/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {RouteButtonLink} from 'components/link';
import {NewButton, EditButton} from 'components/form';
import AddIcon from 'icons/AddIcon';
import './PageHeading.css';

const PageHeading = ({
  text,
  icon: Icon,
  newText,
  newRoute,
  newRouteParams,
  newOnClick,
  newDisabled,
  editButton = false,
}) => {
  return (
    <div className="d-md-flex align-items-center mb-3">
      <h3 className="PageHeading mb-2 mb-md-0 mr-auto">
        {Icon && <Icon />}
        <FormattedMessage {...text} />
      </h3>
      {newRoute ? (
        <RouteButtonLink
          text={newText}
          route={newRoute}
          params={newRouteParams}
          icon={AddIcon}
          onClick={newOnClick}
        />
      ) : (
        newOnClick &&
        (!editButton ? (
          <NewButton
            text={newText}
            onClick={newOnClick}
            disabled={newDisabled}
          />
        ) : (
          <EditButton
            text={newText}
            onClick={newOnClick}
            disabled={newDisabled}
          />
        ))
      )}
    </div>
  );
};

export default PageHeading;
