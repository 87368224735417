/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHourglassHalf} from '@fortawesome/pro-duotone-svg-icons';

const ScheduledReportsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHourglassHalf} {...props} />
);

export default ScheduledReportsIcon;
