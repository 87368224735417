/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHospitals} from '@fortawesome/pro-duotone-svg-icons';

const HealthProviderIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHospitals} {...props} />
);

export default HealthProviderIcon;
