/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useUserContext} from 'app/UserContext';
import {useDetachedMenuShouldRender} from 'app/NavigationContext';
import {useUser} from 'app/hooks';
import {NestingItem, Section} from '@atlaskit/side-navigation';
import ChangePasswordItem from './authenticated-user/ChangePasswordItem';
import GoalsItem from './authenticated-user/GoalsItem';
import PortfolioItem from './authenticated-user/PortfolioItem';
import LogOutItem from './authenticated-user/LogOutItem';
import MyProfileItem from './authenticated-user/MyProfileItem';
import MicrocredentialsItem from './authenticated-user/MicrocredentialsItem';
import PdTranscriptItem from './authenticated-user/PdTranscriptItem';
import ProfessionalLearningItem from './authenticated-user/ProfessionalLearningItem';
import PdApprovalsItem from './authenticated-user/PdApprovalsItem';
import PdCourseSurveysItem from 'components/navigation/side-navigation/menu/authenticated-user/PdCourseSurveysItem';
import MeritPayReportCardItem from 'components/navigation/side-navigation/menu/authenticated-user/MeritPayReportCardItem';
import ReviewerMaterialsItem from 'components/navigation/side-navigation/menu/authenticated-user/ReviewerMaterialsItem';

const AuthenticatedUserMenu = () => {
  const [{userID}] = useUserContext();
  const user = useUser(userID);

  const shouldRender = useDetachedMenuShouldRender('authenticated-user');

  if (!user || !shouldRender) {
    return null;
  }
  const userName = `${user.firstName} ${user.lastName}`;

  return (
    <NestingItem id="authenticated-user" title={userName}>
      <Section title={userName}>
        <MyProfileItem />
        <GoalsItem />
        <PortfolioItem />
        <MicrocredentialsItem />
        <PdApprovalsItem />
        <PdCourseSurveysItem />
        <PdTranscriptItem />
        <ProfessionalLearningItem />
        <ReviewerMaterialsItem />
        <MeritPayReportCardItem />
        <ChangePasswordItem />
      </Section>
      <Section>
        <LogOutItem />
      </Section>
    </NestingItem>
  );
};

export default AuthenticatedUserMenu;
