/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const academics = {
  '': '/midas/settings/academics',
  'class-rank': {
    '': '/midas/settings/academics/class-rank',
  },
  'course-groups': {
    '': '/midas/settings/academics/course-groups',
    'add-course-group':
      '/midas/settings/academics/course-groups/add-course-group',
    'edit-course-group':
      '/midas/settings/academics/course-groups/edit-course-group/:courseGroupID',
    'add-course':
      '/midas/settings/academics/course-groups/add-course/:parentID',
  },
  'course-catalogs': {
    '': '/midas/settings/academics/course-catalogs',
    'add-catalog': '/midas/settings/academics/course-catalogs/add-catalog',
    'edit-catalog':
      '/midas/settings/academics/course-catalogs/edit-catalog/:catalogID',
  },
  grades: {
    '': '/midas/settings/academics/grades',
    'add-grade': '/midas/settings/academics/grades/add-grade',
    'edit-grade': '/midas/settings/academics/grades/edit-grade/:gradeID',
  },
  'grading-comments': {
    '': '/midas/settings/academics/grading-comments',
    'add-grading-comment':
      '/midas/settings/academics/grading-comments/add-grading-comment',
    'edit-grading-comment':
      '/midas/settings/academics/grading-comments/edit-grading-comment/:gradingCommentID',
  },
  'grading-periods': {
    '': '/midas/settings/academics/grading-periods',
    'add-grading-period':
      '/midas/settings/academics/grading-periods/add-grading-period/:gradingPeriodYear',
    'edit-grading-period':
      '/midas/settings/academics/grading-periods/edit-grading-period/:gradingPeriodID',
    'select-grading-period':
      '/midas/settings/academics/grading-periods/select-grading-period/:yearID',
  },
  'grading-scales': {
    '': '/midas/settings/academics/grading-scales',
    'select-grade':
      '/midas/settings/academics/grading-scales/select-grade/:gradingScaleID',
    'select-grading-scale':
      '/midas/settings/academics/grading-scales/select-grading-scale',
    'add-new-grading-scale':
      '/midas/settings/academics/grading-scales/add-new-grading-scale',
    'edit-grade':
      '/midas/settings/academics/grading-scales/edit-grade/:gradingScaleID',
    'edit-grading-scale':
      '/midas/settings/academics/grading-scales/edit-grading-scale/:gradingScaleID',
  },
  'grading-settings': {
    '': '/midas/settings/academics/grading-settings',
    'set-rounding-status':
      '/midas/settings/academics/grading-settings/set-rounding-status',
    'add-gpa-catalog-rule':
      '/midas/settings/academics/grading-settings/add-gpa-catalog-rule',
  },
  'graduation-requirements': {
    '': '/midas/settings/academics/graduation-requirements',
    'add-requirement-set':
      '/midas/settings/academics/graduation-requirements/add-requirement-set',
    'add-requirement':
      '/midas/settings/academics/graduation-requirements/add-requirement/:graduationRequirementSet',
    'edit-requirement':
      '/midas/settings/academics/graduation-requirements/edit-requirement/:requirementID',
    'bulk-assign-requirement-set':
      '/midas/settings/academics/graduation-requirements/bulk-assign-requirement-set',
    'edit-graduation-requirements':
      '/midas/settings/academics/graduation-requirements/edit-graduation-requirements/:graduationRequirementID',
  },
  'honor-roll': {
    '': '/midas/settings/academics/honor-roll',
    'edit-mode': '/midas/settings/academics/honor-roll/edit-mode',
    'add-honor-roll': '/midas/settings/academics/honor-roll/add-honor-roll',
    'edit-honor-roll':
      '/midas/settings/academics/honor-roll/edit-honor-roll/:honorRollID',
    'select-grading-period':
      '/midas/settings/academics/honor-roll/select-grading-period/:honorRollID',
  },
  'per-year-requirements': {
    '': '/midas/settings/academics/per-year-requirements',
    'add-requirement':
      '/midas/settings/academics/per-year-requirements/add-requirement/:yearRequirementID',
    'edit-requirement':
      '/midas/settings/academics/per-year-requirements/edit-requirement/:requirementID',
    'add-requirement-set':
      '/midas/settings/academics/per-year-requirements/add-requirement-set',
    'edit-requirement-set':
      '/midas/settings/academics/per-year-requirements/edit-requirement-set/:yearRequirementID',
  },
  prerequisites: {
    '': '/midas/settings/academics/prerequisites',
    'add-prerequisite':
      '/midas/settings/academics/prerequisites/add-prerequisite',
    'edit-prerequisite':
      '/midas/settings/academics/prerequisites/edit-prerequisite/:prerequisiteID',
  },
  sequence: {
    '': '/midas/settings/academics/sequence',
    'add-sequence': '/midas/settings/academics/sequence/add-sequence',
    'add-sequence-step':
      '/midas/settings/academics/sequence/add-sequence-step/:sequenceID',
    'edit-sequence':
      '/midas/settings/academics/sequence/edit-sequence/:sequenceID',
  },
};

export default academics;
