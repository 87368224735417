/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const classroom = {
  '': '/midas/classroom/:staffID?/:pageName?/:sectionID?',
  'ad-hoc-schedule': '/midas/classroom/:staffID/ad-hoc-schedule/:sectionID',
  attendance: {
    '': '/midas/classroom/:staffID/attendance/:sectionID',
    alerts: '/midas/classroom/:staffID/attendance/:sectionID/alerts/:studentID',
    'user-data':
      '/midas/classroom/:staffID/attendance/:sectionID/user-data/:studentID/:userType',
  },
  'meeting-attendance': {
    '': '/midas/classroom/:staffID/meeting-attendance/:sectionID',
    alerts: '/midas/classroom/:staffID/attendance/:sectionID/alerts/:studentID',
    'user-data':
      '/midas/classroom/:staffID/attendance/:sectionID/user-data/:studentID/:userType',
    'enroll-students':
      '/midas/classroom/:staffID/meeting-attendance/:sectionID/enroll-students',
  },
  'ad-hoc-attendance': {
    '': '/midas/classroom/:staffID/ad-hoc-attendance/:sectionID',
  },
  calendar: {
    '': '/midas/classroom/:staffID/calendar/:sectionID',
    'add-event': '/midas/classroom/:staffID/calendar/:sectionID/add-event',
    'assignment-details':
      '/midas/classroom/:staffID/calendar/:sectionID/assignment-details/:assignmentID',
    'event-details':
      '/midas/classroom/:staffID/calendar/:sectionID/event-details/:eventID',
    'section-details':
      '/midas/classroom/:staffID/calendar/:sectionID/section-details',
  },
  classroom: {
    '': '/midas/classroom/:staffID/classroom/:sectionID/:date?',
    'add-assignment':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/add-assignment',
    'edit-assignment':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/edit-assignment/:assignmentID/:snippetSectionID',
    'add-snippet':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/add-snippet/:snippetType',
    'edit-snippet':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/edit-snippet/:snippetID/:snippetSectionID',
    'message-log':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/message-log/:snippetID/:assignmentID?',
    'respond-to-question':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/respond-to-question/:snippetSectionID',
    'search-snippets':
      '/midas/classroom/:staffID/classroom/:sectionID/:date/search-snippets',
  },
  gradebook: '/midas/classroom/:staffID/gradebook/:sectionID',
  'meal-count': {
    '': '/midas/classroom/:staffID/meal-count/:sectionID',
    'by-class':
      '/midas/classroom/:staffID/meal-count/:sectionID/by-class/:date',
    'by-individual':
      '/midas/classroom/:staffID/meal-count/:sectionID/by-individual/:date',
  },
  'report-card-comments': {
    '': '/midas/classroom/:staffID/report-card-comments/:sectionID',
    'edit-comment':
      '/midas/classroom/:staffID/report-card-comments/:sectionID/edit-comment/:commentID',
    'edit-general-comments':
      '/midas/classroom/:staffID/report-card-comments/:sectionID/edit-general-comments/:commentID',
    'general-comments':
      '/midas/classroom/:staffID/report-card-comments/:sectionID/general-comments/:gradingPeriodID',
    'grade-standards':
      '/midas/classroom/:staffID/report-card-comments/:sectionID/grade-standards/:gradingPeriodID/:userID',
  },
  resources: {
    '': '/midas/classroom/:staffID/resources/:sectionID',
    'add-resource':
      '/midas/classroom/:staffID/resources/:sectionID/add-resource',
    'add-snippet': '/midas/classroom/:staffID/resources/:sectionID/add-snippet',
    'edit-resource':
      '/midas/classroom/:staffID/resources/:sectionID/edit-resource/:resourceID',
    'edit-snippet':
      '/midas/classroom/:staffID/resources/:sectionID/edit-snippet/:snippetID',
  },
  roster: {
    '': '/midas/classroom/:staffID/roster/:sectionID',
    alerts: '/midas/classroom/:staffID/roster/:sectionID/alerts/:studentID',
    print: '/midas/classroom/:staffID/roster/:sectionID/print',
  },
  'pd-roster': {
    '': '/midas/classroom/:staffID/pd-roster/:sectionID',
    'download-csv':
      '/midas/classroom/:staffID/pd-roster/:sectionID/download-csv',
    print: '/midas/classroom/:staffID/pd-roster/:sectionID/print',
  },
  'section-groups': {
    '': '/midas/classroom/:staffID/section-groups/:sectionID',
    'add-group':
      '/midas/classroom/:staffID/section-groups/:sectionID/add-group',
    'edit-group':
      '/midas/classroom/:staffID/section-groups/:sectionID/edit-group/:groupID',
    'add-members':
      '/midas/classroom/:staffID/section-groups/:sectionID/add-members/:groupID',
    'edit-member':
      '/midas/classroom/:staffID/section-groups/:sectionID/edit-member/:memberID',
  },
  'seating-chart': '/midas/classroom/:staffID/seating-chart/:sectionID',
  'section-page': '/midas/classroom/:staffID/:pageName/:sectionID', // used only for nav; not a reachable page
};

export default classroom;
