/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import RubricsIcon from 'icons/RubricsIcon';

const messages = defineMessages({
  title: {id: 'navigation.instructional-materials.rubrics'},
});

const RubricsItem = () => (
  <RouteLinkItem
    iconBefore={RubricsIcon}
    route="midas/instructional-materials/rubrics"
    title={messages.title}
  />
);

export default RubricsItem;
