/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import BiographyIcon from 'icons/BiographyIcon';

const messages = defineMessages({
  title: {id: 'navigation.biography'},
});

const BiographyItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={BiographyIcon}
    route="midas/staff-member/biography"
    title={messages.title}
  />
);

export default BiographyItem;
