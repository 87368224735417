/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import DataExplorerIcon from 'icons/DataExplorerIcon';

const messages = defineMessages({
  title: {id: 'navigation.data-explorer'},
});

const DataExplorerItem = () => (
  <RouteLinkItem
    iconBefore={DataExplorerIcon}
    route="midas/data-explorer"
    title={messages.title}
  />
);

export default DataExplorerItem;
