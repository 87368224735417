/**
 * Hook that calls MIDAS JSON API endpoints. Returns a tuple containing the
 * response as a {json, status} object (or null if the request is incomplete),
 * a loading indicator boolean, and an error object if one was encountered.
 * Accepts an optional AbortController signal to enable batch cancellation of
 * concurrent requests.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useReducer, useRef, useEffect, useCallback} from 'react';
import {isEqual} from 'lodash';
import {callMidasJsonApi} from 'app/api';

const noResponse = {json: {}, status: 0};

const initialState = {
  isLoading: false,
  response: noResponse,
  error: null,
  request: {
    url: null,
    method: 'GET',
    signal: null,
    body: null,
    expectedStatus: null,
  },
};

const MAKE_REQUEST = 'MAKE_REQUEST';
const API_START = 'API_START';
const API_SUCCESS = 'API_SUCCESS';
const API_ERROR = 'API_ERROR';

const reducer = (state, action) => {
  switch (action.type) {
    case MAKE_REQUEST:
      return {
        ...state,
        request: action.payload,
      };

    case API_START:
      return {
        ...state,
        isLoading: true,
        response: noResponse,
        error: null,
      };

    case API_SUCCESS:
      if (action.payload.request !== state.request) {
        return state; // a new request was made before the this one resolved; ignore the response
      }
      return {
        ...state,
        isLoading: false,
        response: action.payload.response,
      };

    case API_ERROR:
      if (action.payload.request !== state.request) {
        return state; // a new request was made before the this one resolved; ignore the error
      }
      return {
        ...state,
        isLoading: false,
        error: action.payload.error,
      };

    default:
      return state;
  }
};

const useMidasJsonApi = ({
  url = null,
  method = 'GET',
  body = null,
  expectedStatus = null,
  signal = null,
  immediate = true,
}) => {
  const [{isLoading, response, error, request}, dispatch] = useReducer(
    reducer,
    initialState
  );

  /* Body and expected status are often provided as inline objects, which will
   * be new on each render. Deeply compare values to ensure the useEffect()
   * for the API call only runs once.
   */
  const bodyRef = useRef(body);
  if (!isEqual(body, bodyRef.current)) {
    bodyRef.current = body;
  }
  const expectedStatusRef = useRef(expectedStatus);
  if (!isEqual(expectedStatus, expectedStatusRef.current)) {
    expectedStatusRef.current = expectedStatus;
  }

  if (
    immediate &&
    (url !== request.url ||
      method !== request.method ||
      signal !== request.signal ||
      bodyRef.current !== request.body ||
      expectedStatusRef.current !== request.expectedStatus)
  ) {
    dispatch({
      type: MAKE_REQUEST,
      payload: {
        url,
        method,
        body: bodyRef.current,
        expectedStatus: expectedStatusRef.current,
        signal,
      },
    });
  }

  const makeRequest = useCallback(
    (params) => {
      dispatch({
        type: MAKE_REQUEST,
        payload: {
          url: (params && params.url) || url,
          method: (params && params.method) || method,
          body: (params && params.body) || bodyRef.current,
          expectedStatus: expectedStatusRef.current,
          signal,
        },
      });
    },
    [url, method, signal]
  );

  useEffect(() => {
    if (!request.url) {
      return;
    }
    dispatch({type: API_START});

    let theSignal = request.signal;
    let controller = null;
    if (!theSignal) {
      controller = new AbortController();
      theSignal = controller.signal;
    }

    let cancelled = false;

    callMidasJsonApi(
      request.url,
      request.method,
      theSignal,
      request.body,
      request.expectedStatus
    )
      .then((response) => {
        if (!cancelled) {
          dispatch({type: API_SUCCESS, payload: {request, response}});
        }
      })
      .catch((error) => {
        if (!cancelled) {
          dispatch({type: API_ERROR, payload: {request, error}});
        }
      });

    return () => {
      cancelled = true;
      if (controller) {
        controller.abort();
      }
    };
  }, [request]);

  return [response, isLoading, error, makeRequest];
};

export default useMidasJsonApi;
