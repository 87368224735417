import './Public.css';
import PublicHeader from 'components/layout/PublicHeader';
import PublicNav from 'components/layout/PublicNav';
import PublicFooter from 'components/layout/PublicFooter';
import PublicContainer from 'components/layout/PublicContainer';
import {useRouteMatch} from 'react-router-dom';
import {useMidasJsonApi} from 'app/hooks';
import {ApiResponseWrapper} from 'components/layout';
import CurriculaDetailContent from 'components/microcredentials/public/CurriculaDetailContent';
import {BackToRouteLink} from 'components/link';
import {defineMessages} from 'react-intl';

const messages = defineMessages({
  backToCurricula: {
    id: 'navigation.back-to-curricula',
  },
});

const Curriculum = () => {
  const match = useRouteMatch();

  const [response, loading, error] = useMidasJsonApi({
    url: `/api/curricula/curriculum/${match.params.curriculumID}`,
    expectedStatus: [200, 404],
  });

  return (
    <PublicContainer>
      <PublicHeader />
      <PublicNav />
      <p className="small font-weight-light">
        <BackToRouteLink
          text={messages.backToCurricula}
          route="microcredentials"
        />
      </p>
      <ApiResponseWrapper error={error} isLoading={loading}>
        {response.status === 200 && (
          <CurriculaDetailContent curriculum={response.json.badgeCurriculum} />
        )}
      </ApiResponseWrapper>
      <PublicFooter />
    </PublicContainer>
  );
};

export default Curriculum;
