import {useMidasJsonApi} from 'app/hooks';
import {Col, Row} from 'react-bootstrap';
import {ApiResponseWrapper} from 'components/layout';
import CurriculumPathwayInfo from 'components/microcredentials/public/CurriculumPathwayInfo';

const CurriculaPathways = ({curriculumID}) => {
  const [response, loading, error] = useMidasJsonApi({
    url: `/api/microcredentials/badge-curriculum-pathways?curriculumID=${curriculumID}&allFields=true`,
    expectedStatus: [200, 404],
  });
  return (
    <Row>
      <Col>
        <div className="d-flex">
          <h5>Pathways</h5>
        </div>
        <ApiResponseWrapper error={error} isLoading={loading}>
          {response.status === 200 &&
            response.json.badgeCurriculumPathways.map((pathway) => {
              return <CurriculumPathwayInfo pathway={pathway} />;
            })}
        </ApiResponseWrapper>
      </Col>
    </Row>
  );
};

export default CurriculaPathways;
