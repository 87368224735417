/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import StateReportingIcon from 'icons/StateReportingIcon';

const messages = defineMessages({
  title: {id: 'navigation.state-reporting'},
});

const StateReportingItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={StateReportingIcon}
    route="midas/staff-member/state-reporting"
    title={messages.title}
  />
);

export default StateReportingItem;
