/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import './InlineForm.css';

const InlineForm = ({show, background = true, children}) => {
  if (!show) {
    return null; // todo: animate this instead
  }
  return background ? (
    <div className="InlineForm mt-0 mb-4 p-3 bg-light rounded">{children}</div>
  ) : (
    <div className="InlineForm mt-0 mb-4 p-3">{children}</div>
  );
};

export default InlineForm;
