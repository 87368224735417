/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {EmailField, Form, FormGroup, SubmitButton} from 'components/form';
import {callMidasJsonApi} from 'app/api';

const messages = defineMessages({
  emailLabel: {id: 'form.label.email'},
  resetPassword: {id: 'form.button.reset-password'},
});

const ForgotUsernameForm = ({onResetSent}) => {
  const forgotUsername = (values) => {
    callMidasJsonApi('/midas/rest/reset-password', 'POST', null, values).then(
      ({status}) => {
        if (status === 204) {
          onResetSent();
        } else {
          throw new Error(
            `Received ${status} response while attempting to reset password`
          );
        }
      }
    );
  };

  return (
    <Form
      initialValues={{emailAddress: '', username: ''}}
      onSubmit={forgotUsername}
    >
      <FormGroup>
        <EmailField label={messages.emailLabel} name="emailAddress" autoFocus />
      </FormGroup>
      <SubmitButton
        submitMessage={messages.resetPassword}
        block
        className="my-4"
      />
    </Form>
  );
};

export default ForgotUsernameForm;
