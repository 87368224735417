/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import AttendanceIcon from 'icons/AttendanceIcon';
import AttendanceTriggersItem from './attendance/AttendanceTriggersItem';
import GroupsAndMarksItem from './attendance/GroupsAndMarksItem';
import PeriodRulesItem from './attendance/PeriodRulesItem';
import TracksItem from './attendance/TracksItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.attendance'},
  sectionTitle: {id: 'navigation.settings.attendance-settings'},
});

const AttendanceMenu = () => (
  <NavigationMenu
    iconBefore={AttendanceIcon}
    route="midas/settings/attendance"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <AttendanceTriggersItem />
    <GroupsAndMarksItem />
    <PeriodRulesItem />
    <TracksItem />
  </NavigationMenu>
);

export default AttendanceMenu;
