/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import InstitutePanelsIcon from 'icons/InstitutePanelsIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.my-meetings'},
});

const MyMeetingsItem = () => (
  <EventRouteLinkItem
    iconBefore={InstitutePanelsIcon}
    route="midas/event/my-meetings"
    title={messages.title}
  />
);

export default MyMeetingsItem;
