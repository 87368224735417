/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import EventStatisticsIcon from 'icons/EventStatisticsIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.statistics'},
});

const StatisticsItem = () => (
  <EventRouteLinkItem
    iconBefore={EventStatisticsIcon}
    route="midas/event/statistics"
    title={messages.title}
  />
);

export default StatisticsItem;
