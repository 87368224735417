/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {useFormatMessage} from 'app/hooks';
import {RouteLink} from 'app/routing';

const BackToRouteLink = ({text, route, params = {}, ...props}) => {
  const linkText = useFormatMessage(text, text.values);
  return (
    <RouteLink route={route} params={params} {...props}>
      <FormattedMessage id="link.back-arrow" values={{text: linkText}} />
    </RouteLink>
  );
};

export default BackToRouteLink;
