/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.end-of-year.promotion-settings'},
});

const PromotionSettingsItem = () => (
  <RouteLinkItem
    route="midas/settings/end-of-year/promotion-settings"
    title={messages.title}
  />
);

export default PromotionSettingsItem;
