/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const educatorGrowth = {
  '': '/midas/settings/educator-growth',
  plans: {
    '': '/midas/settings/educator-growth/plans',
    'add-plan': '/midas/settings/educator-growth/plans/add-plan',
    'edit-plan': '/midas/settings/educator-growth/plans/edit-plan/:planID',
    'view-plan-details':
      '/midas/settings/educator-growth/plans/view-plan-details/:planID',
    'view-plan-users':
      '/midas/settings/educator-growth/plans/view-plan-users/:planID',
    'add-plan-step':
      '/midas/settings/educator-growth/plans/add-plan-step/:planID',
    'edit-plan-step':
      '/midas/settings/educator-growth/plans/edit-plan-step/:planID/:stepID',
    'add-tag': '/midas/settings/educator-growth/plans/add-tag',
    'edit-tag': '/midas/settings/educator-growth/plans/edit-tag/:tagID',
    'assign-users-to-plan':
      '/midas/settings/educator-growth/plans/assign-users-to-plan',
    'add-resources-to-plan':
      '/midas/settings/educator-growth/plans/add-resources-to-plan',
  },
  competencies: {
    '': '/midas/settings/educator-growth/competencies',
    'add-standard-competency':
      '/midas/settings/educator-growth/competencies/add-standard-competency',
    'edit-goal':
      '/midas/settings/educator-growth/competencies/edit-goal/:goalID',
    'view-goal-details':
      '/midas/settings/educator-growth/competencies/view-goal-details/:goalID',
    'view-goal-users':
      '/midas/settings/educator-growth/competencies/view-goal-users/:goalID',
    'preview-rubric':
      '/midas/settings/educator-growth/competencies/preview-rubric/:elementID',
    'add-goal-element':
      '/midas/settings/educator-growth/competencies/add-goal-element/:goalID',
    'edit-goal-element':
      '/midas/settings/educator-growth/competencies/edit-goal-element/:goalID/:elementID',
    'view-goal-element-details':
      '/midas/settings/educator-growth/competencies/view-goal-element-details/:goalID/:elementID',
  },
  'mentors-and-evaluators': {
    '': '/midas/settings/educator-growth/mentors-and-evaluators',
    'add-mentor-evaluator':
      '/midas/settings/educator-growth/mentors-and-evaluators/add-mentor-evaluator/:userID',
    'bulk-add-mentor-evaluator':
      '/midas/settings/educator-growth/mentors-and-evaluators/bulk-add-mentor-evaluator',
  },
  'professional-learning-communities': {
    '': '/midas/settings/educator-growth/professional-learning-communities',
    'add-plc':
      '/midas/settings/educator-growth/professional-learning-communities/add-plc',
    'edit-plc':
      '/midas/settings/educator-growth/professional-learning-communities/edit-plc/:groupID',
    'add-member':
      '/midas/settings/educator-growth/professional-learning-communities/add-member/:groupID',
    'edit-member':
      '/midas/settings/educator-growth/professional-learning-communities/edit-member/:groupID/:userID',
  },
  templates: {
    '': '/midas/settings/educator-growth/templates',
    'add-template': '/midas/settings/educator-growth/templates/add-template',
    'edit-template':
      '/midas/settings/educator-growth/templates/edit-template/:templateID',
    'add-question':
      '/midas/settings/educator-growth/templates/add-question/:templateID',
    'edit-question':
      '/midas/settings/educator-growth/templates/edit-question/:templateID/:questionID',
  },
  analytics: {
    '': '/midas/settings/educator-growth/analytics',
  },
  dates: {
    '': '/midas/settings/educator-growth/dates',
    'add-plan': '/midas/settings/educator-growth/dates/add-plan/:planID',
  },
  resources: {
    '': '/midas/settings/educator-growth/resources',
    'add-resource': '/midas/settings/educator-growth/resources/add-resource',
    'edit-resource':
      '/midas/settings/educator-growth/resources/edit-resource/:resourceID',
  },
  administrators: {
    '': '/midas/settings/educator-growth/administrators',
    'add-administrator':
      '/midas/settings/educator-growth/administrators/add-administrator',
  },
};

export default educatorGrowth;
