/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const endOfYear = {
  '': '/midas/settings/end-of-year',
  'promotion-settings': {
    '': '/midas/settings/end-of-year/promotion-settings',
    'edit-grade':
      '/midas/settings/end-of-year/promotion-settings/edit-grade/:gradeID',
  },
  'promote-students': {
    '': '/midas/settings/end-of-year/promote-students',
  },
};

export default endOfYear;
