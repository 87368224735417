/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const health = {
  '': '/midas/student/:studentID/health',
  allergies: {
    '': '/midas/student/:studentID/health/allergies',
    'add-allergy': '/midas/student/:studentID/health/allergies/add-allergy',
    'edit-allergy':
      '/midas/student/:studentID/health/allergies/edit-allergy/:allergyID',
  },
  conditions: {
    '': '/midas/student/:studentID/health/conditions',
    'add-condition':
      '/midas/student/:studentID/health/conditions/add-condition',
    'edit-condition':
      '/midas/student/:studentID/health/conditions/edit-condition/:conditionID',
    condition:
      '/midas/student/:studentID/health/conditions/condition/:conditionID',
    'add-document': '/midas/student/:studentID/health/conditions/add-document',
    'manage-documents':
      '/midas/student/:studentID/health/conditions/manage-documents/:conditionID',
    'add-event':
      '/midas/student/:studentID/health/conditions/add-event/:conditionID',
    'edit-event':
      '/midas/student/:studentID/health/conditions/edit-event/:eventID',
    event: '/midas/student/:studentID/health/conditions/event/:eventID',
  },
  immunizations: {
    '': '/midas/student/:studentID/health/immunizations',
    'edit-immunization-compliance':
      '/midas/student/:studentID/health/immunizations/edit-immunization-compliance/:complianceID',
    'add-immunization-dose':
      '/midas/student/:studentID/health/immunizations/add-immunization-dose',
    'edit-immunization-dose':
      '/midas/student/:studentID/health/immunizations/edit-immunization-dose/:doseID',
  },
  medications: {
    '': '/midas/student/:studentID/health/medications',
    'add-scheduled-medication': '/midas/student/:studentID/health/medications',
    'edit-scheduled-medication':
      '/midas/student/:studentID/health/medications/:scheduledMedicationID',
    'edit-scheduled-medication-administration':
      '/midas/student/:studentID/health/medications/:administrationID',
    'scheduled-medication':
      '/midas/student/:studentID/health/medications/:scheduledMedicationID',
  },
  providers: {
    '': '/midas/student/:studentID/health/providers',
    'add-provider': '/midas/student/:studentID/health/providers/add-provider',
    'edit-provider':
      '/midas/student/:studentID/health/providers/edit-provider/:providerID',
  },
  screenings: {
    '': '/midas/student/:studentID/health/screenings',
    'add-screening':
      '/midas/student/:studentID/health/screenings/add-screening',
    'edit-screening':
      '/midas/student/:studentID/health/screenings/edit-screening/:screeningID',
    screening:
      '/midas/student/:studentID/health/screenings/screening/:screeningID',
  },
  visits: {
    '': '/midas/student/:studentID/health/visits',
    'add-visit': '/midas/student/:studentID/health/visits/add-visit',
    'edit-visit': '/midas/student/:studentID/health/visits/edit-visit/:visitID',
    visit: '/midas/student/:studentID/health/visits/visit/:visitID',
  },
};

export default health;
