/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {DateTime, IANAZone, LocalZone} from 'luxon';
import {uniqueId} from 'lodash';
import {Form} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const messages = defineMessages({
  placeholder: {id: 'form.placeholder.date'},
});

const DateField = (
  {
    id,
    name,
    label,
    placeholder = messages.placeholder,
    help,
    required = false,
    disabled = false,
    showMonthDropdown = true,
    showYearDropdown = true,
    inputTimeZone = 'local',
    ...props
  },
  ref
) => {
  const intl = useIntl();

  const [{onBlur, value}, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const isInvalid = meta.touched && meta.error && true;

  const CustomInput = forwardRef((customInputProps, ref) => {
    return (
      <Form.Control
        ref={ref}
        aria-required={required}
        isInvalid={isInvalid}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? `${id}-error` : undefined}
        /* TODO: aria-labelledby is getting stripped by <DatePicker> for some reason */
        aria-labelledby={label && `${id}-label`}
        aria-describedby={help && `${id}-help`}
        {...customInputProps}
      />
    );
  });
  CustomInput.displayName = 'DateFieldInput';

  /* react-datepicker works with native JS Date objects, but we use Luxon DateTime,
   * so we need to convert. It also has zero support for time zones, always and only
   * ever displaying and accepting dates in the user's (the browser's) *local* time
   * zone. This is only ever *sometimes* what we want: for example, an assignment due
   * date and time will always be in the school's local time zone, regardless of the
   * time zone of the teacher/student/tech support agent currently logged in. We must
   * transparently map the actual UTC value to and from local time zone, accounting
   * for the difference between local time and the desired input time zone.
   */
  const dateTimeFromISO = value
    ? DateTime.fromISO(value, {setZone: true})
    : null;
  let selectedValue = dateTimeFromISO ? dateTimeFromISO.toJSDate() : null;
  if (value && inputTimeZone !== 'local') {
    const localZone = new LocalZone();
    const inputZone = new IANAZone(inputTimeZone);
    if (!localZone.equals(inputZone)) {
      const localOffset = localZone.offset(value.toMillis());
      const inputOffset = inputZone.offset(value.toMillis());
      selectedValue = value
        ? DateTime.fromISO(value.minus({minutes: localOffset - inputOffset}), {
            setZone: true,
          }).toJSDate()
        : null;
    }
  }

  const onChange = (newDate) => {
    if (!newDate) {
      setFieldValue(name, null);
      return;
    }
    let dateTime = DateTime.fromJSDate(newDate);
    if (inputTimeZone !== 'local') {
      dateTime = dateTime.setZone(inputTimeZone, {keepLocalTime: true});
    }
    setFieldValue(name, dateTime.toUTC());
  };

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <div>
        <DatePicker
          id={id}
          name={name}
          placeholderText={
            placeholder && intl.formatMessage(placeholder, placeholder.values)
          }
          selected={selectedValue}
          onChange={onChange}
          onBlur={onBlur}
          disabled={isSubmitting || disabled}
          customInput={<CustomInput />}
          customInputRef={ref}
          showMonthDropdown={showMonthDropdown}
          showYearDropdown={showYearDropdown}
          dropdownMode="select"
          {...props}
        />
      </div>
    </FieldWrapper>
  );
};
DateField.displayName = 'DateField';

export default forwardRef(DateField);
