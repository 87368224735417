/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import AllergyIcon from 'icons/AllergyIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-allergies'},
});

const AllergiesItem = () => (
  <StudentRouteLinkItem
    iconBefore={AllergyIcon}
    route="midas/student/health/allergies"
    title={messages.title}
  />
);

export default AllergiesItem;
