/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import * as yup from 'app/yup';
import {
  EmailField,
  Form,
  FormGroup,
  SubmitButton,
  TextField,
} from 'components/form';
import {callMidasJsonApi} from 'app/api';

const messages = defineMessages({
  emailLabel: {id: 'form.label.email'},
  usernameLabel: {id: 'form.label.username'},
  usernamePlaceholder: {id: 'form.placeholder.username'},
  resetPassword: {id: 'form.button.reset-password'},
});

const emailValidationSchema = yup.object().shape({
  emailAddress: yup.string().required(),
});

const usernameValidationSchema = yup.object().shape({
  username: yup.string().required(),
});

const ResetPasswordForm = ({usernameMode, onResetSent}) => {
  const resetPassword = (values) => {
    callMidasJsonApi('/midas/rest/reset-password', 'POST', null, values).then(
      ({status}) => {
        if (status === 204) {
          onResetSent();
        } else {
          throw new Error(
            `Received ${status} response while attempting to reset password`
          );
        }
      }
    );
  };

  return (
    <Form
      initialValues={{emailAddress: '', username: ''}}
      validationSchema={
        usernameMode === 'email'
          ? emailValidationSchema
          : usernameValidationSchema
      }
      validateOnBlur={false}
      onSubmit={resetPassword}
    >
      <FormGroup>
        {usernameMode === 'email' ? (
          <EmailField
            label={messages.emailLabel}
            name="emailAddress"
            autoFocus
          />
        ) : (
          <TextField
            label={messages.usernameLabel}
            name="username"
            placeholder={messages.usernamePlaceholder}
            autoFocus
          />
        )}
      </FormGroup>
      <SubmitButton
        submitMessage={messages.resetPassword}
        block
        className="my-4"
      />
    </Form>
  );
};

export default ResetPasswordForm;
