/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import RegistrationFormsIcon from 'icons/RegistrationFormsIcon';
import CategoriesItem from './registration-forms/CategoriesItem';
import FormsItem from './registration-forms/FormsItem';
import SubmissionsItem from './registration-forms/SubmissionsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.registration-forms'},
  sectionTitle: {id: 'navigation.settings.registration-forms-settings'},
});

const RegistrationFormsMenu = () => (
  <NavigationMenu
    iconBefore={RegistrationFormsIcon}
    route="midas/settings/registration-forms"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <FormsItem />
    <SubmissionsItem />
    <CategoriesItem />
  </NavigationMenu>
);

export default RegistrationFormsMenu;
