/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {Component} from 'react';
import {PageContainer} from 'components/layout';
import ErrorDetails from 'components/error/ErrorDetails';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {error: null, errorInfo: null};
  }

  static getDerivedStateFromError(error) {
    return {error};
  }

  componentDidCatch(error, errorInfo) {
    this.setState({error, errorInfo});
    // @todo In production, log the error to an error reporting service
    //   logErrorToMyService(error, errorInfo);
  }

  render() {
    if (this.state.error) {
      return (
        <PageContainer>
          <ErrorDetails
            error={this.state.error}
            errorInfo={this.state.errorInfo}
          />
        </PageContainer>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
