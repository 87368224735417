/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import ProfessionalLearningIcon from 'icons/ProfessionalLearningIcon';
import ApprovalAreasItem from './professional-development/ApprovalAreasItem';
import CreditTypesItem from './professional-development/CreditTypesItem';
import FormsItem from './professional-development/FormsItem';
import LcapItem from './professional-development/LcapItem';
import TagsItem from './professional-development/TagsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.professional-development'},
  sectionTitle: {id: 'navigation.settings.professional-development-settings'},
});

const ProfessionalDevelopmentMenu = () => (
  <NavigationMenu
    iconBefore={ProfessionalLearningIcon}
    route="midas/settings/professional-development"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ApprovalAreasItem />
    <CreditTypesItem />
    <FormsItem />
    <LcapItem />
    <TagsItem />
  </NavigationMenu>
);

export default ProfessionalDevelopmentMenu;
