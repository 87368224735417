/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import ProfessionalLearningIcon from 'icons/ProfessionalLearningIcon';

const messages = defineMessages({
  title: {id: 'navigation.professional-learning'},
});

const ProfessionalLearningItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={ProfessionalLearningIcon}
    route="midas/staff-member/professional-learning"
    title={messages.title}
  />
);

export default ProfessionalLearningItem;
