/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const rims = {
  '': '/rims',
  detail: '/rims/detail/:materialID',
  'view-review-collection': '/rims/view-review-collection/:collectionID',
};

export default rims;
