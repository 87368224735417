/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import LicensesIcon from 'icons/LicensesIcon';

const messages = defineMessages({
  title: {id: 'navigation.licenses'},
});

const LicensesItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={LicensesIcon}
    route="midas/staff-member/licenses"
    title={messages.title}
  />
);

export default LicensesItem;
