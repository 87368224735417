/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import EnrollmentIcon from 'icons/EnrollmentIcon';

const messages = defineMessages({
  title: {id: 'navigation.enrollment'},
});

const EnrollmentItem = () => (
  <StudentRouteLinkItem
    iconBefore={EnrollmentIcon}
    route="midas/student/enrollment"
    title={messages.title}
  />
);

export default EnrollmentItem;
