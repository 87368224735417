/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {ProgressBar} from 'react-bootstrap';
import {RouteLink} from 'app/routing';
import {useFormatMessage, useMidasJsonApi} from 'app/hooks';
import {Markdown} from 'components/layout';
import SetNewPasswordForm from './SetNewPasswordForm';

const messages = defineMessages({
  expiredMessage: {
    id: 'text.password-reset-expired-message',
    description:
      'Message block for the password reset page when a reset token is expired',
    defaultMessage:
      'That reset password link has already been used or it has expired. If necessary, you can submit a new request below.',
  },
  successMessage: {
    id: 'text.password-reset-success-message',
    description:
      'Message block for the password reset page when password was successfully changed',
    defaultMessage:
      'Your new password has been set!\n\nUse the link below to return to the login page.',
  },
  instructions: {
    id: 'text.set-new-password-instructions',
    description: 'Instructions block for the set new password form',
    defaultMessage:
      'To set a new password for your account, please enter and confirm it in the fields below.',
  },
});

const SetNewPassword = ({token, setTokenValidationError}) => {
  const [passwordReset, setPasswordReset] = useState(false);

  const expiredMessage = useFormatMessage(messages.expiredMessage);
  const successMessage = useFormatMessage(messages.successMessage);
  const instructions = useFormatMessage(messages.instructions);

  const [response, isLoading] = useMidasJsonApi({
    url: `/midas/rest/reset-password/${token}`,
  });

  if (isLoading || response.status === 0) {
    return (
      <div className="my-5 text-center">
        <p>
          <FormattedMessage
            id="progress.validating-request"
            description="Progress message indicating a background task is running to validate a user request"
            defaultMessage="Validating request…"
          />
        </p>
        <ProgressBar animated now={100} />
      </div>
    );
  }

  const onTokenValidationError = () => {
    /* This is the only error message we ever display here: do NOT leak useful
     * information to attackers such as the validity of the token, whether it
     * was actually used vs. expired, etc. Legitimate users who are unable to
     * successfully use the self-service tools can always have their password
     * reset manually by their site administrator.
     */
    setTokenValidationError(expiredMessage);
  };

  if (response.status !== 200) {
    onTokenValidationError();
    return null;
  }

  return (
    <>
      {passwordReset ? (
        <Markdown>{successMessage}</Markdown>
      ) : (
        <>
          <p>Hello {response.json.firstName}!</p>
          <Markdown>{instructions}</Markdown>
          <SetNewPasswordForm
            token={token}
            onPasswordReset={() => {
              setPasswordReset(true);
            }}
            onTokenValidationError={onTokenValidationError}
          />
        </>
      )}

      <p className="mb-0 text-center small">
        <RouteLink route="login">
          <FormattedMessage
            id="link.back-to-login"
            description="Link text back to the login page"
            defaultMessage="← Back to login"
          />
        </RouteLink>
      </p>
    </>
  );
};

export default SetNewPassword;
