/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import TransportationIcon from 'icons/TransportationIcon';
import BusDriversItem from './transportation/BusDriversItem';
import BusRoutesItem from './transportation/BusRoutes';
import BusStopsItem from './transportation/BusStops';
import ProvidersItem from './transportation/ProvidersItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.transportation'},
  sectionTitle: {id: 'navigation.settings.transportation-settings'},
});

const TransportationMenu = () => (
  <NavigationMenu
    iconBefore={TransportationIcon}
    route="midas/settings/transportation"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <BusDriversItem />
    <ProvidersItem />
    <BusRoutesItem />
    <BusStopsItem />
  </NavigationMenu>
);

export default TransportationMenu;
