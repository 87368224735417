/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faToolbox} from '@fortawesome/pro-duotone-svg-icons';

const DataToolsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faToolbox} {...props} />
);

export default DataToolsIcon;
