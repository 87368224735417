/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import EditIcon from 'icons/EditIcon';

const EditButton = ({text, ...props}, ref) => (
  <Button ref={ref} variant="primary" type="button" {...props}>
    <EditIcon className="mr-2" />
    <FormattedMessage {...text} />
  </Button>
);
EditButton.displayName = 'EditButton';

export default forwardRef(EditButton);
