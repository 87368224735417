/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPlus} from '@fortawesome/pro-solid-svg-icons';

const AddIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faPlus} {...props} />
);

export default AddIcon;
