/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {RouteLink} from 'app/routing';
import {Button} from 'react-bootstrap';
import classnames from 'classnames';

const IconActionButtonLink = (
  {
    icon: Icon,
    text,
    route,
    params = {},
    compact = false,
    variant = 'outline-primary',
    type = 'button',
    size = 'sm',
    className = '',
    ...props
  },
  ref
) => {
  const buttonClassNames = classnames('d-flex align-items-center', className, {
    'flex-column': !compact,
  });
  const iconClassNames = classnames({
    'mr-2': compact,
    'my-2': !compact,
  });
  return (
    <Button
      ref={ref}
      as={RouteLink}
      route={route}
      params={params}
      type={type}
      variant={variant}
      size={size}
      className={buttonClassNames}
      {...props}
    >
      <Icon className={iconClassNames} size={compact ? undefined : '2x'} />
      <FormattedMessage {...text} />
    </Button>
  );
};
IconActionButtonLink.displayName = 'IconActionButtonLink';

export default forwardRef(IconActionButtonLink);
