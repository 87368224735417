/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useState} from 'react';

const useMatchMedia = (query) => {
  const [matches, setMatches] = useState(() => {
    return window.matchMedia(query).matches;
  });

  useEffect(() => {
    const handleChange = (event) => {
      setMatches(event.matches);
    };

    const mediaQueryList = window.matchMedia(query);

    if (mediaQueryList.addEventListener) {
      mediaQueryList.addEventListener('change', handleChange);
    } else {
      // addListener() is deprecated but required for Safari<14, IE+Edge<16
      mediaQueryList.addListener(handleChange);
    }

    return () => {
      if (mediaQueryList.removeEventListener) {
        mediaQueryList.removeEventListener('change', handleChange);
      } else {
        // removeListener() is deprecated but required for Safari<14, IE+Edge<16
        mediaQueryList.removeListener(handleChange);
      }
    };
  }, [query]);

  return matches;
};

export default useMatchMedia;
