/**
 * Returns a string containing the staff member's first and last names separated
 * by a single space.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import useUser from './useUser';

const useStaffName = (userID) => {
  const user = useUser(userID);

  // TODO: If the authenticated user is a "student", format staff names using formal titles

  return user ? `${user.firstName} ${user.lastName}` : '';
};

export default useStaffName;
