/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import IncidentManagementIcon from 'icons/IncidentManagementIcon';

const messages = defineMessages({
  title: {id: 'navigation.behavior.incident-management-bico'},
});

const IncidentManagementBicoItem = () => (
  <RouteLinkItem
    iconBefore={IncidentManagementIcon}
    route="midas/students/behavior/incident-management-bico"
    title={messages.title}
  />
);

export default IncidentManagementBicoItem;
