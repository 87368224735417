/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

/**
 * User can view any classroom in the school, not just their own.
 */
export const CAN_VIEW_ANY_IN_SCHOOL = 'classroomCanViewAnyInSchool';

/**
 * User can view any classroom in any school in the district, not just their own.
 */
export const CAN_VIEW_ANY_IN_DISTRICT = 'classroomCanViewAnyInDistrict';
