/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const sections = {
  '': '/midas/students/sections/:courseID?',
  'add-section': '/midas/students/sections/:courseID/add-section',
  'edit-section': '/midas/students/sections/:courseID/edit-section/:sectionID',
  'view-attendees':
    '/midas/students/sections/:courseID/view-attendees/:sectionID/:yearID',
  'enroll-students':
    '/midas/students/sections/:courseID/enroll-students/:sectionID',
  'lock-or-unlock-enrollment':
    '/midas/students/sections/:courseID/lock-or-unlock-enrollment/:sectionID',
  'edit-attendee':
    '/midas/students/sections/:courseID/edit-attendee/:attendeeID',
};

export default sections;
