/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import BillingIcon from 'icons/BillingIcon';
import ContractsItem from './billing/ContractsItem';
import InvoicesItem from './billing/InvoicesItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.billing'},
  sectionTitle: {id: 'navigation.settings.billing-settings'},
});

const BillingMenu = () => (
  <NavigationMenu
    iconBefore={BillingIcon}
    route="midas/settings/billing"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <InvoicesItem />
    <ContractsItem />
  </NavigationMenu>
);

export default BillingMenu;
