/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext} from 'react';
import {UserContext} from 'app/UserContext';
import {useRouteMatch} from 'react-router-dom';
import {userHasPermission} from 'app/permissions';

const useUserHasPermission = (permission) => {
  const [context] = useContext(UserContext);
  const match = useRouteMatch();

  return userHasPermission(context, permission, match.params);
};

export default useUserHasPermission;
