/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useUserContext} from 'app/UserContext';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const contextRouteParams = {
  staffID: 'classroom.staffID',
  sectionID: 'classroom.sectionID',
};

const ClassroomRouteLinkItem = (props) => {
  const [{classroom}] = useUserContext();
  if (!classroom.staffID) {
    return null;
  }
  return <RouteLinkItem contextRouteParams={contextRouteParams} {...props} />;
};

export default ClassroomRouteLinkItem;
