/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.financial.authorize-net-credentials'},
});

const AuthorizeNetCredentialsItem = () => (
  <RouteLinkItem
    route="midas/settings/financial/authorize-net-credentials"
    title={messages.title}
  />
);

export default AuthorizeNetCredentialsItem;
