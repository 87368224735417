/**
 * Hook that returns a boolean indicating whether or not the requested
 * feature is enabled for the user's current school.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext} from 'react';
import {UserContext} from 'app/UserContext';

const useFeature = (featureName) => {
  const [context] = useContext(UserContext);

  return context.features.indexOf(featureName) !== -1;
};

export default useFeature;
