/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import BehaviorIcon from 'icons/BehaviorIcon';
import ConcernsItem from './behavior/ConcernItem';
import ConsequencesItem from './behavior/ConsequencesItem';
import IncidentsItem from './behavior/IncidentsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.behavior'},
  sectionTitle: {id: 'navigation.settings.behavior-settings'},
});

const BehaviorMenu = () => (
  <NavigationMenu
    iconBefore={BehaviorIcon}
    route="midas/settings/behavior"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ConcernsItem />
    <ConsequencesItem />
    <IncidentsItem />
  </NavigationMenu>
);

export default BehaviorMenu;
