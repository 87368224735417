/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import BuildingIcon from 'icons/BuildingIcon';
import BuildingsItem from './facilities/BuildingsItem';
import EquipmentCheckoutItem from './facilities/EquipmentCheckoutItem';
import EquipmentItem from './facilities/EquipmentItem';
import LockersItem from './facilities/LockersItem';
import RoomsItem from './facilities/RoomsItem';
import TicketGroupsItem from './facilities/TicketGroupsItem';
import TicketTypesItem from './facilities/TicketTypesItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.facilities'},
  sectionTitle: {id: 'navigation.settings.facilities-settings'},
});

const FacilitiesMenu = () => (
  <NavigationMenu
    iconBefore={BuildingIcon}
    route="midas/settings/facilities"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <BuildingsItem />
    <LockersItem />
    <RoomsItem />
    <TicketTypesItem />
    <EquipmentItem />
    <EquipmentCheckoutItem />
    <TicketGroupsItem />
  </NavigationMenu>
);

export default FacilitiesMenu;
