/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import TestScoreIcon from 'icons/TestScoreIcon';

const messages = defineMessages({
  title: {id: 'navigation.test-scores'},
});

const TestScoresItem = () => (
  <StudentRouteLinkItem
    iconBefore={TestScoreIcon}
    route="midas/student/test-scores"
    title={messages.title}
  />
);

export default TestScoresItem;
