/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import EducationLeaderIcon from 'icons/EducationLeaderIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.education-leaders'},
});

const EducationLeadersItem = () => (
  <EventRouteLinkItem
    iconBefore={EducationLeaderIcon}
    route="midas/event/education-leaders"
    title={messages.title}
  />
);

export default EducationLeadersItem;
