/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import DeleteButton from './DeleteButton';

const messages = defineMessages({
  revert: {id: 'button.revert'},
  reverting: {id: 'button.reverting'},
});

const RevertButton = (
  {
    submitMessage = messages.revert,
    submittingMessage = messages.reverting,
    ...props
  },
  ref
) => {
  return (
    <DeleteButton
      ref={ref}
      submitMessage={submitMessage}
      submittingMessage={submittingMessage}
      {...props}
    />
  );
};
RevertButton.displayName = 'RevertButton';

export default forwardRef(RevertButton);
