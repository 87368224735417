/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const behavior = {
  '': '/midas/students/behavior',
  'submitted-concerns': {
    '': '/midas/students/behavior/submitted-concerns',
    'edit-concern':
      '/midas/students/behavior/submitted-concerns/edit-concern/:concernID',
  },
  'submit-concern': {
    '': '/midas/students/behavior/submit-concern',
    'add-concern': '/midas/students/behavior/submit-concern/add-concern',
    'edit-concern':
      '/midas/students/behavior/submit-concern/edit-concern/:concernID',
  },
  'incident-management-bico': {
    '': '/midas/students/behavior/incident-management-bico',
    'submit-new-incident':
      '/midas/students/behavior/incident-management-bico/submit-new-incident',
    'edit-incident':
      '/midas/students/behavior/incident-management-bico/edit-incident/:incidentID',
  },
  'incident-management': {
    '': '/midas/students/behavior/incident-management',
    'submit-new-incident':
      '/midas/students/behavior/incident-management/submit-new-incident',
    'edit-incident':
      '/midas/students/behavior/incident-management/edit-incident/:incidentID',
    'view-incident-details':
      '/midas/students/behavior/incident-management/view-incident-details/:incidentID',
    'edit-actor-details':
      '/midas/students/behavior/incident-management/edit-actor-details/:userID/:isPrimary/:isSecondary/:isWitness/:isVictim/:incidentID',
    'show-image':
      '/midas/students/behavior/incident-management/show-image/:imageURL',
  },
};

export default behavior;
