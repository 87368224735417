/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const forms = {
  '': '/midas/settings/professional-development/forms',
  'add-form': '/midas/settings/professional-development/forms/add-form',
  'add-question':
    '/midas/settings/professional-development/forms/add-question/:formID',
  'add-section':
    '/midas/settings/professional-development/forms/add-section/:formID',
  'edit-form':
    '/midas/settings/professional-development/forms/edit-form/:formID',
  'edit-question':
    '/midas/settings/professional-development/forms/edit-question/:formID/:questionNumber',
  'edit-section':
    '/midas/settings/professional-development/forms/edit-section/:formID/:sectionNumber',
  preview: '/midas/settings/professional-development/forms/preview/:formID',
};

export default forms;
