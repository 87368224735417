/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCircleNotch} from '@fortawesome/pro-solid-svg-icons';

const LoadingIcon = ({icon, spin, inverse, ...props}) => (
  <FontAwesomeIcon icon={faCircleNotch} spin {...props} />
);

export default LoadingIcon;
