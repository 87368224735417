/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useRef} from 'react';
import {useFormikContext} from 'formik';

const FormValuesChangeHandler = ({onChange}) => {
  const {values} = useFormikContext();
  const isInitialValues = useRef(true);

  useEffect(() => {
    if (isInitialValues.current) {
      isInitialValues.current = false;
      return; // don't fire callback with initial values
    }
    onChange(values);
  }, [onChange, values]);

  return null;
};

export default FormValuesChangeHandler;
