/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useHistory} from 'react-router-dom';
import {urlFromRoute} from 'app/routing';
import NavigationMenu from './NavigationMenu';
import EventsIcon from 'icons/EventsIcon';
import EventsItem from './events/EventsItem';

const messages = defineMessages({
  title: {id: 'navigation.events'},
});

const EventsMenu = () => {
  // TODO: This click handler is temporary, and is only here because this menu
  //  is currently incomplete, containing only a single item. Instead of forcing
  //  users to click twice to see the event listing (which is the only possible
  //  option), we'll immediately redirect to the listing route. Once additional
  //  items are added to this menu, and users can choose their destination from
  //  multiple possibilities, remove this handler.
  const history = useHistory();
  const handleClick = () => {
    history.push(urlFromRoute('midas/events/events'));
  };

  return (
    <NavigationMenu
      iconBefore={EventsIcon}
      route="midas/events"
      title={messages.title}
      onClick={handleClick}
    >
      <EventsItem />
    </NavigationMenu>
  );
};

export default EventsMenu;
