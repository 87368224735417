/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {Link, matchPath, useLocation} from 'react-router-dom';
import {get} from 'lodash';
import {useRouteIsAuthorized} from 'app/hooks';
import {useUserContext} from 'app/UserContext';
import {useNavigationContext} from 'app/NavigationContext';
import {pathFromRoute, urlFromRoute} from 'app/routing';
import {CustomItem} from '@atlaskit/side-navigation';
import IconBefore from 'components/navigation/side-navigation/icon/IconBefore';
import MessageItem from 'components/navigation/side-navigation/item/MessageItem';

const cssFn = ({isSelected}) => {
  return isSelected
    ? {
        '&:visited': {
          color: '#0052cc',
        },
      }
    : {};
};

const RouteLinkItem = ({
  iconBefore,
  route,
  routeParams = {},
  contextRouteParams = {},
  title,
  children,
  ...props
}) => {
  const {dismissMobileSidebar} = useNavigationContext();

  const [userContext] = useUserContext();
  const {pathname} = useLocation();

  const routeIsAuthorized = useRouteIsAuthorized();

  if (!title?.id) {
    throw new Error('RouteLinkItem missing message identifier in title prop');
  }

  if (!routeIsAuthorized(route)) {
    return null;
  }

  const routeMatch = matchPath(pathname, {
    path: pathFromRoute(route),
    strict: true,
    exact: false,
  });

  if (routeMatch) {
    routeParams = {
      ...routeMatch.params,
      ...routeParams,
    };
  }

  for (let [param, contextKey] of Object.entries(contextRouteParams)) {
    routeParams[param] = get(userContext, contextKey);
  }

  let to;
  try {
    to = urlFromRoute(route, routeParams);
  } catch (error) {
    return (
      <MessageItem>
        Unable to render RouteLinkItem for route {route}: {error.message}
      </MessageItem>
    );
  }

  return (
    <CustomItem
      component={Link}
      to={to}
      iconBefore={iconBefore && <IconBefore Icon={iconBefore} />}
      isSelected={routeMatch !== null}
      onClick={dismissMobileSidebar}
      cssFn={cssFn}
      {...props}
    >
      <FormattedMessage {...title} />
      {children}
    </CustomItem>
  );
};

export default RouteLinkItem;
