/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {createContext, useContext, useMemo, useReducer} from 'react';
import {callMidasJsonApi} from 'app/api';

const USER_CACHE_TTL = 120;
const MEMBERSHIP_CACHE_TTL = 120;
const IMAGE_CACHE_TTL = 600;

const initialState = {
  users: new Map(),
  userExpires: new Map(),
  userInFlight: new Set(),
  memberships: new Map(),
  membershipExpires: new Map(),
  membershipInFlight: new Set(),
  images: new Map(),
  imageExpires: new Map(),
  imageInFlight: new Set(),
};

export const REQUEST_USER = 'REQUEST_USER';
const START_USER_REQUEST = 'START_USER_REQUEST';
const CAPTURE_USER = 'CAPTURE_USER';
export const REQUEST_MEMBERSHIP = 'REQUEST_MEMBERSHIP';
const START_MEMBERSHIP_REQUEST = 'START_MEMBERSHIP_REQUEST';
const CAPTURE_MEMBERSHIP = 'CAPTURE_MEMBERSHIP';
const CAPTURE_NO_MEMBERSHIP = 'CAPTURE_NO_MEMBERSHIP';
export const REQUEST_IMAGE = 'REQUEST_IMAGE';
const START_IMAGE_REQUEST = 'START_IMAGE_REQUEST';
const CAPTURE_IMAGE = 'CAPTURE_IMAGE';

const makePlaceholderUser = (userID) => {
  return {
    id: userID,
    firstName: 'User',
    middleName: '',
    lastName: `${userID}`,
    birthDate: '',
    gender: '',
    primaryContactPhone: '',
  };
};

const middleware = (state, dispatch) => {
  return (action) => {
    switch (action.type) {
      case REQUEST_USER: {
        const userID = action.payload;
        if (!userID || state.userInFlight.has(userID)) {
          return;
        }
        if (
          state.users.has(userID) &&
          state.userExpires.get(userID) > Date.now()
        ) {
          return;
        }
        dispatch({
          type: START_USER_REQUEST,
          payload: userID,
        });
        callMidasJsonApi(`/midas/rest/user/${userID}`)
          .then(({json, status}) => {
            if (status === 200) {
              dispatch({
                type: CAPTURE_USER,
                payload: json.user,
              });
            }
          })
          .catch((error) => {
            console.error(`${error} error while attempting to fetch user`);
          });
        break;
      }

      case REQUEST_MEMBERSHIP: {
        const userID = action.payload;
        if (!userID || state.membershipInFlight.has(userID)) {
          return;
        }
        if (
          state.memberships.has(userID) &&
          state.membershipExpires.get(userID) > Date.now()
        ) {
          return;
        }
        dispatch({
          type: START_MEMBERSHIP_REQUEST,
          payload: userID,
        });
        callMidasJsonApi(`/midas/rest/user-membership/${userID}`)
          .then(({json, status}) => {
            if (status === 200) {
              dispatch({
                type: CAPTURE_MEMBERSHIP,
                payload: json.userMembership,
              });
            } else if (status === 404) {
              dispatch({
                type: CAPTURE_NO_MEMBERSHIP,
                payload: userID,
              });
            }
          })
          .catch((error) => {
            console.error(
              `${error} error while attempting to fetch user membership`
            );
          });
        break;
      }

      case REQUEST_IMAGE: {
        const userID = action.payload;
        if (!userID || state.imageInFlight.has(userID)) {
          return;
        }
        if (
          state.images.has(userID) &&
          state.imageExpires.get(userID) > Date.now()
        ) {
          return;
        }
        dispatch({
          type: START_IMAGE_REQUEST,
          payload: userID,
        });
        callMidasJsonApi(`/midas/rest/user-image/${userID}`)
          .then(({json, status}) => {
            if (status === 200) {
              dispatch({
                type: CAPTURE_IMAGE,
                payload: {
                  userID,
                  images: json.images,
                },
              });
            }
          })
          .catch((error) => {
            console.error(
              `${error} error while attempting to fetch user image`
            );
          });
        break;
      }

      default:
        dispatch(action);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case START_USER_REQUEST: {
      const userID = action.payload;
      state.userInFlight.add(userID);
      return {
        ...state,
        users: new Map(state.users).set(userID, makePlaceholderUser(userID)),
      };
    }

    case CAPTURE_USER: {
      const user = action.payload;
      state.userInFlight.delete(user.id);
      return {
        ...state,
        users: new Map(state.users).set(user.id, user),
        userExpires: state.userExpires.set(
          user.id,
          Date.now() + USER_CACHE_TTL * 1000
        ),
      };
    }

    case START_MEMBERSHIP_REQUEST: {
      const userID = action.payload;
      state.membershipInFlight.add(userID);
      return state;
    }

    case CAPTURE_MEMBERSHIP: {
      const userMembership = action.payload;
      state.membershipInFlight.delete(userMembership.userID);
      return {
        ...state,
        memberships: new Map(state.memberships).set(
          userMembership.userID,
          userMembership
        ),
        membershipExpires: state.membershipExpires.set(
          userMembership.userID,
          Date.now() + MEMBERSHIP_CACHE_TTL * 1000
        ),
      };
    }

    case CAPTURE_NO_MEMBERSHIP: {
      const userID = action.payload;
      state.membershipInFlight.delete(userID);
      return {
        ...state,
        memberships: new Map(state.memberships).set(userID, false),
        membershipExpires: state.membershipExpires.set(
          userID,
          Date.now() + MEMBERSHIP_CACHE_TTL * 1000
        ),
      };
    }

    case START_IMAGE_REQUEST: {
      const userID = action.payload;
      state.imageInFlight.add(userID);
      return state;
    }

    case CAPTURE_IMAGE: {
      const {userID, images} = action.payload;
      state.imageInFlight.delete(userID);
      return {
        ...state,
        images: new Map(state.images).set(userID, images),
        imageExpires: state.imageExpires.set(
          userID,
          Date.now() + IMAGE_CACHE_TTL * 1000
        ),
      };
    }

    default:
      return state;
  }
};

export const UserCacheContext = createContext(undefined);

export const UserCacheContextProvider = ({children}) => {
  const [state, reducerDispatch] = useReducer(reducer, initialState);
  const dispatch = useMemo(() => middleware(state, reducerDispatch), [
    state,
    reducerDispatch,
  ]);

  return (
    <UserCacheContext.Provider
      value={{
        users: state.users,
        memberships: state.memberships,
        images: state.images,
        dispatch,
      }}
    >
      {children}
    </UserCacheContext.Provider>
  );
};

export const useUserCacheContext = () => {
  const context = useContext(UserCacheContext);
  if (context === undefined) {
    throw new Error(
      'userCacheContext must be used within a UserCacheContextProvider'
    );
  }
  return context;
};
