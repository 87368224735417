/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.transportation.bus-drivers'},
});

const BusDriversItem = () => (
  <RouteLinkItem
    route="midas/settings/transportation/bus-drivers"
    title={messages.title}
  />
);

export default BusDriversItem;
