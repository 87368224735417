/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const courses = {
  '': '/professional-development/courses',
  course: '/professional-development/courses/course/:courseID',
};

export default courses;
