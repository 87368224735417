/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {Suspense, lazy, useEffect} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {CSSTransition} from 'react-transition-group';
import classnames from 'classnames';
import {useUserContext} from 'app/UserContext';
import {
  SHOW_MOBILE_SIDEBAR_NAV,
  HIDE_MOBILE_SIDEBAR_NAV,
  HIDE_HELP_PANEL,
  useNavigationContext,
  COURSE_SURVEY_NOTIFICATION_COUNT,
} from 'app/NavigationContext';
import {useMaxGridBreakpoint, useMidasJsonApi} from 'app/hooks';
import {
  PageLayout,
  TopNavigation,
  Content,
  LeftPanel,
  LeftSidebar,
  Main,
} from '@atlaskit/page-layout';
import {Button} from 'react-bootstrap';
import SessionMonitor from 'components/SessionMonitor';
import FullPageLoading from 'components/FullPageLoading';
import MobileNavigation from 'components/navigation/mobile-navigation/MobileNavigation';
import ProductNavigation from 'components/navigation/product-navigation/ProductNavigation';
import SideNavigation from 'components/navigation/side-navigation/SideNavigation';
import HelpDocsModal from './HelpDocsModal';
import CloseIcon from 'icons/CloseIcon';
import './MidasLayout.css';

const Midas = lazy(() => import('../views/Midas'));

const messages = defineMessages({
  hideNavigation: {id: 'navigation.hide-navigation'},
});

const MidasLayout = () => {
  const intl = useIntl();
  const [{allowedRoutes}] = useUserContext();
  const {
    mobileSidebarNavVisible,
    helpPanelVisible,
    helpAnchor,
    dispatch,
  } = useNavigationContext();

  const mobileNavVisible = useMaxGridBreakpoint('sm');

  const [response, , , request] = useMidasJsonApi({
    url: '/midas/rest/pd-course-surveys',
    expectedStatus: [200],
    immediate: false,
  });

  useEffect(() => {
    if (!mobileNavVisible) {
      dispatch({type: HIDE_MOBILE_SIDEBAR_NAV});
    }
    if (allowedRoutes.includes('midas/my/pd-course-surveys')) {
      request();
    }
  }, [allowedRoutes, mobileNavVisible, request, dispatch]);

  useEffect(() => {
    if (response.status === 200) {
      dispatch({
        type: COURSE_SURVEY_NOTIFICATION_COUNT,
        payload: response.json.surveys.length,
      });
    }
  }, [dispatch, response]);

  const handleMobileNavToggleClick = () => {
    if (mobileSidebarNavVisible) {
      dispatch({type: HIDE_MOBILE_SIDEBAR_NAV});
    } else {
      dispatch({type: SHOW_MOBILE_SIDEBAR_NAV});
    }
  };

  const handleHideHelpPanel = () => {
    dispatch({type: HIDE_HELP_PANEL});
  };

  const wrapperClassNames = classnames({
    MobileNavVisible: mobileNavVisible,
    MobileSidebarNavVisible: mobileSidebarNavVisible,
  });

  return (
    <div className={wrapperClassNames}>
      {mobileNavVisible && (
        <CSSTransition
          in={mobileSidebarNavVisible}
          timeout={200}
          classNames="MobileSidebarNav"
        >
          <div className="MobileSidebarNav">
            <ProductNavigation />
            <SideNavigation />
            <Button
              variant="outline-secondary"
              size="sm"
              onClick={handleMobileNavToggleClick}
              className="MobileSidebarNavToggle"
              title={intl.formatMessage(messages.hideNavigation)}
            >
              <CloseIcon />
            </Button>
          </div>
        </CSSTransition>
      )}
      <PageLayout>
        {mobileNavVisible && (
          <TopNavigation height={45}>
            <MobileNavigation onToggleClick={handleMobileNavToggleClick} />
          </TopNavigation>
        )}
        {!mobileNavVisible && (
          <LeftPanel width={64} isFixed>
            <ProductNavigation />
          </LeftPanel>
        )}
        <Content>
          {!mobileNavVisible && (
            <LeftSidebar>
              <SideNavigation />
            </LeftSidebar>
          )}
          <Main>
            <Suspense fallback={<FullPageLoading />}>
              <Midas />
            </Suspense>
          </Main>
          {helpPanelVisible && (
            <HelpDocsModal
              onHide={handleHideHelpPanel}
              routeAnchor={helpAnchor}
            />
          )}
        </Content>
      </PageLayout>
    </div>
  );
};

const LayoutLoadingWrapper = () => {
  const [{allowedRoutes}] = useUserContext();
  return (
    <SessionMonitor>
      {allowedRoutes.length < 1 ? (
        <FullPageLoading delayMs={0} />
      ) : (
        <MidasLayout />
      )}
    </SessionMonitor>
  );
};

export default LayoutLoadingWrapper;
