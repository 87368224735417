/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import MicrocredentialIcon from 'icons/MicrocredentialIcon';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.microcredentials'},
});

const MicrocredentialsItem = () => (
  <StudentRouteLinkItem
    iconBefore={MicrocredentialIcon}
    route="midas/student/microcredentials"
    title={messages.title}
  />
);

export default MicrocredentialsItem;
