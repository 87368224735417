/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useUserHasPermission} from 'app/hooks';
import {SITE_EDITOR} from 'app/permissions/cms';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import WebPageIcon from 'icons/WebPageIcon';

const messages = defineMessages({
  title: {id: 'navigation.settings.site-pages'},
});

const SitePagesItem = () => {
  const siteEditor = useUserHasPermission(SITE_EDITOR);
  if (!siteEditor) {
    return null;
  }
  return (
    <RouteLinkItem
      iconBefore={WebPageIcon}
      route="site-pages"
      title={messages.title}
    />
  );
};

export default SitePagesItem;
