/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const dataTools = {
  '': '/midas/data-tools',
  'combine-tool': '/midas/data-tools/combine-tool',
  'filter-tool': '/midas/data-tools/filter-tool',
  'flatten-tool': '/midas/data-tools/flatten-tool',
  'stack-tool': '/midas/data-tools/stack-tool',
};

export default dataTools;
