import {Col, Row} from 'react-bootstrap';
import {Markdown} from 'components/layout';
import {RouteLink} from 'app/routing';

const imageSize = {
  maxWidth: '100px',
  maxHeight: '100px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
};
const CurriculumPathwayInfo = ({pathway}) => {
  const pathwayFileURL = pathway.file_url
    ? pathway.file_url
    : '/images/missing-micro.png';

  return (
    <Row>
      <Col lg={2}>
        <img
          style={imageSize}
          src={pathwayFileURL}
          alt="Curriculum Icon"
          className="mb-4"
        />
      </Col>
      <Col>
        <div className="d-flex">
          <h6>
            <RouteLink route="pathways/detail" params={{pathwayID: pathway.id}}>
              {pathway.name}
            </RouteLink>
          </h6>
        </div>
        <p>
          <Markdown>{pathway.description}</Markdown>
        </p>
      </Col>
    </Row>
  );
};

export default CurriculumPathwayInfo;
