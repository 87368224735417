/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import BigNewIcon from 'icons/BigNewIcon';
import './BigNewActionButton.css';

const BigNewActionButton = ({text, ...props}, ref) => (
  <Button
    ref={ref}
    variant="outline-primary"
    type="button"
    className="BigNewActionButton font-weight-light"
    {...props}
  >
    <BigNewIcon />
    <FormattedMessage {...text} />
  </Button>
);
BigNewActionButton.displayName = 'BigNewActionButton';

export default forwardRef(BigNewActionButton);
