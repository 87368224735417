/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const guardian = {
  '': '/midas/guardian/:guardianID',
  'auto-call': {
    '': '/midas/guardian/:guardianID/auto-call',
    'add-number': '/midas/guardian/:guardianID/auto-call/add-number',
    'select-number': '/midas/guardian/:guardianID/auto-call/select-number',
  },
  calendar: '/midas/guardian/:guardianID/calendar',
  children: '/midas/guardian/:guardianID/children',
  'contact-information': {
    '': '/midas/guardian/:guardianID/contact-information',
    'add-contact-information':
      '/midas/guardian/:guardianID/contact-information/add-contact-information',
    'edit-contact':
      '/midas/guardian/:guardianID/contact-information/edit-contact/:contactID',
    'view-shared-contact':
      '/midas/guardian/:guardianID/contact-information/view-shared-contact/:contactID/:contactType/:sharedUsers',
  },
  demographics: {
    '': '/midas/guardian/:guardianID/demographics',
    'edit-demographics':
      '/midas/guardian/:guardianID/demographics/edit-demographics',
  },
  family: {
    '': '/midas/guardian/:guardianID/family',
    'add-guardian': '/midas/guardian/:guardianID/family/add-guardian',
    'edit-guardian':
      '/midas/guardian/:guardianID/family/edit-guardian/:guardianID',
  },
  'language-citizenship': {
    '': '/midas/guardian/:guardianID/language-citizenship',
    'edit-language-citizenship':
      '/midas/guardian/:guardianID/language-citizenship/edit-language-citizenship',
  },
  'meal-account-payment': {
    '': '/midas/guardian/:guardianID/meal-account-payment',
  },
  photo: {
    '': '/midas/guardian/:guardianID/photo',
    'edit-photo': '/midas/guardian/:guardianID/photo/edit-photo/:yearID',
  },
  'school-membership': {
    '': '/midas/guardian/:guardianID/school-membership',
    'edit-school-membership':
      '/midas/guardian/:guardianID/school-membership/edit-school-membership/:membershipID',
  },
};

export default guardian;
