/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {wrapNonLocalizedMessage} from 'app/intl';
import {PUSH_MENU, useNavigationContext} from 'app/NavigationContext';
import {useUserContext} from 'app/UserContext';
import {useUser} from 'app/hooks';
import ProductNavigationButton from './ProductNavigationButton';
import UserAvatar from 'components/UserAvatar';

const AuthenticatedUserItem = () => {
  const {dispatch} = useNavigationContext();
  const [{userID}] = useUserContext();
  const user = useUser(userID);

  const title = wrapNonLocalizedMessage(
    user ? `${user.firstName} ${user.lastName}` : ''
  );

  const handleClick = () => {
    dispatch({
      type: PUSH_MENU,
      payload: 'authenticated-user',
    });
  };

  const UserAvatarIcon = () => (
    <UserAvatar userID={userID} borderColor="#8db571" size="large" />
  );

  return (
    <ProductNavigationButton
      onClick={handleClick}
      title={title}
      Icon={UserAvatarIcon}
    />
  );
};

export default AuthenticatedUserItem;
