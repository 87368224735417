/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const dataExplorer = {
  '': '/midas/data-explorer',
  create_datasource:
    '/midas/data-explorer/create_datasource/:method?/:key?/:datasource_name?',
  create_datasource_browse_s3:
    '/midas/data-explorer/create_datasource_browse_s3/:path?',
  create_datasource_mapping: '/midas/data-explorer/create_datasource_mapping',
  create_datasource_entities: '/midas/data-explorer/create_datasource_entities',
  create_datasource_save: '/midas/data-explorer/create_datasource_save',
  datasource_details: '/midas/data-explorer/datasource_details/:datasource_id',
  launch: '/midas/data-explorer/launch/:datasource_id',
  refresh_data: '/midas/data-explorer/refresh_data/:datasource_id',
  refresh_upload: '/midas/data-explorer/refresh_upload',
  refresh_error:
    '/midas/data-explorer/refresh_error/:datasource_id/:error_message',
  edit_column: '/midas/data-explorer/edit_column/:datasource_id/:column_params',
  save_column: '/midas/data-explorer/save_column',
  edit_related_entities:
    '/midas/data-explorer/edit_related_entities/:datasource_id',
  save_related_entities: '/midas/data-explorer/save_related_entities',
  edit_individual_sharing:
    '/midas/data-explorer/edit_individual_sharing/:datasource_id',
  save_individual_sharing: '/midas/data-explorer/save_individual_sharing',
  edit_permission_group_sharing:
    '/midas/data-explorer/edit_permission_group_sharing/:dataousrce_id',
  save_permission_group_sharing:
    '/midas/data-explorer/save_permission_group_sharing',
  delete_datasource: '/midas/data-explorer/delete_datasource/:datasource_id',
  delete_datasource_confirm: '/midas/data-explorer/delete_datasource_confirm',
};

export default dataExplorer;
