/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSyringe} from '@fortawesome/pro-duotone-svg-icons';

const ImmunizationIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faSyringe} {...props} />
);

export default ImmunizationIcon;
