/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import LeaveRequestsIcon from 'icons/LeaveRequestsIcon';
import LeaveRequestsItem from './leave-requests/LeaveRequestsItem';
import RequestAllowancesItem from './leave-requests/RequestAllowancesItem';
import RequestReasonsItem from './leave-requests/RequestReasonsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.leave-requests'},
  sectionTitle: {id: 'navigation.settings.leave-requests-settings'},
});

const LeaveRequestsMenu = () => (
  <NavigationMenu
    iconBefore={LeaveRequestsIcon}
    route="midas/settings/leave-requests"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <LeaveRequestsItem />
    <RequestReasonsItem />
    <RequestAllowancesItem />
  </NavigationMenu>
);

export default LeaveRequestsMenu;
