/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

/**
 * Wraps the non-localizable message, which is usually generated server-side,
 * in a FormatJS message identifier object so it can be used with components
 * and APIs that expect localizable strings.
 *
 * @param {string} message
 * @returns {{id: string, values: {message: *}}}
 */
export const wrapNonLocalizedMessage = (message) => {
  return {id: 'server-message', values: {message}};
};

/**
 * Like {@link wrapNonLocalizedMessage} but will return null if there is no
 * message.
 *
 * @param {string|null|undefined} message
 * @returns {{id: string, values: {message: *}}|null}
 */
export const wrapNonLocalizedMessageUnlessEmpty = (message) => {
  if (!message || message.length < 1) {
    return null;
  }
  return {id: 'server-message', values: {message}};
};
