/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {Formik, Form as FormikForm} from 'formik';
import {setFormError} from 'components/form';

const Form = (
  {
    onReset = null,
    onSubmit = null,
    preventEnterSubmit = false,
    children,
    ...props
  },
  ref
) => {
  const doReset = (values, formikProps) => {
    setFormError(null, formikProps);
    if (onReset) {
      onReset(values, formikProps);
    }
  };

  const doSubmit = (values, formikProps) => {
    setFormError(null, formikProps);
    if (onSubmit) {
      onSubmit(values, formikProps);
    }
  };

  return (
    <Formik onReset={doReset} onSubmit={doSubmit} {...props}>
      <FormikForm
        onKeyDown={(e) => {
          if (preventEnterSubmit) {
            if (e.code === 'Enter' || e.code === 'NumpadEnter') {
              e.preventDefault();
            }
          }
        }}
        ref={ref}
        noValidate
      >
        {children}
      </FormikForm>
    </Formik>
  );
};
Form.displayName = 'Form';

export default forwardRef(Form);
