/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import SelfRegistrationIcon from 'icons/SelfRegistrationIcon';
import ManageInvitationsItem from 'components/navigation/side-navigation/menu/self-registration/ManageInvitationsItem';
import SelfRegistrationsItem from 'components/navigation/side-navigation/menu/self-registration/SelfRegistrationsItem';
import {useHistory} from 'react-router-dom';
import {urlFromRoute} from 'app/routing';

const messages = defineMessages({
  title: {
    id: 'navigation.self-registration',
    defaultMessage: 'Self Registration',
  },
});

const SelfRegistrationMenu = () => {
  const history = useHistory();
  const handleClick = () => {
    history.push(urlFromRoute('midas/self-registration/registrations'));
  };

  return (
    <NavigationMenu
      iconBefore={SelfRegistrationIcon}
      route="midas/self-registration"
      title={messages.title}
      onClick={handleClick}
    >
      <ManageInvitationsItem />
      <SelfRegistrationsItem />
    </NavigationMenu>
  );
};

export default SelfRegistrationMenu;
