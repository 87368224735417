/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const timesheets = {
  '': '/midas/settings/timesheets',
  'timesheets-review': {
    '': '/midas/settings/timesheets/timesheets-review',
    'update-timesheet-entry-status':
      '/midas/settings/timesheets/timesheets-review/update-timesheet-entry-status/:timesheetID',
    'bulk-update-timesheets':
      '/midas/settings/timesheets/timesheets-review/bulk-update-timesheets',
  },
  'timesheets-summary': {
    '': '/midas/settings/timesheets/timesheets-summary',
  },
  'timesheet-periods': {
    '': '/midas/settings/timesheets/timesheet-periods',
    'add-timesheet-period':
      '/midas/settings/timesheets/timesheet-periods/add-timesheet-period',
    'edit-timesheet-period':
      '/midas/settings/timesheets/timesheet-periods/edit-timesheet-period/:periodID',
  },
  'timesheet-programs': {
    '': '/midas/settings/timesheets/timesheet-programs',
    'add-timesheet-program':
      '/midas/settings/timesheets/timesheet-programs/add-timesheet-program',
    'edit-timesheet-program':
      '/midas/settings/timesheets/timesheet-programs/edit-timesheet-program/:programID',
  },
};

export default timesheets;
