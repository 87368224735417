/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';
import {REQUEST_USER, useUserCacheContext} from 'app/UserCacheContext';

const useUser = (userID) => {
  const {users, dispatch} = useUserCacheContext();

  const user = users.get(userID || 0);

  useEffect(() => {
    if (user === undefined) {
      dispatch({type: REQUEST_USER, payload: userID});
    }
  }, [user, userID, dispatch]);

  return user || null;
};

export default useUser;
