/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import login from './routes/login';
import microcredentials from './routes/microcredentials';
import midas from './routes/midas';
import professionalDevelopment from './routes/professional-development';
import rims from './routes/rims';
import selfRegister from 'app/routes/self-register';
import pathways from 'app/routes/pathways';
import curricula from 'app/routes/curricula';

const routes = {
  curricula,
  login,
  logout: '/logout',
  microcredentials,
  midas,
  pathways,
  'professional-development': professionalDevelopment,
  rims,
  'self-register': selfRegister,
  'site-pages': '/site.php?page_setup=home_page&edit=1',
};

export default routes;
