/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useFormikContext} from 'formik';
import {ErrorAlert} from 'components/alert';

const FormError = () => {
  const {status} = useFormikContext();
  if (!status || !status.error) {
    return null;
  }
  return <ErrorAlert text={status.error} />;
};

export default FormError;
