/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useContext} from 'react';
import {FormattedMessage} from 'react-intl';
import {UserContext} from 'app/UserContext';
import MidasApiError from 'app/MidasApiError';
import {Alert} from 'react-bootstrap';
import {PageContainer} from 'components/layout';

const ErrorDetails = ({error, errorInfo}) => {
  const [context] = useContext(UserContext);
  return (
    <PageContainer>
      <Alert variant="danger">
        <Alert.Heading>
          <FormattedMessage
            id="title.unexpected-error"
            description="Page title for the unexpected error page"
            defaultMessage="An Unexpected Error Occurred"
          />
        </Alert.Heading>
        {error.response && <p>{error.response.detail}</p>}
      </Alert>
      <p>
        <FormattedMessage
          id="error.please-refresh"
          description="Error message instructing the user to refresh their browser page and try again"
          defaultMessage="Please refresh this page in your browser and try again."
        />
      </p>
      <p>
        <FormattedMessage
          id="error.problem-persists-contact-support"
          description="Error message instructing the user to contact technical support if their problem persists"
          defaultMessage="If the problem persists, please contact support and provide them with the information below."
        />
      </p>
      <p className="small font-weight-light" style={{whiteSpace: 'pre-wrap'}}>
        {window.location.href}
        {'\n'}
        {error.name} — {error.message}
        {'\n'}
        Context: {context.userID}; {context.schoolID}
        {error instanceof MidasApiError && (
          <>
            {'\n'}
            Request: {error.method} {error.url}
            {'\n'}
            {error.body && JSON.stringify(error.body, null, 2)}
            {'\n'}
            Response: {error.response.status}
            {'\n'}
            {error.response.json &&
              JSON.stringify(error.response.json, null, 2)}
          </>
        )}
      </p>
      {errorInfo && process.env.NODE_ENV === 'development' && (
        <p className="small" style={{whiteSpace: 'pre-wrap'}}>
          {errorInfo.componentStack.trim()}
        </p>
      )}
    </PageContainer>
  );
};

export default ErrorDetails;
