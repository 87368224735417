/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const curricula = {
  '': '/curricula',
  detail: '/curricula/detail/:curriculumID',
};

export default curricula;
