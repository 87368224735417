/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import RightArrow from '@atlaskit/icon/glyph/arrow-right-circle';

export const NestingItemIcon = () => (
  <span data-right-arrow={true}>
    <RightArrow />
  </span>
);

// these styles and the exported function below should track with nestingItemStyle() in
//   node_modules/@atlaskit/side-navigation/dist/esm/components/NestingItem/styles.js
const enabledStyles = {
  '&:hover [data-right-arrow]': {
    display: 'inherit',
  },
  '&:active [data-right-arrow]': {
    display: 'inherit',
  },
  '&:focus [data-right-arrow]': {
    display: 'inherit',
  },
  '& [data-right-arrow]': {
    display: 'none',
  },
};
const disabledStyles = {
  '& [data-right-arrow]': {
    display: 'none',
  },
};
export const nestingItemStyle = (currentState) => {
  return currentState.isDisabled ? disabledStyles : enabledStyles;
};
