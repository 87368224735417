/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import DeleteButton from './DeleteButton';

const messages = defineMessages({
  remove: {id: 'button.remove'},
  removing: {id: 'button.removing'},
});

const RemoveButton = (
  {
    submitMessage = messages.remove,
    submittingMessage = messages.removing,
    ...props
  },
  ref
) => {
  return (
    <DeleteButton
      ref={ref}
      submitMessage={submitMessage}
      submittingMessage={submittingMessage}
      {...props}
    />
  );
};
RemoveButton.displayName = 'RemoveButton';

export default forwardRef(RemoveButton);
