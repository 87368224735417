/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages, useIntl, FormattedMessage} from 'react-intl';
import {useFormikContext} from 'formik';
import {ButtonToolbar, Modal} from 'react-bootstrap';
import RemoveButton from './button/RemoveButton';
import CancelButton from './button/CancelButton';

const messages = defineMessages({
  cancel: {id: 'button.cancel'},
});

const RemoveConfirmationModal = ({show, title, onCancel, children}) => {
  const intl = useIntl();

  const {isSubmitting, submitForm} = useFormikContext();

  return (
    <Modal
      show={show}
      backdrop={isSubmitting ? 'static' : true}
      keyboard={!isSubmitting}
      onHide={onCancel}
    >
      <Modal.Header
        closeButton={!isSubmitting}
        closeLabel={intl.formatMessage(messages.cancel)}
      >
        <Modal.Title>
          <FormattedMessage {...title} />
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>

      <Modal.Footer>
        <ButtonToolbar>
          <RemoveButton onClick={submitForm} className="mr-2" />
          <CancelButton onClick={onCancel} />
        </ButtonToolbar>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveConfirmationModal;
