/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import HRIcon from 'icons/HRIcon';
import HrAdministratorsItem from './human-resources/HrAdministratorsItem';
import PositionsItem from './human-resources/PositionsItem';
import SupervisorsItem from './human-resources/SupervisorsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.human-resources'},
  sectionTitle: {id: 'navigation.settings.human-resources-settings'},
});

const HumanResourcesMenu = () => (
  <NavigationMenu
    iconBefore={HRIcon}
    route="midas/settings/human-resources"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <HrAdministratorsItem />
    <PositionsItem />
    <SupervisorsItem />
  </NavigationMenu>
);

export default HumanResourcesMenu;
