/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import SelfRegistrationIcon from 'icons/SelfRegistrationIcon';

const messages = defineMessages({
  title: {
    id: 'navigation.settings.self-registration.configuration',
    defaultMessage: 'Self Registration',
  },
});

const SelfRegistrationConfigurationItem = () => (
  <RouteLinkItem
    iconBefore={SelfRegistrationIcon}
    route="midas/settings/self-registration"
    title={messages.title}
  />
);

export default SelfRegistrationConfigurationItem;
