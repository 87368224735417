/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useUserContext} from 'app/UserContext';
import {userHasAllPermissions} from 'app/permissions';
import {STAFF_ID_MATCHES_USER} from 'app/permissions/routing';
import {
  CAN_VIEW_ANY_IN_DISTRICT,
  CAN_VIEW_ANY_IN_SCHOOL,
} from 'app/permissions/classroom';
import BaseClassroomItem from '../ClassroomItem';

const permissions = [
  [STAFF_ID_MATCHES_USER, CAN_VIEW_ANY_IN_SCHOOL, CAN_VIEW_ANY_IN_DISTRICT],
];

const ClassroomItem = () => {
  const [context] = useUserContext();
  if (
    !userHasAllPermissions(context, permissions, {
      staffID: context.currentStaffID,
    })
  ) {
    return null;
  }
  return <BaseClassroomItem targetUserID={context.currentStaffID} />;
};

export default ClassroomItem;
