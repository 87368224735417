/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPencilAlt} from '@fortawesome/pro-duotone-svg-icons';

const EditIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faPencilAlt} {...props} />
);

export default EditIcon;
