/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {addMethod, string} from 'yup';
import {string as locale} from './locale';
import {isValidPhoneNumber} from 'react-phone-number-input';

addMethod(string, 'phoneNumber', function (message = locale.phoneNumber) {
  return this.test({
    name: 'phoneNumber',
    exclusive: true,
    message,
    test: (value) => {
      return value == null || value === '' || isValidPhoneNumber(value);
    },
  });
});
