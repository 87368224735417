/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import DataToolsIcon from 'icons/DataToolsIcon';
import CombineItem from './data-tools/CombineItem';
import FilterItem from './data-tools/FilterItem';
import FlattenItem from './data-tools/FlattenItem';
import StackItem from './data-tools/StackItem';

const messages = defineMessages({
  title: {id: 'navigation.data-tools'},
});

const DataToolsMenu = () => (
  <NavigationMenu
    iconBefore={DataToolsIcon}
    route="midas/data-tools"
    title={messages.title}
  >
    <CombineItem />
    <FilterItem />
    <FlattenItem />
    <StackItem />
  </NavigationMenu>
);

export default DataToolsMenu;
