/**
 * Returns a string containing the student's first and last names separated by a single space.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import useUser from './useUser';

const useStudentName = (userID) => {
  const user = useUser(userID);
  return user ? `${user.firstName} ${user.lastName}` : '';
};

export default useStudentName;
