/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import AutoCallIcon from 'icons/AutoCallIcon';

const messages = defineMessages({
  title: {id: 'navigation.auto-call'},
});

const AutoCallItem = () => (
  <StudentRouteLinkItem
    iconBefore={AutoCallIcon}
    route="midas/student/auto-call"
    title={messages.title}
  />
);

export default AutoCallItem;
