import {useMidasJsonApi} from 'app/hooks';
import {ApiResponseWrapper} from 'components/layout/index';

const PublicHeader = () => {
  const [response, loading, error] = useMidasJsonApi({
    url: `/api/public-preference/header_image`,
    expectedStatus: [200],
  });

  const [customResponse] = useMidasJsonApi({
    url: `/api/public-preference/custom_header`,
    expectedStatus: [200],
  });

  if (customResponse.status === 200 && customResponse.json.preference !== '') {
    const rawHTML = customResponse.json.preference;
    return <div dangerouslySetInnerHTML={{__html: rawHTML}} />;
  }

  return (
    <div className="col text-center">
      <ApiResponseWrapper error={error} isLoading={loading}>
        {response.status === 200 && (
          <img
            src={response.json.preference.source}
            alt={response.json.preference.alt}
            height={response.json.preference.height}
            width={response.json.preference.width}
          />
        )}
      </ApiResponseWrapper>
      <img
        src="https://tsc-site-page-resources.s3.amazonaws.com/midas_logo_color-FDE58AE06EC448C9937D6BB0036AF1DD.png"
        alt="MIDAS Logo"
        width={300}
        height={90}
      />
    </div>
  );
};

export default PublicHeader;
