/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import CurrentUserItem from 'components/navigation/side-navigation/item/CurrentUserItem';

const CurrentStudentItem = () => (
  <CurrentUserItem contextParam="currentStudentID" menuID="midas/student" />
);

export default CurrentStudentItem;
