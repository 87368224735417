/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import UniversityIcon from 'icons/UniversityIcon';
import AdministratorsItem from './institutes/AdministratorsItem';
import FormsItem from './institutes/FormsItem';
import FormSubmissionsItem from './institutes/FormSubmissionsItem';
import ReportsItem from './institutes/ReportsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.institutes'},
  sectionTitle: {id: 'navigation.settings.institutes-settings'},
});

const InstitutesMenu = () => (
  <NavigationMenu
    iconBefore={UniversityIcon}
    route="midas/settings/institutes"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <FormsItem />
    <FormSubmissionsItem />
    <ReportsItem />
    <AdministratorsItem />
  </NavigationMenu>
);

export default InstitutesMenu;
