/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const reports = {
  '': '/midas/reports',
  'reports-index': '/midas/reports/reports-index',
  view: '/midas/reports/view/:reportType',
  'time-and-effort': '/midas/reports/time-and-effort',
  letters: {
    '': '/midas/reports/letters',
    'add-letter': '/midas/reports/letters/add-letter',
    'edit-letter': '/midas/reports/letters/edit-letter/:id/:class',
  },
  'saved-parameters': {
    '': '/midas/reports/saved-parameters',
  },
  'scheduled-reports': {
    '': '/midas/reports/scheduled-reports',
    'edit-scheduled-report':
      '/midas/reports/scheduled-reports/edit-scheduled-report/:id/:class',
    'schedule-new-report':
      '/midas/reports/scheduled-reports/schedule-new-report',
    'add-recipient': '/midas/reports/scheduled-reports/add-recipient/:reportID',
    'edit-recipient-email':
      '/midas/reports/scheduled-reports/edit-recipient-email/:recipientID',
  },
};

export default reports;
