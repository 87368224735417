/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faChevronDown} from '@fortawesome/pro-solid-svg-icons';

const NavSelectIndicatorIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faChevronDown} {...props} />
);

export default NavSelectIndicatorIcon;
