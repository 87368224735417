/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandsHelping} from '@fortawesome/pro-duotone-svg-icons';

const ServiceIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHandsHelping} {...props} />
);

export default ServiceIcon;
