/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import HealthVisitIcon from 'icons/HealthVisitIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-visits'},
});

const HealthVisitsItem = () => (
  <StudentRouteLinkItem
    iconBefore={HealthVisitIcon}
    route="midas/student/health/visits"
    title={messages.title}
  />
);

export default HealthVisitsItem;
