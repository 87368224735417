/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from './NavigationMenu';
import InstructionalMaterialsIcon from 'icons/InstructionalMaterialsIcon';
import AdoptionTypesItem from './instructional-materials/AdoptionTypesItem';
import AppealsItem from './instructional-materials/AppealsItem';
import AuthorsItem from './instructional-materials/AuthorsItem';
import BidCyclesItem from './instructional-materials/BidCyclesItem';
import MaterialsItem from './instructional-materials/MaterialsItem';
import MediaTypesItem from './instructional-materials/MediaTypesItem';
import PublicCatalogItem from './instructional-materials/PublicCatalogItem';
import PublishersItem from './instructional-materials/PublishersItem';
import RubricsItem from './instructional-materials/RubricsItem';
import SubstitutionsItem from './instructional-materials/SubstitutionsItem';

const messages = defineMessages({
  title: {id: 'navigation.instructional-materials'},
});

const InstructionalMaterialsMenu = () => (
  <NavigationMenu
    iconBefore={InstructionalMaterialsIcon}
    route="midas/instructional-materials"
    title={messages.title}
  >
    <MaterialsItem />
    <BidCyclesItem />
    <PublishersItem />
    <AppealsItem />
    <SubstitutionsItem />
    <AuthorsItem />
    <MediaTypesItem />
    <AdoptionTypesItem />
    <RubricsItem />
    <PublicCatalogItem />
  </NavigationMenu>
);

export default InstructionalMaterialsMenu;
