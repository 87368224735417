/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimes} from '@fortawesome/pro-light-svg-icons';

const CloseIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faTimes} {...props} />
);

export default CloseIcon;
