/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {useHistory, useRouteMatch} from 'react-router-dom';
import {wrapNonLocalizedMessage} from 'app/intl';
import {UPDATE_CLASSROOM_SECTION, useUserContext} from 'app/UserContext';
import {pathFromRoute, urlFromRoute} from 'app/routing';
import PickerItem from 'components/navigation/side-navigation/item/PickerItem';
import MessageItem from 'components/navigation/side-navigation/item/MessageItem';

const ChangeClassItem = () => {
  const [{classroom}, dispatch] = useUserContext();

  const history = useHistory();
  const match = useRouteMatch({
    path: pathFromRoute('midas/classroom/section-page'),
    exact: false,
  });

  const options = useMemo(() => {
    if (classroom.schedule.length < 1) {
      return [];
    }
    const newOptions = new Map();
    for (const section of classroom.schedule) {
      let termYearGroupKey = null;
      if (section.term != null) {
        termYearGroupKey = section.term.termAbbr + ' ' + section.yearAbbr;
      } else {
        termYearGroupKey = section.yearAbbr;
      }
      termYearGroupKey = termYearGroupKey + ' (' + section.sectionStatus + ')';
      if (!newOptions.has(termYearGroupKey)) {
        newOptions.set(termYearGroupKey, {
          label: wrapNonLocalizedMessage(termYearGroupKey),
          options: [],
        });
      }
      const termYearGroup = newOptions.get(termYearGroupKey);
      termYearGroup.options.push({
        value: section.sectionID,
        label: wrapNonLocalizedMessage(section.courseName),
        description: wrapNonLocalizedMessage(section.meetingsDescription),
      });
    }
    return Array.from(newOptions.values());
  }, [classroom.schedule]);

  if (options.length === 0) {
    return (
      <MessageItem>
        <FormattedMessage id="midas.classroom.error-no-classes-message" />
      </MessageItem>
    );
  }

  const changeSection = (sectionID) => {
    dispatch({
      type: UPDATE_CLASSROOM_SECTION,
      payload: sectionID,
    });
    if (match) {
      // currently on a classroom page, refresh with the new section ID
      history.push(
        urlFromRoute('midas/classroom/section-page', {
          ...match.params,
          sectionID,
        })
      );
    }
  };

  let currentSection = null;
  for (const section of classroom.schedule) {
    if (section.sectionID === classroom.sectionID) {
      currentSection = section;
      break;
    }
  }

  return (
    <PickerItem
      options={options}
      value={classroom.sectionID}
      onChange={changeSection}
      description={currentSection?.meetingsDescription}
    >
      <strong>{currentSection?.courseName}</strong>
    </PickerItem>
  );
};

export default ChangeClassItem;
