/**
 * Returns a new string concatenating the student name and localized title using ' - '.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useIntl} from 'react-intl';
import {useRouteMatch} from 'react-router-dom';
import {toNumber} from 'lodash';
import useStudentName from './useStudentName';

const useStudentPageTitle = (descriptor, values) => {
  const match = useRouteMatch();

  const studentName = useStudentName(toNumber(match.params.studentID ?? 0));

  const intl = useIntl();
  const title = intl.formatMessage(descriptor, values);

  return `${studentName} - ${title}`;
};

export default useStudentPageTitle;
