/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import * as yup from 'app/yup';
import {PasswordField, Form, FormGroup, SubmitButton} from 'components/form';
import {callMidasJsonApi} from 'app/api';

const messages = defineMessages({
  newPassword: {id: 'form.label.new-password'},
  confirmPassword: {id: 'form.label.confirm-password'},
  setNewPassword: {id: 'form.button.set-new-password'},
  passwordNoMatch: {id: 'error.passwords-do-not-match'},
});

const validationSchema = yup.object().shape({
  password: yup.string().required(),
  confirmPassword: yup
    .string()
    .required()
    .oneOf([yup.ref('password'), null], messages.passwordNoMatch),
});

const SetNewPasswordForm = ({
  token,
  onTokenValidationError,
  onPasswordReset,
}) => {
  const setNewPassword = (values, {setSubmitting, setFieldError}) => {
    callMidasJsonApi(
      `/midas/rest/reset-password/${token}`,
      'PATCH',
      null,
      values
    ).then(({json, status}) => {
      if (status === 204) {
        onPasswordReset();
      } else if (status === 404) {
        onTokenValidationError();
      } else if (status === 422) {
        setFieldError('password', {
          id: 'server-message',
          values: {message: json.detail},
        });
        setSubmitting(false);
      } else {
        throw new Error(
          `Received ${status} response while attempting to set new password`
        );
      }
    });
  };

  return (
    <Form
      initialValues={{password: '', confirmPassword: ''}}
      validationSchema={validationSchema}
      onSubmit={setNewPassword}
    >
      <FormGroup>
        <PasswordField label={messages.newPassword} name="password" autoFocus />
      </FormGroup>
      <FormGroup>
        <PasswordField
          label={messages.confirmPassword}
          name="confirmPassword"
        />
      </FormGroup>
      <SubmitButton
        submitMessage={messages.setNewPassword}
        block
        className="my-4"
      />
    </Form>
  );
};

export default SetNewPasswordForm;
