/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const staff = {
  '': '/midas/staff',
  add: '/midas/staff/add',
  edit: '/midas/staff/edit/:staffID',
  search: '/midas/staff/search',
  attendance: {
    '': '/midas/staff/attendance',
    alerts: '/midas/staff/attendance/alerts/:staffID',
    'user-data': '/midas/staff/attendance/user-data/:staffID/:userType',
  },
  'pd-playlists': {
    '': '/midas/staff/pd-playlists',
    detail: '/midas/staff/pd-playlists/:playlistID',
  },
  'pd-courses': {
    '': '/midas/staff/pd-courses',
    'add-course': '/midas/staff/pd-courses/add-course',
    'edit-course': '/midas/staff/pd-courses/:courseID/edit-course',
    'add-conference-configuration':
      '/midas/staff/pd-courses/:courseID/add-conference-configuration',
    'edit-conference-configuration':
      '/midas/staff/pd-courses/:courseID/edit-conference-configuration',
    'view-credit-application':
      '/midas/staff/pd-courses/:courseID/view-credit-application/:creditTypeID',
    'edit-credit-application':
      '/midas/staff/pd-courses/:courseID/edit-credit-application/:creditTypeID',
    'credit-application':
      '/midas/staff/pd-courses/:courseID/credit-application/:creditTypeID',
    'edit-credits': '/midas/staff/pd-courses/:courseID/edit-credits',
    'add-prerequisites': '/midas/staff/pd-courses/:courseID/add-prerequisites',
    'edit-prerequisites':
      '/midas/staff/pd-courses/:courseID/edit-prerequisites',
    'audit-log': '/midas/staff/pd-courses/audit-log/:courseID',
    'message-log': '/midas/staff/pd-courses/message-log/:messageID',
  },
  'pd-sections': {
    '': '/midas/staff/pd-sections',
    course: '/midas/staff/pd-sections/course/:courseID',
    'add-section': '/midas/staff/pd-sections/:courseID/add-section',
    section: {
      'bulk-enroll':
        '/midas/staff/pd-sections/:courseID/section/:sectionID/bulk-enroll',
      'edit-section':
        '/midas/staff/pd-sections/:courseID/section/:sectionID/edit-section',
      notifications:
        '/midas/staff/pd-sections/:courseID/section/:sectionID/notifications',
      roster: {
        '': '/midas/staff/pd-sections/:courseID/section/:sectionID/roster',
        'download-csv':
          '/midas/staff/pd-sections/:courseID/section/:sectionID/roster/download-csv',
        print:
          '/midas/staff/pd-sections/:courseID/section/:sectionID/roster/print',
      },
    },
    /*
    'edit-enrollment-record':
      '/midas/staff/pd-sections/:courseID/edit-enrollment-record/:enrollmentRecordID',
    'message-log': {
      '': '/midas/staff/pd-sections/:courseID/message-log/:sectionID',
      'view-message':
        '/midas/staff/pd-sections/:courseID/message-log/:sectionID/view-message/:messageID',
    },*/
  },
  'pd-approval-queue': {
    '': '/midas/staff/pd-approval-queue',
    submission: '/midas/staff/pd-approval-queue/submission/:submissionNumber',
  },
  'ticket-queue': {
    '': '/midas/staff/ticket-queue',
    'add-ticket': '/midas/staff/ticket-queue/add-ticket',
    'edit-ticket': '/midas/staff/ticket-queue/edit-ticket/:ticketID',
    'add-action': '/midas/staff/ticket-queue/:ticketID/add-action',
    'edit-action': '/midas/staff/ticket-queue/edit-action/:actionID',
    'view-ticket': '/midas/staff/ticket-queue/view-ticket/:ticketID',
  },
  groups: {
    '': '/midas/staff/groups',
    'add-group': '/midas/staff/groups/add-group',
    'edit-group': '/midas/staff/groups/edit-group/:groupID',
    'add-members': '/midas/staff/groups/add-members/:groupID',
    'edit-moderator-privileges':
      '/midas/staff/groups/edit-moderator-privileges/:moderatorIDs',
  },
  invite: {
    '': '/midas/staff/invite',
  },
  'meal-point-of-sale': {
    '': '/midas/staff/meal-point-of-sale',
  },
};

export default staff;
