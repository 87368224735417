/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import MedicationIcon from 'icons/MedicationIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-medications'},
});

// TODO: This was disabled in August 2019 because the widget was broken; when
//  it is fixed, remove this disabled item and fix the default export.
const DisabledMedicationsItem = () => null;

// eslint-disable-next-line no-unused-vars
const MedicationsItem = () => (
  <StudentRouteLinkItem
    iconBefore={MedicationIcon}
    route="midas/student/health/medications"
    title={messages.title}
  />
);

export default DisabledMedicationsItem;
