/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import SchedulingIcon from 'icons/SchedulingIcon';
import EnrollSectionToSectionItem from './master-scheduling/EnrollSectionToSectionItem';
import CourseRequestSetUpItem from './master-scheduling/CourseRequestSetUpItem';
import TeacherFteItem from './master-scheduling/TeacherFteItem';
import TeacherRoomsItem from './master-scheduling/TeacherRoomsItem';
import CourseRecommendationsItem from './master-scheduling/CourseRecommendationsItem';
import TeacherAvailabilityItem from './master-scheduling/TeacherAvailabilityItem';

const messages = defineMessages({
  title: {id: 'navigation.master-scheduling'},
});

const MasterSchedulingMenu = () => (
  <NavigationMenu
    iconBefore={SchedulingIcon}
    route="midas/students/scheduling"
    title={messages.title}
  >
    <EnrollSectionToSectionItem />
    <CourseRequestSetUpItem />
    <TeacherFteItem />
    <TeacherRoomsItem />
    <CourseRecommendationsItem />
    <TeacherAvailabilityItem />
  </NavigationMenu>
);

export default MasterSchedulingMenu;
