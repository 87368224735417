/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import WrappedFormControl from './WrappedFormControl';

const messages = defineMessages({
  placeholder: {id: 'form.placeholder.email'},
});

const EmailField = (
  {as, type, placeholder = messages.placeholder, ...props},
  ref
) => {
  return (
    <WrappedFormControl
      ref={ref}
      type="email"
      placeholder={placeholder}
      {...props}
    />
  );
};
EmailField.displayName = 'EmailField';

export default forwardRef(EmailField);
