/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import ReactSelect, {components} from 'react-select';
import colors from './colors.json';

/* react-select uses the actual Option objects as its internal value but, for
 * consistency with other form element types and to enable Yup validation schemas
 * to operate on primitive values, we need to convert to and from the either string
 * value (if multiple is false) or the array of string values (if multiple is true).
 */
const collectSelectedOptions = (selectedOptions, value, multiple, options) => {
  if (value === undefined || value === null) {
    return;
  }
  for (const option of options) {
    if (option.hasOwnProperty('value')) {
      if (multiple) {
        if (value.indexOf(option.value) >= 0) {
          selectedOptions.push(option);
        }
      } else {
        if (option.value === value) {
          selectedOptions.push(option);
        }
      }
    } else if (option.hasOwnProperty('options')) {
      collectSelectedOptions(selectedOptions, value, multiple, option.options);
    }
  }
};

const OptionWithDescription = ({children, ...props}) => (
  <components.Option {...props}>
    {props.data.label}
    {props.data.description && (
      <span className="description">{props.data.description}</span>
    )}
  </components.Option>
);

const ColorPickerField = (
  {
    id,
    name,
    label,
    placeholder,
    help,
    required = false,
    disabled = false,
    multiple = false,
    ...props
  },
  ref
) => {
  const intl = useIntl();

  const [{onBlur, value}, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const colorOptions = useMemo(() => {
    const colorSelectOptions = [];
    Object.keys(colors).forEach((color) => {
      colorSelectOptions.push({
        value: colors[color],
        label: (
          <div
            className="border border-dark"
            style={{
              width: '20px',
              height: '20px',
              backgroundColor: colors[color],
            }}
          ></div>
        ),
      });
    });
    return colorSelectOptions;
  }, []);

  const isInvalid = meta.touched && meta.error && true;

  const selectedOptions = [];
  collectSelectedOptions(selectedOptions, value, multiple, colorOptions);

  const onChange = (changedOptions) => {
    if (!changedOptions) {
      setFieldValue(name, multiple ? [] : '');
    } else if (multiple) {
      const newValue = changedOptions.map((option) => option.value);
      setFieldValue(name, newValue);
    } else {
      setFieldValue(name, changedOptions.value);
    }
  };

  /* The blur event on the react-select component is for its "valueContainer"
   * component, which is its own input element, and not the hidden form element
   * it creates to store the actual value. To allow for field validation on blur,
   * we must intercept this event and re-target it to the proper Formik path.
   */
  const handleOnBlur = (event) => {
    onBlur(name)(event);
  };

  /* react-select uses CSS-in-JS, which means it completely ignores just about all
   * of the built-in Bootstrap styles, and we have to hard-code equivalent rules
   * in these JS functions to maintain a consistent visual look and feel for all
   * form elements in the app. Note that any time Bootstrap is updated, one or more
   * of these functions may need to be updated as well.
   */
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        '&:hover': {},
        borderColor: isInvalid
          ? '#dc3545'
          : state.isFocused
          ? '#80bdff'
          : '#ced4da',
        boxShadow: state.isFocused
          ? isInvalid
            ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
            : '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
          : 'none',
        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
        transition:
          'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      };
    },
    valueContainer: (provided) => {
      return isInvalid
        ? {
            ...provided,
            paddingRight: 'calc(1.5em + 0.75rem)',
            backgroundImage:
              "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
            backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
          }
        : provided;
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#6c757d',
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: '#495057',
      };
    },
    option: (provided, state) => ({
      ...provided,
      fontWeight: state.isSelected ? 'bold' : 'normal',
      color: 'white',
      width: '20px',
      padding: '0px',
      marginRight: '0.25rem',
      display: 'inline-block',
      backgroundColor: state.data.color,
      fontSize: state.selectProps.myFontSize,
    }),
  };
  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <ReactSelect
        ref={ref}
        id={id}
        name={name}
        placeholder={
          placeholder && intl.formatMessage(placeholder, placeholder.values)
        }
        options={colorOptions}
        value={selectedOptions}
        onChange={onChange}
        onBlur={handleOnBlur}
        styles={customStyles}
        components={{Option: OptionWithDescription}}
        aria-required={required}
        isClearable={!required || multiple}
        isDisabled={isSubmitting || disabled}
        isMulti={multiple}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? `${id}-error` : undefined}
        aria-labelledby={label && `${id}-label`}
        aria-describedby={help && `${id}-help`}
        {...props}
      />
    </FieldWrapper>
  );
};
ColorPickerField.displayName = 'ColorPickerField';

export default forwardRef(ColorPickerField);
