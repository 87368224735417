/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ReportCardIcon from 'icons/ReportCardIcon';

const messages = defineMessages({
  title: {id: 'navigation.merit-pay-report-card'},
});

const MeritPayReportCard = () => (
  <RouteLinkItem
    iconBefore={ReportCardIcon}
    route="midas/my/merit-pay-report-card"
    title={messages.title}
  />
);

export default MeritPayReportCard;
