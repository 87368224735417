/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import VideoMentoringIcon from 'icons/VideoMentoringIcon';

const messages = defineMessages({
  title: {id: 'navigation.video-mentoring'},
});

const VideoMentoringItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={VideoMentoringIcon}
    route="midas/staff-member/video-evaluation"
    title={messages.title}
  />
);

export default VideoMentoringItem;
