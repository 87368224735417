/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {Button} from 'react-bootstrap';
import classnames from 'classnames';

const ActionButtonLink = (
  {
    params = {},
    compact = false,
    variant = 'link',
    type = 'button',
    size = 'sm',
    className = '',
    children,
    ...props
  },
  ref
) => {
  const buttonClassNames = classnames('d-flex align-items-center', className, {
    'flex-column': !compact,
  });
  return (
    <Button
      ref={ref}
      variant={variant}
      type={type}
      size={size}
      className={buttonClassNames}
      {...props}
    >
      {children}
    </Button>
  );
};
ActionButtonLink.displayName = 'ActionButtonLink';

export default forwardRef(ActionButtonLink);
