/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PortfolioIcon from 'icons/PortfolioIcon';

const messages = defineMessages({
  title: {id: 'navigation.grants.evidence'},
});

const EvidenceItem = () => (
  <RouteLinkItem
    iconBefore={PortfolioIcon}
    route="midas/grants/evidence"
    title={messages.title}
  />
);

export default EvidenceItem;
