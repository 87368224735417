/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ReportsIcon from 'icons/ReportsIcon';

const messages = defineMessages({
  title: {id: 'navigation.reports'},
});

const ReportsItem = () => (
  <RouteLinkItem
    iconBefore={ReportsIcon}
    route="midas/finance/reports"
    title={messages.title}
  />
);

export default ReportsItem;
