/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef} from 'react';
import {defineMessages, useIntl} from 'react-intl';
import {uniqueId} from 'lodash';
import {Form} from 'react-bootstrap';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import PhoneInput from 'react-phone-number-input/input';
import 'react-phone-number-input/style.css';

const messages = defineMessages({
  placeholder: {id: 'form.placeholder.phone-number'},
});

const PhoneNumberField = (
  {
    id,
    name,
    label,
    placeholder = messages.placeholder,
    help,
    required = false,
    disabled = false,
    defaultCountry = 'US',
    ...props
  },
  ref
) => {
  const intl = useIntl();

  const [{onBlur, value}, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const isInvalid = meta.touched && meta.error && true;

  const onChange = (newPhoneNumber) => {
    setFieldValue(name, newPhoneNumber);
  };

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <div>
        <PhoneInput
          ref={ref}
          id={id}
          name={name}
          placeholder={
            placeholder && intl.formatMessage(placeholder, placeholder.values)
          }
          value={value ?? ''}
          onChange={onChange}
          onBlur={onBlur}
          aria-required={required}
          disabled={isSubmitting || disabled}
          isInvalid={isInvalid}
          aria-invalid={isInvalid}
          aria-errormessage={isInvalid ? `${id}-error` : undefined}
          aria-labelledby={label && `${id}-label`}
          aria-describedby={help && `${id}-help`}
          inputComponent={Form.Control}
          country={defaultCountry}
          defaultCountry={defaultCountry}
          {...props}
        />
      </div>
    </FieldWrapper>
  );
};
PhoneNumberField.displayName = 'PhoneNumberField';

export default forwardRef(PhoneNumberField);
