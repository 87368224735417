/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuoteRight} from '@fortawesome/pro-duotone-svg-icons';

const BiographyIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faQuoteRight} {...props} />
);

export default BiographyIcon;
