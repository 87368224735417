/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import HealthScreeningIcon from 'icons/HealthScreeningIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-screenings'},
});

const ScreeningsItem = () => (
  <StudentRouteLinkItem
    iconBefore={HealthScreeningIcon}
    route="midas/student/health/screenings"
    title={messages.title}
  />
);

export default ScreeningsItem;
