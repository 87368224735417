/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import CurrentUserItem from 'components/navigation/side-navigation/item/CurrentUserItem';

const CurrentGuardianItem = () => (
  <CurrentUserItem contextParam="currentGuardianID" menuID="midas/guardian" />
);

export default CurrentGuardianItem;
