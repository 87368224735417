/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import EducatorGrowthIcon from 'icons/EducatorGrowthIcon';

const messages = defineMessages({
  title: {id: 'navigation.growth-plan'},
});

const GrowthPlanItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={EducatorGrowthIcon}
    route="midas/staff-member/growth-plan"
    title={messages.title}
  />
);

export default GrowthPlanItem;
