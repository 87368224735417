/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import GradesIcon from 'icons/GradesIcon';

const messages = defineMessages({
  title: {id: 'navigation.grades'},
});

const GradesItem = () => (
  <StudentRouteLinkItem
    iconBefore={GradesIcon}
    route="midas/student/grades"
    title={messages.title}
  />
);

export default GradesItem;
