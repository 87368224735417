/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import CategoriesIcon from 'icons/CategoriesIcon';

const messages = defineMessages({
  title: {id: 'navigation.educator-merit-pay.educator-categories'},
});

const EducatorCategoriesItem = () => (
  <RouteLinkItem
    iconBefore={CategoriesIcon}
    route="midas/educator-merit-pay/educator-categories"
    title={messages.title}
  />
);

export default EducatorCategoriesItem;
