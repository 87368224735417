/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';
import {REQUEST_MEMBERSHIP, useUserCacheContext} from 'app/UserCacheContext';

const useUserMembership = (userID) => {
  const {memberships, dispatch} = useUserCacheContext();

  const userMembership = memberships.get(userID);

  useEffect(() => {
    if (userMembership === undefined) {
      dispatch({type: REQUEST_MEMBERSHIP, payload: userID});
    }
  }, [userMembership, userID, dispatch]);

  return userMembership || null;
};

export default useUserMembership;
