/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useFeature} from 'app/hooks';
import {USES_AD_HOC_CALENDAR} from 'app/features';
import ClassroomRouteLinkItem from './ClassroomRouteLinkItem';
import ScheduleIcon from 'icons/ScheduleIcon';

const messages = defineMessages({
  title: {id: 'navigation.schedule'},
});

const ScheduleItem = () => {
  const usesAdHocCalendar = useFeature(USES_AD_HOC_CALENDAR);
  if (!usesAdHocCalendar) {
    return null;
  }
  return (
    <ClassroomRouteLinkItem
      iconBefore={ScheduleIcon}
      route="midas/classroom/ad-hoc-schedule"
      title={messages.title}
    />
  );
};

export default ScheduleItem;
