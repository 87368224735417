/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import InstituteFormsIcon from 'icons/InstituteFormsIcon';

const messages = defineMessages({
  title: {id: 'navigation.institute-forms'},
});

const InstituteFormsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={InstituteFormsIcon}
    route="midas/staff-member/institute-forms"
    title={messages.title}
  />
);

export default InstituteFormsItem;
