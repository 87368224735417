/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const microcredentials = {
  '': '/microcredentials',
  'baked-badge': '/microcredentials/baked-badge/:badgeUserID',
  detail: '/microcredentials/detail/:badgeID',
  resource: {
    asset: '/microcredentials/resource/asset/:uuid/:filename',
  },
};

export default microcredentials;
