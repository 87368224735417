/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faPersonSign} from '@fortawesome/pro-duotone-svg-icons';

const EducationLeaderIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faPersonSign} {...props} />
);

export default EducationLeaderIcon;
