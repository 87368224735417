/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import Alert from './Alert';

const InfoAlert = (props) => {
  return <Alert variant="info" {...props} />;
};

export default InfoAlert;
