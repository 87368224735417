import {useMidasJsonApi} from 'app/hooks';
import {ApiResponseWrapper} from 'components/layout/index';
import {useContext, useEffect, useMemo, useState} from 'react';
import {UserContext} from 'app/UserContext';
import {logout} from 'app/userSession';
import PublicNavItem from 'components/layout/PublicNavItem';
import RememberToCheckoutModal from 'components/microcredentials/public/RememberToCheckoutModal';
import {useHistory} from 'react-router-dom';

const PublicNav = () => {
  const history = useHistory();
  const [context] = useContext(UserContext);
  const [showCartModal, setShowCartModal] = useState(false);

  const [cartResponse] = useMidasJsonApi({
    url: `/midas/rest/cart-count`,
    expectedStatus: [200],
  });

  const [cartWarningResponse] = useMidasJsonApi({
    url: `/midas/rest/user-preference/check-out-warning-time`,
    expectedStatus: [200],
  });

  const [, , , requestPostCartWarningData] = useMidasJsonApi({
    method: 'POST',
    url: `/midas/rest/user-preference`,
    expectedStatus: [200],
    immediate: false,
  });

  const cartCount = useMemo(() => {
    if (cartResponse.status === 200) {
      return cartResponse.json.cartCount;
    }
    return 0;
  }, [cartResponse]);

  const [response, loading, error] = useMidasJsonApi({
    url: `/api/public-preference/header_links`,
    expectedStatus: [200],
  });

  const doLogout = (event) => {
    event.preventDefault();
    logout();
  };

  const isLoggedIn = useMemo(() => {
    return context.userID !== 0;
  }, [context]);

  useEffect(() => {
    if (cartWarningResponse.status === 200) {
      const dateTimeLastWarning = new Date(
        cartWarningResponse.json.userPreference
      );

      // Get the date and time now
      const today = new Date();
      const date =
        today.getFullYear() +
        '-' +
        (today.getMonth() + 1) +
        '-' +
        today.getDate();
      const time =
        today.getHours() + ':' + today.getMinutes() + ':' + today.getSeconds();
      const dateTimeNowString = date + ' ' + time;

      // Check how many minutes between the two dateTimes
      const diff = today.getTime() - dateTimeLastWarning.getTime();
      const minutes = diff / 60000;

      if (minutes > 3) {
        if (isLoggedIn && cartCount > 0) {
          setShowCartModal(true);
          const data = {
            name: 'check-out-warning-time',
            value: dateTimeNowString,
          };
          requestPostCartWarningData({
            body: data,
          });
        }
      }
    }
  }, [cartWarningResponse, requestPostCartWarningData, isLoggedIn, cartCount]);

  const goToCart = () => {
    setShowCartModal(false);
    history.push('/professional-development/catalog/cart');
  };

  const handleCartModalCancel = () => {
    setShowCartModal(false);
  };

  return (
    <>
      <div className="row sticky">
        <div className="col">
          <ApiResponseWrapper error={error} isLoading={loading}>
            {response.status === 200 && response.json.preference && (
              <ul className="nav justify-content-center">
                {response.json.preference.map((link) => {
                  return (
                    <PublicNavItem
                      key={link.name}
                      link={link}
                      isLoggedIn={isLoggedIn}
                      context={context}
                      cartCount={cartCount}
                    />
                  );
                })}
                {isLoggedIn ? (
                  <li className="nav-item">
                    <a
                      className="nav-link naver"
                      href="/logout"
                      onClick={doLogout}
                    >
                      Logout
                    </a>
                  </li>
                ) : (
                  <li className="nav-item">
                    <a className="nav-link naver" href="/login">
                      Login
                    </a>
                  </li>
                )}
              </ul>
            )}
          </ApiResponseWrapper>
        </div>
      </div>
      <RememberToCheckoutModal
        show={showCartModal}
        onCancel={handleCartModalCancel}
        goToCart={goToCart}
      />
    </>
  );
};

export default PublicNav;
