/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {RouteLink, RouteRedirect} from 'app/routing';
import {useDocumentTitle, useFormatMessage} from 'app/hooks';
import {Markdown} from 'components/layout';
import ForgotUsernameForm from 'components/authentication/ForgotUsernameForm';
import PasswordResetSent from 'components/authentication/PasswordResetSent';

const messages = defineMessages({
  title: {
    id: 'title.forgot-username',
    description: 'Forgot username page title',
    defaultMessage: 'Forgot Username',
  },
  instructions: {
    id: 'text.forgot-username-instructions',
    description:
      'Instructions block for the forgotten username form on the login page',
    defaultMessage:
      'Please enter the email address linked to your account. We will send you a message containing a one-time link that you can use to choose a new password.',
  },
});

const ForgotUsername = ({usernameMode}) => {
  useDocumentTitle(useFormatMessage(messages.title));

  const [resetSent, setResetSent] = useState(false);

  const instructions = useFormatMessage(messages.instructions);

  if (usernameMode === 'email') {
    // this form is only used when usernames are not email addresses
    return <RouteRedirect route="login/reset-password" />;
  }

  if (resetSent) {
    return <PasswordResetSent />;
  }

  return (
    <>
      <Markdown>{instructions}</Markdown>

      <ForgotUsernameForm
        onResetSent={() => {
          setResetSent(true);
        }}
      />

      <p className="mb-0 text-center small">
        <RouteLink route="login/reset-password">
          <FormattedMessage
            id="link.back"
            description="Link text to the previous page"
            defaultMessage="← Back"
          />
        </RouteLink>
      </p>
    </>
  );
};

export default ForgotUsername;
