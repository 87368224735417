/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import classroom from './midas/classroom';
import dataexplorer from './midas/data-explorer';
import datatools from './midas/data-tools';
import dataintegrations from './midas/data-integrations';
import dataForge from './midas/data-forge';
import event from './midas/event';
import events from './midas/events';
import finance from './midas/finance';
import grants from './midas/grants';
import guardian from './midas/guardian';
import guardians from './midas/guardians';
import institute from './midas/institute';
import instructionalMaterials from './midas/instructional-materials';
import microcredentialing from './midas/microcredentialing';
import my from './midas/my';
import reports from './midas/reports';
import selfRegistration from './midas/self-registration';
import staff from './midas/staff';
import staffMember from './midas/staff-member';
import settings from './midas/settings';
import student from './midas/student';
import students from './midas/students';
import educatormeritpay from './midas/educator-merit-pay';

const midas = {
  '': '/midas',
  'change-password': {
    '': '/midas/change-password',
    form: '/midas/change-password/form',
  },
  classroom,
  event,
  events,
  finance,
  'data-explorer': dataexplorer,
  'data-tools': datatools,
  'data-integrations': dataintegrations,
  'data-forge': dataForge,
  grants,
  guardian,
  guardians,
  institute,
  'instructional-materials': instructionalMaterials,
  microcredentialing,
  my,
  'my-classroom': '/midas/my-classroom',
  reports,
  search: '/midas/search',
  'educator-merit-pay': educatormeritpay,
  'self-registration': selfRegistration,
  settings,
  student,
  students,
  staff,
  'staff-member': staffMember,
};

export default midas;
