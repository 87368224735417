/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import GrantAwardApprovalIcon from 'icons/GrantAwardApprovalIcon';

const messages = defineMessages({
  title: {id: 'navigation.grants.award-workflows'},
});

const AwardWorkflowsItem = () => (
  <RouteLinkItem
    iconBefore={GrantAwardApprovalIcon}
    route="midas/grants/award-workflows"
    title={messages.title}
  />
);

export default AwardWorkflowsItem;
