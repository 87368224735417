/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useFeature} from 'app/hooks';
import {PD_CUSTOMER} from 'app/features';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ProfessionalLearningIcon from 'icons/ProfessionalLearningIcon';

const messages = defineMessages({
  title: {id: 'navigation.professional-development.public-catalog'},
});

const PdCatalogItem = () => {
  const pdCustomer = useFeature(PD_CUSTOMER);
  if (!pdCustomer) {
    return null;
  }
  return (
    <RouteLinkItem
      iconBefore={ProfessionalLearningIcon}
      route="professional-development/courses"
      title={messages.title}
    />
  );
};

export default PdCatalogItem;
