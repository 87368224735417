/**
 * MIDAS REST API helper functions.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import MidasApiError from './MidasApiError';

const isExpectedStatus = (status, expectedStatus) => {
  if (expectedStatus) {
    return expectedStatus.indexOf(status) !== -1;
  }
  return status >= 200 && status < 600;
};

export const callMidasJsonApi = (
  url,
  method,
  signal = null,
  body = null,
  expectedStatus = null
) => {
  const headers = {
    Accept: 'application/json',
  };
  if (body) {
    headers['Content-Type'] = 'application/json';
  }

  return fetch(url, {
    method,
    headers,
    body: body && JSON.stringify(body),
    mode: 'same-origin',
    credentials: 'same-origin',
    redirect: 'error',
    signal,
  }).then(
    (response) => {
      if (!isExpectedStatus(response.status, expectedStatus)) {
        return response.text().then((text) => {
          throw new MidasApiError(
            url,
            method,
            body,
            {
              json: text ? JSON.parse(text) : null,
              status: response.status,
            },
            `Unexpected HTTP ${response.status} response`
          );
        });
      }
      return response.text().then((text) => {
        let json;
        try {
          json = text ? JSON.parse(text) : {};
        } catch (error) {
          throw new MidasApiError(
            url,
            method,
            body,
            {
              json: text,
              status: response.status,
            },
            `Unable to parse JSON response`
          );
        }
        return Promise.resolve({
          json,
          status: response.status,
        });
      });
    },
    (error) => {
      if (error.name === 'AbortError') {
        throw error;
      }
      throw new MidasApiError(url, method, body, error, error.name);
    }
  );
};
