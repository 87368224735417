/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const educatorMeritPay = {
  '': '/midas/educator-merit-pay',
  'educator-categories':
    '/midas/educator-merit-pay/educator-categories/:categoryID?',
  'effectiveness-areas': {
    '': '/midas/educator-merit-pay/effectiveness-areas',
    area: '/midas/educator-merit-pay/effectiveness-areas/area/:areaID',
  },
  'annual-evaluation-settings': {
    '': '/midas/educator-merit-pay/annual-evaluation-settings',
    'category-year':
      '/midas/educator-merit-pay/annual-evaluation-settings/category-year/:categoryID/:yearID',
  },
  'aggregate-data-entry': {
    '':
      '/midas/educator-merit-pay/aggregate-data-entry/:effectivenessAreaComponentID',
  },
  'spot-observations': {
    '': '/midas/educator-merit-pay/spot-observations',
    'new-spot-observation-selector':
      '/midas/educator-merit-pay/spot-observations/new-spot-observation-selector',
    'new-spot-observation':
      '/midas/educator-merit-pay/spot-observations/new-spot-observation/:educatorID/:formID',
    'view-spot-observation':
      '/midas/educator-merit-pay/spot-observations/view-spot-observation/:formID/:submissionID/:canEdit',
  },
};

export default educatorMeritPay;
