/**
 * Renders a large loading indicator horizontally- and vertically-centered on
 * the page.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useState, useEffect} from 'react';
import LoadingIcon from 'icons/LoadingIcon';
import './FullPageLoading.css';

const FullPageLoading = ({delayMs = 250}) => {
  const [hidden, setHidden] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setHidden(false);
    }, delayMs);
    return () => {
      clearTimeout(timer);
    };
  }, [delayMs]);

  return (
    <div className={`FullPageLoading ${hidden ? 'hidden' : ''}`}>
      <LoadingIcon size="10x" />
    </div>
  );
};

export default FullPageLoading;
