/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import ClassroomRouteLinkItem from './ClassroomRouteLinkItem';
import ScheduleIcon from 'icons/ScheduleIcon';
import {useUserContext} from 'app/UserContext';
import {useMidasJsonApi} from 'app/hooks';

const messages = defineMessages({
  title: {id: 'navigation.section-groups'},
});

const SectionGroupsItem = () => {
  const [{classroom}] = useUserContext();

  const [response] = useMidasJsonApi({
    url: `/midas/rest/pd-section/${classroom.sectionID}?fields=id`,
    expectedStatus: [200, 404],
  });

  // If a PD section does exist, don't show the section groups link (this item)
  if (response.json?.section) {
    return null;
  }

  return (
    <ClassroomRouteLinkItem
      iconBefore={ScheduleIcon}
      route="midas/classroom/section-groups"
      title={messages.title}
    />
  );
};

export default SectionGroupsItem;
