/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import PdTranscriptIcon from 'icons/PdTranscriptIcon';

const messages = defineMessages({
  title: {id: 'navigation.pd-transcript'},
});

const PdTranscriptItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={PdTranscriptIcon}
    route="midas/staff-member/pd-transcript"
    title={messages.title}
  />
);

export default PdTranscriptItem;
