/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import EventRouteLinkItem from './EventRouteLinkItem';
import MeetingListIcon from 'icons/MeetingListIcon';

const messages = defineMessages({
  title: {id: 'navigation.event.meeting-list'},
});

const MeetingListsItem = () => (
  <EventRouteLinkItem
    iconBefore={MeetingListIcon}
    route="midas/event/meeting-list"
    title={messages.title}
  />
);

export default MeetingListsItem;
