/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const approvalAreas = {
  '': '/midas/settings/professional-development/approval-areas',
  'add-approval-area':
    '/midas/settings/professional-development/approval-areas/add-approval-area',
  'edit-approval-area':
    '/midas/settings/professional-development/approval-areas/edit-approval-area/:approvalAreaID',
};

export default approvalAreas;
