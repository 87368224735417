/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useMemo, useRef} from 'react';
import {wrapNonLocalizedMessage} from 'app/intl';
import {CHANGE_SCHOOL, useUserContext} from 'app/UserContext';
import {useSchoolName} from 'app/hooks';
import PickerItem from './PickerItem';

const cssFn = () => ({
  padding: '6px 8px',
  fontSize: '12px',
  color: '#6b778c',
});

const ChangeSchoolItem = () => {
  const [{schoolID, allowedSchools}, dispatch] = useUserContext();
  const currentSchoolName = useSchoolName(schoolID);

  const lastSchoolID = useRef(schoolID);

  const schoolOptions = useMemo(() => {
    const districts = new Map();
    for (const school of allowedSchools) {
      if (!districts.has(school.districtID)) {
        districts.set(school.districtID, {
          label: wrapNonLocalizedMessage(school.districtName),
          options: [],
        });
      }
      const district = districts.get(school.districtID);
      district.options.push({
        value: school.schoolID,
        label: wrapNonLocalizedMessage(school.schoolName),
      });
    }
    return Array.from(districts.values()).sort((a, b) => {
      // ensure the current district group is always at the top
      for (const option of a.options) {
        if (option.value === schoolID) {
          return -1;
        }
      }
      return 0;
    });
  }, [allowedSchools, schoolID]);

  // TODO: Until we've had time to test existing components to ensure they
  //  properly respond to a school context change, play it safe and reload
  //  the current page. If the URL params are inappropriate for the new
  //  school context this may result in a user-facing error
  useEffect(() => {
    // ref may be null very early during initial render
    if (!lastSchoolID.current) {
      lastSchoolID.current = schoolID;
      return;
    }
    if (schoolID !== lastSchoolID.current) {
      window.location.reload();
      return null;
    }
  }, [schoolID]);

  const changeSchoolID = (newSchoolID) => {
    if (schoolID !== newSchoolID) {
      dispatch({
        type: CHANGE_SCHOOL,
        payload: newSchoolID,
      });
    }
  };

  return (
    <PickerItem
      options={schoolOptions}
      value={schoolID}
      onChange={changeSchoolID}
      cssFn={cssFn}
    >
      {currentSchoolName}
    </PickerItem>
  );
};

export default ChangeSchoolItem;
