/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import health from './student/health';

const student = {
  '': '/midas/student/:studentID',
  alerts: '/midas/student/:studentID/alerts',
  'assigned-to': {
    '': '/midas/student/:studentID/assigned-to',
    'add-locker': '/midas/student/:studentID/assigned-to/add-locker',
    'edit-assigned-to':
      '/midas/student/:studentID/assigned-to/edit-assigned-to/:assignedToID',
  },
  assignments: '/midas/student/:studentID/assignments',
  attendance: {
    '': '/midas/student/:studentID/attendance',
    'attendance-calendar':
      '/midas/student/:studentID/attendance/attendance-calendar',
    'arrival-dismissal-times':
      '/midas/student/:studentID/attendance/arrival-dismissal-times/:date',
  },
  'auto-call': {
    '': '/midas/student/:studentID/auto-call',
    'add-number': '/midas/student/:studentID/auto-call/add-number',
    'select-number': '/midas/student/:studentID/auto-call/select-number',
  },
  behavior: {
    '': '/midas/student/:studentID/behavior',
    'add-consequence': '/midas/student/:studentID/behavior/add-consequence',
    'edit-consequence':
      '/midas/student/:studentID/behavior/edit-consequence/:consequenceID',
  },
  'behavior-concerns': {
    '': '/midas/student/:studentID/behavior-concerns',
    'add-concern': '/midas/student/:studentID/behavior-concerns/add-concern',
  },
  calendar: {
    '': '/midas/student/:studentID/calendar',
    'assignment-details':
      '/midas/student/:studentID/calendar/assignment-details/:assignmentID',
    'event-details':
      '/midas/student/:studentID/calendar/event-details/:eventID',
    'section-details':
      '/midas/student/:studentID/calendar/section-details/:sectionID',
  },
  'contact-information': {
    '': '/midas/student/:studentID/contact-information',
    'add-contact-information':
      '/midas/student/:studentID/contact-information/add-contact-information',
    'edit-contact':
      '/midas/student/:studentID/contact-information/edit-contact/:contactID',
    'view-shared-contact':
      '/midas/student/:studentID/contact-information/view-shared-contact/:contactID/:contactType/:sharedUsers',
  },
  'course-requests': {
    '': '/midas/student/:studentID/course-requests',
    'add-request':
      '/midas/student/:studentID/course-requests/add-request/:yearID',
    'add-alternate':
      '/midas/student/:studentID/course-requests/add-alternate/:yearID',
    'approve-requests':
      '/midas/student/:studentID/course-requests/approve-requests/:yearID',
  },
  demographics: {
    '': '/midas/student/:studentID/demographics',
    'edit-demographics':
      '/midas/student/:studentID/demographics/edit-demographics',
  },
  enrollment: {
    '': '/midas/student/:studentID/enrollment',
    'add-enrollment': '/midas/student/:studentID/enrollment/add-enrollment',
    'add-historical-grade-level':
      '/midas/student/:studentID/enrollment/add-historical-grade-level/:enrollmentID',
    'add-outplaced-attendance':
      '/midas/student/:studentID/enrollment/add-outplaced-attendance',
    'edit-enrollment':
      '/midas/student/:studentID/enrollment/edit-enrollment/:enrollmentID',
    'edit-historical-grade-level':
      '/midas/student/:studentID/enrollment/edit-historical-grade-level/:historicalGradeLevelID',
  },
  family: {
    '': '/midas/student/:studentID/family',
    'add-guardian': '/midas/student/:studentID/family/add-guardian',
    'edit-guardian':
      '/midas/student/:studentID/family/edit-guardian/:guardianID',
  },
  goals: {
    '': '/midas/student/:studentID/goals',
    'edit-goal':
      '/midas/student/:studentID/goals/edit-goal/:schoolID/:goalNumber',
    goal: '/midas/student/:studentID/goals/goal/:schoolID/:goalNumber',
    'new-goal': '/midas/student/:studentID/goals/new-goal',
  },
  grades: {
    '': '/midas/student/:studentID/grades',
    gpa: '/midas/student/:studentID/grades/gpa',
    assignments:
      '/midas/student/:studentID/grades/assignments/:sectionID/:gradingPeriodID',
    'add-non-district-course':
      '/midas/student/:studentID/grades/add-non-district-course',
    'edit-non-district-course':
      '/midas/student/:studentID/grades/edit-non-district-course/:gradeID',
    'edit-grade': '/midas/student/:studentID/grades/edit-grade/:classID',
  },
  'graduation-requirements': {
    '': '/midas/student/:studentID/graduation-requirements',
    'select-requirement-set':
      '/midas/student/:studentID/graduation-requirements/select-requirement-set',
  },
  health,
  iep: {
    '': '/midas/student/:studentID/iep',
    'add-iep': '/midas/student/:studentID/iep/add-iep',
    'edit-iep': '/midas/student/:studentID/iep/edit-iep/:iepID',
    'add-service': '/midas/student/:studentID/iep/add-service',
    'edit-service': '/midas/student/:studentID/iep/edit-service/:serviceID',
  },
  'intervention-documentation': {
    submission:
      '/midas/student/:studentID/intervention-documentation/submission/:submissionID',
  },
  'intervention-log': {
    'add-log': '/midas/student/:studentID/intervention-log/add-log',
    'edit-log': '/midas/student/:studentID/intervention-log/edit-log/:logID',
  },
  'intervention-progress': {
    'assign-response':
      '/midas/student/:studentID/intervention-progress/assign-response',
    'add-measure':
      '/midas/student/:studentID/intervention-progress/add-measure/:responseID',
    'edit-measure':
      '/midas/student/:studentID/intervention-progress/edit-measure/:measureID',
    response: '/midas/student/:studentID/intervention-progress/response',
  },
  interventions: {
    '': '/midas/student/:studentID/interventions',
    'add-intervention':
      '/midas/student/:studentID/interventions/add-intervention',
  },
  'language-citizenship': {
    '': '/midas/student/:studentID/language-citizenship',
    'edit-language-citizenship':
      '/midas/student/:studentID/language-citizenship/edit-language-citizenship',
  },
  microcredentials: {
    '': '/midas/student/:studentID/microcredentials',
    'add-evidence':
      '/midas/student/:studentID/microcredentials/add-evidence/:badgeID/:templateID/:isUsersOwnPage',
    'add-reflection':
      '/midas/student/:studentID/microcredentials/add-reflection/:badgeID/:templateID/:isUsersOwnPage/:questionID',
    'edit-reflection':
      '/midas/student/:studentID/microcredentials/edit-reflection/:badgeID/:templateID/:isUsersOwnPage/:questionID/:ID',
    'edit-evidence':
      '/midas/student/:studentID/microcredentials/edit-evidence/:badgeID/:isUsersOwnPage/:ID',
    'view-evidence':
      '/midas/student/:studentID/microcredentials/view-evidence/:badgeID/:evidenceID',
  },
  'other-contacts': {
    '': '/midas/student/:studentID/other-contacts',
    'add-contact': '/midas/student/:studentID/other-contacts/add-contact',
    'edit-contact':
      '/midas/student/:studentID/other-contacts/edit-contact/:contactID',
  },
  photo: {
    '': '/midas/student/:studentID/photo',
    'edit-photo': '/midas/student/:studentID/photo/edit-photo/:yearID',
  },
  portfolio: {
    '': '/midas/student/:studentID/portfolio',
    'add-evidence': '/midas/student/:studentID/portfolio/add-evidence',
    evidence: '/midas/student/:studentID/portfolio/evidence/:contentUUID',
  },
  profile: '/midas/student/:studentID/profile',
  schedule: {
    '': '/midas/student/:studentID/schedule',
    'add-drop-log': '/midas/student/:studentID/schedule/add-drop-log/:yearID',
    'edit-enrollment':
      '/midas/student/:studentID/schedule/edit-enrollment/:scheduleID',
    'class-page': {
      '': '/midas/student/:studentID/schedule/class-page/:sectionID/:date?',
      'respond-to-question':
        '/midas/student/:studentID/schedule/class-page/:sectionID/:date/respond-to-question/:snippetSectionID',
    },
  },
  'schedule-timeline': '/midas/student/:studentID/schedule-timeline',
  'service-hours': {
    '': '/midas/student/:studentID/service-hours',
    'add-hours': '/midas/student/:studentID/service-hours/add-hours',
    'edit-hours':
      '/midas/student/:studentID/service-hours/edit-hours/:serviceHoursID',
  },
  'standards-grades': '/midas/student/:studentID/standards-grades',
  'state-reporting': {
    '': '/midas/student/:studentID/state-reporting',
    sims: '/midas/student/:studentID/state-reporting/sims',
    'edit-sims':
      '/midas/student/:studentID/state-reporting/sims/edit-sims/:collectionID',
    pims: '/midas/student/:studentID/state-reporting/pims',
    'edit-pims':
      '/midas/student/:studentID/state-reporting/pims/edit-pims/:collectionID',
  },
  'ia-state-reporting': {
    '': '/midas/student/:studentID/ia-state-reporting',
    'edit-sri':
      '/midas/student/:studentID/ia-state-reporting/edit-sri/:collectionID/:enrollmentID',
  },
  'ia-state-reporting-2': {
    '': '/midas/student/:studentID/ia-state-reporting-2',
    'edit-sri':
      '/midas/student/:studentID/ia-state-reporting-2/edit-sri/:collectionID',
  },
  summary: '/midas/student/:studentID/summary',
  'test-scores': {
    '': '/midas/student/:studentID/test-scores',
    'add-test-score': '/midas/student/:studentID/test-scores/add-test-score',
    'edit-test-score':
      '/midas/student/:studentID/test-scores/edit-test-score/:testScoreID',
  },
  transportation: {
    '': '/midas/student/:studentID/transportation',
    'add-bus-route': '/midas/student/:studentID/transportation/add-bus-route',
    'edit-daily-travel-plans':
      '/midas/student/:studentID/transportation/edit-daily-travel-plans/:travelPlanID',
  },
  registration: {
    '': '/midas/student/:studentID/registration',
    'add-submission': '/midas/student/:studentID/registration/add-submission',
    'edit-submission':
      '/midas/student/:studentID/registration/edit-submission/:submissionID',
    'view-submission':
      '/midas/student/:studentID/registration/view-submission/:submissionID',
  },
};

export default student;
