/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const intervention = {
  '': '/midas/settings/intervention',
  'progress-monitors': {
    '': '/midas/settings/intervention/progress-monitors',
    'add-progress-monitor':
      '/midas/settings/intervention/progress-monitors/add-progress-monitor',
    'edit-progress-monitor':
      '/midas/settings/intervention/progress-monitors/edit-progress-monitor/:monitorID',
  },
  'rti-forms': {
    '': '/midas/settings/intervention/rti-forms',
    'add-form': '/midas/settings/intervention/rti-forms/add-form',
    'edit-form': '/midas/settings/intervention/rti-forms/edit-form/:formID',
    'add-section-heading':
      '/midas/settings/intervention/rti-forms/add-section-heading/:formID',
    'preview-questions':
      '/midas/settings/intervention/rti-forms/preview-questions/:formID',
    'edit-section-heading':
      '/midas/settings/intervention/rti-forms/edit-section-heading/:formID',
    'add-question':
      '/midas/settings/intervention/rti-forms/add-question/:formID',
  },
  administration: {
    '': '/midas/settings/intervention/administration',
    'add-intervention':
      '/midas/settings/intervention/administration/add-intervention',
    'edit-intervention':
      '/midas/settings/intervention/administration/edit-intervention/:interventionID',
    'select-user':
      '/midas/settings/intervention/administration/select-user/:interventionID',
    'bulk-assign-users':
      '/midas/settings/intervention/administration/bulk-assign-users',
  },
};

export default intervention;
