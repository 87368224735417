import {useMemo} from 'react';

const PublicNavItem = ({link, isLoggedIn, context, cartCount}) => {
  const route = useMemo(() => {
    if (typeof link.route === 'object') {
      const keyToUse =
        context.roles.publisher.length > 0
          ? 'publisher'
          : context.roles.staff.length > 0
          ? 'staff'
          : 'student';
      return link.route[keyToUse];
    } else {
      return String(link.route ? link.route : '');
    }
  }, [link, context]);

  return route && route.includes('midas') && isLoggedIn ? (
    <li key={route} className="nav-item">
      {link.disabled ? (
        <span className="text-muted nav-link">{link.name}</span>
      ) : (link.name === 'Admin' && context.roles.pdAdministrator.length > 0) ||
        link.name !== 'Admin' ? (
        <a className="nav-link naver" href={route}>
          {link.name}
        </a>
      ) : null}
    </li>
  ) : route && link.name === 'Cart' && isLoggedIn ? (
    <li key={route} className="nav-item">
      {link.disabled ? (
        <span className="text-muted nav-link">{link.name}</span>
      ) : (
        <a className="nav-link naver" href={route}>
          {link.name}
          <span id="cart-badge" className="ml-2 badge badge-info">
            {cartCount}
          </span>
        </a>
      )}
    </li>
  ) : route && !route.includes('midas') && link.name !== 'Cart' ? (
    <li key={route} className="nav-item">
      {link.disabled ? (
        <span className="text-muted nav-link">{link.name}</span>
      ) : (
        <a className="nav-link naver" href={route}>
          {link.name}
        </a>
      )}
    </li>
  ) : null;
};

export default PublicNavItem;
