/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FormattedMessage} from 'react-intl';
import {Form} from 'react-bootstrap';

const FieldLabel = ({id, label, required, className = ''}) => {
  if (!label) {
    return null;
  }
  return (
    <Form.Label id={`${id}-label`} htmlFor={id} className={className}>
      <FormattedMessage {...label} />
      {required && (
        <span className="ml-1 small text-muted">
          <FormattedMessage
            id="label.field-required-indicator"
            description="Indicator appearing next to a form element label if the field is required"
            defaultMessage=" (required)"
          />
        </span>
      )}
    </Form.Label>
  );
};

export default FieldLabel;
