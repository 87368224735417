/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {createContext, useContext, useMemo, useReducer} from 'react';

const initialState = {
  catalogMode: 0,
};

export const SET_CATALOG_MODE = 'SET_CATALOG_MODE';

const reducer = (state, action) => {
  switch (action.type) {
    case SET_CATALOG_MODE:
      return {...state, catalogMode: action.payload};

    default:
      return state;
  }
};

const PublicCatalogContext = createContext(undefined);

export const PublicCatalogContextProvider = ({children}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const value = useMemo(() => {
    return {
      catalogMode: state.catalogMode,
      dispatch,
    };
  }, [state, dispatch]);

  return (
    <PublicCatalogContext.Provider value={value}>
      {children}
    </PublicCatalogContext.Provider>
  );
};

export const usePublicCatalogContext = () => {
  const context = useContext(PublicCatalogContext);
  if (context === undefined) {
    throw new Error(
      'usePublicCatalogContext must be used within a PublicCatalogContextProvider'
    );
  }
  return context;
};
