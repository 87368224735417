/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

// TODO: These links have been broken for a while, and are being transitioned
//  to event management; for now, just use 0 to render the link.
const routeParams = {
  instituteID: 0,
};

const InstitutesSettingsRouteLinkItem = (props) => (
  <RouteLinkItem routeParams={routeParams} {...props} />
);

export default InstitutesSettingsRouteLinkItem;
