/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useNavigationContext} from 'app/NavigationContext';
import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PdCourseSurveysIcon from 'icons/PdCourseSurveysIcon';
import {Badge} from 'react-bootstrap';

const messages = defineMessages({
  title: {id: 'navigation.microcredentialing.pd-course-surveys'},
});
const PdCourseSurveysItem = () => {
  const {courseSurveyNotificationCount} = useNavigationContext();

  return (
    <RouteLinkItem
      iconBefore={PdCourseSurveysIcon}
      route="midas/my/pd-course-surveys"
      title={messages.title}
    >
      {courseSurveyNotificationCount > 0 && (
        <Badge pill variant="primary" className="ml-2 px-2 py-1">
          {courseSurveyNotificationCount}
        </Badge>
      )}
    </RouteLinkItem>
  );
};

export default PdCourseSurveysItem;
