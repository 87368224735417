/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import SchoolIcon from 'icons/SchoolIcon';
import DistrictsItem from './schools/DistrictsItem';
import LevelsItem from './schools/LevelsItem';
import LogosItem from './schools/LogosItem';
import SchoolBoardItem from './schools/SchoolBoardItem';
import SchoolsItem from './schools/SchoolsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.schools'},
  sectionTitle: {id: 'navigation.settings.schools-settings'},
});

const SchoolsMenu = () => (
  <NavigationMenu
    iconBefore={SchoolIcon}
    route="midas/settings/schools"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <DistrictsItem />
    <SchoolBoardItem />
    <SchoolsItem />
    <LevelsItem />
    <LogosItem />
  </NavigationMenu>
);

export default SchoolsMenu;
