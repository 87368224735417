/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import CourseRequestsIcon from 'icons/CourseRequestsIcon';

const messages = defineMessages({
  title: {id: 'navigation.course-requests'},
});

const CourseRequestsItem = () => (
  <StudentRouteLinkItem
    iconBefore={CourseRequestsIcon}
    route="midas/student/course-requests"
    title={messages.title}
  />
);

export default CourseRequestsItem;
