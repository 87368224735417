import {ApiResponseWrapper, Markdown} from 'components/layout/index';
import {useMidasJsonApi} from 'app/hooks';

const PublicFooter = () => {
  const [imageResponse, imageLoading, imageError] = useMidasJsonApi({
    url: `/api/public-preference/footer_image`,
    expectedStatus: [200],
  });

  const [titleResponse, titleLoading, titleError] = useMidasJsonApi({
    url: `/api/public-preference/footer_title`,
    expectedStatus: [200],
  });

  const [addressResponse, addressLoading, addressError] = useMidasJsonApi({
    url: `/api/public-preference/footer_address`,
    expectedStatus: [200],
  });

  const [phoneResponse, phoneLoading, phoneError] = useMidasJsonApi({
    url: `/api/public-preference/footer_contact_phone`,
    expectedStatus: [200],
  });
  const currentYear = new Date().getFullYear();
  return (
    <>
      <ApiResponseWrapper
        error={imageError || titleError || addressError || phoneError}
        isLoading={
          imageLoading || titleLoading || addressLoading || phoneLoading
        }
      >
        <div
          className="mt-4 p-4 footer-container-div"
          style={{
            backgroundColor:
              imageResponse.status === 200
                ? imageResponse.json.preference.backgroundColor
                : '#35495e',
          }}
        >
          <div className="row">
            {imageResponse.status === 200 &&
              imageResponse.json.preference.source && (
                <div className="col-12 col-md-4 mb-3">
                  <img
                    className="footer-image"
                    src={imageResponse.json.preference.source}
                    alt={imageResponse.json.preference.alt}
                    height={
                      imageResponse.json.preference.height
                        ? imageResponse.json.preference.height
                        : ''
                    }
                    width={
                      imageResponse.json.preference.width
                        ? imageResponse.json.preference.width
                        : '100%'
                    }
                    data-pin-nopin={true}
                  />
                </div>
              )}
            <div className="col-12 col-md-8">
              <span className="footer-title-span-container footer-title">
                <strong>
                  {titleResponse.status === 200 && (
                    <span>{titleResponse.json.preference}</span>
                  )}
                </strong>
              </span>
              {addressResponse.status === 200 &&
              addressResponse.json.preference.length > 1 ? (
                <div className="d-md-flex">
                  {addressResponse.json.preference.map((address) => {
                    return (
                      <div className="mr-4 footer-address">
                        <Markdown>{address.address}</Markdown>
                        <p className="mt-0">
                          {address.city}, {address.state} {address.zip}
                        </p>
                      </div>
                    );
                  })}
                </div>
              ) : addressResponse.status === 200 ? (
                <div className="footer-address">
                  <p>{addressResponse.json.preference.address}</p>
                  <p>
                    {addressResponse.json.preference.city},{' '}
                    {addressResponse.json.preference.state}{' '}
                    {addressResponse.json.preference.zip}
                  </p>
                </div>
              ) : null}
              {phoneResponse.status === 200 &&
                phoneResponse.json.preference !== '' && (
                  <p className="footer-phone">
                    <span style={{color: '#e1ba11'}}>Phone: </span>
                    <>{phoneResponse.json.preference}</>
                  </p>
                )}
            </div>
          </div>
        </div>
      </ApiResponseWrapper>
      <footer className="footer mt-3">
        <div className="container">
          <ApiResponseWrapper error={titleError} isLoading={titleLoading}>
            <p className="text-center">
              © Copyright {currentYear}{' '}
              {titleResponse.status === 200 && titleResponse.json.preference}
            </p>
          </ApiResponseWrapper>
        </div>
      </footer>
    </>
  );
};

export default PublicFooter;
