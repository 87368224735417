/**
 * Error subclass specific to MIDAS API errors. Handled specially by the
 * application-level ErrorBoundary to show and/or log details of the
 * API request for debugging.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

class MidasApiError extends Error {
  constructor(url, method, body, response, ...params) {
    super(...params);

    // From MDN: maintains proper stack trace on V8
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, MidasApiError);
    }

    this.name = 'MidasApiError';
    this.url = url;
    this.method = method;
    this.body = body;
    this.response = response;
  }
}

export default MidasApiError;
