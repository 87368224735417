/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import PhotoIcon from 'icons/PhotoIcon';

const messages = defineMessages({
  title: {id: 'navigation.photo'},
});

const PhotoItem = () => (
  <StudentRouteLinkItem
    iconBefore={PhotoIcon}
    route="midas/student/photo"
    title={messages.title}
  />
);

export default PhotoItem;
