/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUsers} from '@fortawesome/pro-duotone-svg-icons';

const StaffIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faUsers} {...props} />
);

export default StaffIcon;
