/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import HealthScreeningIcon from 'icons/HealthScreeningIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-screenings'},
});

const ScreeningsItem = () => (
  <RouteLinkItem
    iconBefore={HealthScreeningIcon}
    route="midas/students/health/health-screenings"
    title={messages.title}
  />
);

export default ScreeningsItem;
