/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import InstructionalMaterialsIcon from 'icons/InstructionalMaterialsIcon';

const messages = defineMessages({
  title: {id: 'navigation.publisher-materials'},
});

const PublisherMaterialsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={InstructionalMaterialsIcon}
    route="midas/staff-member/instructional-materials/publisher-materials"
    title={messages.title}
  />
);

export default PublisherMaterialsItem;
