/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import EmploymentIcon from 'icons/EmploymentIcon';

const messages = defineMessages({
  title: {id: 'navigation.employment'},
});

const EmploymentItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={EmploymentIcon}
    route="midas/staff-member/employment"
    title={messages.title}
  />
);

export default EmploymentItem;
