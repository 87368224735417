/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';

const messages = defineMessages({
  title: {
    id: 'navigation.self-registration.self-registrations',
    defaultMessage: 'Registrations',
  },
});

const SelfRegistrationsItem = () => (
  <RouteLinkItem
    route="midas/self-registration/registrations"
    title={messages.title}
  />
);

export default SelfRegistrationsItem;
