/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages, FormattedMessage} from 'react-intl';
import {useFormikContext} from 'formik';
import {Button} from 'react-bootstrap';
import ButtonSpinnerIcon from 'icons/ButtonSpinnerIcon';

const messages = defineMessages({
  submit: {id: 'button.submit'},
  submitting: {id: 'button.submitting'},
});

const SubmitButton = (
  {
    submitMessage = messages.submit,
    submittingMessage = messages.submitting,
    variant = 'primary',
    type = 'submit',
    disabled = false,
    ...props
  },
  ref
) => {
  const {isSubmitting, isValid, submitCount} = useFormikContext();

  return (
    <Button
      ref={ref}
      type={type}
      variant={isValid || submitCount < 1 ? variant : 'warning'}
      disabled={isSubmitting || disabled}
      {...props}
    >
      {isSubmitting ? (
        <>
          <ButtonSpinnerIcon className="mr-2" />
          <FormattedMessage {...submittingMessage} />
        </>
      ) : (
        <FormattedMessage {...submitMessage} />
      )}
    </Button>
  );
};
SubmitButton.displayName = 'SubmitButton';

export default forwardRef(SubmitButton);
