/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useIntl} from 'react-intl';
import {Markdown} from 'components/layout/index';
import './DocumentationBlock.css';

const DocumentationBlock = ({text}) => {
  const intl = useIntl();
  return (
    <div className="DocumentationBlock mb-4 p-3 bg-light rounded font-weight-light text-dark">
      <Markdown>{intl.formatMessage(text, text.values)}</Markdown>
    </div>
  );
};

export default DocumentationBlock;
