/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faAngleUp} from '@fortawesome/pro-light-svg-icons';

const style = {
  fontSize: '1.8em',
  verticalAlign: '-20%',
};

const UpIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faAngleUp} style={style} {...props} />
);

export default UpIcon;
