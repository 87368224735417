import {Button, Col, Row} from 'react-bootstrap';
import {Markdown} from 'components/layout';
import {useHistory} from 'react-router-dom';
import {useContext, useEffect, useMemo, useState} from 'react';
import {UserContext} from 'app/UserContext';
import {useMidasJsonApi} from 'app/hooks';
import {urlFromRoute} from 'app/routing';
import {FormattedMessage} from 'react-intl';

const imageSize = {
  maxWidth: '175px',
  maxHeight: '175px',
  marginLeft: 'auto',
  marginRight: 'auto',
  display: 'block',
};
const CurriculaInfo = ({curriculum}) => {
  const history = useHistory();
  const [context] = useContext(UserContext);
  const [inProgress, setInProgress] = useState(false);

  const [CurriculumUserResponse] = useMidasJsonApi({
    url: `/api/microcredentials/badge-curriculum-user?curriculumID=${curriculum.id}&userID=${context.userID}`,
    expectedStatus: [200, 422],
  });

  const [, , , requestPatch] = useMidasJsonApi({
    method: 'PATCH',
    url: `/midas/rest/microcredentials/${curriculum.id}`,
    expectedStatus: [200, 422],
    immediate: false,
  });

  const isLoggedIn = useMemo(() => {
    return context.userID !== 0;
  }, [context]);

  useEffect(() => {
    if (CurriculumUserResponse.status === 200) {
      if (CurriculumUserResponse.json.badgeCurriculumUsers.length > 0) {
        setInProgress(true);
      }
    }
  }, [CurriculumUserResponse]);

  const curriculumFileURL = curriculum.fileUrl
    ? curriculum.fileUrl
    : '/images/missing-micro.png';

  const button = useMemo(() => {
    if (inProgress) {
      return (
        <Button
          className="mt-2 mb-2"
          variant="primary"
          onClick={() => {
            history.push(
              urlFromRoute('midas/my/microcredentials/curriculum', {
                curriculumID: curriculum.id,
              })
            );
          }}
        >
          <FormattedMessage id="microcredentials.public.catalog.start-working" />
        </Button>
      );
    } else {
      return (
        <Button
          className="mt-2 mb-2"
          variant="success"
          onClick={() => {
            setInProgress(true);
            requestPatch({
              body: {
                entity: 'badgeCurriculumUser',
              },
            });
          }}
        >
          <FormattedMessage id="microcredentials.public.catalog.earn-this-curriculum" />
        </Button>
      );
    }
  }, [inProgress, requestPatch, history, curriculum]);

  return (
    <Row>
      <Col lg={2}>
        <img
          style={imageSize}
          src={curriculumFileURL}
          alt="Curriculum Icon"
          className="mb-4"
        />
      </Col>
      <Col>
        <div className="d-flex">
          <h5>{curriculum.name}</h5>
        </div>
        {isLoggedIn && <>{button}</>}
        <p>
          <Markdown>{curriculum.description}</Markdown>
        </p>
      </Col>
    </Row>
  );
};

export default CurriculaInfo;
