/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import './FilterBlock.css';

const FilterBlock = ({children}) => (
  <div className="FilterBlock my-4 p-3 bg-light rounded">{children}</div>
);

export default FilterBlock;
