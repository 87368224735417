/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import PdApprovalsIcon from 'icons/PdApprovalsIcon';

const messages = defineMessages({
  title: {id: 'navigation.my.pd-approvals'},
});
const PdApprovalsItem = () => {
  return (
    <RouteLinkItem
      iconBefore={PdApprovalsIcon}
      route="midas/my/pd-approvals"
      title={messages.title}
    />
  );
};

export default PdApprovalsItem;
