/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import HealthConditionIcon from 'icons/HealthConditionIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-conditions'},
});

const ConditionsItem = () => (
  <StudentRouteLinkItem
    iconBefore={HealthConditionIcon}
    route="midas/student/health/conditions"
    title={messages.title}
  />
);

export default ConditionsItem;
