/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ProfessionalLearningIcon from 'icons/ProfessionalLearningIcon';

const messages = defineMessages({
  title: {id: 'navigation.pd-courses'},
});

const PdCoursesItem = () => (
  <RouteLinkItem
    iconBefore={ProfessionalLearningIcon}
    route="midas/staff/pd-courses"
    title={messages.title}
  />
);

export default PdCoursesItem;
