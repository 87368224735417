/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const equipment = {
  '': '/midas/students/equipment',
  'add-equipment-piece': '/midas/students/equipment/add-equipment-piece',
  'edit-equipment-piece':
    '/midas/students/equipment/edit-equipment-piece/:equipmentPieceID',
  'check-piece-in-or-out':
    '/midas/students/equipment/check-piece-in-or-out/:equipmentPieceID',
  'edit-checkout': '/midas/students/equipment/edit-checkout/:equipmentPieceID',
};

export default equipment;
