/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useNavigationContext} from 'app/NavigationContext';
import AuthenticatedUserMenu from './menu/AuthenticatedUserMenu';
import ClassroomMenu from './menu/ClassroomMenu';
import EventMenu from './menu/EventMenu';
import GuardianMenu from './menu/GuardianMenu';
import StaffMemberMenu from './menu/StaffMemberMenu';
import StudentMenu from './menu/StudentMenu';

/**
 * The menus returned here are not rooted at a single location within the
 * navigation hierarchy: they are associated with pages that may be directly
 * linked from multiple places within the application, where the behavior of
 * the "go back" item at the top of the nav menu would be expected to return
 * the user to their previous menu, not necessarily the menu's "parent" in
 * the usual hierarchy.
 *
 * This behavior is atypical, and can be confusing for users. Consequently,
 * it is only used for menus such as student detail, where links to students
 * may be found in the directory, from scheduling pages, a teacher classroom,
 * etc. Most menus should appear as ordinary children of other menus.
 *
 * These menus may require special accommodation in the NavigationContext to
 * set up a sane default navigation stack in the case of a full-page reload
 * or direct bookmark link.
 */
const DetachedMenus = () => {
  const {stack} = useNavigationContext();
  if (stack.length === 0) {
    // do not render detached menus when at the root nav level
    return null;
  }

  return (
    <>
      <AuthenticatedUserMenu />
      <ClassroomMenu />
      <EventMenu />
      <GuardianMenu />
      <StaffMemberMenu />
      <StudentMenu />
    </>
  );
};

export default DetachedMenus;
