/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {PUSH_MENU, useNavigationContext} from 'app/NavigationContext';
import {useFormatMessage} from 'app/hooks';
import {OverlayTrigger, Tooltip} from 'react-bootstrap';
import RouteLink from 'components/routing/RouteLink';
import MidasLogo from 'components/MidasLogo';
import './ProductNavigationHome.css';

const messages = defineMessages({
  title: {id: 'navigation.home'},
});

const ProductNavigationHome = () => {
  const title = useFormatMessage(messages.title);
  const {dispatch, dismissMobileSidebar} = useNavigationContext();

  const handleClick = () => {
    // home click should always reset to the root menu
    dispatch({type: PUSH_MENU, payload: 'midas'});
    dismissMobileSidebar();
  };

  return (
    <OverlayTrigger
      overlay={<Tooltip id="navigation-home">{title}</Tooltip>}
      placement="right"
    >
      <RouteLink
        route="midas"
        title={title}
        className="ProductNavigationHome"
        onClick={handleClick}
      >
        <MidasLogo width="46px" height="46px" stroke />
      </RouteLink>
    </OverlayTrigger>
  );
};

export default ProductNavigationHome;
