/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import ImmunizationIcon from 'icons/ImmunizationIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-immunizations'},
});

const ImmunizationsItem = () => (
  <StudentRouteLinkItem
    iconBefore={ImmunizationIcon}
    route="midas/student/health/immunizations"
    title={messages.title}
  />
);

export default ImmunizationsItem;
