/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const microcredentialing = {
  '': '/midas/microcredentialing',
  review: {
    '': '/midas/microcredentialing/review',
    'microcredential-review': {
      '':
        '/midas/microcredentialing/review/microcredential-review/:badgeUserID',
      evidence:
        '/midas/microcredentialing/review/microcredential-review/:badgeUserID/evidence/:badgeUserEvidenceID',
      reflection:
        '/midas/microcredentialing/review/microcredential-review/:badgeUserID/reflection/:badgeUserReflectionID',
    },
  },
  resources: {
    '': '/midas/microcredentialing/resources',
    'add-resource': '/midas/microcredentialing/resources/add-resource',
    'edit-resource':
      '/midas/microcredentialing/resources/edit-resource/:resourceID',
  },
  stacks: {
    '': '/midas/microcredentialing/stacks',
    'add-stack': '/midas/microcredentialing/stacks/add-stack',
    'edit-stack': '/midas/microcredentialing/stacks/edit-stack/:stackID',
  },
  approvals: {
    '': '/midas/microcredentialing/approvals',
    'add-approval-area':
      '/midas/microcredentialing/approvals/add-approval-area',
    'edit-approval-area':
      '/midas/microcredentialing/approvals/edit-approval-area/:approvalAreaID',
  },
  'reflection-templates': {
    '': '/midas/microcredentialing/reflection-templates',
    'add-template':
      '/midas/microcredentialing/reflection-templates/add-template',
    'edit-template':
      '/midas/microcredentialing/reflection-templates/edit-template/:templateID',
    'add-prompt':
      '/midas/microcredentialing/reflection-templates/add-prompt/:templateID',
    'edit-prompt':
      '/midas/microcredentialing/reflection-templates/edit-prompt/:promptID',
  },
  microcredentials: {
    '': '/midas/microcredentialing/microcredentials',
    microcredential:
      '/midas/microcredentialing/microcredentials/microcredential/:badgeID',
    'add-microcredential':
      '/midas/microcredentialing/microcredentials/add-microcredential',
    'edit-microcredential': {
      '':
        '/midas/microcredentialing/microcredentials/edit-microcredential/:microcredentialID',
      'edit-evidence-options-with-categories':
        '/midas/microcredentialing/microcredentials/edit-microcredential/:microcredentialID/edit-evidence-options-with-categories/:categoryID',
      'edit-evidence-options':
        '/midas/microcredentialing/microcredentials/edit-microcredential/:microcredentialID/edit-evidence-options',
    },
    'add-evidence-options':
      '/midas/microcredentialing/microcredentials/add-evidence-options/:microcredentialID',
    'add-evidence-options-with-categories':
      '/midas/microcredentialing/microcredentials/add-evidence-options-with-categories/:microcredentialID',
    notify:
      '/midas/microcredentialing/microcredentials/notify/:microcredentialID',
  },
  submissions: {
    '': '/midas/microcredentialing/submissions',
  },
  prepayments: {
    '': '/midas/microcredentialing/prepayments',
    'new-prepayment': '/midas/microcredentialing/prepayments/new-prepayment',
    prepayment: '/midas/microcredentialing/prepayments/prepayment/:orderItemID',
  },
  'coaching-subjects': {
    '': '/midas/microcredentialing/coaching-subjects',
  },
  'curricula-and-pathways': {
    '': '/midas/microcredentialing/curricula-and-pathways',
    curricula: '/midas/microcredentialing/curricula-and-pathways/curricula',
    curriculum:
      '/midas/microcredentialing/curricula-and-pathways/curriculum/:badgeCurriculumID',
    pathways: '/midas/microcredentialing/curricula-and-pathways/pathways',
    pathway:
      '/midas/microcredentialing/curricula-and-pathways/pathway/:badgePathwayID',
  },
  classrooms: {
    '': '/midas/microcredentialing/classrooms',
    students: {
      '': '/midas/microcredentialing/classrooms/students/:sectionID',
      microcredentials: {
        '':
          '/midas/microcredentialing/classrooms/students/:sectionID/microcredentials/:userID',
        microcredential:
          '/midas/microcredentialing/classrooms/students/:sectionID/microcredentials/:userID/microcredential/:badgeUserID',
      },
    },
  },
};

export default microcredentialing;
