/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {HeadingItem} from '@atlaskit/side-navigation';
import {useUserContext} from 'app/UserContext';
import {useStaffName} from 'app/hooks';
import NavigationMenu from './NavigationMenu';
import AdHocAttendanceItem from './classroom/AdHocAttendanceItem';
import AttendanceItem from './classroom/AttendanceItem';
import CalendarItem from './classroom/CalendarItem';
import ChangeClassItem from './classroom/ChangeClassItem';
import ClassroomItem from './classroom/ClassroomItem';
import GradebookItem from './classroom/GradebookItem';
import MealCountItem from './classroom/MealCountItem';
import MeetingAttendanceItem from './classroom/MeetingAttendanceItem';
import ReportCardCommentsItem from './classroom/ReportCardCommentsItem';
import ResourcesItem from './classroom/ResourcesItem';
import RosterItem from './classroom/RosterItem';
import PdRosterItem from './classroom/PdRosterItem';
import ScheduleItem from './classroom/ScheduleItem';
import SeatingChartItem from './classroom/SeatingChartItem';
import SectionGroupsItem from './classroom/SectionGroupsItem';

const messages = defineMessages({
  title: {id: 'navigation.classroom'},
});

const ClassroomMenu = () => {
  const [{classroom}] = useUserContext();
  const name = useStaffName(classroom.staffID);

  return (
    <NavigationMenu route="midas/classroom" title={messages.title} detached>
      <HeadingItem>{name}</HeadingItem>
      <ChangeClassItem />
      <AttendanceItem />
      <MeetingAttendanceItem />
      <AdHocAttendanceItem />
      <GradebookItem />
      <RosterItem />
      <PdRosterItem />
      <ScheduleItem />
      <SectionGroupsItem />
      <SeatingChartItem />
      <ClassroomItem />
      <ResourcesItem />
      <CalendarItem />
      <MealCountItem />
      <ReportCardCommentsItem />
    </NavigationMenu>
  );
};

export default ClassroomMenu;
