/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useMemo} from 'react';
import {wrapNonLocalizedMessage} from 'app/intl';
import {useUsers} from './index';
import {lastFirstAscSort} from 'app/sort/user';

const useUserOptions = (userIDs, sort = lastFirstAscSort) => {
  const users = useUsers(userIDs, sort);

  return useMemo(() => {
    const options = [];
    for (const user of users.values()) {
      options.push({
        value: user.id,
        label: wrapNonLocalizedMessage(`${user.firstName} ${user.lastName}`),
      });
    }
    return options;
  }, [users]);
};

export default useUserOptions;
