/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const schools = {
  '': '/midas/settings/schools',
  districts: {
    '': '/midas/settings/schools/districts',
    'add-district': '/midas/settings/schools/districts/add-district',
    'edit-district':
      '/midas/settings/schools/districts/edit-district/:districtID',
    'add-school': '/midas/settings/schools/districts/add-school/:districtID',
    'edit-school': '/midas/settings/schools/districts/edit-school/:schoolID',
  },
  'school-board': {
    '': '/midas/settings/schools/school-board',
    'add-member':
      '/midas/settings/schools/school-board/add-member/:schoolBoardID',
    'edit-member': '/midas/settings/schools/school-board/edit-member/:memberID',
  },
  schools: {
    '': '/midas/settings/schools/schools',
    'add-school': '/midas/settings/schools/schools/add-school/:isDistrict',
    'edit-school': '/midas/settings/schools/schools/edit-school/:schoolID',
    'edit-lea': '/midas/settings/schools/schools/edit-lea/:isDistrict',
    'export-schools': '/midas/settings/schools/schools/export-schools',
  },
  levels: {
    '': '/midas/settings/schools/levels',
    'add-school-level': '/midas/settings/schools/levels/add-school-level',
    'add-grade-level': '/midas/settings/schools/levels/add-grade-level',
  },
  logos: '/midas/settings/schools/logos',
  'new-client': '/midas/settings/schools/new-client',
};

export default schools;
