/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import GraduationIcon from 'icons/GraduationIcon';

const messages = defineMessages({
  title: {id: 'navigation.graduation-requirements'},
});

const GraduationRequirementsItem = () => (
  <StudentRouteLinkItem
    iconBefore={GraduationIcon}
    route="midas/student/graduation-requirements"
    title={messages.title}
  />
);

export default GraduationRequirementsItem;
