/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faHandHoldingWater} from '@fortawesome/pro-duotone-svg-icons';

const GrowthResourcesIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faHandHoldingWater} {...props} />
);

export default GrowthResourcesIcon;
