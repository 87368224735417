/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import ReportsIcon from 'icons/ReportsIcon';

const messages = defineMessages({
  title: {id: 'navigation.reports.reports-index'},
});

const ReportsIndexItem = () => (
  <RouteLinkItem
    iconBefore={ReportsIcon}
    route="midas/reports/reports-index"
    title={messages.title}
  />
);

export default ReportsIndexItem;
