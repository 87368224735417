/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';
import {PUSH_MENU, useNavigationContext} from 'app/NavigationContext';

const useNavigationMenu = (menuID) => {
  const {dispatch} = useNavigationContext();

  useEffect(() => {
    dispatch({
      type: PUSH_MENU,
      payload: menuID,
    });

    // Only update the navigation menu once on component mount.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useNavigationMenu;
