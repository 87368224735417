/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import ScheduleIcon from 'icons/ScheduleIcon';

const messages = defineMessages({
  title: {id: 'navigation.schedule-timeline'},
});

const ScheduleTimelineItem = () => (
  <StudentRouteLinkItem
    iconBefore={ScheduleIcon}
    route="midas/student/schedule-timeline"
    title={messages.title}
  />
);

export default ScheduleTimelineItem;
