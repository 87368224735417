/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import InstitutesSettingsRouteLinkItem from './InstitutesSettingsRouteLinkItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.institutes.administrators'},
});

const AdministratorsItem = () => (
  <InstitutesSettingsRouteLinkItem
    route="midas/settings/institutes/administrators"
    title={messages.title}
  />
);

export default AdministratorsItem;
