/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTasks} from '@fortawesome/pro-duotone-svg-icons';

const StandardsIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faTasks} {...props} />
);

export default StandardsIcon;
