/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {defineMessages} from 'react-intl';
import IconActionButton from './IconActionButton';
import AddIcon from 'icons/AddIcon';

const messages = defineMessages({
  add: {id: 'button.add'},
});

const AddActionButton = ({message = messages.add, ...props}, ref) => (
  <IconActionButton ref={ref} icon={AddIcon} message={message} {...props} />
);
AddActionButton.displayName = 'AddActionButton';

export default forwardRef(AddActionButton);
