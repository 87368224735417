/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faClipboardList} from '@fortawesome/pro-duotone-svg-icons';

const RosterIcon = ({icon, ...props}) => (
  <FontAwesomeIcon icon={faClipboardList} {...props} />
);

export default RosterIcon;
