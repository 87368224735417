/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {DateTime} from 'luxon';

const dobAscSort = (a, b) => {
  if (!a.birthDate || !b.birthDate) {
    return 0;
  }
  const aDate = DateTime.fromISO(a.birthDate);
  const bDate = DateTime.fromISO(b.birthDate);
  if (aDate < bDate) {
    return -1;
  } else if (aDate > bDate) {
    return 1;
  }
  return 0;
};

export default dobAscSort;
