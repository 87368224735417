/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';

const InlineActionButton = (
  {message, variant = 'secondary', type = 'button', className = '', ...props},
  ref
) => {
  return (
    <Button
      ref={ref}
      variant={variant}
      type={type}
      className={`d-flex flex-column align-items-center ${className}`}
      {...props}
    >
      {message === undefined ? null : <FormattedMessage {...message} />}
    </Button>
  );
};
InlineActionButton.displayName = 'InlineActionButton';

export default forwardRef(InlineActionButton);
