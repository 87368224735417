/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import InstructionalMaterialsIcon from 'icons/InstructionalMaterialsIcon';

const messages = defineMessages({
  title: {id: 'navigation.instructional-materials.public-catalog'},
});

const PublicCatalogItem = () => (
  <RouteLinkItem
    iconBefore={InstructionalMaterialsIcon}
    route="rims"
    title={messages.title}
  />
);

export default PublicCatalogItem;
