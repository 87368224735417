/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useFeature} from 'app/hooks';
import {HIDE_STAFF_NAVIGATION} from 'app/features';
import NavigationMenu from './NavigationMenu';
import StaffIcon from 'icons/StaffIcon';
import AddItem from './staff/AddItem';
import AttendanceItem from './staff/AttendanceItem';
import CurrentStaffMemberItem from './staff/CurrentStaffMemberItem';
import GroupsItem from './staff/GroupsItem';
import InviteItem from './staff/InviteItem';
import PdApprovalQueueItem from './staff/PdApprovalQueueItem';
import PdCoursesItem from './staff/PdCoursesItem';
import PdPlaylistsItem from './staff/PdPlaylistsItem';
import SearchItem from './staff/SearchItem';
import TicketQueueItem from './staff/TicketQueueItem';

const messages = defineMessages({
  title: {id: 'navigation.staff'},
});

const StaffMenu = () => {
  const hideNavigation = useFeature(HIDE_STAFF_NAVIGATION);
  if (hideNavigation) {
    return null;
  }

  return (
    <NavigationMenu
      iconBefore={StaffIcon}
      route="midas/staff"
      title={messages.title}
    >
      <CurrentStaffMemberItem />
      <SearchItem />
      <AddItem />
      <GroupsItem />
      <InviteItem />
      <AttendanceItem />
      <PdCoursesItem />
      <PdPlaylistsItem />
      <PdApprovalQueueItem />
      <TicketQueueItem />
    </NavigationMenu>
  );
};

export default StaffMenu;
