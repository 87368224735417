/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {useFeature} from 'app/hooks';
import {HIDE_STUDENT_NAVIGATION} from 'app/features';
import NavigationMenu from './NavigationMenu';
import StudentsIcon from 'icons/StudentsIcon';
import ActivitiesItem from './students/ActivitiesItem';
import AddItem from './students/AddItem';
import AdHocScheduleItem from './students/AdHocScheduleItem';
import AlertsItem from './students/AlertsItem';
import AttendanceItem from './students/AttendanceItem';
import BehaviorMenu from './students/BehaviorMenu';
import CoursesItem from './students/CoursesItem';
import CurrentStudentItem from './students/CurrentStudentItem';
import EquipmentItem from './students/EquipmentItem';
import HealthMenu from './students/HealthMenu';
import HomeroomsItem from './students/HomeroomsItem';
import HousesAndTeamsItem from './students/HousesAndTeamsItem';
import MasterCatalogItem from './students/MasterCatalogItem';
import MasterSchedulingMenu from './students/MasterSchedulingMenu';
import ResponseToInterventionItem from './students/ResponseToInterventionItem';
import SearchItem from './students/SearchItem';
import SectionsItem from './students/SectionsItem';
import TransactionsItem from './students/TransactionsItem';

const messages = defineMessages({
  title: {id: 'navigation.students'},
});

const StudentsMenu = () => {
  const hideNavigation = useFeature(HIDE_STUDENT_NAVIGATION);
  if (hideNavigation) {
    return null;
  }

  return (
    <NavigationMenu
      iconBefore={StudentsIcon}
      route="midas/students"
      title={messages.title}
    >
      <CurrentStudentItem />
      <SearchItem />
      <AddItem />
      <AttendanceItem />
      <ActivitiesItem />
      <AlertsItem />
      <BehaviorMenu />
      <CoursesItem />
      <EquipmentItem />
      <HealthMenu />
      <HomeroomsItem />
      <HousesAndTeamsItem />
      <MasterCatalogItem />
      <MasterSchedulingMenu />
      <SectionsItem />
      <ResponseToInterventionItem />
      <AdHocScheduleItem />
      <TransactionsItem />
    </NavigationMenu>
  );
};

export default StudentsMenu;
