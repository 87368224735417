/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useNavigationContext} from 'app/NavigationContext';
import AuthenticatedUserItem from './item/AuthenticatedUserItem';
import HelpItem from './item/HelpItem';
import ProductNavigationHome from './item/ProductNavigationHome';
// import SearchItem from './item/SearchItem';
import './ProductNavigation.css';

const ProductNavigation = () => {
  const {nonProduction} = useNavigationContext();

  return (
    <div
      className={`ProductNavigation ${nonProduction ? 'NonProduction' : ''}`}
    >
      <span role="presentation" className="nav-shadow" />
      <div className="top-items">
        <ProductNavigationHome />
        {/*<SearchItem />*/}
      </div>
      <div className="bottom-items">
        <HelpItem />
        <AuthenticatedUserItem />
      </div>
    </div>
  );
};

export default ProductNavigation;
