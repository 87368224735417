/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const attendance = {
  '': '/midas/settings/attendance',
  'attendance-period-rules': {
    '': '/midas/settings/attendance/attendance-period-rules',
    'add-period-to-period-copying-rule':
      '/midas/settings/attendance/attendance-period-rules/add-period-to-period-copying-rule',
    'edit-period-rules':
      '/midas/settings/attendance/attendance-period-rules/edit-period-rules/:periodID',
  },
  'attendance-tracks': {
    '': '/midas/settings/attendance/attendance-tracks',
    'add-track': '/midas/settings/attendance/attendance-tracks/add-track',
    'edit-track':
      '/midas/settings/attendance/attendance-tracks/edit-track/:trackID',
    'add-track-rule':
      '/midas/settings/attendance/attendance-tracks/add-track-rule',
    'bulk-assign-users':
      '/midas/settings/attendance/attendance-tracks/bulk-assign-users',
    'select-user':
      '/midas/settings/attendance/attendance-tracks/select-user/:parentID',
    'edit-tracks-inner':
      '/midas/settings/attendance/attendance-tracks/edit-tracks-inner/:id/:class',
  },
  'attendance-triggers': {
    '': '/midas/settings/attendance/attendance-triggers',
    'add-attendance-trigger':
      '/midas/settings/attendance/attendance-triggers/add-attendance-trigger',
    'edit-attendance-trigger':
      '/midas/settings/attendance/attendance-triggers/edit-attendance-trigger/:triggerID',
  },
  'groups-and-marks': {
    '': '/midas/settings/attendance/groups-and-marks',
    'add-attendance-group':
      '/midas/settings/attendance/groups-and-marks/add-attendance-group',
    'select-attendance-group':
      '/midas/settings/attendance/groups-and-marks/select-attendance-group',
    'add-attendance-mark':
      '/midas/settings/attendance/groups-and-marks/add-attendance-mark',
    'edit-mark':
      '/midas/settings/attendance/groups-and-marks/edit-mark/:markID',
    'select-mark':
      '/midas/settings/attendance/groups-and-marks/select-mark/:markID',
    'edit-group':
      '/midas/settings/attendance/groups-and-marks/edit-group/:groupID',
    'edit-course-settings':
      '/midas/settings/attendance/groups-and-marks/edit-course-settings',
  },
};

export default attendance;
