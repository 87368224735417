/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import BidCyclesIcon from 'icons/BidCyclesIcon';

const messages = defineMessages({
  title: {id: 'navigation.intent-to-bid'},
});

const IntentToBidItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={BidCyclesIcon}
    route="midas/staff-member/instructional-materials/intent-to-bid"
    title={messages.title}
  />
);

export default IntentToBidItem;
