/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef} from 'react';
import {FormattedMessage} from 'react-intl';
import {Button} from 'react-bootstrap';
import AddIcon from 'icons/AddIcon';

const NewButton = ({text, ...props}, ref) => (
  <Button ref={ref} variant="primary" type="button" {...props}>
    <AddIcon className="mr-2" />
    <FormattedMessage {...text} />
  </Button>
);
NewButton.displayName = 'NewButton';

export default forwardRef(NewButton);
