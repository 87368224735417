/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import GraduationIcon from 'icons/GraduationIcon';
import ClassRankItem from './academics/ClassRankItem';
import CourseCatalogsItem from './academics/CourseCatalogsItem';
import CourseGroupsItem from './academics/CourseGroupsItem';
import GradesItem from './academics/GradesItem';
import GradingCommentsItem from './academics/GradingCommentsItem';
import GradingPeriodsItem from './academics/GradingPeriodsItem';
import GradingScalesItem from './academics/GradingScalesItem';
import GradingSettingsItem from './academics/GradingSettingsItem';
import GraduationRequirementsItem from './academics/GraduationRequirementsItem';
import HonorRollItem from './academics/HonorRollItem';
import PerYearRequirementsItem from './academics/PerYearRequirementsItems';
import PrerequisitesItem from './academics/PrerequisitesItems';
import SequenceItem from './academics/SequenceItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.academics'},
  sectionTitle: {id: 'navigation.settings.academics-settings'},
});

const AcademicsMenu = () => (
  <NavigationMenu
    iconBefore={GraduationIcon}
    route="midas/settings/academics"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ClassRankItem />
    <CourseCatalogsItem />
    <CourseGroupsItem />
    <GradesItem />
    <GradingCommentsItem />
    <GradingScalesItem />
    <GradingSettingsItem />
    <GradingPeriodsItem />
    <GraduationRequirementsItem />
    <HonorRollItem />
    <PerYearRequirementsItem />
    <PrerequisitesItem />
    <SequenceItem />
  </NavigationMenu>
);

export default AcademicsMenu;
