/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const grants = {
  '': '/midas/grants',
  grants: {
    '': '/midas/grants/grants',
    'add-grant': '/midas/grants/grants/add-grant',
    'edit-grant': '/midas/grants/grants/edit-grant/:grantID',
    detail: {
      '': '/midas/grants/grants/detail/:grantID',
      'add-award': '/midas/grants/grants/detail/:grantID/add-award',
      'edit-award': '/midas/grants/grants/detail/:grantID/edit-award/:awardID',
      'view-award-evidence':
        '/midas/grants/grants/detail/:grantID/view-award-evidence/:awardID/:evidenceID',
      'add-criterion':
        '/midas/grants/grants/detail/:grantID/add-criterion/:compliancePeriodID',
      'edit-criterion':
        '/midas/grants/grants/detail/:grantID/edit-criterion/:criterionID',
    },
  },
  categories: {
    '': '/midas/grants/categories',
    'add-category': '/midas/grants/categories/add-category',
    'edit-category': '/midas/grants/categories/edit-category/:categoryID',
  },
  programs: {
    '': '/midas/grants/programs',
    'add-program': '/midas/grants/programs/add-program',
    'edit-program': '/midas/grants/programs/edit-program/:programID',
  },
  awards: {
    '': '/midas/grants/awards',
    detail: {
      '': '/midas/grants/awards/detail/:awardID',
      'add-criterion-response':
        '/midas/grants/awards/detail/:awardID/add-criterion-response/:criterionID',
      'edit-criterion-response':
        '/midas/grants/awards/detail/:awardID/edit-criterion-response/:responseID',
      'view-criterion-response':
        '/midas/grants/awards/detail/:awardID/view-criterion-response/:responseID',
    },
    permissions: {
      '': '/midas/grants/awards/permissions/:awardID',
    },
  },
  evidence: {
    '': '/midas/grants/evidence',
    'add-evidence': '/midas/grants/evidence/add-evidence',
    detail: '/midas/grants/evidence/detail/:contentUUID',
    content: '/midas/grants/evidence/content/:uuid/:filename',
  },
  'award-workflows': {
    '': '/midas/grants/award-workflows',
    'add-workflow': '/midas/grants/award-workflows/add-workflow',
    'edit-workflow': '/midas/grants/award-workflows/edit-workflow/:workflowID',
  },
};

export default grants;
