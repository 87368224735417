/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const institute = {
  '': '/midas/institute/:instituteID',
  form: {
    evaluation:
      '/midas/institute/:instituteID/form/:formID/evaluation/:panelInstanceID/:submissionID?',
  },
};

export default institute;
