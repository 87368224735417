/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useRef, useMemo} from 'react';
import {useIntl} from 'react-intl';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import ReactSelect, {components} from 'react-select';
import LocalizeOptions from 'components/form/field/LocalizeOptions';
import {isEmpty} from 'lodash';

/* react-select uses the actual Option objects as its internal value but, for
 * consistency with other form element types and to enable Yup validation schemas
 * to operate on primitive values, we need to convert to and from the either string
 * value (if multiple is false) or the array of string values (if multiple is true).
 */
const collectSelectedOptions = (selectedOptions, value, multiple, options) => {
  if (value === undefined || value === null) {
    return;
  }
  for (const option of options) {
    if (option.hasOwnProperty('value')) {
      if (multiple) {
        if (value.indexOf(option.value) >= 0) {
          selectedOptions.push(option);
        }
      } else {
        if (option.value === value) {
          selectedOptions.push(option);
        }
      }
    } else if (option.hasOwnProperty('options')) {
      collectSelectedOptions(selectedOptions, value, multiple, option.options);
    }
  }
};

const OptionWithDescription = ({children, ...props}) => (
  <components.Option {...props}>
    {props.data.label}
    {props.data.description && (
      <span className="description">{props.data.description}</span>
    )}
  </components.Option>
);

const SelectField = (
  {
    id,
    name,
    label,
    placeholder,
    options,
    help,
    required = false,
    disabled = false,
    multiple = false,
    onCustomChange, // added a new onCustomChange prop just ot get the value of select field without overriding the existing onChange function
    setMultiValues, // add a new setMultiValues prop so that we can adjust the select visual when using multi on user select
    unfilteredSlicedOptions, // add a new unfilteredSlicedOptions prop so that it can be searched for existing items in multi select cases
    ...props
  },
  ref
) => {
  const intl = useIntl();

  const [{onBlur, value}, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const localizedOptions = useMemo(() => {
    return LocalizeOptions(intl.formatMessage, options);
  }, [options, intl]);

  const isInvalid = meta.error && true;

  const selectedOptions = [];
  collectSelectedOptions(selectedOptions, value, multiple, localizedOptions);

  const onChange = (changedOptions) => {
    if (!changedOptions) {
      setFieldValue(name, multiple ? [] : '');
    } else if (multiple) {
      const newValue = changedOptions.map((option) => option.value);
      const newLabel = changedOptions.map((option) => option.label);

      setFieldValue(name, newValue);
      // Since unfilteredSlicedOptions is only getting used for CurrentContextUserSelectField.js. We have to ensures
      // that unfilteredSlicedOptions must be set, and it must be an array before attempting to call the find method.
      if (Array.isArray(unfilteredSlicedOptions)) {
        const newValues = [];
        for (let key in newValue) {
          const existingKey = unfilteredSlicedOptions.find(
            (x) => x.value === newValue[key]
          );
          if (isEmpty(existingKey)) {
            newValues.push({
              value: newValue[key],
              label: {
                id: 'server-message',
                values: {
                  message: newLabel[key],
                },
              },
            });
          }
        }
        setMultiValues(newValues);
      }
    } else {
      setFieldValue(name, changedOptions.value);
      if (onCustomChange) {
        onCustomChange(changedOptions);
      }
    }
  };

  /* The blur event on the react-select component is for its "valueContainer"
   * component, which is its own input element, and not the hidden form element
   * it creates to store the actual value. To allow for field validation on blur,
   * we must intercept this event and re-target it to the proper Formik path.
   */
  const handleOnBlur = (event) => {
    onBlur(name)(event);
  };

  /* react-select uses CSS-in-JS, which means it completely ignores just about all
   * of the built-in Bootstrap styles, and we have to hard-code equivalent rules
   * in these JS functions to maintain a consistent visual look and feel for all
   * form elements in the app. Note that any time Bootstrap is updated, one or more
   * of these functions may need to be updated as well.
   */
  const customStyles = {
    control: (provided, state) => {
      return {
        ...provided,
        '&:hover': {},
        borderColor: isInvalid
          ? '#dc3545'
          : state.isFocused
          ? '#80bdff'
          : '#ced4da',
        boxShadow: state.isFocused
          ? isInvalid
            ? '0 0 0 0.2rem rgba(220, 53, 69, 0.25)'
            : '0 0 0 0.2rem rgba(0, 123, 255, 0.25)'
          : 'none',
        backgroundColor: state.isDisabled ? '#e9ecef' : '#fff',
        transition:
          'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      };
    },
    valueContainer: (provided) => {
      return isInvalid
        ? {
            ...provided,
            paddingRight: 'calc(1.5em + 0.75rem)',
            backgroundImage:
              "url(\"data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e\")",
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
            backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)',
          }
        : provided;
    },
    placeholder: (provided) => {
      return {
        ...provided,
        color: '#6c757d',
      };
    },
    singleValue: (provided) => {
      return {
        ...provided,
        color: '#495057',
      };
    },
    option: (provided, state) => {
      return {
        ...provided,
        '& span.description': {
          fontSize: '0.8em',
          color: state.isSelected ? 'rgba(255, 255, 255, 0.8)' : '#6c757d',
          display: 'block',
        },
      };
    },
  };

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <ReactSelect
        ref={ref}
        id={id}
        name={name}
        placeholder={
          placeholder && intl.formatMessage(placeholder, placeholder.values)
        }
        options={localizedOptions}
        value={selectedOptions}
        onChange={onChange}
        onBlur={handleOnBlur}
        styles={customStyles}
        components={{Option: OptionWithDescription}}
        aria-required={required}
        isClearable={!required || multiple}
        isDisabled={isSubmitting || disabled}
        isMulti={multiple}
        aria-invalid={isInvalid}
        aria-errormessage={isInvalid ? `${id}-error` : undefined}
        aria-labelledby={label && `${id}-label`}
        aria-describedby={help && `${id}-help`}
        {...props}
      />
    </FieldWrapper>
  );
};
SelectField.displayName = 'SelectField';

export default forwardRef(SelectField);
