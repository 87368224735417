/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import LeaveRequestsIcon from 'icons/LeaveRequestsIcon';

const messages = defineMessages({
  title: {id: 'navigation.leave-requests'},
});

const LeaveRequestsItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={LeaveRequestsIcon}
    route="midas/staff-member/leave-requests"
    title={messages.title}
  />
);

export default LeaveRequestsItem;
