/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useState} from 'react';
import {ButtonItem} from '@atlaskit/side-navigation';
import {useNavigationContext} from 'app/NavigationContext';
import {Form, FormValuesChangeHandler, SelectField} from 'components/form';
import SelectIcon from 'components/navigation/side-navigation/icon/SelectIcon';
import './PickerItem.css';

const PickerForm = ({options, value, onChange, onMenuClose}) => (
  <Form initialValues={{picker: value}}>
    <FormValuesChangeHandler onChange={onChange} />
    <div className="SideNavigationPicker">
      <span className="SideNavigationPickerBackground" />
      <SelectField
        name="picker"
        options={options}
        required={true}
        controlShouldRenderValue={false}
        autoFocus={true}
        openMenuOnFocus={true}
        blurInputOnSelect={false}
        menuIsOpen={true}
        onMenuClose={onMenuClose}
      />
    </div>
  </Form>
);

const PickerItem = ({options, value, onChange, children, ...props}) => {
  const {mobileSidebarNavVisible} = useNavigationContext();
  const [pickerOpen, setPickerOpen] = useState(false);
  const [canHidePicker, setCanHidePicker] = useState(false);

  useEffect(() => {
    if (!mobileSidebarNavVisible) {
      // Unmount form if the mobile sidebar closes
      setPickerOpen(false);
    }
  }, [mobileSidebarNavVisible]);

  const openPicker = () => {
    setCanHidePicker(false);
    setPickerOpen(true);

    // iOS/mobile will immediately blur the input after autoFocus because they
    // only allow the keyboard to scroll into view in response to direct user
    // interaction with the text entry field. Set a small delay to ignore that
    // first onMenuClose() event if it arrives.
    setTimeout(() => {
      setCanHidePicker(true);
    }, 200);
  };

  const handleMenuClose = () => {
    if (!canHidePicker) {
      return;
    }
    // If a selection has been made, the SelectField's onMenuClose() will fire
    // before the form's onChange(). Briefly delay the unmounting of the form
    // to give onChange() a chance to fire if necessary.
    setTimeout(() => {
      setPickerOpen(false);
    }, 100);
  };

  const handleChange = (values) => {
    if (values.picker !== value) {
      onChange(values.picker);
    }
  };

  return (
    <div className="SideNavigationPickerItem">
      <ButtonItem iconAfter={<SelectIcon />} {...props} onClick={openPicker}>
        {children}
      </ButtonItem>
      {pickerOpen && (
        <PickerForm
          options={options}
          value={value}
          onChange={handleChange}
          onMenuClose={handleMenuClose}
        />
      )}
    </div>
  );
};

export default PickerItem;
