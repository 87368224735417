/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import AssignmentIcon from 'icons/AssignmentIcon';

const messages = defineMessages({
  title: {id: 'navigation.assignments'},
});

const AssignmentsItem = () => (
  <StudentRouteLinkItem
    iconBefore={AssignmentIcon}
    route="midas/student/assignments"
    title={messages.title}
  />
);

export default AssignmentsItem;
