/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import GoalIcon from 'icons/GoalIcon';

const messages = defineMessages({
  title: {id: 'navigation.goals'},
});

const GoalsItem = () => (
  <StudentRouteLinkItem
    iconBefore={GoalIcon}
    route="midas/student/goals"
    title={messages.title}
  />
);

export default GoalsItem;
