/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect} from 'react';
import {REQUEST_IMAGE, useUserCacheContext} from 'app/UserCacheContext';

const defaultMissingImage = '/images/missing.png';

const useUserImage = (
  userID,
  imageRole,
  missingImage = defaultMissingImage
) => {
  const {images, dispatch} = useUserCacheContext();

  const userImages = images.get(userID || 0);

  useEffect(() => {
    if (userImages === undefined) {
      dispatch({type: REQUEST_IMAGE, payload: userID});
    }
  }, [userImages, userID, dispatch]);

  return (userImages && userImages[imageRole]) || missingImage;
};

export default useUserImage;
