/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import ClassroomRouteLinkItem from './ClassroomRouteLinkItem';
import ClassroomIcon from 'icons/ClassroomIcon';

const messages = defineMessages({
  title: {id: 'navigation.classroom'},
});

const ClassroomItem = () => (
  <ClassroomRouteLinkItem
    iconBefore={ClassroomIcon}
    route="midas/classroom/classroom"
    title={messages.title}
  />
);

export default ClassroomItem;
