/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const standards = {
  '': '/midas/settings/standards',
  categories: {
    '': '/midas/settings/standards/categories',
    'add-category': '/midas/settings/standards/categories/add-category',
    'edit-category':
      '/midas/settings/standards/categories/edit-category/:categoryID',
    'edit-category-inner':
      '/midas/settings/standards/categories/edit-category-inner/:standardID',
    'add-standard':
      '/midas/settings/standards/categories/add-standard/:parentID',
    'set-rubric': '/midas/settings/standards/categories/set-rubric/:standardID',
  },
  collection: {
    '': '/midas/settings/standards/collection',
    'add-collection': '/midas/settings/standards/collection/add-collection',
    'edit-collection':
      '/midas/settings/standards/collection/edit-collection/:collectionID',
    'select-collection':
      '/midas/settings/standards/collection/select-collection',
    'add-subject':
      '/midas/settings/standards/collection/add-subject/:collectionID',
    'edit-subject':
      '/midas/settings/standards/collection/edit-subject/:subjectID',
  },
  courses: {
    '': '/midas/settings/standards/courses',
    'select-standards':
      '/midas/settings/standards/courses/select-standards/:currentYearID/:courseID',
    'copy-standards':
      '/midas/settings/standards/courses/copy-standards/:currentYearID/:courseID',
  },
  'course-codes': {
    '': '/midas/settings/standards/course-codes',
    code: '/midas/settings/standards/course-codes/code/:codeID',
  },
  // districts: {
  //     '': '/midas/settings/schools/districts',
  //     'add-district': '/midas/settings/schools/districts/add-district',
  //     'edit-district':
  //     '/midas/settings/schools/districts/edit-district/:districtID',
  //     'add-school': '/midas/settings/schools/districts/add-school/:districtID',
  //     'edit-school': '/midas/settings/schools/districts/edit-school/:schoolID',
  // },
  // 'school-board': {
  //     '': '/midas/settings/schools/school-board',
  //     'add-member':
  //     '/midas/settings/schools/school-board/add-member/:schoolBoardID',
  //     'edit-member': '/midas/settings/schools/school-board/edit-member/:memberID',
  // },
  // schools: {
  //     '': '/midas/settings/schools/schools',
  //     'add-school': '/midas/settings/schools/schools/add-school/:isDistrict',
  //     'edit-school': '/midas/settings/schools/schools/edit-school/:schoolID',
  //     'edit-lea': '/midas/settings/schools/schools/edit-lea/:isDistrict',
  // },
  // levels: {
  //     '': '/midas/settings/schools/levels',
  //     'add-school-level': '/midas/settings/schools/levels/add-school-level',
  //     'add-grade-level': '/midas/settings/schools/levels/add-grade-level',
  // },
};

export default standards;
