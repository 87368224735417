/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {ButtonToolbar} from 'react-bootstrap';
import {CancelButton, SubmitButton} from 'components/form';

const SubmitCancelButtonToolbar = ({onCancel, ...props}) => {
  return (
    <ButtonToolbar {...props}>
      <SubmitButton className="mr-2" />
      <CancelButton onClick={onCancel} />
    </ButtonToolbar>
  );
};

export default SubmitCancelButtonToolbar;
