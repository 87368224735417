/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {forwardRef, useContext, useRef} from 'react';
import {uniqueId} from 'lodash';
import {useField, useFormikContext} from 'formik';
import FieldWrapper from './FieldWrapper';
import {Widget} from '@uploadcare/react-widget';
import uploadcareTabEffects from 'uploadcare-widget-tab-effects';
import './FileField.css';
import {UserContext} from 'app/UserContext';

// NOTE: This fileInfoToFileDescriptor() function MUST functionally match the
// implementation found in module/App/view/helper/form-file-field.phtml
const fileInfoToFileDescriptor = ({
  uuid,
  name,
  mimeType,
  size,
  cdnUrl,
  cdnUrlModifiers,
  crop,
  originalUrl,
  originalImageInfo,
  sourceInfo,
}) => {
  let width = undefined;
  let height = undefined;

  if (originalImageInfo) {
    for (let [key, value] of Object.entries(originalImageInfo)) {
      if (value === null) {
        delete originalImageInfo[key];
      } else if (key === 'width') {
        width = value;
        delete originalImageInfo[key];
      } else if (key === 'height') {
        height = value;
        delete originalImageInfo[key];
      }
    }
  }

  const fileDescriptor = {
    source: 'uploadcare',
    uuid,
    filename: name,
    canonicalUrl: cdnUrl,
    canonicalMetadata: {
      mediaType: mimeType,
      width: crop ? crop.width : width,
      height: crop ? crop.height : height,
      modifiers: cdnUrlModifiers || undefined,
    },
  };

  if (cdnUrl !== originalUrl) {
    fileDescriptor.originalUrl = originalUrl;
    fileDescriptor.originalMetadata = {
      mediaType: mimeType,
      byteSize: size,
      width,
      height,
      image: originalImageInfo || undefined,
      source: sourceInfo,
    };
  } else {
    fileDescriptor.canonicalMetadata.byteSize = size;
    fileDescriptor.canonicalMetadata.image = originalImageInfo || undefined;
    fileDescriptor.canonicalMetadata.source = sourceInfo;
  }

  return fileDescriptor;
};

const FileField = (
  {
    id,
    name,
    label,
    help,
    required = false,
    disabled = false,
    tabs = 'file camera url preview',
    doNotStore,
    onChange,
    ...props
  },
  ref
) => {
  const [, meta] = useField(name);
  const {isSubmitting, setFieldValue} = useFormikContext();
  const [userContext] = useContext(UserContext);

  const {current: generatedId} = useRef(uniqueId('field-'));
  id = id || generatedId;

  const isInvalid = meta.touched && meta.error && true;

  // CLEARING DIDNT' CLEAR THE VALUE!!
  const handleFileSelect = (file) => {
    if (!file) {
      setFieldValue(name, null);
    } else {
      file.done((fileInfo) => {
        setFieldValue(name, fileInfoToFileDescriptor(fileInfo));
      });
    }
  };

  return (
    <FieldWrapper
      id={id}
      label={label}
      help={help}
      required={required}
      meta={meta}
    >
      <div className={`FileField ${isInvalid ? 'is-invalid' : ''}`}>
        <Widget
          ref={ref}
          // value is explicitly NOT set here as the UC widget manages state in a unique way, and we don't leave objects in their CDN anyway
          clearable={!required}
          // TODO: figure out some solution for disabling the UI since the widget doesn't support it natively
          disabled={isSubmitting || disabled}
          publicKey="5dbc8142da85bde30db7"
          customTabs={{preview: uploadcareTabEffects}}
          cdnBase="https://cdn.midaseducation.com/"
          effects="all"
          crop="free, 16:9, 4:3, 5:4, 1:1"
          localeTranslations={
            userContext.uploadCare ? userContext.uploadCare : {}
          }
          tabs={tabs}
          doNotStore={true} // passed prop is ignored; this cannot be overridden
          previewStep={true}
          onFileSelect={handleFileSelect}
          {...props}
        />
      </div>
    </FieldWrapper>
  );
};
FileField.displayName = 'FileField';

export default forwardRef(FileField);
