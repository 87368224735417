/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from '../StudentRouteLinkItem';
import HealthProviderIcon from 'icons/HealthProviderIcon';

const messages = defineMessages({
  title: {id: 'navigation.health-providers'},
});

const ProvidersItem = () => (
  <StudentRouteLinkItem
    iconBefore={HealthProviderIcon}
    route="midas/student/health/providers"
    title={messages.title}
  />
);

export default ProvidersItem;
