/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {useEffect, useMemo} from 'react';
import {REQUEST_USER, useUserCacheContext} from 'app/UserCacheContext';

const useUsers = (userIDs, sort = undefined) => {
  const {users, dispatch} = useUserCacheContext();

  useEffect(() => {
    for (const userID of userIDs.values()) {
      const user = users.get(userID);
      if (user === undefined) {
        dispatch({type: REQUEST_USER, payload: userID});
      }
    }
  }, [userIDs, users, dispatch]);

  return useMemo(() => {
    let sortedUsers = [];
    for (const userID of userIDs.values()) {
      const user = users.get(userID);
      if (user) {
        sortedUsers.push(user);
      }
    }

    if (sort) {
      sortedUsers = sortedUsers.sort(sort);
    }

    const returnedUsers = new Map();
    for (const user of sortedUsers) {
      returnedUsers.set(user.id, user);
    }
    return returnedUsers;
  }, [userIDs, users, sort]);
};

export default useUsers;
