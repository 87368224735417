/**
 * The UserContext provides essential application-wide information about the
 * authenticated user that must be shared between most or all components, such
 * as current locale, timezone, user ID and name, selected school, etc.
 *
 * It is conceptually similar to a server-side session and should be used
 * similarly. Keep the data stored here as minimal as possible: anything that
 * can be easily computed by a child component should be; we don't want to have
 * to worry about tons of synchronization code.
 *
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {createContext, useMemo, useReducer, useEffect, useContext} from 'react';
import {callMidasJsonApi} from './api';

const initialState = {
  userID: 0,
  stateID: null,
  loginRoute: '',
  roles: {
    staff: [],
    teacher: [],
    student: [],
    guardian: [],
    publisher: [],
    pdAdministrator: [],
  },
  language: navigator.language,
  schoolID: null,
  districtID: null,
  schoolTimeZone: 'local',
  navLogoURL: '/images/midas_thumb.png',
  features: [],
  permissions: [],
  allowedSchools: [],
  allowedRoutes: [],
  uploadCare: {},
  classroom: {
    staffID: 0,
    sectionID: 0,
    schedule: [],
  },
  currentStudentID: null,
  currentStaffID: null,
  currentGuardianID: null,
  currentEventID: null,
  returnUrl: '',
};

const sortSchedule = (schedule) => {
  return schedule.sort((a, b) => {
    if (a.sectionStatus === b.sectionStatus) {
      // Same status so within the same status sort by dates/names/section numbers
      if (a.startDate !== null && b.startDate !== null) {
        // if dates are available order by start date (newest first), then course name, then section
        const aDate = new Date(a.startDate);
        const bDate = new Date(b.startDate);
        if (aDate < bDate) return 1;
        else if (aDate > bDate) return -1;
        else {
          if (a.courseName < b.courseName) return -1;
          else if (a.courseName > b.courseName) return 1;
          else {
            if (a.sectionNumber < b.sectionNumber) return -1;
            else if (a.sectionNumber > b.sectionNumber) return 1;
            else return 0;
          }
        }
      } else if (a.startDate !== null && b.startDate === null) {
        // a has a date, b does not. No dates go at the end
        return -1;
      } else if (a.startDate === null && b.startDate !== null) {
        // a has no date, b does. No date goes at the end
        return 1;
      } else {
        // Neither section has dates, sort by name and section
        if (a.courseName < b.courseName) return -1;
        else if (a.courseName > b.courseName) return 1;
        else {
          if (a.sectionNumber < b.sectionNumber) return -1;
          else if (a.sectionNumber > b.sectionNumber) return 1;
          else return 0;
        }
      }
    }
    // different statuses, so sort the three top level groups
    else if (a.sectionStatus === 'current') {
      // Current is always first
      return -1;
    } else if (
      a.sectionStatus === 'future' &&
      b.sectionStatus === 'historical'
    ) {
      // future comes before historical
      return -1;
    } else if (a.sectionStatus === 'future' && b.sectionStatus === 'current') {
      // future comes after current
      return 1;
    } else {
      // Must be historical and they always go at the end
      return 1;
    }
  });
};
const pickDefaultSectionID = (schedule) => {
  // Determine the section to selection by finding the first section with
  // start and end dates that bracket today's date
  if (schedule.length === 0) {
    return 0;
  }
  const today = new Date();
  let selectedSectionID = schedule[0].sectionID;
  for (const section of schedule) {
    if (section.startDate != null && section.endDate != null) {
      const startDate = new Date(section.startDate);
      const endDate = new Date(section.endDate);
      if (today >= startDate && today <= endDate) {
        selectedSectionID = section.sectionID;
        break;
      }
    }
  }
  return selectedSectionID;
};

export const CHANGE_LANGUAGE = 'CHANGE_LANGUAGE';
export const CHANGE_SCHOOL = 'CHANGE_SCHOOL';
export const CHANGE_CLASSROOM_STAFF_MEMBER = 'CHANGE_CLASSROOM_STAFF_MEMBER';
export const UPDATE_CLASSROOM_SECTION = 'UPDATE_CLASSROOM_SECTION';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_CURRENT_STUDENT = 'UPDATE_CURRENT_STUDENT';
export const UPDATE_CURRENT_STAFF_MEMBER = 'UPDATE_CURRENT_STAFF_MEMBER';
export const UPDATE_CURRENT_GUARDIAN = 'UPDATE_CURRENT_GUARDIAN';
export const UPDATE_CURRENT_EVENT = 'UPDATE_CURRENT_EVENT';
export const SET_RETURN_URL = 'SET_RETURN_URL';
const INIT_USER_CONTEXT = 'INIT_USER_CONTEXT';
const CLEAR_USER_CONTEXT = 'CLEAR_USER_CONTEXT';
const SET_USER_CONTEXT = 'SET_USER_CONTEXT';
const SET_SCHOOL_CONTEXT = 'SET_SCHOOL_CONTEXT';
const SET_USER_PERMISSIONS = 'SET_USER_PERMISSIONS';
const UPDATE_CLASSROOM = 'UPDATE_CLASSROOM';

const middleware = (dispatch) => {
  return (action) => {
    switch (action.type) {
      case INIT_USER_CONTEXT:
      case LOGIN_SUCCESS:
        callMidasJsonApi('/midas/rest/user-context', 'GET').then(
          ({json: userContextJson, status}) => {
            if (status === 200) {
              const setSchoolContext = callMidasJsonApi(
                '/midas/rest/school-context',
                'GET'
              ).then(({json, status}) => {
                if (status === 200) {
                  dispatch({
                    type: SET_SCHOOL_CONTEXT,
                    payload: json.schoolContext,
                  });
                } else {
                  throw new Error(
                    `Received ${status} response while attempting to fetch school context`
                  );
                }
              });

              const userPermissionsSummary = callMidasJsonApi(
                '/midas/rest/user-permissions-summary',
                'GET'
              ).then(({json, status}) => {
                if (status === 200) {
                  dispatch({
                    type: SET_USER_PERMISSIONS,
                    payload: json.userPermissions,
                  });
                } else {
                  throw new Error(
                    `Received ${status} response while attempting to fetch permissions`
                  );
                }
              });

              Promise.all([setSchoolContext, userPermissionsSummary]).then(
                () => {
                  dispatch({
                    type: SET_USER_CONTEXT,
                    payload: userContextJson.userContext,
                  });
                }
              );
            } else if (status === 401) {
              dispatch({
                type: CLEAR_USER_CONTEXT,
              });
            } else {
              throw new Error(
                `Received ${status} response while attempting to fetch user context`
              );
            }
          }
        );
        break;

      case CHANGE_SCHOOL:
        // ajax, dispatch when complete
        callMidasJsonApi(`/midas/rest/school-context/${action.payload}`, 'PUT')
          .then(({json, status}) => {
            if (status === 200) {
              dispatch({
                type: SET_SCHOOL_CONTEXT,
                payload: json.schoolContext,
              });
            } else {
              console.error(
                `Received ${status} response while attempting to change school`
              );
            }
          })
          .catch((error) => {
            console.error(`${error} error while attempting to change school`);
          });
        break;

      case CHANGE_CLASSROOM_STAFF_MEMBER:
        // ajax, dispatch when complete
        callMidasJsonApi(
          `/midas/rest/teacher-schedule/${action.payload}`,
          'GET'
        )
          .then(({json, status}) => {
            if (status === 200) {
              const sortedSchedule = sortSchedule(json.schedule);
              const selectedSectionID = pickDefaultSectionID(sortedSchedule);
              dispatch({
                type: UPDATE_CLASSROOM,
                payload: {
                  staffID: action.payload,
                  sectionID: selectedSectionID,
                  schedule: sortedSchedule,
                },
              });
            } else {
              console.error(
                `Received ${status} response while attempting to change staff member`
              );
            }
          })
          .catch((error) => {
            console.error(
              `${error} error while attempting to change staff member`
            );
          });
        break;

      default:
        dispatch(action);
    }
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case CLEAR_USER_CONTEXT:
      return {...initialState};

    case SET_USER_CONTEXT:
      return {
        ...state,
        userID: action.payload.userID,
        stateID: action.payload.stateID,
        loginRoute: action.payload.loginRoute,
        roles: action.payload.roles,
      };

    case SET_SCHOOL_CONTEXT:
      return {
        ...state,
        schoolID: action.payload.schoolID,
        stateID: action.payload.stateID,
        districtID: action.payload.districtID,
        schoolTimeZone: action.payload.timeZone,
        navLogoURL: action.payload.navLogoURL,
        features: action.payload.features,
        uploadCare: action.payload.uploadCare,
        classroom: {
          staffID: 0,
          sectionID: 0,
          schedule: [],
        },
      };

    case SET_USER_PERMISSIONS:
      return {
        ...state,
        permissions: action.payload.permissions,
        allowedSchools: action.payload.allowedSchools,
        allowedRoutes: action.payload.allowedRoutes,
      };

    case UPDATE_CLASSROOM:
      return {
        ...state,
        classroom: action.payload,
      };

    case UPDATE_CLASSROOM_SECTION:
      if (state.classroom.sectionID === action.payload) {
        return state;
      }
      let isValid = false;
      for (let section of state.classroom.schedule) {
        if (section.sectionID === action.payload) {
          isValid = true;
          break;
        }
      }
      if (!isValid) {
        return state;
      }
      const classroom = {
        ...state.classroom,
        sectionID: action.payload,
      };
      return {
        ...state,
        classroom,
      };

    case UPDATE_CURRENT_STUDENT:
      if (state.currentStudentID === action.payload) {
        return state;
      }
      return {
        ...state,
        currentStudentID: action.payload,
      };

    case UPDATE_CURRENT_STAFF_MEMBER:
      if (state.currentStaffID === action.payload) {
        return state;
      }
      return {
        ...state,
        currentStaffID: action.payload,
      };

    case UPDATE_CURRENT_GUARDIAN:
      if (state.currentGuardianID === action.payload) {
        return state;
      }
      return {
        ...state,
        currentGuardianID: action.payload,
      };

    case UPDATE_CURRENT_EVENT:
      if (state.currentEventID === action.payload) {
        return state;
      }
      return {
        ...state,
        currentEventID: action.payload,
      };

    case SET_RETURN_URL:
      return {
        ...state,
        returnUrl: action.payload,
      };

    default:
      return state;
  }
};

export const UserContext = createContext(undefined);

export const UserContextProvider = ({children}) => {
  const [context, reducerDispatch] = useReducer(reducer, initialState);
  const dispatch = useMemo(() => middleware(reducerDispatch), [
    reducerDispatch,
  ]);

  useEffect(() => {
    dispatch({type: INIT_USER_CONTEXT});
  }, [dispatch]);

  return (
    <UserContext.Provider value={[context, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error('userContext must be used within a UserContextProvider');
  }
  return context;
};
