/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import DemographicsIcon from 'icons/DemographicsIcon';

const messages = defineMessages({
  title: {id: 'navigation.demographics'},
});

const DemographicsItem = () => (
  <StudentRouteLinkItem
    iconBefore={DemographicsIcon}
    route="midas/student/demographics"
    title={messages.title}
  />
);

export default DemographicsItem;
