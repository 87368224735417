/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import {SHOW_HELP_PANEL, useNavigationContext} from 'app/NavigationContext';
import {useFeature} from 'app/hooks';
import {DISPLAY_HELP_DOCS} from 'app/features';
import ProductNavigationButton from './ProductNavigationButton';
import HelpIcon from 'icons/HelpIcon';

const messages = defineMessages({
  title: {id: 'navigation.help'},
});

const HelpItem = () => {
  const {dispatch, dismissMobileSidebar} = useNavigationContext();
  const displayHelpDocs = useFeature(DISPLAY_HELP_DOCS);

  if (!displayHelpDocs) {
    return null;
  }

  const handleClick = () => {
    dispatch({type: SHOW_HELP_PANEL});
    dismissMobileSidebar();
  };

  return (
    <ProductNavigationButton
      onClick={handleClick}
      title={messages.title}
      Icon={HelpIcon}
    />
  );
};

export default HelpItem;
