/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import PhotoIcon from 'icons/PhotoIcon';

const messages = defineMessages({
  title: {id: 'navigation.photo'},
});

const PhotoItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={PhotoIcon}
    route="midas/staff-member/photo"
    title={messages.title}
  />
);

export default PhotoItem;
