/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StudentRouteLinkItem from './StudentRouteLinkItem';
import FamilyIcon from 'icons/FamilyIcon';

const messages = defineMessages({
  title: {id: 'navigation.family'},
});

const FamilyItem = () => (
  <StudentRouteLinkItem
    iconBefore={FamilyIcon}
    route="midas/student/family"
    title={messages.title}
  />
);

export default FamilyItem;
