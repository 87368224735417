/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import EndOfYearIcon from 'icons/EndOfYearIcon';
import PromoteStudentsItem from './end-of-year/PromoteStudentsItem';
import PromotionSettingsItem from './end-of-year/PromotionSettingsItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.end-of-year'},
  sectionTitle: {id: 'navigation.settings.end-of-year-settings'},
});

const EndOfYearMenu = () => (
  <NavigationMenu
    iconBefore={EndOfYearIcon}
    route="midas/settings/end-of-year"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <PromotionSettingsItem />
    <PromoteStudentsItem />
  </NavigationMenu>
);

export default EndOfYearMenu;
