/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import RouteLinkItem from 'components/navigation/side-navigation/item/RouteLinkItem';
import GoalIcon from 'icons/GoalIcon';

const messages = defineMessages({
  title: {id: 'navigation.goals'},
});

const GoalsItem = () => (
  <RouteLinkItem
    iconBefore={GoalIcon}
    route="midas/my/goals"
    title={messages.title}
  />
);

export default GoalsItem;
