/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import HealthIcon from 'icons/HealthIcon';
import AllergensItem from './health/AllergensItem';
import GuidanceItem from './health/GuidanceItem';
import ScreeningsItem from './health/ScreeningsItem';
import VisitLogItem from './health/VisitLogItem';
import ImmunizationsItem from './health/ImmunizationsItem';
import MedicationLogItem from './health/MedicationLogItem';

const messages = defineMessages({
  title: {id: 'navigation.health'},
});

const HealthMenu = () => (
  <NavigationMenu
    iconBefore={HealthIcon}
    route="midas/students/health"
    title={messages.title}
  >
    <AllergensItem />
    <GuidanceItem />
    <ScreeningsItem />
    <VisitLogItem />
    <ImmunizationsItem />
    <MedicationLogItem />
  </NavigationMenu>
);

export default HealthMenu;
