/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

const instructionalMaterials = {
  '': '/midas/instructional-materials',
  materials: {
    '': '/midas/instructional-materials/materials',
    detail: '/midas/instructional-materials/materials/detail/:materialID',
    'download-csv': '/midas/instructional-materials/materials/download-csv',
    'edit-review-collection':
      '/midas/instructional-materials/materials/edit-review-collection/:collectionID',
    'view-review-collection':
      '/midas/instructional-materials/materials/view-review-collection/:collectionID',
    'evaluate-review-collection':
      '/midas/instructional-materials/materials/evaluate-review-collection/:collectionID',
    'add-instructional-material':
      '/midas/instructional-materials/materials/add-instructional-material',
    'add-pricing-model':
      '/midas/instructional-materials/materials/:materialID/add-pricing-model',
    'related-materials':
      '/midas/instructional-materials/materials/:materialID/related-materials',
  },
  'bid-cycles': {
    '': '/midas/instructional-materials/bid-cycles',
    'add-bid-cycle': '/midas/instructional-materials/bid-cycles/add-bid-cycle',
    detail: {
      '': '/midas/instructional-materials/bid-cycles/detail/:bidCycleID',
      'add-reviewer':
        '/midas/instructional-materials/bid-cycles/detail/:bidCycleID/add-reviewer',
    },
  },
  publishers: {
    '': '/midas/instructional-materials/publishers',
    'add-publisher': '/midas/instructional-materials/publishers/add-publisher',
    'edit-publisher':
      '/midas/instructional-materials/publishers/edit-publisher/:publisherID',
    'add-representative':
      '/midas/instructional-materials/publishers/:publisherID/add-representative',
    'edit-representative':
      '/midas/instructional-materials/publishers/edit-representative/:publisherID/:userID',
  },
  appeals: {
    '': '/midas/instructional-materials/appeals',
    'update-appeal':
      '/midas/instructional-materials/appeals/update-appeal/:appealID',
  },
  substitutions: {
    '': '/midas/instructional-materials/substitutions',
    'update-substitution':
      '/midas/instructional-materials/substitutions/update-substitution/:substitutionID',
  },
  authors: {
    '': '/midas/instructional-materials/authors',
    'add-author': '/midas/instructional-materials/authors/add-author',
    'edit-author':
      '/midas/instructional-materials/authors/edit-author/:authorID',
  },
  'media-types': {
    '': '/midas/instructional-materials/media-types',
    'add-media-type':
      '/midas/instructional-materials/media-types/add-media-type',
    'edit-media-type':
      '/midas/instructional-materials/media-types/edit-media-type/:mediaTypeID',
  },
  'adoption-types': {
    '': '/midas/instructional-materials/adoption-types',
    'add-adoption-type':
      '/midas/instructional-materials/adoption-types/add-adoption-type',
    'edit-adoption-type':
      '/midas/instructional-materials/adoption-types/edit-adoption-type/:adoptionTypeID',
  },
  rubrics: {
    '': '/midas/instructional-materials/rubrics',
    'add-rubric': '/midas/instructional-materials/rubrics/add-rubric',
    'edit-rubric':
      '/midas/instructional-materials/rubrics/edit-rubric/:rubricID',
    'add-heading':
      '/midas/instructional-materials/rubrics/:rubricID/add-heading',
    'edit-heading':
      '/midas/instructional-materials/rubrics/edit-heading/:headingID',
    'add-item': '/midas/instructional-materials/rubrics/:rubricID/add-item',
    'edit-item': '/midas/instructional-materials/rubrics/edit-item/:itemID',
    'edit-item-descriptions':
      '/midas/instructional-materials/rubrics/edit-item-descriptions/:itemID/:gradingScaleID',
  },
};

export default instructionalMaterials;
