/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import NavigationMenu from '../NavigationMenu';
import HealthIcon from 'icons/HealthIcon';
import ActionsItem from './health/ActionsItem';
import AssessmentsItem from './health/AssessmentsItem';
import ConcernsItem from './health/ConcernsItem';
import ConditionsItem from './health/ConditionsItem';
import LogsItem from './health/LogsItem';
import MedicationsItem from './health/MedicationsItem';
import OutcomesItem from './health/OutcomesItem';
import ProvidersItem from './health/ProvidersItem';
import SymptomsItem from './health/SymptomsItem';
import TemplatesItem from './health/TemplatesItem';

const messages = defineMessages({
  title: {id: 'navigation.settings.health'},
  sectionTitle: {id: 'navigation.settings.health-settings'},
});

const HealthMenu = () => (
  <NavigationMenu
    iconBefore={HealthIcon}
    route="midas/settings/health"
    title={messages.title}
    sectionTitle={messages.sectionTitle}
  >
    <ActionsItem />
    <AssessmentsItem />
    <ConcernsItem />
    <ConditionsItem />
    <LogsItem />
    <MedicationsItem />
    <OutcomesItem />
    <ProvidersItem />
    <SymptomsItem />
    <TemplatesItem />
  </NavigationMenu>
);

export default HealthMenu;
