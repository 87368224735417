/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import {defineMessages} from 'react-intl';
import StaffMemberRouteLinkItem from './StaffMemberRouteLinkItem';
import GrowthResourcesIcon from 'icons/GrowthResourcesIcon';

const messages = defineMessages({
  title: {id: 'navigation.growth-resources'},
});

const GrowthResourcesItem = () => (
  <StaffMemberRouteLinkItem
    iconBefore={GrowthResourcesIcon}
    route="midas/staff-member/growth-resources"
    title={messages.title}
  />
);

export default GrowthResourcesItem;
