/**
 * @copyright Copyright MIDAS Eduction, LLC. (https://www.midaseducation.com/)
 */

import behavior from './students/behavior';
import courses from './students/courses';
import equipment from './students/equipment';
import scheduling from './students/scheduling';
import health from './students/health';
import sections from './students/sections';
import masterCatalog from './students/master-catalog';

const students = {
  '': '/midas/students',
  add: '/midas/students/add',
  edit: '/midas/students/edit/:studentID',
  search: '/midas/students/search',
  activities: {
    '': '/midas/students/activities',
    'add-activity': '/midas/students/activities/add-activity',
    'edit-activity': '/midas/students/activities/edit-activity/:activityID',
    'add-members': '/midas/students/activities/add-members/:activityID',
    'edit-member': '/midas/students/activities/edit-member/:activityID',
  },
  'ad-hoc-schedule': '/midas/students/ad-hoc-schedule',
  'assign-counselors': '/midas/students/assign-counselors',
  attendance: {
    '': '/midas/students/attendance',
    alerts: '/midas/students/attendance/alerts/:studentID',
    'user-data': '/midas/students/attendance/user-data/:studentID/:userType',
  },
  alerts: {
    '': '/midas/students/alerts',
    'add-alert': '/midas/students/alerts/add-alert',
    'edit-alert': '/midas/students/alerts/edit-alert/:alertID',
    'add-student-alert': '/midas/students/alerts/:alertID/add-student-alert',
    'edit-student-alert':
      '/midas/students/alerts/edit-student-alert/:studentAlertID',
  },
  behavior,
  'link-guardian': '/midas/students/link-guardian/:studentID',
  'edit-guardian-permissions':
    '/midas/students/edit-guardian-permissions/:studentID/:guardianID',
  'response-to-intervention': {
    '': '/midas/students/response-to-intervention',
    'refer-student': '/midas/students/response-to-intervention/refer-student',
    'edit-referral':
      '/midas/students/response-to-intervention/edit-referral/:referralID',
    'add-submission':
      '/midas/students/response-to-intervention/add-submission/:referralID',
    'edit-submission':
      '/midas/students/response-to-intervention/edit-submission/:submissionID',
    'view-submission':
      '/midas/students/response-to-intervention/view-submission/:submissionID',
    'view-comments':
      '/midas/students/response-to-intervention/view-comments/:submissionID',
    'view-all-comments':
      '/midas/students/response-to-intervention/view-all-comments/:referralID',
    'view-status-changes':
      '/midas/students/response-to-intervention/view-status-changes/:submissionID',
  },
  grants: {
    '': '/midas/students/grants',
    'add-grant': '/midas/students/grants/add-grant',
    'edit-grant': '/midas/students/grants/edit-grant/:grantID',
  },
  homerooms: {
    '': '/midas/students/homerooms',
    'add-section': '/midas/students/homerooms/add-section/:courseID',
    'enroll-students': '/midas/students/homerooms/enroll-students/:sectionID',
  },
  'houses-and-teams': {
    '': '/midas/students/houses-and-teams',
    'add-house-or-team': '/midas/students/houses-and-teams/add-house-or-team',
    'edit-house-or-team':
      '/midas/students/houses-and-teams/edit-house-or-team/:groupID',
    'add-members':
      '/midas/students/houses-and-teams/add-members/:groupID/:yearID',
    'update-members':
      '/midas/students/houses-and-teams/update-members/:memberID/:isModerator',
  },
  'master-catalog': masterCatalog,
  scheduling,
  courses,
  sections,
  equipment,
  health,
};

export default students;
